import { async } from "@firebase/util";
import { signInWithGoogle, registerUserWithEmailPassword, loginWithEmailPassword, logoutFirebase } from "../../firebase/providers";
import { checkingCredentials, clearErrorMessage, isVerifying, login, logout } from "./authSlice"
import { startNewClearProfile } from "../slices/profile/thunks";
import { useEffect } from "react";
import { loadProfile } from "../../helpers/loadProfiles";
import { getAuth, sendEmailVerification } from "firebase/auth";
import Swal from 'sweetalert2';

export const checkingAuthentication = (email, password) => {
    return async (dispatch) => {
        dispatch(checkingCredentials());
    }
}

export const startGoogleSignIn = () => {
    return async (dispatch) => {
        dispatch(checkingCredentials());
        const result = await signInWithGoogle();

        if (!result.ok) dispatch(logout(result.errorMessage))

        const profile = await loadProfile(result.uid);

        if (profile) {
            dispatch(login(result))
        }
    }
}

export const startCreatingUserWithEmailPassword = ({ email, password, displayName, dominio, existingDomain, existingIdCompany }) => {
    return async (dispatch) => {
        dispatch(checkingCredentials());
        let userType = sessionStorage.getItem('userType');
        const { ok, uid, photoURL, errorMessage, emailVerified } = await registerUserWithEmailPassword({ email, password, displayName, photoURL: userType, dominio, existingDomain, existingIdCompany });

        if (!ok) return dispatch(logout({ errorMessage }));

        if (emailVerified) {
            const profile = await loadProfile(uid);
            dispatch(login({ uid, displayName, email, photoURL, emailVerified }));
        } else {
            isVerifying({});
        }

        // if (ok) {
        //     const newProfile = {
        //         email,
        //         displayName,
        //         userType
        //     }
        //     dispatch(startNewClearProfile(newProfile))
        // }
    }
}

export const startLoginWithEmailPassword = ({ email, password }) => {
    return async (dispatch) => {
        dispatch(checkingCredentials());

        const result = await loginWithEmailPassword({ email, password });

        if (!result.ok) return dispatch(logout(result));
        const profile = await loadProfile(result.uid);

        let stateResult = {};
        let isVerified = false;
        if (profile && result) {

            const {
                displayName,
                email,
                uid,
                emailVerified
            } = result

            isVerified = emailVerified;
            const {
                photoURL
            } = profile[0]

            stateResult = { displayName, uid, email, photoURL, emailVerified };
        }
        if (profile) {
            if (isVerified) {
                dispatch(login(stateResult));
            } else {
                Swal.fire({
                    icon: 'Error',
                    title: 'Error',
                    text: 'Debes verificar el correo para poder ingresar'
                })
                dispatch(isVerifying({}));
            }

        }

    }

}

export const startLogout = () => {
    return async (dispatch) => {
        await logoutFirebase();

        dispatch(logout({}));
    }
}

