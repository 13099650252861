import { collection, getDocs, query } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";

export const loadNoitification = async (uid = '') => {
        if (!uid) throw new Error('El UID del usuario no existe');
    
        const q = query(
            collection(FirebaseDB, 'notification'),
            // where('status', '==', 'Created')
        );
    
        const docs = await getDocs(q);
    
        const notifications = [];
        docs.forEach(doc => {
            notifications.push({ id: doc.id, ...doc.data() });
        })
    
        return notifications;
    }
