import { Box, Tab, Tabs, Modal, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { PreviewProfile } from './PreviewProfile';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 350,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 0,
};

export const AllCandidatesEmployer = ({ employeeProfiles }) => {
    const [previewProfile, setPreviewProfile] = useState(false);
    const { profile } = useSelector(state => state.profile);
    const { jobs } = useSelector(state => state.job);
    const [selectedProfile, setSelectedProfile] = useState([]);
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { uid } = useParams();

    useEffect(() => {
        if (profile.length > 0) {
            //Obtengo jobs de la empresa
            let filteredJobs = jobs.filter(item => item.companyId === profile[0].idCompany);
            const filteredJobIds = filteredJobs.map(job => job.id);
            // Filtrar los empleados que están aplicando a los trabajos de la empresa especificada
            const filteredEmployees = employeeProfiles.filter(profile => {
                if (profile.applyingJob && Array.isArray(profile.applyingJob)) {
                    for (let i = 0; i < profile.applyingJob.length; i++) {
                        if (filteredJobIds.includes(profile.applyingJob[i].jobId)) {
                            return true;
                        }
                    }
                }
                return false;
            });
            setFilteredEmployees(filteredEmployees);
        }
    }, [jobs, employeeProfiles, profile])

    useEffect(() => {

    }, [previewProfile])

    useEffect(() => {
        if (previewProfile && selectedProfile && selectedProfile.id) {
            navigate(`/candidates/${selectedProfile.id}`);
        }
    }, [previewProfile, selectedProfile?.id]);


    const onSelect = (data) => {
        setSelectedProfile(data);
        setPreviewProfile(true);
    }

    const handleSelectedProfile = () => {
        setPreviewProfile(false);
    }

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    const great = () => {
        console.log("WW")
    }

    return (
        <>
            {previewProfile &&
                <PreviewProfile

                    data={selectedProfile} />
            }
            {!previewProfile &&
                <div className='w-full pl-20 pr-20'>
                    <div className='w-full flex flex-row pt-14'>
                        <TextField
                            id='search'
                            className='w-full'
                            placeholder={t("candidate.search")}
                            onChange={(e) => setSearch(e.target.value)}
                            value={search}
                            InputProps={{
                                style: {
                                    borderRadius: '32px',
                                    border: '1px solid #D7DEE1',
                                    background: '#FFF'
                                }
                            }}
                        />
                        <img className='absolute ml-[75%] mt-4 w-6 h-6' src='../assets/icons/search.png' alt='' />
                    </div>

                    {filteredEmployees && filteredEmployees.filter((item) => {
                        return search.toLowerCase() === '' ? item : item.displayName.toLowerCase().includes(search)
                    }).map((item, index) => (
                        // const { pages } = item;
                        <div key={index} className='w-full flex flex-row justify-center pt-6 h-20' onClick={() => onSelect(item)}>
                            <div className='w-full flex flex-row bg-white rounded-3xl border opacity-90 border-[#818181]' >
                                <div className='w-4/12 flex'>
                                    <div className='w-1/3'>
                                        {item.pages.profileImageUrl &&
                                            <div className='pt-1 pl-1'>
                                                <img className=' w-12 h-12 rounded-full' src={item.pages.profileImageUrl} alt='' />
                                            </div>
                                        }
                                        {!item.pages.profileImageUrl &&
                                            <div className='pt-1 pl-1'>
                                                <img className=' w-12 h-12 rounded-full' src="../assets/images/login-user-default.png" alt='' />
                                            </div>
                                        }
                                    </div>
                                    <div className='w-2/3 pt-2'>
                                        <div className='flex flex-row w-full'>
                                            <p className="text-zinc-800 text-base font-medium">{item.displayName}</p>
                                        </div>
                                        <div className='flex flex-row w-full'>
                                            <p className="text-gray-600 text-xs font-normal">Location, GT</p>
                                            {/* <div className="w-12 text-right justify-end h-px origin-top-left rotate-90 border border-neutral-300 absolute pl-2" style={{ top: 8, }}></div> */}
                                        </div>
                                    </div>
                                    <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 mt-1"></div>
                                </div>
                                <div className='w-4/12 flex'>
                                    <div className='flex'>
                                        <div className='pt-1'>
                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=c6f44aae-7902-45d1-b48f-fb097b8a0912" />
                                        </div>
                                        <div className='pt-1 pl-1'>
                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%203.png?alt=media&token=45b35fbf-9ff1-45f9-a048-63d328284a7c" />
                                        </div>
                                        <div className='pt-1 pl-1'>
                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%204.png?alt=media&token=1ec44765-7d7a-4d56-8e04-c0976483e366" />
                                        </div>
                                        <div className='pt-1 pl-1'>
                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%205.png?alt=media&token=260be5f7-9283-4104-a958-831df00d71dc" />
                                        </div>
                                        <div className='pt-1 pl-1 pr-6'>
                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%206.png?alt=media&token=19cb9f22-4cae-4903-90c9-137dd3e3ab7c" />
                                        </div>
                                        <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 mt-1"></div>
                                    </div>
                                </div>

                                <div className='w-4/12 flex justify-center'>
                                    {/*<div className='w-1/5 pt-2.5'>
                                        <div className='w-8 h-8 pl-0.5 rounded-full border border-slate-800 items-center justify-center'>
                                            <img className=" w-6 h-6 mt-1 text-center items-center justify-center" src="../assets/icons/thumbs-down.png" />
                                        </div>
                                    </div>
                                    <div className='w-1/5 pt-2.5'>
                                        <div className='w-8 h-8 pl-0.5 rounded-full border border-slate-800 items-center justify-center'>
                                            <img className="w-6 h-6 mt-1 rounded-full m-auto inset-0" src="../assets/icons/thumbs-up.png" />
                                        </div>
                                    </div>*/}
                                    <div className='w-3/5 pt-3 flex'>
                                        {/**<img className=' absolute pl-4 mt-1' src='../assets/icons/star.png' alt='' />*/}
                                        <button id='great' type='button' className="text-slate-800 w-28 h-8 text-sm font-normal rounded-3xl border border-slate-800"> {t("candidate.preview")} </button>
                                    </div>
                                    {/*  <div className="w-12 h-px ml-4 origin-top-left rotate-90 border border-neutral-300 mt-1"></div>*/}
                                </div>
                                {/* <div className='w-2/12 pt-3 flex'>
                                    <img className='absolute ml-3 mt-1 w-6 h-6' src='../assets/icons/envelope-star.png' alt='' />
                                    <button id='invite' onClick={handleOpen} type='button' className="text-slate-800 w-28 h-8 pl-2 text-sm font-normal rounded-3xl border border-slate-800">Invite</button>
                                    </div>*/}
                            </div>
                        </div>
                    ))

                    }

                </div>
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full text-left'>
                        <div className="w-full flex h-20 pt-4 bg-zinc-100 rounded-tl-lg pl-6 rounded-tr-lg">
                            <div>{selectedProfile.pages &&
                                <img className="w-14 h-14 rounded-full" src={selectedProfile.pages.profileImageUrl ? selectedProfile.pages.profileImageUrl : ''} />
                            }
                            </div>
                            <div className='pl-2 pt-1'>
                                <p className=' text-zinc-800 font-bold text-[18px]'>Invite to Apply for a Job</p>
                                <p className="text-gray-600 text-sm font-medium">{selectedProfile.displayName}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row mt-14 pl-10 pr-8'>
                        <div className='w-full'>
                            <FormControl className='w-11/12'>
                                <InputLabel id="active-job-label">Select an Active Job</InputLabel>
                                <Select
                                    labelId="active-job-label"
                                    id="active-job"
                                    label='Select an Active Job'
                                    placeholder='Select an Active Job'
                                    defaultValue=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                // onChange={handleChangeLanguage}
                                // value={language}
                                >
                                    {jobs.map((job, index) => (
                                        <MenuItem key={index} value={job.jobtitle}>
                                            {job.jobtitle}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className='flex flex-row mt-14 pb-12 justify-center'>
                        {/* <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleSubmit(onSubmit)}>Save</button> */}
                        <div className="w-68 h-12 pl-8 pr-7 py-1 bg-orange-500 rounded-3xl justify-center items-center gap-2.5 inline-flex">
                            <button type='button' onClick={handleClose} className="text-white text-base font-medium">Invite Now</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
