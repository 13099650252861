import React, { useState, useEffect } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useForm } from 'react-hook-form';

const hours = [
    { name: '8:00 a.m.', value: '8' },
    { name: '9:00 a.m.', value: '9' },
    { name: '10:00 a.m.', value: '10' },
    { name: '11:00 a.m.', value: '11' },
    { name: '12:00 p.m.', value: '12' },
    { name: '1:00 p.m.', value: '1' },
    { name: '2:00 p.m.', value: '2' },
    { name: '3:00 p.m.', value: '3' },
    { name: '4:00 p.m.', value: '4' },
    { name: '5:00 p.m.', value: '5' }
]

export const VideoInterViewStage = ({ sendUpdate, data }) => {
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [startHour, setStartHour] = useState('');
    const [endHour, setEndHour] = useState('');
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            date: "",
            startHour1: '',
            endHour1: ''
        }
    });

    useEffect(() => {

    }, [data])

    const handleChangeStartTime = (event) => {
        const {
            target: { value },
        } = event;
        setStartHour(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeEndTime = (event) => {
        const {
            target: { value },
        } = event;
        setEndHour(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const onSubmit = (data) => {
        console.log('entra a onSubmit', data);
        setValue('date', startDate);
        setValue('startHour1', startHour);
        setValue('endHour1', endHour);
        console.log('data', data);
        sendUpdate(6, data);
    }

    return (
        <>
            <div className="w-full h-24 bg-zinc-100 flex">
                <div className='p-4'>
                    <img className="w-14 h-14 rounded-full" src={(data.profileImageUrl.length > 0) ? data.profileImageUrl : "https://via.placeholder.com/59x59"} />
                </div>
                <div className='pt-6'>
                    <div className="text-slate-800 text-lg font-bold font-['Poppins']">Schedule Video Interview</div>
                    <div className="text-gray-600 text-sm font-medium font-['Poppins']">{data?.displayName}</div>
                </div>
            </div>
            <div className='w-full'>
                <div>
                    <div className='w-full pt-4 pl-10 pr-4'>
                        <p className="text-gray-600 text-sm font-medium font-['Poppins']">Crear entrevista automática para el candidato, al finalizar la entrevista verás la opción para confirmar o rechazar al candidato. </p><br /><p>Ingresa la fecha máxima para la entrevista.</p>
                    </div>
                    <div className='flex flex-row mt-6  pl-12 pr-12'>
                        <div className='w-full justify-center'>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker value={startDate} onChange={(newValue) => setStartDate(newValue)} className='w-11/12' />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>

                    </div>
                    <div className='flex flex-row mt-4 pl-12 pr-12'>
                        {/* <div className='w-1/2 pr-2'>
                            <FormControl className='w-11/12'>
                                <Select
                                    labelId='start-hour-label'
                                    id='startHour'
                                    label='startTime'
                                    defaultValue=''
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={startHour}
                                    onChange={handleChangeStartTime}
                                >
                                    {hours.map((name) => (
                                        <MenuItem key={name.value} value={name.value}>
                                            {name.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div> */}
                        {/* <div className='w-1/2 pr-4'>
                            <FormControl className='w-11/12'>
                                <Select
                                    labelId='end-hour-label'
                                    id='endHour'
                                    label='endTime'
                                    defaultValue=''
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={endHour}
                                    onChange={handleChangeEndTime}
                                >
                                    {hours.map((name) => (
                                        <MenuItem key={name.value} value={name.value}>
                                            {name.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div> */}
                    </div>
                    <div className='flex flex-row'>
                        <div className='w-full justify-center items-center text-center pt-16'>
                            <button className="w-72 h-14 px-8 py-1 bg-orange-500 rounded-3xl justify-center items-center gap-2.5 inline-flex text-white text-base font-medium font-['Poppins']"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Request an Interview
                            </button>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}
