import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";

export const loadMessages = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'message'),
        where('uidSender', '==', uid)
    );

    const q2 = query(
        collection(FirebaseDB, 'message'),
        where('uidReceived', '==', uid)
    );

    const docs = await getDocs(q);
    const docs2 = await getDocs(q2);

    const messages = [];
    docs.forEach(doc => {
        messages.push({ id: doc.id, ...doc.data() })
    })
    docs2.forEach(doc => {
        messages.push({ id: doc.id, ...doc.data() })
    })

    // Filtrar duplicados si es necesario
    const uniqueMessages = messages.filter((value, index, self) =>
        index === self.findIndex((t) => (
            t.id === value.id
        ))
    );

    return uniqueMessages;
}