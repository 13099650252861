import { CheckBox } from '@mui/icons-material'
import { TextField } from '@mui/material'
import React, { useState } from 'react'
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';

export const Billing = () => {
    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;

        setState((prev) => ({ ...prev, [name]: value }));
    }

    const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
    }

    return (
        <>
            <div className='w-full min-h-[390px]'>
                <div className='w-full flex flex-row pl-20 pr-20'>
                    <div className='w-1/3'>
                        <div className="w-64 h-80 bg-white rounded--lg border border-orange-500">
                            <div className="text-slate-800 text-lg font-bold">Basic</div>
                            <div className="text-zinc-800 text-xs font-medium">Great for hiring on a budget</div>
                            <div className="w-56 ml-4 mt-2 h-px border border-neutral-300 text-center justify-center items-center"></div>
                            <div className="text-slate-800 text-sm font-medium">Benefit 01</div>
                            <div className="w-56 ml-4 h-px border border-neutral-300 mt-48"></div>
                            <div className="text-slate-800 text-lg font-bold">Free -  Current Plan</div>
                        </div>
                    </div>
                    <div className='w-1/3 pl-8'>
                        <div className="w-64 h-80 bg-white rounded--lg border border-lime-400">
                            <div className="text-sky-900 text-lg font-bold">Standard</div>
                            <div className="text-zinc-800 text-xs font-medium">Great for hiring on a budget</div>
                            <div className="w-56 ml-4 mt-2 h-px border border-neutral-300 text-center justify-center items-center"></div>
                            <div className="text-slate-800 text-sm font-medium">Benefit 01</div>
                            <div className="text-slate-800 text-sm font-medium">Benefit 02</div>
                            <div className="text-slate-800 text-sm font-medium">Benefit 03</div>
                            <div className="w-56 ml-4 h-px border border-neutral-300 mt-40"></div>
                            <div className='w-full'>
                                <p className=" text-sky-900 text-lg font-bold">$100.00/month</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/3 pl-8'>
                        <div className="w-64 h-80 bg-white rounded--lg border border-sky-900">
                            <div className="text-sky-900 text-lg font-bold">Pro</div>
                            <div className="text-zinc-800 text-xs font-medium">The best way to hire fast</div>
                            <div className="w-56 ml-4 mt-2 h-px border border-neutral-300 text-center justify-center items-center"></div>
                            <div className="text-slate-800 text-sm font-medium">Benefit 01</div>
                            <div className="text-slate-800 text-sm font-medium">Benefit 02</div>
                            <div className="text-slate-800 text-sm font-medium">Benefit 03</div>
                            <div className="text-slate-800 text-sm font-medium">Benefit 04</div>
                            <div className="text-slate-800 text-sm font-medium">Benefit 05</div>
                            <div className="w-56 ml-4 h-px border border-neutral-300 mt-32"></div>
                            <div className='w-full'>
                                <p className=" text-sky-900 text-lg font-bold">$100.00/month</p>
                                {/* <p className="text-sky-900 text-xs font-bold">/month</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full flex mt-4 pl-4 min-h-[500px]'>
                <div className='w-1/2'>
                    <div className='w-full flex flex-row justify-between pl-12 pr-12'>
                        <div className='w-1/2 text-left'>
                            <p className="text-zinc-800 text-[18px] font-bold">Pages</p>
                        </div>
                        <div className='w-1/2 text-right'>
                            <button><img className=" w-5 h-5" src="../assets/icons/edit.png" alt='' /></button>
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-4'>
                        <div className='w-full'>
                            <TextField
                                className='w-10/12'
                                placeholder='Name on Card'
                                type="name"
                                name="name"
                                value={state.name}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-4'>
                        <div className='w-full'>
                            <TextField
                                className='w-10/12'
                                placeholder='Credit Card Number'
                                type="number"
                                name="number"
                                value={state.number}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                inputProps={{ maxLength: 24 }}
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-4 pl-6 pr-6'>
                        <div className='w-full'>
                            <TextField
                                className='w-10/12'
                                placeholder='Exp. Date MM/YYYY'
                                type="expiry"
                                name="expiry"
                                value={state.expiry}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                            />
                        </div>
                        <div className='w-full'>
                            <TextField
                                className='w-10/12'
                                placeholder='CVV'
                                type="cvc"
                                name="cvc"
                                value={state.cvc}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-4'>
                        <div className='w-full'>
                            <TextField
                                className='w-10/12'
                                placeholder='Billing Address'
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-4 pl-10 pr-4'>
                        <div className='w-1/3'>
                            <TextField
                                className='w-11/12'
                                placeholder='Zip Code'
                            />
                        </div>
                        <div className='w-2/3'>
                            <TextField
                                className='w-10/12'
                                placeholder='State'
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-4 pl-20 flex-wrap'>
                        <CheckBox />
                        <p className="w-96 h-7 text-gray-600 text-xs font-medium">I understand that, after the free trial, I will be charged $35.00 USD per day per active job. I'll be billed monthly, when my account reaches $500, or upon cancellation, whichever comes first. I can cancel at any time.</p>
                    </div>
                </div>
                <div className='w-1/2'>
                    {/* <div className='w-full flex justify-center items-center text-center pt-10'>
                        <img className=" w-[358px] h-[214px] bg-gradient-to-r from-black to-neutral-700 rounded-3xl" src='../assets/icons/card.png' />
                    </div> */}
                    <Cards
                        number={state.number}
                        expiry={state.expiry}
                        cvc={state.cvc}
                        name={state.name}
                        focused={state.focus}
                    />
                </div>
            </div>
        </>
    )
}

