import { FormControl, InputLabel, MenuItem, Select, TextField, Modal, Checkbox, ListItemText, Box, Typography, OutlinedInput, Autocomplete } from '@mui/material'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Webcam from 'react-webcam';
import CountrySelect from '../../utils/CountrySelect';
import { WorkExperienceEmployee } from './WorkExperienceEmployee';
import { EducationEmployee } from './EducationEmployee';
import { CertificatesEmployee } from './CertificatesEmployee';
import { startLoadingProfile, startNewProfile, startSaveProfile } from '../../store/slices/profile/thunks';
import Swal from 'sweetalert2';
import { Fab } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { ProfilePages } from './ProfilePages';
import { ProfileBio } from './ProfileBio';
import { EarnBadgesEmployee } from './EarnBadgesEmployee';
import { Delete, Save, StopCircle } from '@mui/icons-material';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';
import { FirebaseStorage } from '../../firebase/config';
import ReactPlayer from 'react-player';
import { MuiTelInput } from 'mui-tel-input';
import { setActiveProfile } from '../../store/slices/profile/profileSlice';
import { tagsJ } from '../../store/slices/job/tags';
import { useTranslation } from 'react-i18next'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const languageData = [
    '🇺🇸 Ingles',
    'Español',
    'Frances',
    'Aleman',
    'Italiano',
]

const positionData = [
    'Full Stack Developer',
    'Front End Developer',
    'Backend Developer',
    'Team Leader',
    'Project Manager'
]

const countrys = [
    { name: '🇬🇹 Guatemala', value: '🇬🇹 Guatemala' },
    { name: '🇺🇸 Estados Unidos', value: '🇺🇸 Estados Unidos' },
    { name: '🇲🇽 Mexico', value: '🇲🇽 Mexico' },
    { name: '🇨🇦 Canadá', value: '🇨🇦 Canadá' },
    { name: '🇭🇳 Honduras', value: '🇭🇳 Honduras' },
    { name: '🇸🇻 El Salvador', value: '🇸🇻 El Salvador' },
    { name: '🇳🇮 Nicaragua', value: '🇳🇮 Nicaragua' },
    { name: '🇨🇷 Costa Rica', value: '🇨🇷 Costa Rica' },
]

export const ProfileTabEmployee = ({ onClick, data }) => {
    const { uid, email } = useSelector(state => state.auth);
    const { t } = useTranslation();
    // const { profile } = useSelector(state => state.profile);
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [preview, setPreview] = useState(false);
    const [phone, setPhone] = useState('');
    const [language, setLanguage] = useState([]);
    const [position, setPosition] = useState([]);
    const [joblocation, setJobLocation] = useState([]);
    const [interest, setInterest] = useState([]);
    const [salary, setSalary] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [urlVideo, setUrlVideo] = useState('');
    const [selected, setIsSelected] = useState(false);
    const [file, setFile] = useState("");
    // const [urlVideoLocal, setUrlVideoLocal] = useState('');
    const [videoSaved, setVideoSaved] = useState(false);
    const dispatch = useDispatch();
    const { status, errorMessage } = useSelector(state => state.auth);
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [countryName, setCountryName] = useState([]);
    const [showWebCam, setShowWebCam] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [experience, setExperience] = useState([]);
    const [education, setEducation] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [bio, setBio] = useState('');
    const [pages, setPages] = useState({});
    const [tagsEmployee, setTagsEmployee] = useState([]);
    const [jobDepartment, setJobDepartment] = useState('');
    const [filterTitle, setFilterTitle] = useState([]);
    const [filterTags, setFilterTags] = useState([]);

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            joblocation: "",
            tagsEmployee: ""
        }
    });

    const handleOpen = () => {
        setOpen(true)
        setShowWebCam(true);
        // setPreview(false);
    };

    const handleClose = () => {
        setOpen(false)
        setShowWebCam(false);
    };

    const onCapturePartner = () => { }

    const videoConstraints = {
        // width: "-webkit -fill -available",
        width: 640,
        height: 360,
        facingMode: "environment",
        border: "5px solid #444",
    };

    useEffect(() => {
        setValue('name', data[0].name);
        setInterest(data[0].interest);
        setCurrency(data[0].currency);
        setSalary(data[0].salary);
        setJobLocation(data[0].joblocation);
        setLanguage(data[0].language);
        setBio(data[0].bio);
        setCertificate(data[0].certificate);
        setEducation(data[0].education);
        setExperience(data[0].experience);
        setPosition(data[0].position);
        setPages(data[0].pages);
        setPhone(data[0].phone);
        setUrlVideo(data[0].urlVideo);
        setTagsEmployee(data[0].tagsEmployee)
        // setUrlVideoLocal(data[0].urlVideoLocal);
    }, [data])

    useEffect(() => {
        dispatch(startLoadingProfile())
    }, [])

    // useEffect(() => {
    //     // setActiveProfile({});
    // }, [profile])

    useEffect(() => {
        setVideoSaved(true);
    }, [urlVideo])

    // useEffect(() => {
    //     setVideoSaved(true);
    // }, [urlVideoLocal])

    useEffect(() => {
        saveLocalVideo();
    }, [file])

    // const onSubmit = (dataProfile) => {
    //     let urlVideoTmp = '';
    //     if (urlVideoLocal) {
    //         urlVideoTmp = urlVideoLocal;
    //     }

    //     const {
    //         currency,
    //         interest,
    //         joblocation,
    //         name,
    //         salary
    //     } = dataProfile;

    //     const newData = {
    //         bio: bio,
    //         currency,
    //         interest,
    //         joblocation,
    //         name,
    //         salary,
    //         pages: pages,
    //         experience: experience,
    //         education: education,
    //         certificate: certificate,
    //         urlVideo: urlVideo,
    //         urlVideoLocal: urlVideoTmp,
    //         phone: phone,
    //         language: language
    //     }

    //     if (dataProfile) {
    //         dispatch(startNewProfile(newData));
    //         if (!errorMessage) {
    //             showSuccess();
    //         }
    //     }
    // }

    const onSubmit = (dataProfile) => {
        // let urlVideoTmp = '';
        // if (urlVideoLocal) {
        //     urlVideoTmp = urlVideoLocal;
        // }
        const { tagsEmployee } = dataProfile

        const {
            name,
        } = dataProfile;

        let id = '';
        if (data) {
            id = data[0].id;
        }

        const newData = {
            id: id,
            uid,
            bio: bio,
            currency: currency,
            interest: interest,
            joblocation: joblocation,
            name,
            department: jobDepartment,
            salary: salary,
            pages: pages,
            experience: experience,
            education: education,
            certificate: certificate,
            urlVideo: urlVideo,
            phone: phone,
            language: language,
            position: position,
            tagsEmployee
        }

        dispatch(setActiveProfile(newData));

        if (newData) {
            onUpdateProfile(newData);
            if (!errorMessage) {
                showSuccess();
                closeUpdateProfile();
            }
        }
    }
    
    const titleTics = [
        "Cloud Architect",
    "Data Specialist",
    "Full Stack Developer",
    "Frontend Developer",
    "Backend Developer",
    "Data Scientist",
    "Machine Learning Engineer",
    "Artificial Intelligence Engineer",
    "Software Engineer",
    "Quality Assurance Engineer",
    "Systems Engineer",
    "Database Administrator",
    "Security Engineer",
    "Web Developer",
    "Mobile Developer",
    "Game Developer",
    "DevOps Engineer",
    "Cloud Engineer",
    "Data Architect",
    "Software Architect",
    "Technical Writer",
    "UX/UI Designer",
    "Product Manager",
    "Project Manager",
    "Business Analyst",
    "Software Consultant",
    "Software Trainer",
    "Freelancer",
    "Entrepreneur"
    ]


    const titledep1 =[
        t("titlesdep1.t1"),
        t("titlesdep1.t2"),
        t("titlesdep1.t3"),
        t("titlesdep1.t4"),
        t("titlesdep1.t5"),
        t("titlesdep1.t6"),
        t("titlesdep1.t7"),
        t("titlesdep1.t8"),
        t("titlesdep1.t9"),
        t("titlesdep1.t10"),
        t("titlesdep1.t11"),
        t("titlesdep1.t12"),
        t("titlesdep1.t13"),
        t("titlesdep1.t14"),
        t("titlesdep1.t15"),
        t("titlesdep1.t16"),
        t("titlesdep1.t17"),
        t("titlesdep1.t18"),
        t("titlesdep1.t19"),
        t("titlesdep1.t20")
    ]

    const titledep2 = [
        t("titlesdep2.t1"),
        t("titlesdep2.t2"),
        t("titlesdep2.t3"),
        t("titlesdep2.t4"),
        t("titlesdep2.t5"),
        t("titlesdep2.t6"),
        t("titlesdep2.t7"),
        t("titlesdep2.t8"),
        t("titlesdep2.t9"),
        t("titlesdep2.t10"),
        t("titlesdep2.t11"),
        t("titlesdep2.t12"),
        t("titlesdep2.t13"),
        t("titlesdep2.t14"),
        t("titlesdep2.t15"),
        t("titlesdep2.t16"),
        t("titlesdep2.t17"),
        t("titlesdep2.t18"),
        t("titlesdep2.t19"),
        t("titlesdep2.t20")        
    ]

    const titledep3 = [
        t("titlesdep3.t1"),
        t("titlesdep3.t2"),
        t("titlesdep3.t3"),
        t("titlesdep3.t4"),
        t("titlesdep3.t5"),
        t("titlesdep3.t6"),
        t("titlesdep3.t7"),
        t("titlesdep3.t8"),
        t("titlesdep3.t9"),
        t("titlesdep3.t10"),
        t("titlesdep3.t11"),
        t("titlesdep3.t12"),
        t("titlesdep3.t13"),
        t("titlesdep3.t14"),
        t("titlesdep3.t15"),
        t("titlesdep3.t16"),
        t("titlesdep3.t17"),
        t("titlesdep3.t18"),
        t("titlesdep3.t19"),
        t("titlesdep3.t20")
        
    ]

    const titledep4 =[
        t("titlesdep4.t1"),
        t("titlesdep4.t2"),
        t("titlesdep4.t3"),
        t("titlesdep4.t4"),
        t("titlesdep4.t5"),
        t("titlesdep4.t6"),
        t("titlesdep4.t7"),
        t("titlesdep4.t8"),
        t("titlesdep4.t9"),
        t("titlesdep4.t10"),
        t("titlesdep4.t11"),
        t("titlesdep4.t12"),
        t("titlesdep4.t13"),
        t("titlesdep4.t14"),
        t("titlesdep4.t15"),
        t("titlesdep4.t16"),
        t("titlesdep4.t17"),
        t("titlesdep4.t18"),
        t("titlesdep4.t19"),
        t("titlesdep4.t20")
    ]

    const titledep5 =[
        t("titlesdep5.t1"),
        t("titlesdep5.t2"),
        t("titlesdep5.t3"),
        t("titlesdep5.t4"),
        t("titlesdep5.t5"),
        t("titlesdep5.t6"),
        t("titlesdep5.t7"),
        t("titlesdep5.t8"),
        t("titlesdep5.t9"),
        t("titlesdep5.t10"),
        t("titlesdep5.t11"),
        t("titlesdep5.t12"),
        t("titlesdep5.t13"),
        t("titlesdep5.t14"),
        t("titlesdep5.t15"),
        t("titlesdep5.t16"),
        t("titlesdep5.t17"),
        t("titlesdep5.t18"),
        t("titlesdep5.t19"),
        t("titlesdep5.t20")
    ]

    const titledep6 = [
        t("titlesdep6.t1"),
        t("titlesdep6.t2"),
        t("titlesdep6.t3"),
        t("titlesdep6.t4"),
        t("titlesdep6.t5"),
        t("titlesdep6.t6"),
        t("titlesdep6.t7"),
        t("titlesdep6.t8"),
        t("titlesdep6.t9"),
        t("titlesdep6.t10"),
        t("titlesdep6.t11"),
        t("titlesdep6.t12"),
        t("titlesdep6.t13"),
        t("titlesdep6.t14"),
        t("titlesdep6.t15"),
        t("titlesdep6.t16"),
        t("titlesdep6.t17"),
        t("titlesdep6.t18"),
        t("titlesdep6.t19"),
        t("titlesdep6.t20")
    ]

    const titledep8 = [
        t("titlesdep8.t1"),
        t("titlesdep8.t2"),
        t("titlesdep8.t3"),
        t("titlesdep8.t4"),
        t("titlesdep8.t5"),
        t("titlesdep8.t6"),
        t("titlesdep8.t7"),
        t("titlesdep8.t8"),
        t("titlesdep8.t9"),
        t("titlesdep8.t10"),
        t("titlesdep8.t11"),
        t("titlesdep8.t12"),
        t("titlesdep8.t13"),
        t("titlesdep8.t14"),
        t("titlesdep8.t15"),
        t("titlesdep8.t16"),
        t("titlesdep8.t17"),
        t("titlesdep8.t18"),
        t("titlesdep8.t19"),
        t("titlesdep8.t20")
    ]

    const titledep9=[
        t("titlesdep9.t1"),
        t("titlesdep9.t2"),
        t("titlesdep9.t3"),
        t("titlesdep9.t4"),
        t("titlesdep9.t5"),
        t("titlesdep9.t6"),
        t("titlesdep9.t7"),
        t("titlesdep9.t8"),
        t("titlesdep9.t9"),
        t("titlesdep9.t10"),
        t("titlesdep9.t11"),
        t("titlesdep9.t12"),
        t("titlesdep9.t13"),
        t("titlesdep9.t14"),
        t("titlesdep9.t15"),
        t("titlesdep9.t16"),
        t("titlesdep9.t17"),
        t("titlesdep9.t18"),
        t("titlesdep9.t19"),
        t("titlesdep9.t20")
    ]

    const titledep10 = [
        t("titlesdep10.t1"),
        t("titlesdep10.t2"),
        t("titlesdep10.t3"),
        t("titlesdep10.t4"),
        t("titlesdep10.t5"),
        t("titlesdep10.t6"),
        t("titlesdep10.t7"),
        t("titlesdep10.t8"),
        t("titlesdep10.t9"),
        t("titlesdep10.t10"),
        t("titlesdep10.t11"),
        t("titlesdep10.t12"),
        t("titlesdep10.t13"),
        t("titlesdep10.t14"),
        t("titlesdep10.t15"),
        t("titlesdep10.t16"),
        t("titlesdep10.t17"),
        t("titlesdep10.t18"),
        t("titlesdep10.t19"),
        t("titlesdep10.t20")
    ]

    const titledep11 = [
        t("titlesdep11.t1"),
        t("titlesdep11.t2"),
        t("titlesdep11.t3"),
        t("titlesdep11.t4"),
        t("titlesdep11.t5"),
        t("titlesdep11.t6"),
        t("titlesdep11.t7"),
        t("titlesdep11.t8"),
        t("titlesdep11.t9"),
        t("titlesdep11.t10"),
        t("titlesdep11.t11"),
        t("titlesdep11.t12"),
        t("titlesdep11.t13"),
        t("titlesdep11.t14"),
        t("titlesdep11.t15"),
        t("titlesdep11.t16"),
        t("titlesdep11.t17"),
        t("titlesdep11.t18"),
        t("titlesdep11.t19"),
        t("titlesdep11.t20")
    ]

    const titledep12 = [
        t("titlesdep12.t1"),
        t("titlesdep12.t2"),
        t("titlesdep12.t3"),
        t("titlesdep12.t4"),
        t("titlesdep12.t5"),
        t("titlesdep12.t6"),
        t("titlesdep12.t7"),
        t("titlesdep12.t8"),
        t("titlesdep12.t9"),
        t("titlesdep12.t10"),
        t("titlesdep12.t11"),
        t("titlesdep12.t12"),
        t("titlesdep12.t13"),
        t("titlesdep12.t14"),
        t("titlesdep12.t15"),
        t("titlesdep12.t16"),
        t("titlesdep12.t17"),
        t("titlesdep12.t18"),
        t("titlesdep12.t19"),
        t("titlesdep12.t20")
    ]

    const tagsJ = [
        "Javascript",
        "CSS",
        "HTML5",
        "Java",
        "CSS3",
        "C++",
        "BootStrap",
        "Java EE",
        "Java SE",
        "Node JS",
        "React JS",
        "Angular JS",
        "Java POO",
        "Phyton",
        "Go",
        "Rust",
        "TypeScript",
        "Django",
        "Laravel",
        "Spring",
        "Unity",
        "Vue.js",
        "Rails",
        "PHP",
        "SQL",
        "C#",
        "Express",
        "Flask",
        "Rails",
        "Bash",
        "Perl",
        "Ruby",
        "Haskell",
        "Bash"
    ]

    const tagsDep1 = [
        t("tagsDep1.tags1"),
        t("tagsDep1.tags2"),
        t("tagsDep1.tags3"),
        t("tagsDep1.tags4"),
        t("tagsDep1.tags5"),
        t("tagsDep1.tags6"),
        t("tagsDep1.tags7"),
        t("tagsDep1.tags8"),
        t("tagsDep1.tags9"),
        t("tagsDep1.tags10"),
        t("tagsDep1.tags11"),
        t("tagsDep1.tags12"),
        t("tagsDep1.tags13"),
        t("tagsDep1.tags14"),
        t("tagsDep1.tags15"),
        t("tagsDep1.tags16"),
        t("tagsDep1.tags17"),
        t("tagsDep1.tags18"),
        t("tagsDep1.tags19"),
        t("tagsDep1.tags20")
    ];


    const tagsDep2 = [
        t("tagsDep2.tags1"),
        t("tagsDep2.tags2"),
        t("tagsDep2.tags3"),
        t("tagsDep2.tags4"),
        t("tagsDep2.tags5"),
        t("tagsDep2.tags6"),
        t("tagsDep2.tags7"),
        t("tagsDep2.tags8"),
        t("tagsDep2.tags9"),
        t("tagsDep2.tags10"),
        t("tagsDep2.tags11"),
        t("tagsDep2.tags12"),
        t("tagsDep2.tags13"),
        t("tagsDep2.tags14"),
        t("tagsDep2.tags15"),
        t("tagsDep2.tags16"),
        t("tagsDep2.tags17"),
        t("tagsDep2.tags18"),
        t("tagsDep2.tags19"),
        t("tagsDep2.tags20")
    ];

    const tagsDep3 = [
        t("tagsDep3.tags1"),
        t("tagsDep3.tags2"),
        t("tagsDep3.tags3"),
        t("tagsDep3.tags4"),
        t("tagsDep3.tags5"),
        t("tagsDep3.tags6"),
        t("tagsDep3.tags7"),
        t("tagsDep3.tags8"),
        t("tagsDep3.tags9"),
        t("tagsDep3.tags10"),
        t("tagsDep3.tags11"),
        t("tagsDep3.tags12"),
        t("tagsDep3.tags13"),
        t("tagsDep3.tags14"),
        t("tagsDep3.tags15"),
        t("tagsDep3.tags16"),
        t("tagsDep3.tags17"),
        t("tagsDep3.tags18"),
        t("tagsDep3.tags19"),
        t("tagsDep3.tags20")
    ];

    const tagsDep4 = [
        t("tagsDep4.tags1"),
        t("tagsDep4.tags2"),
        t("tagsDep4.tags3"),
        t("tagsDep4.tags4"),
        t("tagsDep4.tags5"),
        t("tagsDep4.tags6"),
        t("tagsDep4.tags7"),
        t("tagsDep4.tags8"),
        t("tagsDep4.tags9"),
        t("tagsDep4.tags10"),
        t("tagsDep4.tags11"),
        t("tagsDep4.tags12"),
        t("tagsDep4.tags13"),
        t("tagsDep4.tags14"),
        t("tagsDep4.tags15"),
        t("tagsDep4.tags16"),
        t("tagsDep4.tags17"),
        t("tagsDep4.tags18"),
        t("tagsDep4.tags19"),
        t("tagsDep4.tags20")
    ];

    const tagsDep5 = [
        t("tagsDep5.tags1"),
        t("tagsDep5.tags2"),
        t("tagsDep5.tags3"),
        t("tagsDep5.tags4"),
        t("tagsDep5.tags5"),
        t("tagsDep5.tags6"),
        t("tagsDep5.tags7"),
        t("tagsDep5.tags8"),
        t("tagsDep5.tags9"),
        t("tagsDep5.tags10"),
        t("tagsDep5.tags11"),
        t("tagsDep5.tags12"),
        t("tagsDep5.tags13"),
        t("tagsDep5.tags14"),
        t("tagsDep5.tags15"),
        t("tagsDep5.tags16"),
        t("tagsDep5.tags17"),
        t("tagsDep5.tags18"),
        t("tagsDep5.tags19"),
        t("tagsDep5.tags20")
    ];

    const tagsDep6 = [
        t("tagsDep6.tags1"),
        t("tagsDep6.tags2"),
        t("tagsDep6.tags3"),
        t("tagsDep6.tags4"),
        t("tagsDep6.tags5"),
        t("tagsDep6.tags6"),
        t("tagsDep6.tags7"),
        t("tagsDep6.tags8"),
        t("tagsDep6.tags9"),
        t("tagsDep6.tags10"),
        t("tagsDep6.tags11"),
        t("tagsDep6.tags12"),
        t("tagsDep6.tags13"),
        t("tagsDep6.tags14"),
        t("tagsDep6.tags15"),
        t("tagsDep6.tags16"),
        t("tagsDep6.tags17"),
        t("tagsDep6.tags18"),
        t("tagsDep6.tags19"),
        t("tagsDep6.tags20")
    ];

    const tagsDep8 = [
        t("tagsDep8.tags1"),
        t("tagsDep8.tags2"),
        t("tagsDep8.tags3"),
        t("tagsDep8.tags4"),
        t("tagsDep8.tags5"),
        t("tagsDep8.tags6"),
        t("tagsDep8.tags7"),
        t("tagsDep8.tags8"),
        t("tagsDep8.tags9"),
        t("tagsDep8.tags10"),
        t("tagsDep8.tags11"),
        t("tagsDep8.tags12"),
        t("tagsDep8.tags13"),
        t("tagsDep8.tags14"),
        t("tagsDep8.tags15"),
        t("tagsDep8.tags16"),
        t("tagsDep8.tags17"),
        t("tagsDep8.tags18"),
        t("tagsDep8.tags19"),
        t("tagsDep8.tags20")
    ];

    const tagsDep9 = [
        t("tagsDep9.tags1"),
        t("tagsDep9.tags2"),
        t("tagsDep9.tags3"),
        t("tagsDep9.tags4"),
        t("tagsDep9.tags5"),
        t("tagsDep9.tags6"),
        t("tagsDep9.tags7"),
        t("tagsDep9.tags8"),
        t("tagsDep9.tags9"),
        t("tagsDep9.tags10"),
        t("tagsDep9.tags11"),
        t("tagsDep9.tags12"),
        t("tagsDep9.tags13"),
        t("tagsDep9.tags14"),
        t("tagsDep9.tags15"),
        t("tagsDep9.tags16"),
        t("tagsDep9.tags17"),
        t("tagsDep9.tags18"),
        t("tagsDep9.tags19"),
        t("tagsDep9.tags20")
    ];

    const tagsDep10 = [
        t("tagsDep10.tags1"),
        t("tagsDep10.tags2"),
        t("tagsDep10.tags3"),
        t("tagsDep10.tags4"),
        t("tagsDep10.tags5"),
        t("tagsDep10.tags6"),
        t("tagsDep10.tags7"),
        t("tagsDep10.tags8"),
        t("tagsDep10.tags9"),
        t("tagsDep10.tags10"),
        t("tagsDep10.tags11"),
        t("tagsDep10.tags12"),
        t("tagsDep10.tags13"),
        t("tagsDep10.tags14"),
        t("tagsDep10.tags15"),
        t("tagsDep10.tags16"),
        t("tagsDep10.tags17"),
        t("tagsDep10.tags18"),
        t("tagsDep10.tags19"),
        t("tagsDep10.tags20")
    ];

    const tagsDep11 = [
        t("tagsDep11.tags1"),
        t("tagsDep11.tags2"),
        t("tagsDep11.tags3"),
        t("tagsDep11.tags4"),
        t("tagsDep11.tags5"),
        t("tagsDep11.tags6"),
        t("tagsDep11.tags7"),
        t("tagsDep11.tags8"),
        t("tagsDep11.tags9"),
        t("tagsDep11.tags10"),
        t("tagsDep11.tags11"),
        t("tagsDep11.tags12"),
        t("tagsDep11.tags13"),
        t("tagsDep11.tags14"),
        t("tagsDep11.tags15"),
        t("tagsDep11.tags16"),
        t("tagsDep11.tags17"),
        t("tagsDep11.tags18"),
        t("tagsDep11.tags19"),
        t("tagsDep11.tags20")
    ];

    const tagsDep12 = [
        t("tagsDep12.tags1"),
        t("tagsDep12.tags2"),
        t("tagsDep12.tags3"),
        t("tagsDep12.tags4"),
        t("tagsDep12.tags5"),
        t("tagsDep12.tags6"),
        t("tagsDep12.tags7"),
        t("tagsDep12.tags8"),
        t("tagsDep12.tags9"),
        t("tagsDep12.tags10"),
        t("tagsDep12.tags11"),
        t("tagsDep12.tags12"),
        t("tagsDep12.tags13"),
        t("tagsDep12.tags14"),
        t("tagsDep12.tags15"),
        t("tagsDep12.tags16"),
        t("tagsDep12.tags17"),
        t("tagsDep12.tags18"),
        t("tagsDep12.tags19"),
        t("tagsDep12.tags20")
    ];

    useEffect(() => {
        filterTitles(jobDepartment);
    }, [jobDepartment]);


    const filterTitles = (jobDepartment) => {
        switch (jobDepartment) {
            case t("department.dep1"):
                setFilterTitle(titledep1);
                setFilterTags(tagsDep1)
                return;
            case t("department.dep2"):
                setFilterTitle(titledep2);
                setFilterTags(tagsDep2)
                return;
            case t("department.dep3"):
                setFilterTitle(titledep3);
                setFilterTags(tagsDep3)
                return;
            case t("department.dep4"):
                setFilterTitle(titledep4);
                setFilterTags(tagsDep4)
                return;
            case t("department.dep5"):
                setFilterTitle(titledep5);
                setFilterTags(tagsDep5)
                return;
            case t("department.dep6"):
                setFilterTitle(titledep6);
                setFilterTags(tagsDep6)
                return;
            case t("department.dep7"):
                setFilterTitle(titleTics);
                setFilterTags(tagsJ)
                return;
            case t("department.dep8"):
                setFilterTitle(titledep8);
                setFilterTags(tagsDep8)
                return;
            case t("department.dep9"):
                setFilterTitle(titledep9);
                setFilterTags(tagsDep9)
                return;
            case t("department.dep10"):
                setFilterTitle(titledep10);
                setFilterTags(tagsDep10)
                return;
            case t("department.dep11"):
                setFilterTitle(titledep11);
                setFilterTags(tagsDep11)
                return;
            case t("department.dep12"):
                setFilterTitle(titledep12);
                setFilterTags(tagsDep12)
                return;
            default:
                setFilterTitle([]);
                setFilterTags([]);
        }
    }

    const handleDepartmentChange = (event) => {
        const selectedDepartment = event.target.value;
        filterTitles(selectedDepartment);
    };

    const onUpdateProfile = () => {
        dispatch(startSaveProfile());
    }

    const handleChangeCountry = (event) => {
        const {
            target: { value },
        } = event;
        setCountryName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeLanguage = (event) => {
        const {
            target: { value },
        } = event;
        setLanguage(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeLocation = (event) => {
        const {
            target: { value },
        } = event;
        setJobLocation(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeInterest = (event) => {
        const {
            target: { value },
        } = event;
        setInterest(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeCurrency = (event) => {
        const {
            target: { value },
        } = event;
        setCurrency(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangePosition = (event) => {
        const {
            target: { value },
        } = event;
        setPosition(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeSalary = (event) => {
        const {
            target: { value },
        } = event;
        setSalary(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const loadExperience = (data) => {
        setExperience(data);
    }

    const loadBio = (data) => {
        setBio(data);
    }

    const loadPages = (data) => {
        setPages(data);
    }

    const loadEducation = (data) => {
        setEducation(data);
    }

    const loadCertificates = (data) => {
        setCertificate(data);
    }

    const showSuccess = () => {
        Swal.fire('Profile created successfully', 'Saved', 'success');
    };

    const closeUpdateProfile = () => {
        onClick(false);
    }

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStartCaptureClick = useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        // handleDownload();
        setCapturing(false);
        // setPreview(true);

    }, [mediaRecorderRef, setCapturing]);

    const handleDownload = useCallback(() => {
        // setPreview(false);
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                // type: "video/webm",
                type: "video/mp4",
            });
            const url = URL.createObjectURL(blob);

            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "react-webcam-stream-capture.webm";
            a.click();
            if (a) {
                const videoRef = ref(FirebaseStorage, `videos/profile/${uid}`);
                uploadBytes(videoRef, blob).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                        setUrlVideo(url);
                    })
                })
            }
            window.URL.revokeObjectURL(url);
            setRecordedChunks([]);
            setOpen(false);
        }
    }, [recordedChunks]);

    const handleChangePhone = (value) => {
        setPhone(value);
    }

    const handleChangeFile = (event) => {
        setFile(event.target.files[0]);
        setIsSelected(true);
    }

    const handleDeleteFile = () => {
        setFile("");
        setUrlVideo('');
        setRecordedChunks([]);
        setOpen(false);
        const desertRef = ref(FirebaseStorage, `videos/profile/${uid}`);
        deleteObject(desertRef).then(() => {
            console.log('archivo eliminado correctamente');
        }).catch((error) => {
            console.log(error);
        })
        // setUrlVideoLocal('');

    }

    const saveLocalVideo = () => {
        if (file) {
            // const storageRef = ref(FirebaseStorage, `videos/profile/${file.name}`)
            const storageRef = ref(FirebaseStorage, `videos/profile/${uid}`)

            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setUrlVideo(url);
                    setUrlVideo((state) => {
                        return state;
                    });
                })
            })
        }
    }

    return (
        <>
            <div className='flex flex-row w-full'>
                <button onClick={() => closeUpdateProfile()}><img className="w-6 h-6 absolute rounded-mx mt-2 ml-2" src="../assets/icons/left-arrow-from-left.png" alt='' /></button>
                {/* <p className=" text-2xl font-bold text-gray-800 ml-1 mt-2" style={{ left: 86 }}>Preview Profile</p> */}
            </div>

            <div className='flex w-full h-full bg-white  pt-10'>
                <div className='flex flex-1 w-full'>
                    <div className='w-1/5'>
                        <div className='border-r h-2/5'>
                            <div className='flex flex-row'>
                                {!pages.profileImageUrl &&
                                    <img className="w-48 h-48 mx-auto rounded-full object-cover" src="../assets/images/profile_default.png" alt='' />
                                }
                                {pages.profileImageUrl &&
                                    <img className="w-48 h-48 mx-auto rounded-full object-cover" src={pages.profileImageUrl} alt='' />
                                }
                            </div>
                            <div className='flex flex-row mt-10 pl-2'>
                                <p className="text-zinc-800 text-[18px] font-bold">{t('profileTabEmployee.info')}</p>
                            </div>
                            <div className='flex flex-row mt-4 pl-2'>
                                <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                                <p className="pl-1 text-sky-900 text-[12px] font-normal">{email}</p>
                            </div>
                            <div className='flex flex-row mt-4 pl-2'>
                                <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                                {phone &&
                                    <p className="pl-1 text-sky-900 text-[12px] font-normal">{phone}</p>
                                }
                                {!phone &&
                                    <p className="pl-1 text-sky-900 text-[12px] font-normal"></p>
                                }
                            </div>
                            <ProfilePages getPages={loadPages} />
                            <div className='flex flex-row mt-4 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                                {pages.website &&
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal text-left">{pages.website}</p>
                                }
                                {!pages.website &&
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal">{t('profileTabEmployee.website')}</p>
                                }
                            </div>
                            <div className='flex flex-row mt-4 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/linkedin-alt.png" alt='' />
                                {!pages.linkedin &&
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal">{t('profileTabEmployee.linkedin')}</p>
                                }
                                {pages.linkedin &&
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal text-left">{pages.linkedin}</p>
                                }
                            </div>
                            <div className='flex flex-row mt-4 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/twitter.png" alt='' />
                                {!pages.twitter &&
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal">{t('profileTabEmployee.twitter')}</p>
                                }
                                {pages.twitter &&
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal text-left">{pages.twitter}</p>
                                }
                            </div>
                            <div className='flex flex-row mt-4 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/facebook.png" alt='' />
                                {pages.facebook &&
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal text-left">{pages.facebook}</p>
                                }
                                {!pages.facebook &&
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal">{t('profileTabEmployee.facebook')}</p>
                                }
                            </div>
                            <div className='flex flex-row mt-4 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/behance.png" alt='' />
                                {pages.behance &&
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal text-left">{pages.behance}</p>
                                }
                                {!pages.behance &&
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal">{t('profileTabEmployee.behance')}</p>
                                }
                            </div>
                            {!pages.github &&
                                <div className='flex flex-row mt-4 overflow-hidden'>
                                    <img className='w-5 h-5' src="../assets/icons/github.png" alt='' />
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal">{t('profileTabEmployee.github')}</p>
                                </div>
                            }
                            {pages.github &&
                                <div className='flex flex-row mt-4 overflow-hidden'>
                                    <img className='w-5 h-5' src="../assets/icons/github.png" alt='' />
                                    <p className="pl-2 text-sky-900 text-[12px] font-normal text-left">{pages.github}</p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='w-4/5 pl-6 pr-6'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='flex flex-row pl-6'>
                                <div className='w-4/6'>
                                    <TextField
                                        id='name'
                                        className='w-full'
                                        placeholder={t('profileTabEmployee.name')}
                                        {...register("name")}
                                    />
                                </div>
                               {/*} <div className='w-2/6'>
                                    <FormControl className='w-11/12'>
                                        <InputLabel id='basic-assessment-label'>{t('profileTabEmployee.position')}</InputLabel>
                                        <Select
                                            labelId="basic-assessment-label"
                                            id="basic-assessment"
                                            // multiple
                                            placeholder={t('profileTabEmployee.position')}
                                            label={t('profileTabEmployee.position')}
                                            value={position}
                                            onChange={handleChangePosition} 
                                        // input={<OutlinedInput label="Select Position" />}
                                        // renderValue={(selected)}
                                        // MenuProps={MenuProps}
                                        >
                                            {positionData.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    // comentar esto <Checkbox checked={position.indexOf(name) > -1} />
                                                  // comentar esto   <ListItemText primary={name} /> 
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>*/}
                            </div>
                            <div className='flex flex-row pl-4 pt-8'>
                                <div className='w-4/12'>
                                    <FormControl className='w-11/12'>
                                        <InputLabel id="">{t('profileTabEmployee.location')}</InputLabel>
                                        <Select
                                            labelId="job-location-label"
                                            id="job-location"
                                            label={t('profileTabEmployee.location')}
                                            placeholder={t('profileTabEmployee.location')}
                                            defaultValue=""
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            // {...register("joblocation")}
                                            value={joblocation}
                                            onChange={handleChangeLocation}
                                        >
                                            {countrys.map((name) => (
                                                <MenuItem key={name.value} value={name.value}>
                                                    {name.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-4/12'>
                                    {/* <div className="w-[232px] h-14 pl-[23px] pr-[140px] py-4 bg-white rounded-lg  border border-gray-300 justify-start items-center inline-flex">
                                        <div className="text-gray-600 text-[16px] font-medium">Location</div>
                                    </div> */}
                                    <MuiTelInput className='bg-white w-full rounded-lg border border-gray-300' defaultCountry={'us'} placeholder={t('profileTabEmployee.phone')} value={phone} onChange={handleChangePhone} required />
                                </div>
                                <div className='w-4/12'>
                                    <FormControl className='w-11/12'>
                                        <InputLabel id="language-label">{t('profileTabEmployee.language')}</InputLabel>
                                        <Select
                                            labelId="language-label"
                                            id="language"
                                            label={t('profileTabEmployee.language')}
                                            // multiple
                                            placeholder={t('profileTabEmployee.language')}
                                            // defaultValue=""
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            onChange={handleChangeLanguage}
                                            value={language}
                                        >
                                            {languageData.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <ProfileBio getBio={loadBio} data={bio} />
                            {/* <div className='flex flex-row mt-6'>
                                <div className='w-full pl-5 pr-2'>
                                    <TextField
                                        multiline
                                        rows={4}
                                        placeholder='Add Bio'
                                        className='w-full'
                                        {...register("bio")}
                                    />
                                </div>
                            </div> */}
                            <div className='mt-10 pl-6 mr-2'>
                                <hr />
                            </div>
                            <WorkExperienceEmployee getExperiencie={loadExperience} data={experience} />
                            <div className='mt-8 pl-6 mr-2'>
                                <hr />
                            </div>
                            <EducationEmployee getEducation={(loadEducation)} data={education} />
                            <div className='mt-8 pl-6 mr-2'>
                                <hr />
                            </div>
                            {/* <EarnBadgesEmployee /> */}
                            {/* <div className='mt-8 pl-6 mr-2'>
                                <hr />
                            </div> */}
                            <CertificatesEmployee getCertificates={(loadCertificates)} data={certificate} />
                            {/* {certificate &&
                                <div className='w-full pl-6 pt-8'>
                                    <table className='w-full'>
                                        {certificate.map((certificate, index) => (
                                            <tr key={index}>
                                                <td className=''>{certificate.certificateType}</td>
                                                <td className='pl-4'>{certificate.certificateName}</td>
                                                <td className='pl-4'><img className='w-6 h-6' src={certificate.urlCertificate} alt='' /></td>
                                                <td><button className='pl-4' type='button' onClick={() => handleOpen()}><img className=" w-5 h-5" src="../assets/icons/edit.png" alt='' /></button></td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            } */}
                            <div className='mt-8 pl-6 mr-2'>
                                <hr />
                            </div>
                            <div className='w-full mt-2 pl-6 text-left'>
                                <p className='inline-flex text-zinc-800 font-bold text-[18px]'>{t('profileTabEmployee.role')}</p>
                            </div>
                            <div className='flex flex-row mt-6 pl-6'>
                                <div className='w-6/12'>
                                    <FormControl className='w-full'>
                                    <InputLabel id="job-department-label">department</InputLabel>
                                    <Select
                                        labelId="job-department-label"
                                        id="job-department"
                                        label='Job Department'
                                        placeholder='Job Department'
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        {...register("department")}
                                        onChange={(e) => {
                                            filterTitles(e.target.value);
                                        }}
                                        multiple={false}
                                        >
                                        <MenuItem value={t("department.dep1")} > {t("department.dep1")} </MenuItem>
                                        <MenuItem value={t("department.dep2")} > {t("department.dep2")} </MenuItem>
                                        <MenuItem value={t("department.dep3")} > {t("department.dep3")} </MenuItem>
                                        <MenuItem value={t("department.dep4")} > {t("department.dep4")} </MenuItem>
                                        <MenuItem value={t("department.dep5")} > {t("department.dep5")} </MenuItem>
                                        <MenuItem value={t("department.dep6")} > {t("department.dep6")} </MenuItem>
                                        <MenuItem value={t("department.dep7")} > {t("department.dep7")} </MenuItem>
                                        <MenuItem value={t("department.dep8")} > {t("department.dep8")} </MenuItem>
                                        <MenuItem value={t("department.dep9")} > {t("department.dep9")} </MenuItem>
                                        <MenuItem value={t("department.dep10")} > {t("department.dep10")} </MenuItem>
                                        <MenuItem value={t("department.dep11")} > {t("department.dep11")} </MenuItem>
                                        <MenuItem value={t("department.dep12")} > {t("department.dep12")} </MenuItem>
                                    </Select>
                                </FormControl>
                                </div>
                                <div  className='w-6/12'>
                                <FormControl className='w-11/12'>
                                <InputLabel id="job-title-label">{t("createJob.jobT")}</InputLabel>
                                <Select
                                    labelId="interest-label"
                                        id="interest"
                                        label={t('profileTabEmployee.role')}
                                        placeholder={t('profileTabEmployee.role')}
                                        defaultValue=""
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        value={interest}
                                        //{...register("interest")}
                                        onChange={handleChangeInterest}
                            >
                                {filterTitle.map((title, index) => (
                                    <MenuItem key={index} value={title}>
                                        {title}
                                    </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                                </div>
                            </div>
                            <div className='w-full mt-2 pl-6 text-left'>
                                <p className='inline-flex text-zinc-800 font-bold text-[18px]'>{t('createJob.tags')} </p>
                            </div>
                            <div className='flex flex-row mt-6 pl-6'>
                                <FormControl className='w-11/12'>
                                    <Autocomplete
                                        multiple
                                        id="tags-employee"
                                        options={filterTags}
                                        defaultValue={[]}
                                        onChange={(event, newValue) => {
                                            setValue("tagsEmployee", newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('profileTabEmployee.tags')}
                                                placeholder={t('profileTabEmployee.tags')}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    'aria-label': 'Without label'
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </div>
                            <div className='mt-8 pl-6 mr-2'>
                                <hr />
                            </div>
                            <div className='w-full mt-2 pl-6 text-left'>
                                <p className='inline-flex text-zinc-800 font-bold text-[18px]'>{t('profileTabEmployee.video')}</p>
                                <div className="text-zinc-500 inline-flex pl-4 text-[12px] font-medium">{t('profileTabEmployee.tellus')}</div>
                            </div>
                            <div className='flex flex-row'>
                                <div className='w-1/2  mt-6 pl-6'>
                                    <div className='flex flex-row w-full justify-center'>
                                        <div>
                                            <button type='button' className="inline-flex items-center justify-start px-4 py-1 border rounded-full border-gray-800 text-sm text-gray-800"
                                                onClick={handleOpen} disabled={urlVideo}>
                                                {t('profileTabEmployee.record')}
                                            </button>
                                            {/* <p className="text-base font-extrabold tracking-widest text-gray-900 text-opacity-40 pl-6">OR</p>
                                        <input className='pt-6' type="file" onChange={handleChangeFile} /> */}

                                            <label class="block pt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white" for="file_input">{t('register.or')}</label>
                                            <input
                                                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input"
                                                type="file" onChange={handleChangeFile} disabled={urlVideo} />
                                            {/* <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG or GIF (MAX. 800x400px).</p> */}
                                        </div>
                                    </div>
                                    <div className='flex flex-row w-full pt-4'>
                                        <div className='w-3/5 justify-end items-end text-right pl-2 pr-2'>
                                            {/* <Fab className='justify-end items-end text-right' color="primary" aria-label="edit">
                                                <Save />
                                            </Fab> */}

                                            <Fab className='pl-2' color='error' aria-label="edit" placeholder='Eliminar video pl-4' disabled={!urlVideo}>
                                                <Delete onClick={handleDeleteFile} />
                                            </Fab>
                                        </div>
                                        {/* <div className='1/5 text-left pt-4'>

                                            <p>Deleted!</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className='w-1/2 items-center justify-between'>
                                    <div className='w-[320px] h-[180px] items-center justify-between pl-20'>
                                        {/* {urlVideoLocal &&
                                            <ReactPlayer width={320} height={180} url={urlVideoLocal} controls={true} />
                                        } */}
                                        {urlVideo &&
                                            <ReactPlayer width={320} height={180} url={urlVideo} controls={true} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='mt-6 pl-6 mr-2'>
                                <hr />
                            </div>
                            <div className='w-full mt-2 pl-6 text-left'>
                                <p className='inline-flex text-zinc-800 font-bold text-[18px]'>{t('profileTabEmployee.salary')}</p>
                            </div>
                            <div className='flex flex-row mt-6 pl-6'>
                                <div className='w-1/6'>
                                    <FormControl className='w-11/12'>
                                        <InputLabel id="applied-badges-label">{t('profileTabEmployee.currency')}</InputLabel>
                                        <Select
                                            labelId="currency-label"
                                            id="currency"
                                            label={t('profileTabEmployee.currency')}
                                            placeholder={t('profileTabEmployee.currency')}
                                            defaultValue=""
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            value={currency}
                                            onChange={handleChangeCurrency}
                                        >
                                            <MenuItem value="USD" >USD</MenuItem>
                                            <MenuItem value="EURO"  >Euro</MenuItem>
                                            <MenuItem value="QUETZAL"  >Quetzal</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-3/6'>
                                    <FormControl className='w-11/12'>
                                        <InputLabel id="salary-label">{t('profileTabEmployee.salary')}</InputLabel>
                                        <Select
                                            labelId="salary-label"
                                            id="salary"
                                            label={t('profileTabEmployee.salary')}
                                            placeholder={t('profileTabEmployee.salary')}
                                            defaultValue=""
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            value={salary}
                                            onChange={handleChangeSalary}
                                        >
                                            <MenuItem value="4000" >1,000 - 4,000</MenuItem>
                                            <MenuItem value="10000" >5,000 - 10,000</MenuItem>
                                            <MenuItem value="15000" >11,000 - 15,000</MenuItem>
                                            <MenuItem value="20000" >16,000 - 20,000</MenuItem>
                                            <MenuItem value="25000" >21,000 - 25,000</MenuItem>
                                            <MenuItem value="30000" >26,000 - 30,000</MenuItem>
                                            <MenuItem value="35000" >31,000 - 35,000</MenuItem>
                                            <MenuItem value="40000" >36,000 - 40,000</MenuItem>
                                            <MenuItem value="45000" >41,000 - 45,000</MenuItem>
                                            <MenuItem value="50000" >46,000 - 50,000</MenuItem>
                                            <MenuItem value="55000" >51,000 - 55,000</MenuItem>
                                            <MenuItem value="60000" >56,000 - 60,000</MenuItem>
                                            <MenuItem value="65000" >61,000 - 65,000</MenuItem>
                                            <MenuItem value="70000" >66,000 - 70,000</MenuItem>
                                            <MenuItem value="75000"  >71,000 - 75,000</MenuItem>
                                            <MenuItem value="80000"  >76,000 - 80,000</MenuItem>
                                            <MenuItem value="85000"  >81,000 - 85,000</MenuItem>
                                            <MenuItem value="90000"  >86,000 - 90,000</MenuItem>
                                            <MenuItem value="95000"  >81,000 - 95,000</MenuItem>
                                            <MenuItem value="100000"  >96,000 - 100,000</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-1/6 justify-start text-left'>
                                    <p className="text-gray-400 pt-4 text-[16px] font-medium">Anual</p>
                                </div>
                            </div>
                            <div className='flex flex-row mt-20 justify-end'>
                                <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium'>{t('pagesEmployee.save')}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                            Save Video
                        </Typography><br />
                        <div className='grid formgrid'>
                            <div style={{ border: '5px solid #BDBDBD ', padding: 'px' }}>
                                {/* {preview &&
                                    <ReactPlayer url={urlVideo} controls={true} />
                                }
                                {!preview && */}
                                <Webcam
                                    audio={true}
                                    height={'100%'}
                                    screenshotFormat="image/jpeg"
                                    frameBorder={"10px"}
                                    width={'100%'}
                                    ref={webcamRef}
                                    videoConstraints={videoConstraints}
                                >
                                </Webcam>
                                {/* } */}

                            </div>
                            {capturing ? (
                                // <button type='button' onClick={handleStopCaptureClick}>Stop Capture</button>
                                <div className='text-center w-20 h-20'>
                                    <StopCircle onClick={handleStopCaptureClick} />
                                </div>
                            ) : (

                                <div style={{ textAlign: 'center' }}>
                                    <button className=" rounded-full p-button-danger mr-2 mb-2 mt-4 bg-red-600 h-14 w-14" onClick={handleStartCaptureClick} />
                                    {/* <button type='button' onClick={handleStartCaptureClick}>Start Capture</button> */}
                                </div>
                            )}
                            {recordedChunks.length > 0 && (
                                // <button type='button' onClick={handleDownload}>Download</button>
                                <div className='flex flex-row mt-8 justify-center'>
                                    <button type="button" className='w-40 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleDownload}>Confirmar</button>
                                </div>
                            )}
                        </div>
                        {/* <div style={{ textAlign: 'center' }}>
                            <button className=" rounded-full p-button-danger mr-2 mb-2 mt-4 bg-red-600 h-14 w-14" onClick={onCapturePartner} />
                        </div> */}
                    </Box>
                </Modal>
            </div>
        </>
    )
}
