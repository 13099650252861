import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveCompanyProfile } from '../store/slices/companyProfile/thunks';

export const CompleteDetailsEmployer = ({ onFinish }) => {
    const { t } = useTranslation();
    const [numberEmployees, setNumberEmployees] = useState('');
    const [hiringNeeds, setHiringNeeds] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { profile } = useSelector(state => state.profile);
    const idCompany = profile[0].idCompany

    const submit = async () => {
        
        if (numberEmployees.length > 0) {
            onFinish();
            const formdata = {
                numberEmployees,
                hiringNeeds
            }
            await dispatch(saveCompanyProfile({ idCompany: idCompany, formdata: formdata }));
            Swal.fire({
                title: 'Ha actualizado el perfil correctamente',
                text: 'Satisfactorio',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/jobs", { replace: true });
                    window.location.reload(); 
                }
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error...',
                text: 'Por favor revisa los datos ingresados'
            })
        }
    }

    return (
        <>
            <div className='flex flex-col bg-white w-full place-items-center h-[780px]'>
                <div className='flex flex-col w-4/12 my-14'>
                    <div className="flex flex-row w-full justify-between mb-5 gap-20">
                        <div className="w-9/12 font-['Poppins'] text-gray-900  whitespace-nowrap text-2xl font-bold">{t('details.title')}</div>
                        <div className="w-3/12 font-['Poppins'] text-2xl font-bold text-gray-400 relative">3/3</div>
                    </div>
                    <div className='flex flex-col w-full my-4'>
                        <select className='w-full rounded-lg indent-3' type='text' defaultValue={'1'} onChange={(e) => setNumberEmployees(e.target.value)} placeholder='Number of employees' style={{ border: '1px solid #D7DEE1', height: '56px' }}>
                            <option value="1" disabled>{t('details.number')}</option>
                            <option value="1-5">1 - 5</option>
                            <option value="5-10">5 - 10</option>
                            <option value="10-20">10 - 20</option>
                            <option value="20-50">20 - 50</option>
                            <option value="50-100">50 - 100</option>
                            <option value="100-x">100 - en adelante</option>
                        </select>
                    </div>
                    <div className='flex flex-col w-full my-4'>
                        <select className='w-full rounded-lg indent-3' type='text' defaultValue={'1'} onChange={(e) => setHiringNeeds(e.target.value)} placeholder='Hiring needs' style={{ border: '1px solid #D7DEE1', height: '56px' }}>
                            <option value="1" disabled>{t('details.hiring')}</option>
                            <option value="2">1 hiring per month</option>
                            <option value="3">2 hirings per month</option>
                            <option value="4">3 hirings per month</option>
                        </select>
                    </div>
                    <div className='my-8'>
                        <button className=' h-14 bg-[#0D497D] rounded-full w-full text-white' type='submit' onClick={() => submit()}>{t('details.complete')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
