import { useDispatch, useSelector } from 'react-redux';
import quizCompleteImg from '../../assets/quiz-complete.png';
import Swal from 'sweetalert2';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { startNewEarnBadge } from '../../store/slices/earnBadge/thunks';

export default function Summary({ userAnswers, userResponses, onClick }) {
    const dispatch = useDispatch();
    const { active } = useSelector(state => state.evaluation);
    const skippedAnswers = userAnswers.filter(answer => answer === null);

    const correctAnswers = userResponses.filter(
        (response, index) => response === true);

    const skippedAnswersShare = Math.round(
        (skippedAnswers.length / userAnswers.length) * 100
    );

    const correctAnswersShare = Math.round(
        (correctAnswers.length / userAnswers.length) * 100
    );

    const wrongAnswersShare = 100 - skippedAnswersShare - correctAnswersShare;

    const endEvaluation = () => {
        const newEarnBadge = {
            id_badge: active.badge[0],
            id_evaluation: active.id,
            result: correctAnswersShare
        }

        if (correctAnswersShare > 60) {
            dispatch(startNewEarnBadge(newEarnBadge));
        }

        showSuccess();
        onClick(false);
        // showSuccess();
        // navigate("/badges");
    }

    const showSuccess = () => {
        Swal.fire('Ha finalizado la evaluación correctamente', 'Saved', 'success');
    };

    return <div className='pt-20 w-full place-content-center justify-center items-center'>
        <div className='w-full'>
            <h2>Se ha finalizado la evaluación</h2>
        </div>

        <div className='w-full mt-10 flex place-content-center justify-center items-center'>
            <img className=' w-48' src={quizCompleteImg} alt='Trophy icon' />
        </div>

        {correctAnswersShare > 60 &&
            <div id="summary-stats">

                <div className='w-full'>
                    <div className='w-full pt-9 pl-6'>
                        <CircularProgressbar
                            className='w-24 h-24'
                            value={correctAnswersShare}
                            text={correctAnswersShare + '%'}
                            styles={buildStyles({ pathColor: "#8BC34A", textColor: '#525967', textSize: 24 })}
                        />
                    </div>
                </div>
                <div className='w-full pt-12'>
                    <p>
                        <span className='number'>{correctAnswersShare}%</span>
                        <span className='text'>Contestadas Correctamente</span>
                    </p>
                    <p>
                        <span className='number'>{wrongAnswersShare}%</span>
                        <span className='text'>Contestadas Incorrectamente</span>
                    </p>
                </div>
            </div>
        }
        {correctAnswersShare < 60 &&
            <div id="summary-stats">
                <div className='w-full'>
                    <div className='w-full pt-9 pl-6'>
                        <CircularProgressbar
                            className='w-24 h-24'
                            value={correctAnswersShare}
                            text={correctAnswersShare + '%'}
                            styles={buildStyles({ pathColor: "#FF0000", textColor: '#FF0000', textSize: 24 })}
                        />
                    </div>
                </div>
                <div className='w-full pt-12'>
                    <p>
                        <span className='number'>{correctAnswersShare}%</span>
                        <span className='text'>Contestadas Correctamente</span>
                    </p>
                    <p>
                        <span className='number'>{wrongAnswersShare}%</span>
                        <span className='text'>Contestadas Incorrectamente</span>
                    </p>
                </div>
            </div>
        }

        <div className='flex flex-row mt-10 justify-center'>
            <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => endEvaluation()}>Finalizar</button>
        </div>
    </div>
}