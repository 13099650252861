import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";

export const loadApplyingJob = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'applyingJob'),
        where('uid', '==', uid)
    )

    const docs = await getDocs(q);

    const applyingjobs = [];
    docs.forEach(doc => {
        applyingjobs.push({ id: doc.id, ...doc.data() });
    })
    return applyingjobs;
}

export const loadAppliedJobs = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'applyingJob'),
        where('uid', '==', uid)
    )

    const docs = await getDocs(q);

    const applyingjobs = [];

    docs.forEach(doc => {
        applyingjobs.push({ id: doc.id, ...doc.data() });
    })
    return applyingjobs;
}

export const loadCurrentProcessJob = async (idCompany = '') => {
    console.log('llega a loadCurrentProcessJob');
    console.log('idCompany', idCompany);
    const q = query(
        collection(FirebaseDB, 'applyingJob'),
        where('companyId', '==', idCompany)
    )
    const docs = await getDocs(q);
    const currentProcess = [];

    docs.forEach(doc => {
        currentProcess.push({ id: doc.id, ...doc.data() });
    })
    console.log('currentProcess en loadCurrentProcessJobs', currentProcess);
    return currentProcess;
}