import React, { useState, useEffect } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

const hours = [
    { name: '8:00 a.m.', value: '8' },
    { name: '9:00 a.m.', value: '9' },
    { name: '10:00 a.m.', value: '10' },
    { name: '11:00 a.m.', value: '11' },
    { name: '12:00 p.m.', value: '12' },
    { name: '1:00 p.m.', value: '1' },
    { name: '2:00 p.m.', value: '2' },
    { name: '3:00 p.m.', value: '3' },
    { name: '4:00 p.m.', value: '4' },
    { name: '5:00 p.m.', value: '5' }
]

export const FinalStage = ({ sendUpdate, sendInterview, sendRejected, data }) => {
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [startHour, setStartHour] = useState('');
    const [endHour, setEndHour] = useState('');
    const [interviewLink, setInterviewLink] = useState('');
    const [scheduled, setScheduled] = useState(false);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            date: "",
            startHour1: '',
            endHour1: '', 
            link: ''
        }
    });

    useEffect(() => {
        if (data.finalInterviewEmailSend === true) {
            setScheduled(true);
        }
    }, [data])

    useEffect(() => {

    }, [scheduled])

    useEffect(() => {
        console.log('startDate', startDate);
        setValue('date', startDate);
    }, [startDate])

    useEffect(() => {
        setValue('startHour1', startHour);
    }, [startHour])

    useEffect(() => {
        setValue('endHour1', endHour);
    }, [endHour])

    const onSubmit = (data) => {
        // setValue('date', startDate);
        // setValue('startHour1', startHour);
        // setValue('endHour1', endHour);
        sendUpdate(6, data);
    }

    const onReject = (data) => {
        sendRejected(4, data, 'Rejected');
    }

    const sendDate = (data) => {
        setValue('date', startDate);
        setValue('startHour1', startHour);
        setValue('endHour1', endHour);
        setValue('link', interviewLink);
        sendInterview(6, data);
    }

    const handleChangeStartTime = (event) => {
        const {
            target: { value },
        } = event;
        setStartHour(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeEndTime = (event) => {
        const {
            target: { value },
        } = event;
        setEndHour(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <>
            <div className="w-full h-24 bg-zinc-100 flex">
                <div className='p-4'>
                    <img className="w-14 h-14 rounded-full" src={(data.profileImageUrl.length > 0) ? data.profileImageUrl : "https://via.placeholder.com/59x59"} />
                </div>
                <div className='pt-6'>
                    <div className="text-slate-800 text-lg font-bold font-['Poppins']">Schedule Final Interview</div>
                    <div className="text-gray-600 text-sm font-medium font-['Poppins']">{data?.displayName}</div>
                </div>
            </div>
            {scheduled && 
                <div className='w-full mb-10'>
                <div >
                    <div className='w-full pt-4 pl-10 pr-4'>
                        <p className="text-gray-600 text-sm font-medium font-['Poppins']">El candidato ha pasado la entrevista final cumpliendo los requisitos establecidos para esta etapa?, agrega un comentario ya sea que haya aprobado o declinado.</p>
                    </div>
                    <div className='flex flex-row mt-4 pl-6'>
                        <div className='w-full pl-6 pr-6'>
                            <TextField
                                multiline
                                rows={4}
                                placeholder='Agregar Comentario'
                                className='w-full'
                                error={errors.summary?.type === "required" ? true : false}
                                {...register("summary", { required: true, minLength: 5 })}
                            />
                            <div className='w-full flex flex-row pt-0'>
                                {
                                    errors.summary && errors.summary.type === "required" &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">Comment is required</p>
                                }
                                {
                                    errors.summary && errors.summary.type === "minLength" &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">Must have at least 5 characters</p>
                                }
                            </div>
                        </div>

                    </div>

                    <div className='flex flex-row'>
                        <div className='w-full justify-center items-center text-center pt-8'>
                            <button className="w-72 h-14 px-8 py-1 bg-sky-900 rounded-3xl justify-center items-center gap-2.5 inline-flex text-white text-base font-medium font-['Poppins']" onClick={handleSubmit(onSubmit)}>
                                Approve
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-row pb-10'>
                        <div className='w-full justify-center items-center text-center pt-2'>
                            <button className="w-72 h-14 px-8 py-1 bg-orange-500 rounded-3xl justify-center items-center gap-2.5 inline-flex text-white text-base font-medium font-['Poppins']" onClick={handleSubmit(onReject)}>
                                Reject
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            }
            {!scheduled && 
            <div className='w-full'>
                <div>
                    <div className='w-full pt-2 pl-10 pr-4'>
                        <p className="text-gray-600 text-sm font-medium font-['Poppins']">Agenda una entrevista con el candidato por medio de Zoom.</p>
                        {/* <br /><p>Ingresa fecha y hora para la entrevista.</p> */}
                    </div>
                    <div className='flex flex-row mt-4  pl-12 pr-12'>
                        <div className='w-full justify-center'>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker value={startDate} onChange={(newValue) => setStartDate(newValue)} className='w-11/12' />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>

                    </div>
                    <div className='flex flex-row mt-2 pl-12 pr-12'>
                        <div className='w-1/2 pr-2'>
                            <FormControl className='w-11/12'>
                                <Select
                                    labelId='start-hour-label'
                                    id='startHour'
                                    label='startTime'
                                    defaultValue=''
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={startHour}
                                    onChange={handleChangeStartTime}
                                >
                                    {hours.map((name) => (
                                        <MenuItem key={name.value} value={name.value}>
                                            {name.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-1/2 pr-4'>
                            <FormControl className='w-11/12'>
                                <Select
                                    labelId='end-hour-label'
                                    id='endHour'
                                    label='endTime'
                                    defaultValue=''
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    value={endHour}
                                    onChange={handleChangeEndTime}
                                >
                                    {hours.map((name) => (
                                        <MenuItem key={name.value} value={name.value}>
                                            {name.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='flex flex-row mt-2 pl-12 pr-12'>
                        <div className='w-full pr-8'>
                            <TextField
                                multiline
                                rows={3}
                                placeholder='Agregar Link de Reunion'
                                className='w-full'
                                error={errors.link?.type === "required" ? true : false}
                                {...register("link", { required: true, minLength: 5 })}
                            />
                            <div className='w-full flex flex-row pt-0'>
                                {
                                    errors.link && errors.link.type === "required" &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">link is required</p>
                                }
                                {
                                    errors.link && errors.link.type === "minLength" &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">Must have at least 5 characters</p>
                                }
                            </div>
                        </div>

                    </div>
                    <div className='flex flex-row'>
                        <div className='w-full justify-center items-center text-center pt-4'>
                            <button className="w-72 h-14 px-8 py-1 bg-orange-500 rounded-3xl justify-center items-center gap-2.5 inline-flex text-white text-base font-medium font-['Poppins']"
                                onClick={handleSubmit(sendDate)}
                            >
                                Send Interview
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            }
        </>
    )
}
