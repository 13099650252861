import React, { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'

function MyTimer({ expiryTimestamp, onClick }) {
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

    useEffect(() => {
        if (!isRunning) {
            onClick(true);
        }
    }, [isRunning])

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '25px' }}>
                <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            </div>
        </div>
    );
}

export const TimerEvaluation = ({ evaluationTime, onClick }) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + (evaluationTime * 60));  // 10 minutes timer

    const finishEvaluation = () => {
        onClick(true);
    }

    return (
        // <div>00:00:00</div>
        <div>
            <MyTimer expiryTimestamp={time} onClick={finishEvaluation} />
            {/* <MyTimer expiryTimestamp={time} /> */}
        </div>
    )
}
