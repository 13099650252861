import { collection, deleteDoc, doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../../firebase/config";
import { loadBadgesCatalog } from "../../../helpers/loadBadgesCatalog";
import { addNewEmptyBadgesCatalog, deleteBadgeById, deletebadgeById, setActiveBadgesCatalog, setBadgesCatalog, setBadgesCatalogs, setSaving, updateBadgesCatalog } from "./badgesCatalogSlice";


export const startNewBadgesCatalog = (badge) => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const {
            name,
            description,
            categorie,
            imageUrl,
            companyId
        } = badge;

        const newBadge = {
            name,
            description,
            categorie,
            imageUrl,
            companyId,
            creationDate: new Date().getTime(),
            uid
        }

        const newDoc = doc(collection(FirebaseDB, `badges`));
        await setDoc(newDoc, newBadge);
        newBadge.id = newDoc.id;
        dispatch(addNewEmptyBadgesCatalog(newBadge));
        dispatch(setActiveBadgesCatalog(newBadge));
    }
}

export const startLoadingBadgesCatalog = (idCompany = '') => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        // if (!uid) throw new Error("El UID del usuario no existe");
        const badgesCatalog = await loadBadgesCatalog();
        dispatch(setBadgesCatalogs(badgesCatalog));
    }
}

export const startSaveBadgesCatalog = () => {
    return async (dispatch, getState) => {
        dispatch(setSaving());

        const { active: badgesCatalog } = getState().badgesCatalog;
        const badgeToFireStore = { ...badgesCatalog };
        delete badgeToFireStore.id;

        const docRef = doc(FirebaseDB, `badges/${badgesCatalog.id}`)
        await setDoc(docRef, badgeToFireStore, { merge: true });
        dispatch(updateBadgesCatalog(badgesCatalog));
    }
}

export const startDeleteBadge = (badgeId) => {
    return async (dispatch) => {
        try {
            const docRef = doc(FirebaseDB, `badges/${badgeId}`);
            await deleteDoc(docRef);
            dispatch(deleteBadgeById(badgeId));
            // Aquí puedes hacer más cosas después de eliminar, si es necesario
        } catch (error) {
            // Maneja cualquier error aquí
            console.error("Error deleting badge:", error);
        }
    }
}
