import React, { useEffect, useState } from 'react'
import { Fab, FormControl, InputLabel, MenuItem, Select, TextField, Modal, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { FirebaseStorage } from '../../firebase/config';
import { useSelector } from 'react-redux';
import { UploadFile } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const CertificatesEmployee = ({ getCertificates, data }) => {
    const [certificate, setCertificate] = useState([]);
    const [certificateTmp, setCertificateTmp] = useState({});
    const [open, setOpen] = React.useState(false);
    const [file, setFile] = useState("");
    const { uid } = useSelector(state => state.auth);
    const [urlCertificate, setUrlCertificate] = useState('');
    const delay = (ms) => new Promise(res => setTimeout(res, ms));
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            certificateType: "",
            certificateName: "",
            institutionName: "",
        }
    });
    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
            setCertificate(data);
        }
    }, [data])

    useEffect(() => {
        if (certificateTmp && urlCertificate) {
            const {
                certificateType,
                certificateName,
                institutionName
            } = certificateTmp;

            const data = {
                certificateType,
                certificateName,
                institutionName,
                urlCertificate: urlCertificate
            }
            setCertificate([...certificate, data])

        }
    }, [urlCertificate])

    const saveImage = () => {
        if (file) {
            const storageRef = ref(FirebaseStorage, `certificates/profile/${uid}/${file.name}`)

            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setUrlCertificate(url);
                })
            })
        }
    }

    const handleChangeFile = (event) => {
        setFile(event.target.files[0]);
    }

    useEffect(() => {
        if (certificate && urlCertificate) {
            saveCertificate(certificate);
        }
    }, [certificate])

    useEffect(() => {
        if (certificateTmp) {
            saveImage();
        }
    }, [certificateTmp])

    const onSubmit = async (dataCertificate) => {
        setCertificateTmp(dataCertificate);
    }

    const saveCertificate = (dataCertificate) => {
        getCertificates(dataCertificate);
        handleClose();
        limpiar();
    }

    const limpiar = () => {
        setValue('certificateType', "")
        setValue('certificateName', "")
        setValue('urlCertificate', "")
        setValue('institutionName', "")
        setUrlCertificate("");
        setFile("");
    }

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <>
            <div className='flex flex-row w-full mt-8 pl-6'>
                <div className='w-full text-left'>
                    <p className='inline-flex text-zinc-800 pt-1 font-bold text-[18px]'>{t('profileTabEmployee.certificates')}</p>
                </div>
                <div className='w-1/2 justify-end items-end place-content-end'>
                    <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                        onClick={handleOpen}>
                        + {t('profileTabEmployee.addCertificates')}
                        {/* <img className="w-5 h-5 relative" src='' /> */}
                    </button>
                </div>
            </div>
            {certificate &&
                <div className='w-full pl-6 pt-8'>
                    <table className='w-full'>
                        {certificate.map((certificate, index) => (
                            <tr key={index}>
                                <td className=''>{certificate.certificateType}</td>
                                <td className='pl-4'>{certificate.certificateName}</td>
                                <td className='pl-4'><img className='w-6 h-6' src={certificate.urlCertificate} alt='' /></td>
                                {/*<td><button className='pl-4' type='button' onClick={() => handleOpen()}><img className=" w-5 h-5" src="../assets/icons/edit.png" alt='' /></button></td>*/}
                            </tr>
                        ))}
                    </table>
                </div>
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full mt-2 pl-6 text-left'>
                        <div className='w-1/2 mt-4'>
                            <p className=' text-zinc-800 font-bold text-[18px]'>{t('profileTabEmployee.certificates')}</p>
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-4 justify-between'>
                        <div className='w-4/12'>
                            <FormControl className='w-11/12'>
                                <InputLabel id="certificate-type-label">{t('certificateEmployee.type')}</InputLabel>
                                <Select
                                    labelId="certificate-type-label"
                                    id="certificate-type-level"
                                    label={t('certificateEmployee.type')}
                                    placeholder={t('certificateEmployee.type')}
                                    defaultValue=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    {...register("certificateType")}
                                >
                                    <MenuItem value="Titulo" >Titulo Universitario</MenuItem>
                                    <MenuItem value="Diploma"  >Diploma</MenuItem>
                                    <MenuItem value="Certificacion"  >Certificación</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-4/12'>
                            <TextField
                                id={t('certificateEmployee.name')}
                                className='w-11/12'
                                placeholder={t('certificateEmployee.name')}
                                {...register("certificateName")}
                            />
                        </div>
                        <div className='w-4/12'>
                            <TextField
                                id='certificate-institution-name'
                                className='w-11/12'
                                placeholder={t('certificateEmployee.institution')}
                                {...register("institutionName")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-4 justify-between'>
                        <div className='w-1/2'>
                            <input className='pt-6' type="file" onChange={handleChangeFile} />
                        </div>
                    </div>
                    <div className='flex flex-row mt-20 justify-end'>
                        <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleSubmit(onSubmit)}>{t('pagesEmployee.save')}</button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}