// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBpNzrF9kvJAvG7nh6czJowffwcD6tGrGY",
    authDomain: "xrecruit-5c93b.firebaseapp.com",
    projectId: "xrecruit-5c93b",
    storageBucket: "xrecruit-5c93b.appspot.com",
    messagingSenderId: "46374652165",
    appId: "1:46374652165:web:16d674a1c5eb0d38398f6c",
    measurementId: "G-C6GLMC7HT9"
};

// Initialize Firebase
export const FirebaseApp = initializeApp(firebaseConfig);
export const FirebaseAuth = getAuth(FirebaseApp);
export const FirebaseDB = getFirestore(FirebaseApp)
export const FirebaseStorage = getStorage(FirebaseApp);