import { doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../../firebase/config";
import { setCompanyProfile, setCompanyProfiles, setSaving, updateCompanyProfile } from "./companyProfileSlice";
import { loadCompanyProfile, loadCompanyProfiles } from "../../../helpers/loadCompanyProfiles";


export const startNewClearCompanyProfile = (profile) => {

}

export const startNewCompanyProfile = (profile) => {

}

export const startLoadingCompanyProfile = (idCompany = '') => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const companyProfile = await loadCompanyProfile(idCompany);
        dispatch(setCompanyProfile(companyProfile));
    }
}

export const startLoadingCompanyProfiles = () => {
    return async (dispatch) => {
        // const { uid } = getState().auth;

        // if (!uid) throw new Error("El UID del usuario no existe");
        const companyProfiles = await loadCompanyProfiles();
        dispatch(setCompanyProfiles(companyProfiles));
    }
}

export const startSaveCompanyProfile = () => {
    return async (dispatch, getState) => {
        dispatch(setSaving());

        const { active: companyProfile } = getState().companyProfile;
        const profileToFireStore = { ...companyProfile };
        delete profileToFireStore.id;

        const docRef = doc(FirebaseDB, `companyProfile/${companyProfile.idCompany}`)
        await setDoc(docRef, profileToFireStore, { merge: true });
        dispatch(updateCompanyProfile(companyProfile));
    }
}
export const saveCompanyProfile = ({ idCompany, formdata }) => {
    return async (dispatch, getState) => {
        dispatch(setSaving());
        try {
            const docRef = doc(FirebaseDB, `companyProfile/${idCompany}`);
            const editedCompanyProfile = { ...formdata };
            await setDoc(docRef, editedCompanyProfile, { merge: true });
            dispatch(updateCompanyProfile({ idCompany, formdata }));
        } catch (e) {
            console.log("no funciona", e);
        }
    };
};
