import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { extraerDominio } from '../../../utils/ExtractDomain';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';
import { startLoadingCompanyProfiles } from '../../../store/slices/companyProfile/thunks';
import { startLoadingProfiles, startSaveProfile } from '../../../store/slices/profile/thunks';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { setActiveProfile, updateProfile } from '../../../store/slices/profile/profileSlice';

const options = [
    'Eliminar'
];
const ITEM_HEIGHT = 48;

export const TeamMembers = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { active, profile, profiles } = useSelector(state => state.profile);
    const { companyProfiles } = useSelector(state => state.companyProfile);
    const [teamMembers, setTeamMembers] = useState([]);
    const [firsTime, setFirstTime] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        dispatch(startLoadingProfiles());
    }, [])


    const handleClick = (event) => {

        setAnchorEl(event.currentTarget);
        console.log('entra a handleclick', event.currentTarget);
    };

    const handleClose = (event, option) => {
        event.preventDefault();
        const action = event.target.outerText;
        setAnchorEl(null);
        // if (option) {
        //     console.log('entra a activar perfil');

        // }
        if (action === 'Eliminar') {
            dispatch(setActiveProfile(option));
        }
    };

    useEffect(() => {
        if (active) {
            inactivateProfile(active);
        }
    }, [active])

    const inactivateProfile = (profile) => {

        const {
            companyName,
            displayName,
            email,
            id,
            idCompany,
            name,
            phone,
            photoURL,
            profileImageUrl,
            rol,
            role,
            typeAccount,
            uid
        } = profile

        const newData = {
            companyName,
            displayName,
            email,
            id,
            idCompany,
            name,
            phone,
            photoURL,
            profileImageUrl,
            rol,
            role,
            typeAccount,
            uid,
            status: 'Inactive'
        }

        if (newData) {
            dispatch(startSaveProfile(newData));
            dispatch(startLoadingProfiles());
            // Swal.fire({
            //     title: 'Se ha eliminado el perfil correctamente',
            //     text: 'Satisfactorio',
            //     icon: 'success',
            //     confirmButtonText: 'Ok'
            // }).then((result) => {
            //     if (result.isConfirmed) {
            //         window.location.reload();
            //     }
            // });
        }
    }

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            email: "",
        }
    })

    useEffect(() => {
        dispatch(startLoadingProfiles());
    }, [])

    useEffect(() => {
        let correo = profile[0].email;
        let company = profile[0].idCompany;

        let domain = extraerDominio(correo);
        if (profiles) {
            let teamTmp = profiles.filter(x => x.idCompany === company && x.email !== correo && x.status === 'Active');
            setTeamMembers(teamTmp);
            setFirstTime(false);
        }
    }, [profile, profiles])

    const showError = () => {
        Swal.fire('Error', 'El correo debe ser del mismo dominio de tu empresa', 'error');
    };

    const showSuccess = () => {
        Swal.fire('Success', 'Se ha enviado la invitación correctamente', 'success');
    };

    const sendEmail = (data) => {
        emailjs.send('service_at1ifea', 'template_jan8bmr', data, 'duPSJjSVD98Fu9J-t')
            .then(function (response) {
                showSuccess();
            }, function (error) {
                showError();
            });
    }


    const onSubmit = (data) => {
        let correo = data.email;
        let correoProfile = '';

        if (profile) {
            correoProfile = profile[0].email;
        }

        let dominioProfile = extraerDominio(correoProfile);
        let dominio = extraerDominio(correo);

        const newData = {
            email: correo,
            domain: dominio
        }

        if (dominioProfile !== dominio) {
            showError();
        } else {
            sendEmail(newData);
            clear();
        }
    }

    const clear = () => {
        setValue("email", "");
    }

    return (
        <div>


            <div className='w-full h-44 bg-neutral-100 rounded-xl'>
                <div className='w-full flex flex-row pt-8'>
                    <p className="w-full text-center text-zinc-800 text-2xl font-bold font-['Poppins']">{t('team.title')}</p>
                </div>
                <div className='w-full flex flex-row place-content-center pt-8'>
                    <div className='w-4/12'>
                        <input className="w-full h-[40px] text-gray-600 text-base font-medium font-['Poppins'] bg-white rounded-lg border border-gray-300 justify-start items-center inline-flex indent-3" placeholder='Email (s)' {...register('email', { required: true, minLength: 6 })} />
                    </div>
                    <div className='w-1/12'>

                    </div>
                    <div className='w-2/12'>
                        <button className="w-full h-[40px] justify-center items-center text-white text-base font-medium font-['Poppins'] bg-sky-900 rounded-3xl gap-2.5 inline-flex" onClick={handleSubmit(onSubmit)} >{t('team.button')}</button>
                    </div>
                </div>
            </div>

            <div className='w-full'>
                <p className="pt-10 left-[57px] top-[416px] absolute text-slate-800 text-2xl font-bold font-['Poppins']">{t('team.myTeam')}</p>
                {/* <div className="w-72 h-20 left-[57px] top-[476px] absolute">
                    <div className="w-72 h-20 left-0 top-0 absolute rounded-lg border border-neutral-300" />
                    <div className="left-[88px] top-[41px] absolute text-gray-600 text-xs font-normal font-['Poppins']">Role #1</div>
                    <div className="left-[88px] top-[20px] absolute text-zinc-800 text-base font-medium font-['Poppins']">Cynthia Boyer</div>
                    <div className="w-6 h-6 py-0.5 left-[261px] top-[11px] absolute justify-center items-center inline-flex" />
                    <img className="w-14 h-14 left-[12px] top-[11px] absolute" src="https://via.placeholder.com/59x59" />
                </div> */}
            </div>

            <div className='w-full flex flex-wrap pt-32'>
                {teamMembers.map((item, index) => (
                    <div key={index} className='w-72 h-20 border border-neutral-300 rounded-lg absolute pl-4'>
                        <img className="w-14 h-14 left-[12px] top-[11px] rounded-full absolute" src={item.profileImageUrl ? item.profileImageUrl : '../../../assets/images/login-user-default.png'} />
                        <div className="left-[88px] top-[41px] absolute text-gray-600 text-xs font-normal font-['Poppins']">User</div>
                        <div className="left-[88px] top-[20px] absolute text-zinc-800 text-base font-medium font-['Poppins']">{item.displayName}</div>


                        <div className='absolute left-[240px]'>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? 'long-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                <MoreVert />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5,
                                        width: '20ch',
                                    },
                                }}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option} selected={option === 'Eliminar'} onClick={(e) => handleClose(e, item)}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>



                    </div>
                ))}
            </div>

            {/* <div className='pt-20'>
                <div className='w-full flex flex-wrap justify-between'>
                    <div className='w-1/3 h-20 '>

                    </div>

                </div>

            </div> */}





        </div>
    )
}
