import { useRef } from 'react';

export default function Answers({
    answers,
    selectedAnswer,
    answerState,
    onSelect
}) {
    const shuffledAnswers = useRef();

    if (!shuffledAnswers.current) {
        shuffledAnswers.current = [...answers];
        shuffledAnswers.current.sort(() => Math.random() - 0.5);
    }

    return (
        <ul id='answers' className="">
            {shuffledAnswers.current.map((answer) => {
                const isSelected = selectedAnswer === answer;
                let cssClass = '';

                if (answerState === 'answered' && isSelected) {
                    cssClass = 'selected';
                }

                if (
                    (answerState === 'correct' || answerState === 'wrong') &&
                    isSelected
                ) {
                    cssClass = answerState;
                }

                return (

                    <li key={answer} className="hover:transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...">

                        <button className='w-full flex flex-row justify-center items-center h-20 border-2'
                            onClick={() => onSelect(answer)}
                            // className={cssClass}
                            disabled={answerState !== ''}>
                            {answer}
                        </button>
                    </li>
                );
            })}
        </ul>
    )
}