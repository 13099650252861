import { StopCircle } from '@mui/icons-material';
import React, { useState, useRef, useCallback } from 'react'
import Webcam from 'react-webcam';
import { FirebaseStorage } from '../../firebase/config';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';

export const VideoInterview = ({ onClickVideo, data }) => {
    const { uid } = useSelector(state => state.auth);
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(1);
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [urlVideo, setUrlVideo] = useState('');
    console.log('data que devuelve VideoInterview', data);


    const videoConstraints = {
        // width: "-webkit -fill -available",
        width: 640,
        height: 360,
        facingMode: "environment",
        border: "5px solid #444",
        width: { min: 480 },
        height: { min: 720 },
        aspectRatio: 0.6666666667
    };

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    };

    const closeDetail = () => {
        onClickVideo(false);
    }

    const showSuccess = () => {
        Swal.fire('Ha finalizado la entrevista correctamente', 'Saved', 'success');
    };

    const startInterview = () => {
        console.log('startInterview');
        setStep(2);
        // setVideoSaved1(false);
        //  handleStartCaptureClick;
    }

    const endInterview = () => {
        showSuccess();
        onClickVideo(false);
    }

    const nextVideo = (idVideo) => {
        console.log('llega a nextVideo', idVideo);
        if (idVideo === 0) {
            handleDownload();
            setStep(3);
        }
        if (idVideo === 1) {
            handleDownload();
            setStep(4);
        }
        if (idVideo === 2) {
            handleDownload();
            setStep(5);
        }
        if (idVideo === 3) {
            handleDownload();
            setStep(6);
        }
        if (idVideo === 4) {
            handleDownload();
            setStep(7);
        }
    }

    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStartCaptureClick = useCallback(() => {
        console.log('inicia a grabar');
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        // handleDownload();
        setCapturing(false);
        // setPreview(true);

    }, [mediaRecorderRef, setCapturing]);

    const handleDownload = useCallback(() => {
        console.log('entra a handleDowload');
        // setPreview(false);
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                // type: "video/webm",
                type: "video/mp4",
            });
            const url = URL.createObjectURL(blob);

            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "react-webcam-stream-capture.webm";
            a.click();
            if (a) {
                const videoRef = ref(FirebaseStorage, `videos/profileInterview/${uid + step}`);
                uploadBytes(videoRef, blob).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                        setUrlVideo(url);
                    })
                })
            }
            window.URL.revokeObjectURL(url);
            setRecordedChunks([]);
            setOpen(false);
        }
    }, [recordedChunks]);

    return (

        <div className='w-full bg-white  min-h-[720px]'>



            <div className='w-full flex flex-row pt-4 h-20'>
                <div className='w-1/12 pt-2'>
                    <button onClick={() => closeDetail()}><img className="w-6 h-6 rounded-mx" src="../assets/icons/left-arrow-from-left.png" alt='' /></button>
                </div>
            </div>

            {step == 1 &&
                <div className='pt-20'>
                    <p>Estas por iniciar la entrevista para el puesto, asegurate de disponer del tiempo para realizarla, al darle iniciar ya no podrás pausar la entrevista. </p>

                    <div className='flex flex-row mt-10 justify-center'>
                        <button type="button" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => startInterview()}>Iniciar</button>
                    </div>

                </div>
            }
            {step == 2 &&
                <div className='pt-20'>
                    <p>{data[0]}</p>
                    {/* {step == 2 && !videoSaved1 &&
                        <div className='flex flex-row mt-10 justify-center'>
                            <button type="button" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => startVideo(0)}>Iniciar Video</button>
                        </div>
                    }
                    {videoSaved1 &&
                        <div className='flex flex-row mt-10 justify-center'>
                            <button type="button" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => startVideo(1)}>Siguiente</button>
                        </div>
                    } */}

                    <div className='w-full flex flex-row justify-center pt-10'>
                        <div className='w-1/3'></div>
                        <div className='w-1/3 justify-center items-center pl-20'>
                            <Webcam
                                audio={true}
                                // height={'100%'}
                                screenshotFormat="image/jpeg"
                                frameBorder={"10px"}
                                // width={'40%'}
                                width={240}
                                height={360}
                                ref={webcamRef}
                                videoConstraints={videoConstraints}
                            >
                            </Webcam>

                        </div>
                        <div className='w-1/3'></div>
                    </div>
                    <div className='w-full flex flex-row justify-center'>
                        <div className='w-1/3'></div>
                        <div className='w-1/3'>
                            {capturing ? (
                                // <button type='button' onClick={handleStopCaptureClick}>Stop Capture</button>
                                // <div className='text-center w-20 h-20'>
                                //     <StopCircle onClick={handleStopCaptureClick} />
                                // </div>
                                <div className='flex flex-row mt-8 justify-center'>
                                    <button type="button" className='w-40 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleStopCaptureClick}>Finalizar</button>
                                </div>
                            ) : (

                                <div style={{ textAlign: 'center' }}>
                                    {/* <button className=" rounded-full p-button-danger mr-2 mb-2 mt-4 bg-red-600 h-14 w-14" onClick={handleStartCaptureClick} /> */}
                                    <div className='flex flex-row mt-10 justify-center'>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => nextVideo(0)}>Siguiente</button>
                                    </div>
                                </div>
                            )}
                            {recordedChunks.length > 0 && (
                                // <button type='button' onClick={handleDownload}>Download</button>
                                <div className='flex flex-row mt-8 justify-center'>
                                    <button type="button" className='w-40 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleDownload()}>Siguiente1</button>
                                </div>
                            )}
                        </div>
                        <div className='w-1/3'></div>
                    </div>
                </div>
            }
            {step == 3 &&
                <div className='pt-20'>
                    <p>{data[1]}</p>

                    <div className='w-full flex flex-row justify-center pt-10'>
                        <div className='w-1/3'></div>
                        <div className='w-1/3 justify-center items-center pl-20'>
                            <Webcam
                                audio={true}
                                // height={'100%'}
                                screenshotFormat="image/jpeg"
                                frameBorder={"10px"}
                                // width={'40%'}
                                width={240}
                                height={360}
                                ref={webcamRef}
                                videoConstraints={videoConstraints}
                            >
                            </Webcam>

                        </div>
                        <div className='w-1/3'></div>
                    </div>
                    <div className='w-full flex flex-row justify-center'>
                        <div className='w-1/3'></div>
                        <div className='w-1/3'>
                            {capturing ? (
                                <div className='flex flex-row mt-8 justify-center'>
                                    <button type="button" className='w-40 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleStopCaptureClick}>Finalizar</button>
                                </div>
                            ) : (

                                <div style={{ textAlign: 'center' }}>
                                    <div className='flex flex-row mt-10 justify-center'>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => nextVideo(1)}>Siguiente</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='w-1/3'></div>
                    </div>

                </div>
            }
            {step == 4 &&
                <div className='pt-20'>
                    <p>{data[2]}</p>
                    <div className='w-full flex flex-row justify-center pt-10'>
                        <div className='w-1/3'></div>
                        <div className='w-1/3 justify-center items-center pl-20'>
                            <Webcam
                                audio={true}
                                // height={'100%'}
                                screenshotFormat="image/jpeg"
                                frameBorder={"10px"}
                                // width={'40%'}
                                width={240}
                                height={360}
                                ref={webcamRef}
                                videoConstraints={videoConstraints}
                            >
                            </Webcam>

                        </div>
                        <div className='w-1/3'></div>
                    </div>
                    <div className='w-full flex flex-row justify-center'>
                        <div className='w-1/3'></div>
                        <div className='w-1/3'>
                            {capturing ? (
                                <div className='flex flex-row mt-8 justify-center'>
                                    <button type="button" className='w-40 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleStopCaptureClick}>Finalizar</button>
                                </div>
                            ) : (

                                <div style={{ textAlign: 'center' }}>
                                    <div className='flex flex-row mt-10 justify-center'>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => nextVideo(2)}>Siguiente</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='w-1/3'></div>
                    </div>

                </div>
            }
            {step == 5 &&
                <div className='pt-20'>
                    <p>{data[3]}</p>

                    <div className='w-full flex flex-row justify-center pt-10'>
                        <div className='w-1/3'></div>
                        <div className='w-1/3 justify-center items-center pl-20'>
                            <Webcam
                                audio={true}
                                // height={'100%'}
                                screenshotFormat="image/jpeg"
                                frameBorder={"10px"}
                                // width={'40%'}
                                width={240}
                                height={360}
                                ref={webcamRef}
                                videoConstraints={videoConstraints}
                            >
                            </Webcam>

                        </div>
                        <div className='w-1/3'></div>
                    </div>
                    <div className='w-full flex flex-row justify-center'>
                        <div className='w-1/3'></div>
                        <div className='w-1/3'>
                            {capturing ? (
                                <div className='flex flex-row mt-8 justify-center'>
                                    <button type="button" className='w-40 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleStopCaptureClick}>Finalizar</button>
                                </div>
                            ) : (

                                <div style={{ textAlign: 'center' }}>
                                    <div className='flex flex-row mt-10 justify-center'>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => nextVideo(3)}>Siguiente</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='w-1/3'></div>
                    </div>

                </div>
            }
            {step == 6 &&
                <div className='pt-20'>
                    <p>Has finalizado la entrevista satisfactoriamente, puedes consultar tu progreso en cuanto tu evaluación haya sido validada para el puesto.</p>

                    <div className='flex flex-row mt-10 justify-center'>
                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => endInterview()}>Finalizar</button>
                    </div>
                </div>
            }
        </div>

    )
}
