import { FormControl, MenuItem, Select, TextField, OutlinedInput, RadioGroup, FormControlLabel, Radio, FormLabel, FormGroup, Checkbox, InputLabel, Modal, Box, Typography, ListItemText, Autocomplete, Fab } from '@mui/material';
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { FirebaseStorage } from '../../../firebase/config';
import Webcam from 'react-webcam';
import { startEditJob, startLoadingJobs, startNewJob } from '../../../store/slices/job/thunks';
import { BasicFitCheck } from './BasicFitCheck';
import { Compatibility } from './Compatibility';
import { SkillsAssesments } from './SkillsAssesments';
import { ManualFiltering } from './ManualFiltering';
import { VideoInterview } from './VideoInterview';
import { FinalInterview } from './FinalInterview';
import { Delete, StopCircle } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import { tagsJ } from '../../../store/slices/job/tags';
import { startLoadingBadgeCategories } from '../../../store/slices/badgesCategories/thunks';
import { startLoadingBadgesCatalog } from '../../../store/slices/badgesCatalog/thunks';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Beneficio 1',
    'Beneficio 2',
    'Beneficio 3'
];

const basicFitCheck = [
    'Psicometricos',
    'Númericos'
];

const skills = [
    'React',
    'Java',
    'C#',
    'Python',
    'Typescript'
];

export const CreateAJob = ({ onClick, jobEdit, jobDetail, jobId }) => {
    const { uid, email } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [videoSaved, setVideoSaved] = useState(false);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const { profile } = useSelector(state => state.profile);
    const { badgesCategories } = useSelector(state => state.badgesCategorie);
    const { badgesCatalogs } = useSelector(state => state.badgesCatalog);
    const [tmpJob, setTmpJob] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [basicFitCheck, setBasicFitCheck] = useState([]);
    const [compatibilityCheck, setCompatibilityCheck] = useState([]);
    const [manualFilteringCheck, setManualFilteringCheck] = useState(false);
    const [skillsAssessmentsCheck, setSkillsAssessmentsCheck] = useState([]);
    const [videoInterviewCheck, setVideoInterviewCheck] = useState(false);
    const [finalInterviewCheck, setFinalInterviewCheck] = useState(false);
    const webcamRef = useRef(null);
    const [benefitName, setBenefitName] = useState([]);
    const [basicName, setBasicName] = useState([]);
    const [skillsName, setSkillsName] = useState([]);
    const [jobModel, setJobModel] = useState('');
    const [showWebCam, setShowWebCam] = useState(false);
    const [disableCountry, setDisableCountry] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [selected, setIsSelected] = useState(false);
    const [videoFileName, setVideoFileName] = useState("");
    const { t } = useTranslation()
    const [file, setFile] = useState(null);
    const [urlVideo, setUrlVideo] = useState('');
    const job = useSelector(state => state.job.active);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [jobLocation, setJobLocation] = useState([]);
    const [jobDepartment, setJobDepartment] = useState('');
    const [filterTitle, setFilterTitle] = useState([]);
    const [filterTags, setFilterTags] = useState([]);
    const { companyProfile } = useSelector(state => state.companyProfile)
  

    const paisesDelMundo = [
        "Afganistán",
        "Albania",
        "Alemania",
        "Andorra",
        "Angola",
        "Antigua y Barbuda",
        "Arabia Saudita",
        "Argelia",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaiyán",
        "Bahamas",
        "Bangladés",
        "Barbados",
        "Baréin",
        "Bélgica",
        "Belice",
        "Benín",
        "Bielorrusia",
        "Birmania",
        "Bolivia",
        "Bosnia y Herzegovina",
        "Botsuana",
        "Brasil",
        "Brunéi",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Bután",
        "Cabo Verde",
        "Camboya",
        "Camerún",
        "Canadá",
        "Catar",
        "Chad",
        "Chile",
        "China",
        "Chipre",
        "Ciudad del Vaticano",
        "Colombia",
        "Comoras",
        "Corea del Norte",
        "Corea del Sur",
        "Costa de Marfil",
        "Costa Rica",
        "Croacia",
        "Cuba",
        "Dinamarca",
        "Dominica",
        "Ecuador",
        "Egipto",
        "El Salvador",
        "Emiratos Árabes Unidos",
        "Eritrea",
        "Eslovaquia",
        "Eslovenia",
        "España",
        "Estados Unidos",
        "Estonia",
        "Etiopía",
        "Filipinas",
        "Finlandia",
        "Fiyi",
        "Francia",
        "Gabón",
        "Gambia",
        "Georgia",
        "Ghana",
        "Granada",
        "Grecia",
        "Guatemala",
        "Guinea",
        "Guinea-Bisáu",
        "Guinea Ecuatorial",
        "Guyana",
        "Haití",
        "Honduras",
        "Hungría",
        "India",
        "Indonesia",
        "Irak",
        "Irán",
        "Irlanda",
        "Islandia",
        "Islas Marshall",
        "Islas Salomón",
        "Israel",
        "Italia",
        "Jamaica",
        "Japón",
        "Jordania",
        "Kazajistán",
        "Kenia",
        "Kirguistán",
        "Kiribati",
        "Kuwait",
        "Laos",
        "Lesoto",
        "Letonia",
        "Líbano",
        "Liberia",
        "Libia",
        "Liechtenstein",
        "Lituania",
        "Luxemburgo",
        "Macedonia del Norte",
        "Madagascar",
        "Malasia",
        "Malaui",
        "Maldivas",
        "Malí",
        "Malta",
        "Marruecos",
        "Mauricio",
        "Mauritania",
        "México",
        "Micronesia",
        "Moldavia",
        "Mónaco",
        "Mongolia",
        "Montenegro",
        "Mozambique",
        "Namibia",
        "Nauru",
        "Nepal",
        "Nicaragua",
        "Níger",
        "Nigeria",
        "Noruega",
        "Nueva Zelanda",
        "Omán",
        "Países Bajos",
        "Pakistán",
        "Palaos",
        "Panamá",
        "Papúa Nueva Guinea",
        "Paraguay",
        "Perú",
        "Polonia",
        "Portugal",
        "Qatar",
        "Reino Unido",
        "República Centroafricana",
        "República Checa",
        "República del Congo",
        "República Democrática del Congo",
        "República Dominicana",
        "Ruanda",
        "Rumanía",
        "Rusia",
        "Samoa",
        "San Cristóbal y Nieves",
        "San Marino",
        "San Vicente y las Granadinas",
        "Santa Lucía",
        "Santo Tomé y Príncipe",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leona",
        "Singapur",
    ]

    const titleTics = [
        "Cloud Architect",
    "Data Specialist",
    "Full Stack Developer",
    "Frontend Developer",
    "Backend Developer",
    "Data Scientist",
    "Machine Learning Engineer",
    "Artificial Intelligence Engineer",
    "Software Engineer",
    "Quality Assurance Engineer",
    "Systems Engineer",
    "Database Administrator",
    "Security Engineer",
    "Web Developer",
    "Mobile Developer",
    "Game Developer",
    "DevOps Engineer",
    "Cloud Engineer",
    "Data Architect",
    "Software Architect",
    "Technical Writer",
    "UX/UI Designer",
    "Product Manager",
    "Project Manager",
    "Business Analyst",
    "Software Consultant",
    "Software Trainer",
    "Freelancer",
    "Entrepreneur"
    ]

    const titledep1 =[
        t("titlesdep1.t1"),
        t("titlesdep1.t2"),
        t("titlesdep1.t3"),
        t("titlesdep1.t4"),
        t("titlesdep1.t5"),
        t("titlesdep1.t6"),
        t("titlesdep1.t7"),
        t("titlesdep1.t8"),
        t("titlesdep1.t9"),
        t("titlesdep1.t10"),
        t("titlesdep1.t11"),
        t("titlesdep1.t12"),
        t("titlesdep1.t13"),
        t("titlesdep1.t14"),
        t("titlesdep1.t15"),
        t("titlesdep1.t16"),
        t("titlesdep1.t17"),
        t("titlesdep1.t18"),
        t("titlesdep1.t19"),
        t("titlesdep1.t20")
    ]

    const titledep2 = [
        t("titlesdep2.t1"),
        t("titlesdep2.t2"),
        t("titlesdep2.t3"),
        t("titlesdep2.t4"),
        t("titlesdep2.t5"),
        t("titlesdep2.t6"),
        t("titlesdep2.t7"),
        t("titlesdep2.t8"),
        t("titlesdep2.t9"),
        t("titlesdep2.t10"),
        t("titlesdep2.t11"),
        t("titlesdep2.t12"),
        t("titlesdep2.t13"),
        t("titlesdep2.t14"),
        t("titlesdep2.t15"),
        t("titlesdep2.t16"),
        t("titlesdep2.t17"),
        t("titlesdep2.t18"),
        t("titlesdep2.t19"),
        t("titlesdep2.t20")        
    ]

    const titledep3 = [
        t("titlesdep3.t1"),
        t("titlesdep3.t2"),
        t("titlesdep3.t3"),
        t("titlesdep3.t4"),
        t("titlesdep3.t5"),
        t("titlesdep3.t6"),
        t("titlesdep3.t7"),
        t("titlesdep3.t8"),
        t("titlesdep3.t9"),
        t("titlesdep3.t10"),
        t("titlesdep3.t11"),
        t("titlesdep3.t12"),
        t("titlesdep3.t13"),
        t("titlesdep3.t14"),
        t("titlesdep3.t15"),
        t("titlesdep3.t16"),
        t("titlesdep3.t17"),
        t("titlesdep3.t18"),
        t("titlesdep3.t19"),
        t("titlesdep3.t20")
        
    ]

    const titledep4 =[
        t("titlesdep4.t1"),
        t("titlesdep4.t2"),
        t("titlesdep4.t3"),
        t("titlesdep4.t4"),
        t("titlesdep4.t5"),
        t("titlesdep4.t6"),
        t("titlesdep4.t7"),
        t("titlesdep4.t8"),
        t("titlesdep4.t9"),
        t("titlesdep4.t10"),
        t("titlesdep4.t11"),
        t("titlesdep4.t12"),
        t("titlesdep4.t13"),
        t("titlesdep4.t14"),
        t("titlesdep4.t15"),
        t("titlesdep4.t16"),
        t("titlesdep4.t17"),
        t("titlesdep4.t18"),
        t("titlesdep4.t19"),
        t("titlesdep4.t20")
    ]

    const titledep5 =[
        t("titlesdep5.t1"),
        t("titlesdep5.t2"),
        t("titlesdep5.t3"),
        t("titlesdep5.t4"),
        t("titlesdep5.t5"),
        t("titlesdep5.t6"),
        t("titlesdep5.t7"),
        t("titlesdep5.t8"),
        t("titlesdep5.t9"),
        t("titlesdep5.t10"),
        t("titlesdep5.t11"),
        t("titlesdep5.t12"),
        t("titlesdep5.t13"),
        t("titlesdep5.t14"),
        t("titlesdep5.t15"),
        t("titlesdep5.t16"),
        t("titlesdep5.t17"),
        t("titlesdep5.t18"),
        t("titlesdep5.t19"),
        t("titlesdep5.t20")
    ]

    const titledep6 = [
        t("titlesdep6.t1"),
        t("titlesdep6.t2"),
        t("titlesdep6.t3"),
        t("titlesdep6.t4"),
        t("titlesdep6.t5"),
        t("titlesdep6.t6"),
        t("titlesdep6.t7"),
        t("titlesdep6.t8"),
        t("titlesdep6.t9"),
        t("titlesdep6.t10"),
        t("titlesdep6.t11"),
        t("titlesdep6.t12"),
        t("titlesdep6.t13"),
        t("titlesdep6.t14"),
        t("titlesdep6.t15"),
        t("titlesdep6.t16"),
        t("titlesdep6.t17"),
        t("titlesdep6.t18"),
        t("titlesdep6.t19"),
        t("titlesdep6.t20")
    ]

    const titledep8 = [
        t("titlesdep8.t1"),
        t("titlesdep8.t2"),
        t("titlesdep8.t3"),
        t("titlesdep8.t4"),
        t("titlesdep8.t5"),
        t("titlesdep8.t6"),
        t("titlesdep8.t7"),
        t("titlesdep8.t8"),
        t("titlesdep8.t9"),
        t("titlesdep8.t10"),
        t("titlesdep8.t11"),
        t("titlesdep8.t12"),
        t("titlesdep8.t13"),
        t("titlesdep8.t14"),
        t("titlesdep8.t15"),
        t("titlesdep8.t16"),
        t("titlesdep8.t17"),
        t("titlesdep8.t18"),
        t("titlesdep8.t19"),
        t("titlesdep8.t20")
    ]

    const titledep9=[
        t("titlesdep9.t1"),
        t("titlesdep9.t2"),
        t("titlesdep9.t3"),
        t("titlesdep9.t4"),
        t("titlesdep9.t5"),
        t("titlesdep9.t6"),
        t("titlesdep9.t7"),
        t("titlesdep9.t8"),
        t("titlesdep9.t9"),
        t("titlesdep9.t10"),
        t("titlesdep9.t11"),
        t("titlesdep9.t12"),
        t("titlesdep9.t13"),
        t("titlesdep9.t14"),
        t("titlesdep9.t15"),
        t("titlesdep9.t16"),
        t("titlesdep9.t17"),
        t("titlesdep9.t18"),
        t("titlesdep9.t19"),
        t("titlesdep9.t20")
    ]

    const titledep10 = [
        t("titlesdep10.t1"),
        t("titlesdep10.t2"),
        t("titlesdep10.t3"),
        t("titlesdep10.t4"),
        t("titlesdep10.t5"),
        t("titlesdep10.t6"),
        t("titlesdep10.t7"),
        t("titlesdep10.t8"),
        t("titlesdep10.t9"),
        t("titlesdep10.t10"),
        t("titlesdep10.t11"),
        t("titlesdep10.t12"),
        t("titlesdep10.t13"),
        t("titlesdep10.t14"),
        t("titlesdep10.t15"),
        t("titlesdep10.t16"),
        t("titlesdep10.t17"),
        t("titlesdep10.t18"),
        t("titlesdep10.t19"),
        t("titlesdep10.t20")
    ]

    const titledep11 = [
        t("titlesdep11.t1"),
        t("titlesdep11.t2"),
        t("titlesdep11.t3"),
        t("titlesdep11.t4"),
        t("titlesdep11.t5"),
        t("titlesdep11.t6"),
        t("titlesdep11.t7"),
        t("titlesdep11.t8"),
        t("titlesdep11.t9"),
        t("titlesdep11.t10"),
        t("titlesdep11.t11"),
        t("titlesdep11.t12"),
        t("titlesdep11.t13"),
        t("titlesdep11.t14"),
        t("titlesdep11.t15"),
        t("titlesdep11.t16"),
        t("titlesdep11.t17"),
        t("titlesdep11.t18"),
        t("titlesdep11.t19"),
        t("titlesdep11.t20")
    ]

    const titledep12 = [
        t("titlesdep12.t1"),
        t("titlesdep12.t2"),
        t("titlesdep12.t3"),
        t("titlesdep12.t4"),
        t("titlesdep12.t5"),
        t("titlesdep12.t6"),
        t("titlesdep12.t7"),
        t("titlesdep12.t8"),
        t("titlesdep12.t9"),
        t("titlesdep12.t10"),
        t("titlesdep12.t11"),
        t("titlesdep12.t12"),
        t("titlesdep12.t13"),
        t("titlesdep12.t14"),
        t("titlesdep12.t15"),
        t("titlesdep12.t16"),
        t("titlesdep12.t17"),
        t("titlesdep12.t18"),
        t("titlesdep12.t19"),
        t("titlesdep12.t20")
    ]

    const tagsJ = [
        "Javascript",
        "CSS",
        "HTML5",
        "Java",
        "CSS3",
        "C++",
        "BootStrap",
        "Java EE",
        "Java SE",
        "Node JS",
        "React JS",
        "Angular JS",
        "Java POO",
        "Phyton",
        "Go",
        "Rust",
        "TypeScript",
        "Django",
        "Laravel",
        "Spring",
        "Unity",
        "Vue.js",
        "Rails",
        "PHP",
        "SQL",
        "C#",
        "Express",
        "Flask",
        "Rails",
        "Bash",
        "Perl",
        "Ruby",
        "Haskell",
        "Bash"
    ]

    const tagsDep1 = [
        t("tagsDep1.tags1"),
        t("tagsDep1.tags2"),
        t("tagsDep1.tags3"),
        t("tagsDep1.tags4"),
        t("tagsDep1.tags5"),
        t("tagsDep1.tags6"),
        t("tagsDep1.tags7"),
        t("tagsDep1.tags8"),
        t("tagsDep1.tags9"),
        t("tagsDep1.tags10"),
        t("tagsDep1.tags11"),
        t("tagsDep1.tags12"),
        t("tagsDep1.tags13"),
        t("tagsDep1.tags14"),
        t("tagsDep1.tags15"),
        t("tagsDep1.tags16"),
        t("tagsDep1.tags17"),
        t("tagsDep1.tags18"),
        t("tagsDep1.tags19"),
        t("tagsDep1.tags20")
    ];


    const tagsDep2 = [
        t("tagsDep2.tags1"),
        t("tagsDep2.tags2"),
        t("tagsDep2.tags3"),
        t("tagsDep2.tags4"),
        t("tagsDep2.tags5"),
        t("tagsDep2.tags6"),
        t("tagsDep2.tags7"),
        t("tagsDep2.tags8"),
        t("tagsDep2.tags9"),
        t("tagsDep2.tags10"),
        t("tagsDep2.tags11"),
        t("tagsDep2.tags12"),
        t("tagsDep2.tags13"),
        t("tagsDep2.tags14"),
        t("tagsDep2.tags15"),
        t("tagsDep2.tags16"),
        t("tagsDep2.tags17"),
        t("tagsDep2.tags18"),
        t("tagsDep2.tags19"),
        t("tagsDep2.tags20")
    ];

    const tagsDep3 = [
        t("tagsDep3.tags1"),
        t("tagsDep3.tags2"),
        t("tagsDep3.tags3"),
        t("tagsDep3.tags4"),
        t("tagsDep3.tags5"),
        t("tagsDep3.tags6"),
        t("tagsDep3.tags7"),
        t("tagsDep3.tags8"),
        t("tagsDep3.tags9"),
        t("tagsDep3.tags10"),
        t("tagsDep3.tags11"),
        t("tagsDep3.tags12"),
        t("tagsDep3.tags13"),
        t("tagsDep3.tags14"),
        t("tagsDep3.tags15"),
        t("tagsDep3.tags16"),
        t("tagsDep3.tags17"),
        t("tagsDep3.tags18"),
        t("tagsDep3.tags19"),
        t("tagsDep3.tags20")
    ];

    const tagsDep4 = [
        t("tagsDep4.tags1"),
        t("tagsDep4.tags2"),
        t("tagsDep4.tags3"),
        t("tagsDep4.tags4"),
        t("tagsDep4.tags5"),
        t("tagsDep4.tags6"),
        t("tagsDep4.tags7"),
        t("tagsDep4.tags8"),
        t("tagsDep4.tags9"),
        t("tagsDep4.tags10"),
        t("tagsDep4.tags11"),
        t("tagsDep4.tags12"),
        t("tagsDep4.tags13"),
        t("tagsDep4.tags14"),
        t("tagsDep4.tags15"),
        t("tagsDep4.tags16"),
        t("tagsDep4.tags17"),
        t("tagsDep4.tags18"),
        t("tagsDep4.tags19"),
        t("tagsDep4.tags20")
    ];

    const tagsDep5 = [
        t("tagsDep5.tags1"),
        t("tagsDep5.tags2"),
        t("tagsDep5.tags3"),
        t("tagsDep5.tags4"),
        t("tagsDep5.tags5"),
        t("tagsDep5.tags6"),
        t("tagsDep5.tags7"),
        t("tagsDep5.tags8"),
        t("tagsDep5.tags9"),
        t("tagsDep5.tags10"),
        t("tagsDep5.tags11"),
        t("tagsDep5.tags12"),
        t("tagsDep5.tags13"),
        t("tagsDep5.tags14"),
        t("tagsDep5.tags15"),
        t("tagsDep5.tags16"),
        t("tagsDep5.tags17"),
        t("tagsDep5.tags18"),
        t("tagsDep5.tags19"),
        t("tagsDep5.tags20")
    ];

    const tagsDep6 = [
        t("tagsDep6.tags1"),
        t("tagsDep6.tags2"),
        t("tagsDep6.tags3"),
        t("tagsDep6.tags4"),
        t("tagsDep6.tags5"),
        t("tagsDep6.tags6"),
        t("tagsDep6.tags7"),
        t("tagsDep6.tags8"),
        t("tagsDep6.tags9"),
        t("tagsDep6.tags10"),
        t("tagsDep6.tags11"),
        t("tagsDep6.tags12"),
        t("tagsDep6.tags13"),
        t("tagsDep6.tags14"),
        t("tagsDep6.tags15"),
        t("tagsDep6.tags16"),
        t("tagsDep6.tags17"),
        t("tagsDep6.tags18"),
        t("tagsDep6.tags19"),
        t("tagsDep6.tags20")
    ];

    const tagsDep8 = [
        t("tagsDep8.tags1"),
        t("tagsDep8.tags2"),
        t("tagsDep8.tags3"),
        t("tagsDep8.tags4"),
        t("tagsDep8.tags5"),
        t("tagsDep8.tags6"),
        t("tagsDep8.tags7"),
        t("tagsDep8.tags8"),
        t("tagsDep8.tags9"),
        t("tagsDep8.tags10"),
        t("tagsDep8.tags11"),
        t("tagsDep8.tags12"),
        t("tagsDep8.tags13"),
        t("tagsDep8.tags14"),
        t("tagsDep8.tags15"),
        t("tagsDep8.tags16"),
        t("tagsDep8.tags17"),
        t("tagsDep8.tags18"),
        t("tagsDep8.tags19"),
        t("tagsDep8.tags20")
    ];

    const tagsDep9 = [
        t("tagsDep9.tags1"),
        t("tagsDep9.tags2"),
        t("tagsDep9.tags3"),
        t("tagsDep9.tags4"),
        t("tagsDep9.tags5"),
        t("tagsDep9.tags6"),
        t("tagsDep9.tags7"),
        t("tagsDep9.tags8"),
        t("tagsDep9.tags9"),
        t("tagsDep9.tags10"),
        t("tagsDep9.tags11"),
        t("tagsDep9.tags12"),
        t("tagsDep9.tags13"),
        t("tagsDep9.tags14"),
        t("tagsDep9.tags15"),
        t("tagsDep9.tags16"),
        t("tagsDep9.tags17"),
        t("tagsDep9.tags18"),
        t("tagsDep9.tags19"),
        t("tagsDep9.tags20")
    ];

    const tagsDep10 = [
        t("tagsDep10.tags1"),
        t("tagsDep10.tags2"),
        t("tagsDep10.tags3"),
        t("tagsDep10.tags4"),
        t("tagsDep10.tags5"),
        t("tagsDep10.tags6"),
        t("tagsDep10.tags7"),
        t("tagsDep10.tags8"),
        t("tagsDep10.tags9"),
        t("tagsDep10.tags10"),
        t("tagsDep10.tags11"),
        t("tagsDep10.tags12"),
        t("tagsDep10.tags13"),
        t("tagsDep10.tags14"),
        t("tagsDep10.tags15"),
        t("tagsDep10.tags16"),
        t("tagsDep10.tags17"),
        t("tagsDep10.tags18"),
        t("tagsDep10.tags19"),
        t("tagsDep10.tags20")
    ];

    const tagsDep11 = [
        t("tagsDep11.tags1"),
        t("tagsDep11.tags2"),
        t("tagsDep11.tags3"),
        t("tagsDep11.tags4"),
        t("tagsDep11.tags5"),
        t("tagsDep11.tags6"),
        t("tagsDep11.tags7"),
        t("tagsDep11.tags8"),
        t("tagsDep11.tags9"),
        t("tagsDep11.tags10"),
        t("tagsDep11.tags11"),
        t("tagsDep11.tags12"),
        t("tagsDep11.tags13"),
        t("tagsDep11.tags14"),
        t("tagsDep11.tags15"),
        t("tagsDep11.tags16"),
        t("tagsDep11.tags17"),
        t("tagsDep11.tags18"),
        t("tagsDep11.tags19"),
        t("tagsDep11.tags20")
    ];

    const tagsDep12 = [
        t("tagsDep12.tags1"),
        t("tagsDep12.tags2"),
        t("tagsDep12.tags3"),
        t("tagsDep12.tags4"),
        t("tagsDep12.tags5"),
        t("tagsDep12.tags6"),
        t("tagsDep12.tags7"),
        t("tagsDep12.tags8"),
        t("tagsDep12.tags9"),
        t("tagsDep12.tags10"),
        t("tagsDep12.tags11"),
        t("tagsDep12.tags12"),
        t("tagsDep12.tags13"),
        t("tagsDep12.tags14"),
        t("tagsDep12.tags15"),
        t("tagsDep12.tags16"),
        t("tagsDep12.tags17"),
        t("tagsDep12.tags18"),
        t("tagsDep12.tags19"),
        t("tagsDep12.tags20")
    ];

    useEffect(() => {
        dispatch(startLoadingBadgeCategories());
    }, [])

    useEffect(() => {
        dispatch(startLoadingBadgesCatalog());
    }, [])

    useEffect(() => {
        filterLocations(jobModel);
    }, [jobModel]);

    useEffect(() => {
        filterTitles(jobDepartment);
    }, [jobDepartment]);


    const filterTitles = (jobDepartment) => {
        switch (jobDepartment) {
            case t("department.dep1"):
                setFilterTitle(titledep1);
                setFilterTags(tagsDep1)
                return;
            case t("department.dep2"):
                setFilterTitle(titledep2);
                setFilterTags(tagsDep2)
                return;
            case t("department.dep3"):
                setFilterTitle(titledep3);
                setFilterTags(tagsDep3)
                return;
            case t("department.dep4"):
                setFilterTitle(titledep4);
                setFilterTags(tagsDep4)
                return;
            case t("department.dep5"):
                setFilterTitle(titledep5);
                setFilterTags(tagsDep5)
                return;
            case t("department.dep6"):
                setFilterTitle(titledep6);
                setFilterTags(tagsDep6)
                return;
            case t("department.dep7"):
                setFilterTitle(titleTics);
                setFilterTags(tagsJ)
                return;
            case t("department.dep8"):
                setFilterTitle(titledep8);
                setFilterTags(tagsDep8)
                return;
            case t("department.dep9"):
                setFilterTitle(titledep9);
                setFilterTags(tagsDep9)
                return;
            case t("department.dep10"):
                setFilterTitle(titledep10);
                setFilterTags(tagsDep10)
                return;
            case t("department.dep11"):
                setFilterTitle(titledep11);
                setFilterTags(tagsDep11)
                return;
            case t("department.dep12"):
                setFilterTitle(titledep12);
                setFilterTags(tagsDep12)
                return;
            default:
                setFilterTitle([]);
                setFilterTags([]);
        }
    }

    const filterLocations = (jobModel) => {
        switch (jobModel) {
            case "Onsite":
                setFilteredLocations(paisesDelMundo);
                return;
            case "Remote":
                setFilteredLocations(paisesDelMundo);
                return;
            case "Latam":
                setFilteredLocations(/*Acá iran los arrays para cada opcion*/);
                return;
            case "Global":
                setFilteredLocations(/*Acá iran los arrays para cada opcion*/);
                return;
            case "Hybrid":
                setFilteredLocations(paisesDelMundo);
                return;
            default:
                setFilteredLocations([]);
        }
    };
    

    const { register, handleSubmit, setValue, control, getValues, reset } = useForm({

        defaultValues: {
            basicAssessments: [],
            currency: "",
            description: "",
            frequency: "",
            jobType: "",
            joblocation: "",
            locationCity: "",
            jobtitle: "",
            customJobTitle: "",
            maximum: "",
            minimum: "",
            payType: "",
            payment: "",
            plusComission: "",
            skillAssessments: "",
            urlVideo: "",
            typeInterview: "",
            tagsJob: [],
            benefits: {
                lifeInsurance: false,
                '401K': false,
                dentalInsurance: false,
                medicalInsurance: false
            },
            uploadAssessment: ""
        }
    });


    useEffect(() => {
        const loadData = async () => {
            try {
                dispatch(startLoadingJobs());

                const jobData = jobDetail;

                reset({
                    jobtitle: jobData.jobtitle || '',
                    description: jobData.description || '',
                    basicAssessments: jobData.basicAssessments || [],
                    currency: jobData.currency || '',
                    customJobTitle: jobData.customJobTitle || '',
                    frequency: jobData.frequency || '',
                    jobType: jobData.jobType || '',
                    joblocation: jobData.joblocation || '',
                    locationCity: jobData.locationCity || '',
                    maximum: jobData.maximum || '',
                    minimum: jobData.minimum || '',
                    urlVideo: jobData.urlVideo || '',
                    payType: jobData.payment || '',
                    payment: jobData.payment || '',
                    plusComission: jobData.plusComission || '',
                    skillAssessments: jobData.skillAssessments || '',
                    typeInterview: jobData.typeInterview || '',
                    tagsJob: jobData.tagsJob || [],
                    department: jobData.department || '',
                    benefits: {
                        lifeInsurance: jobData.benefits?.lifeInsurance || false,
                        '401K': jobData.benefits?.['401K'] || false,
                        dentalInsurance: jobData.benefits?.dentalInsurance || false,
                        medicalInsurance: jobData.benefits?.medicalInsurance || false,
                    }
                });
                filterTitles(jobData.department);
            } catch (error) {
                console.error('Error al cargar datos del trabajo:', error);
            }
        };

        if (jobDetail && Object.keys(jobDetail).length > 0) {
            loadData();
        }
    }, [jobDetail, dispatch, reset]);

    const handleOpen = () => {
        setOpen(true)
        setShowWebCam(true);
    };

    const handleClose = () => {
        setOpen(false)
        setShowWebCam(false);
    };


    useEffect(() => {
        setVideoSaved(true);
    }, [urlVideo])

    useEffect(() => {
        saveLocalVideo();
    }, [file])


    const handleDataAvailable = useCallback(
        ({ data }) => {
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStartCaptureClick = useCallback(() => {
        if (webcamRef.current && webcamRef.current.stream) {
            setCapturing(true);
            const videoFileName = `react-webcam-${uid}-${Date.now()}.webm`;
            setVideoFileName(videoFileName);
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                mimeType: "video/webm",
            });
            mediaRecorderRef.current.addEventListener(
                "dataavailable",
                handleDataAvailable
            );
            mediaRecorderRef.current.start();
        } else {
            console.error("La referencia a la cámara web no está configurada correctamente.");
        }
    }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable, uid, setVideoFileName]);


    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
    }, [mediaRecorderRef, setCapturing]);

    const handleDownload = useCallback(() => {
        if (recordedChunks.length && videoFileName) {
            const blob = new Blob(recordedChunks, { type: "video/mp4", });
            const videoRef = ref(FirebaseStorage, `videos/jobs/${uid}/${videoFileName}`);

            uploadBytes(videoRef, blob).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setUrlVideo(url);
                });
            });

            setRecordedChunks([]);
            setOpen(false);
        }
    }, [recordedChunks, uid, setUrlVideo, setRecordedChunks, setOpen, videoFileName]);

    const saveLocalVideo = () => {
        if (file) {
            const videoFileName = `react-webcam-${uid}-${Date.now()}.webm`;
            const storageRef = ref(FirebaseStorage, `videos/jobs/${uid}/${videoFileName}`);

            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setUrlVideo(url);
                });
            });
        }
    }

    const deleteVideoFile = async (videoFileName) => {
        try {
            setFile(null);
            setRecordedChunks([]);
            setUrlVideo('');
            setOpen(false);
        } catch (error) {
            console.error('Error al eliminar el archivo:', error);
        }
    };

    const handleDeleteFile = async () => {
        try {
            if (urlVideo) {
                const videoFileName = urlVideo.split('/').pop();
                await deleteVideoFile(videoFileName);
            } else {
                console.warn('No hay video para eliminar');
            }
        } catch (error) {
            console.error('Error al eliminar el archivo:', error);
        }
    };

    const videoConstraints = {
        width: 640,
        height: 360,
        facingMode: "environment",
        border: "5px solid #444",
    };

    useEffect(() => {
        if (tmpJob && isSaving) {
            saveJob(tmpJob);
            onClick(false);
        }
    }, [tmpJob])

    useEffect(() => {
        setValue('urlVideo', urlVideo);
    }, [urlVideo, setValue]);


    const closeJob = () => {
        onClick(false);
    }

    const onSubmit = async (dataJob) => {
        try {
            if (jobEdit && tmpJob) {
                await handleEdit(tmpJob);
            } else {
                const requiredFields = {
                    jobtitle: 'Job Title',
                    jobModel: 'Job Model',
                    jobType: 'Job Type',
                };
                const missingFields = Object.keys(requiredFields).filter(field => !dataJob[field]);
                if (missingFields.length > 0) {
                    const errorMessage = `Campo/s requerido: ${missingFields.map(field => requiredFields[field]).join(', ')}.`;
                    Swal.fire({
                        icon: 'error',
                        title: 'Error...',
                        text: errorMessage,
                    });
                } else if (!dataJob.description || dataJob.description.trim().length === 0) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error...',
                        text: 'El campo de descripción no puede estar vacío.',
                    });
                } else {
                    setIsSaving(true);
                    setTmpJob(dataJob);
                }
            }
        } catch (error) {
            console.error('Error al procesar el formulario:', error);
        }
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link', 'image'],
            ['code-block'],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'font': [] }],
        ],
    };

    const formats = [
        'header', 'bold', 'italic', 'underline', 'strike',
        'list', 'bullet', 'link', 'image', 'code-block', 'size', 'font'
    ];

    const saveJob = (dataJob) => {
        let companyId = '';
        if (profile.length > 0) {
            companyId = profile[0].idCompany;
        }
        const {
            basicAssessments,
            currency,
            description,
            frequency,
            jobType,
            joblocation,
            locationCity,
            jobtitle,
            maximum,
            minimum,
            urlVideo,
            customJobTitle,
            benefits,
            payType,
            payment,
            plusComission,
            skillAssessments,
            tagsJob,
            department,
            companyName,
            typeInterview
        } = dataJob

        const job = {
            basicAssessments: basicFitCheck,
            compatibility: compatibilityCheck,
            manualFiltering: manualFilteringCheck,
            skillsAssessments: skillsAssessmentsCheck,
            videoInterview: videoInterviewCheck,
            finalInterview: finalInterviewCheck,
            currency,
            description,
            frequency,
            jobType,
            joblocation,
            locationCity,
            jobtitle,
            maximum,
            minimum,
            customJobTitle,
            benefits,
            moreBenefits: benefitName,
            payType,
            payment,
            plusComission,
            typeInterview,
            jobModel: jobModel,
            status: 'Created',
            companyId,
            urlVideo: urlVideo,
            tagsJob,
            department: jobDepartment,
            companyName : companyProfile[0].companyName,
            file: file
        }
        dispatch(startNewJob(job));
        showSuccess();
        onClearData();
    }

    const onClearData = () => {
        setValue('basicAssessments', "")
        setValue('currency', "")
        setValue('description', "")
        setValue('frequency', "")
        setValue('jobType', "")
        setValue('joblocation', "")
        setValue('locationCity')
        setValue('jobtitle', "")
        setValue('customJobTitle')
        setValue('maximum', "")
        setValue('minimum', "")
        setValue('urlVideo', "")
        setValue('benefits', "")
        setValue('payType', "")
        setValue('payment', "")
        setValue('plusComission', "")
        setValue('skillAssessments', "")
        setValue('typeInterview', "")
        setValue('date: Date.now()', "")
        setValue('status', "")
        setValue('tagsJob', "")
        setValue('department', '')
    }

    const showSuccess = () => {
        Swal.fire('Job created successfully', 'Saved', 'success');
    };

    const onCapturePartner = () => { }

    const handleChangeBenefit = (event) => {
        const {
            target: { value },
        } = event;
        setBenefitName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeBasic = (event) => {
        const {
            target: { value },
        } = event;
        setBasicName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeSkills = (event) => {
        const {
            target: { value },
        } = event;
        setSkillsName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleJobModel = (event) => {
        const {
            target: { value },
        } = event;
        setJobModel(value);
        if (value === 'Global' || value === 'Latam') {
            setDisableCountry(true);
        } else {
            setDisableCountry(false);
        }
    }

    const handleChangeFile = async (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setIsSelected(true);

        try {
            const videoFileName = `react-webcam-${uid}-${Date.now()}.webm`;
            const storageRef = ref(FirebaseStorage, `videos/jobs/${uid}/${videoFileName}`);
            await uploadBytes(storageRef, selectedFile);
            const url = await getDownloadURL(storageRef);
        } catch (error) {
            console.error('Error al subir el archivo:', error);
        }
    };


    const loadBasicFitData = (data) => {
        setBasicFitCheck(data);
    }

    const loadCompatibilityData = (data) => {
        setCompatibilityCheck(data);
    }

    const loadManualFilteringData = (data) => {
        setManualFilteringCheck(data);
    }

    const loadSkillsAssessmentsData = (data) => {
        setSkillsAssessmentsCheck(data);
    }

    const loadVideoInterviewData = (data) => {
        setVideoInterviewCheck(data);
    }

    const loadFinalInterviewData = (data) => {
        setFinalInterviewCheck(data);
    }

    const handleEdit = async () => {
        const formValues = getValues();
        const editedJob = { ...tmpJob, ...formValues };

        if (jobEdit && editedJob) {

            
            await dispatch(startEditJob({ jobId, editedJob }));

            showSuccess();
            onClick(false);
        } else {
            console.log('Esto no está funcionando');
        }
    };

    return (
        <div className='flex flex-col bg-white w-full h-full'>
            <button onClick={() => closeJob()}><img className="w-6 h-6 absolute rounded-mx mt-11 ml-12" src="../assets/icons/left-arrow-from-left.png" alt='' /></button>
            <p className="absolute text-2xl font-bold text-gray-800 ml-1 mt-[38px]" style={{ left: 86 }}>
                {jobEdit ? t("editJob.title") : t("createJob.title")}
            </p>
            <div className='mt-28'>
                <form onSubmit={handleSubmit(onSubmit)}>
                <div  className='flex flex-row ml-24 mr-24  justify-center'>
                        <FormControl className='w-11/12'>
                        <InputLabel id="job-department-label">department</InputLabel>
                        <Select
                            labelId="job-department-label"
                            id="job-department"
                            label='Job Department'
                            placeholder='Job Department'
                            inputProps={{ 'aria-label': 'Without label' }}
                            {...register("department")}
                            defaultValue={jobEdit ? jobDetail.department : ""}
                            onChange={(e) => {
                                filterTitles(e.target.value);
                            }}
                            multiple={false}
                            >
                            <MenuItem value={t("department.dep1")} > {t("department.dep1")} </MenuItem>
                            <MenuItem value={t("department.dep2")} > {t("department.dep2")} </MenuItem>
                            <MenuItem value={t("department.dep3")} > {t("department.dep3")} </MenuItem>
                            <MenuItem value={t("department.dep4")} > {t("department.dep4")} </MenuItem>
                            <MenuItem value={t("department.dep5")} > {t("department.dep5")} </MenuItem>
                            <MenuItem value={t("department.dep6")} > {t("department.dep6")} </MenuItem>
                            <MenuItem value={t("department.dep7")} > {t("department.dep7")} </MenuItem>
                            <MenuItem value={t("department.dep8")} > {t("department.dep8")} </MenuItem>
                            <MenuItem value={t("department.dep9")} > {t("department.dep9")} </MenuItem>
                            <MenuItem value={t("department.dep10")} > {t("department.dep10")} </MenuItem>
                            <MenuItem value={t("department.dep11")} > {t("department.dep11")} </MenuItem>
                            <MenuItem value={t("department.dep12")} > {t("department.dep12")} </MenuItem>
                        </Select>
                        </FormControl>
                        </div>
                    <div className='flex flex-row ml-24 mr-24 mt-6'>
                        <div className='w-1/2'>
                            <FormControl className='w-11/12'>
                                <InputLabel id="job-title-label">{t("createJob.jobT")}</InputLabel>
                                <Select
                                labelId="job-title-label"
                                id="job-title"
                                label='Job Title'
                                placeholder='Job Title'
                                inputProps={{ 'aria-label': 'Without label' }}
                                {...register("jobtitle")}
                                defaultValue={jobEdit ? jobDetail.jobtitle : ""}
                                multiple={false}
                            >
                                {filterTitle.map((title, index) => (
                                    <MenuItem key={index} value={title}>
                                        {title}
                                    </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </div>
                        <div className='w-1/2'>
                            <TextField
                                id='customJobTitle'
                                className='w-11/12'
                                placeholder={t("createJob.CustomT")}
                                {...register("customJobTitle")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row ml-24 mr-24 mt-6'>
                        <div className='w-2/12'>
                            <p className='mt-2 inline-flex text-gray-800'>{t("createJob.jobModel")}</p>
                        </div>
                        <div className='w-10/12 inline-flex mt-2'>
                            <FormControl>
                                <RadioGroup
                                    {...register("jobModel")}
                                    onChange={(e) => {
                                        handleJobModel(e);
                                        filterLocations(e.target.value);
                                    }}
                                >
                                    <FormControlLabel value="Onsite" control={<Radio />} label="Onsite" {...register("jobModel")} />
                                    <FormControlLabel value="Remote" control={<Radio />} label="Remote" {...register("jobModel")} />
                                    {/*<FormControlLabel value="Latam" control={<Radio />} label="Latam" {...register("jobModel")} />
                                    <FormControlLabel value="Global" control={<Radio />} label="Global" {...register("jobModel")} />*/}
                                    <FormControlLabel value="Hybrid" control={<Radio />} label="Hybrid" {...register("jobModel")} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className='flex flex-row ml-24 mr-24 mt-6'>
                        <div className='w-1/2'>
                            <FormControl className="w-full">
                                <InputLabel id="job-location-label">{t("createJob.location")}</InputLabel>
                                <Select
                                    labelId="job-location-label"
                                    id="job-location"
                                    label={t("createJob.location")}
                                    placeholder={t("createJob.location")}
                                    //defaultValue={jobModel === "Latam" ? paisesLATAM[0] : jobModel === "Global" ? restoPaisesMundo[0] : ""}
                                    multiple={false}
                                    inputProps={{ "aria-label": "Without label" }}
                                    {...register("joblocation")}
                                    //disabled={jobModel === "Onsite"}
                                    value={jobLocation}
                                    onChange={(e) => setJobLocation(e.target.value)}
                                >
                                    {filteredLocations.map((location) => (
                                        <MenuItem key={location} value={location}>
                                            {location}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-1/2'>
                            <TextField
                                id='locationCity'
                                className='w-11/12'
                                placeholder="Location City"
                                {...register("locationCity")}
                            />
                        </div>
                        <div className='w-1/2'>
                            <FormControl className='w-11/12'>
                                <InputLabel id="job-type-label">{t("createJob.type")}</InputLabel>
                                <Select
                                    labelId="job-type-label"
                                    id="job-type"
                                    label='Job Type'
                                    placeholder='Job Type'
                                    defaultValue={jobEdit ? jobDetail.jobType : ""}
                                    multiple={false}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    {...register("jobType")}
                                >
                                    <MenuItem value="Full Time" >{t("createJob.type1")}</MenuItem>
                                    <MenuItem value="Part Time"  >{t("createJob.type2")}</MenuItem>
                                    <MenuItem value="Contract"  >{t("createJob.type3")}</MenuItem>
                                    <MenuItem value="Temporary"  >{t("createJob.type4")}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='flex flex-row ml-24 mr-24 mt-6 justify-center'>
                        <div className='w-full pl-6 pr-6'>
                            <label htmlFor="description">{t("createJob.description")}</label>
                            <ReactQuill
                                id='description'
                                onChange={(content) => setValue('description', content)}
                                modules={modules}
                                formats={formats}
                                defaultValue={jobEdit ? jobDetail.description : ""}
                                multiple={false}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row ml-24 mr-24 mt-6 justify-center'>
                        <FormControl className='w-11/12'>
                            <Autocomplete
                                multiple
                                id="tagsJob"
                                options={filterTags}
                                defaultValue={jobEdit ? (Array.isArray(jobDetail.tagsJob) ? jobDetail.tagsJob : []) : []}
                                onChange={(event, newValue) => {
                                    setValue("tagsJob", newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("createJob.tags")}
                                        placeholder="Tags"
                                    />
                                )}
                            />
                        </FormControl>
                    </div>
                    <div className='flex flex-row ml-24 mr-24 mt-6 justify-left'>
                        <p className="text-base font-bold text-gray-800 pl-6">{t("createJob.tell-us")}</p>
                        <p className="text-xs font-medium text-gray-500 pt-2 pl-2">{t("createJob.txt-video")}</p>
                    </div>
                    <div className='flex flex-row'>
                        <div className='w-1/2  mt-6 pl-6 pt-6'>
                            <div className='flex flex-row ml-24 mr-24 justify-center w-full'>
                                <div>
                                    <button type='button' className="inline-flex items-center justify-start px-4 py-1 border rounded-full border-gray-800 text-sm text-gray-800"
                                        onClick={handleOpen} disabled={urlVideo}>
                                        {t("createJob.record")}
                                    </button>
                                    <input className='pt-6' type="file" onChange={handleChangeFile} />
                                    <label className="block pt-2 mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">OR</label>
                                    <input
                                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="file_input_help" id="file_input"
                                        type="file" onChange={handleChangeFile} disabled={urlVideo} />
                                    {/* <p class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG or GIF (MAX. 800x400px).</p> */}
                                </div>
                            </div>
                            <div className='flex flex-row w-full pt-4'>
                                <div className='w-3/5 justify-end items-end text-right pl-2 pr-2'>
                                    <Fab className='pl-2' color='error' aria-label="edit" placeholder='Eliminar video pl-4' disabled={!urlVideo}>
                                        <Delete onClick={handleDeleteFile} />
                                    </Fab>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/2 items-center justify-between pt-6'>
                            <div className='w-[320px] h-[180px] items-center justify-between pl-20'>
                                {urlVideo &&
                                    <ReactPlayer width={320} height={180} url={urlVideo} controls={true} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row ml-24 mr-24 mt-6 pl-6'>
                        <p className="text-base font-bold text-gray-800 mt-2">{t("createJob.benefits")}</p>
                    </div>
                    <div className='flex flex-row ml-24 mr-24 mt-2 pl-6'>
                        <FormGroup
                            row
                        >
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="benefits.medicalInsurance"
                                        control={control}
                                        render={({ field }) => <Checkbox {...field} />}
                                    />
                                }
                                label={t("createJob.ben1")}
                                className='pl-8'
                            />
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="benefits.lifeInsurance"
                                        control={control}
                                        render={({ field }) => <Checkbox {...field} />}
                                    />
                                }
                                label={t("createJob.ben2")}
                                className='pl-8'
                            />
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="benefits.dentalInsurance"
                                        control={control}
                                        render={({ field }) => <Checkbox {...field} />}
                                    />
                                }
                                label={t("createJob.ben3")}
                                className='pl-8'
                            />
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="benefits.401K"
                                        control={control}
                                        render={({ field }) => <Checkbox {...field} />}
                                    />
                                }
                                label={t("createJob.ben4")}
                                className='pl-8'
                            />
                        </FormGroup>
                        <div className=' w-2/12'>
                            <FormControl className='w-10/12'>
                                <InputLabel id='select-benefit-label'>{t("createJob.ben5")}</InputLabel>
                                <Select
                                    labelId="select-benefit-label"
                                    id="select-benefit"
                                    multiple
                                    value={benefitName}
                                    onChange={handleChangeBenefit}
                                    input={<OutlinedInput label="+ More" />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                    defaultValue=""
                                >
                                    {names.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={benefitName.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='ml-24 mr-24 mt-4 pl-6 bg-blue-900 bg-opacity-10'>
                        <div className='flex flex-row justify-start'>
                            <p className="text-base font-bold text-black mt-4">{t("createJob.payment")}</p>
                            <p className="text-xs font-medium text-gray-500  pl-4  mt-5">{t("createJob.txt-payment")}</p>
                        </div>
                        <div className='flex flex-row w-full mt-4 space-x-4 justify-center'>
                            <FormControl className='w-2/12 bg-white'>
                                <InputLabel id="pay-type-label">{t("createJob.paytype")}</InputLabel>
                                <Select
                                    id="pay-type"
                                    labelId='pay-type-label'
                                    defaultValue="Range"
                                    label='Pay Type'
                                    placeholder='Job Type'
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    {...register("payType")}
                                >
                                    <MenuItem value="Range">{t("createJob.paytp1")}</MenuItem>
                                    {/* <MenuItem value="E">Exact Amount</MenuItem> */}
                                </Select>
                            </FormControl>
                            <FormControl className='w-2/12 bg-white'>
                                <TextField
                                    id="minimum"
                                    label={t("createJob.paymin")}
                                    type="number"
                                    {...register("minimum")}
                                    defaultValue={jobEdit ? jobDetail.minimum : ""}
                                    inputProps={{ 'aria-label': 'Minimum pay', min: 0, className: 'no-spinner' }}
                                    fullWidth
                                />
                            </FormControl>
                            <FormControl className='w-2/12 bg-white'>
                                <TextField
                                    id="maximum"
                                    label={t("createJob.paymax")}
                                    type="number"
                                    {...register("maximum")}
                                    defaultValue={jobEdit ? jobDetail.maximum : ""}
                                    inputProps={{ 'aria-label': 'Maximum pay', min: 0, className: 'no-spinner' }}
                                    fullWidth
                                />
                            </FormControl>
                            <FormControl className='w-2/12 bg-white'>
                                <InputLabel id="select-currency-label">{t("createJob.currency")}</InputLabel>
                                <Select
                                    id="select-currency"
                                    labelId='select-currency-label'
                                    label='Currency'
                                    placeholder='Currency'
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    {...register("currency")}
                                    defaultValue={jobEdit ? jobDetail.currency : ""}
                                    multiple={false}
                                >
                                    <MenuItem value="D" >Dolar</MenuItem>
                                    <MenuItem value="E"  >Euro</MenuItem>
                                    <MenuItem value="Q"  >Quetzal</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className='w-2/12 bg-white'>
                                <InputLabel id="select-frequency-label">{t("createJob.freq")}</InputLabel>
                                <Select
                                    id="select-frequency"
                                    labelId='select-frequency-label'
                                    label='Frequency'
                                    placeholder='Frequency'
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    {...register("frequency")}
                                    defaultValue={jobEdit ? jobDetail.frequency : ""}
                                    multiple={false}
                                >
                                    <MenuItem value="A" >Anual</MenuItem>
                                    <MenuItem value="M" >Mensual</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='mt-6 pb-6'>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name="plusComission"
                                            control={control}
                                            render={({ field }) => <Checkbox {...field} />}
                                        />
                                    }
                                    label={t("createJob.plus")}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className='flex flex-row ml-24 mr-24 mt-8 pl-6'>
                        <p className="text-base font-bold text-gray-800 mt-2">{t("createJob.hiringP")}</p>
                    </div>
                    <BasicFitCheck getBasicFitData={loadBasicFitData} />
                    <Compatibility getCompatibilityData={loadCompatibilityData} />
                    <SkillsAssesments getSkillsAssessmentsData={loadSkillsAssessmentsData} />
                    <ManualFiltering getManualFilteringData={loadManualFilteringData} />
                    <VideoInterview getVideoInterviewData={loadVideoInterviewData} />
                    <FinalInterview getFinalInterviewData={loadFinalInterviewData} />
                    <div className='flex flex-row w-full mt-14 mr-24 justify-end items-end place-content-end'>
                        <div className='w-5/12'>
                        </div>
                        <div className='w-7/12 mr-20 ml-14'>
                            {jobEdit ? (
                                <button onClick={handleEdit} type='submit' className="justify-center w-64 px-14 py-4 bg-blue-900 rounded-full text-base font-medium text-white ml-2"  >{t("editJob.btn")}</button>
                            ) : (
                                <div>
                                    {/*  <button onClick={onsubmit} className=" justify-center w-64 px-14 py-4 bg-white border rounded-full border-gray-800 text-base font-medium text-gray-800">
                    {t("createJob.btn-drafts")}
                            </button> */}
                                    <button type='submit' className="justify-center w-64 px-14 py-4 bg-blue-900 rounded-full text-base font-medium text-white ml-2">
                                        {t("createJob.btn-save")}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-row pb-28 justify-end w-full mt-6'>
                        <div className='w-6/12'></div>
                        {!jobEdit && (
                            <div className='w-6/12 justify-end text-right mr-24'>
                                <p className="absolute text-xs font-medium text-gray-600 mr-24">
                                    {t("createJob.txt-btn")}
                                </p>
                            </div>
                        )}
                    </div>
                </form>
            </div >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='text-center'>
                        Save Video
                    </Typography><br />
                    <div className='grid formgrid'>
                        <div style={{ border: '5px solid #BDBDBD ', padding: 'px' }}>
                            <Webcam
                                audio={true}
                                height={'100%'}
                                screenshotFormat="image/jpeg"
                                frameBorder={"10px"}
                                width={'100%'}
                                ref={webcamRef}
                                videoConstraints={videoConstraints}
                            >
                            </Webcam>
                        </div>
                        {capturing ? (
                            <div className='text-center w-20 h-20'>
                                <StopCircle onClick={handleStopCaptureClick} />
                            </div>
                        ) : (
                            <div style={{ textAlign: 'center' }}>
                                <button className=" rounded-full p-button-danger mr-2 mb-2 mt-4 bg-red-600 h-14 w-14" onClick={handleStartCaptureClick} />
                            </div>
                        )}
                        {recordedChunks.length > 0 && (
                            <div className='flex flex-row mt-8 justify-center'>
                                <button type="button" className='w-40 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleDownload}>Confirmar</button>
                            </div>
                        )}
                    </div>
                </Box>
            </Modal>
        </div >
    )
}
