import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, CircularProgress, Container, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { FirebaseStorage } from '../../../firebase/config';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { setActiveBadgesCatalog } from '../../../store/slices/badgesCatalog/badgesCatalogSlice';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { startNewBadgesCatalog, startSaveBadgesCatalog } from '../../../store/slices/badgesCatalog/thunks';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

export const CreateBadgeEmployer = ({ onClick, dataCategories, edit }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { active } = useSelector(state => state.badgesCatalog);
    const [open, setOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [urlImageBadge, setUrlImageBadge] = useState('');
    const [changeImage, setChangeImage] = useState(false);
    const [categorie, setCategorie] = useState('');
    const [categories, setCategories] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [percent, setPercent] = useState(0);
    const { companyProfile } = useSelector(state => state.companyProfile);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            description: "",
            categorie: "",
            imageurl: ""
        }
    });

    useEffect(() => {
        if (edit) {
            if (active) {
                setValue('name', active.name);
                setValue('description', active.description);
                setImageUrl(active.imageUrl);
                setCategorie(active.categorie);
            }
        }
    }, [edit])

    useEffect(() => {
        if (dataCategories) {
            setCategories(dataCategories);
        }
    }, [dataCategories])

    const closeUpdateBadge = () => {
        onClick(true);
    }

    const onSubmit = async (data) => {
        setIsSaving(true);
        let companyProf = '';

        if (companyProfile.length > 0) {
            companyProf = companyProfile[0].id;
        }

        if (edit) {
            let urlTmpImage = '';

            if (changeImage) {
                urlTmpImage = await saveImage(active.name);
            } else {
                urlTmpImage = imageUrl;
            }

            const {
                name,
                description
            } = data;

            const newData = {
                id: active.id,
                categorie: categorie,
                description: description,
                name: name,
                imageUrl: urlTmpImage
            }
            dispatch(setActiveBadgesCatalog(newData));

            if (newData) {
                dispatch(startSaveBadgesCatalog());
                showUpdatedSuccess();
                onClear();
            }
            closeUpdateBadge();
            setIsSaving(false);
        } else {

            if (validate()) {
                const {
                    name,
                    description
                } = data;

                const dataBadge = {
                    name,
                    description,
                    categorie: categorie[0],
                    companyId: companyProf
                }
                saveBadge(dataBadge);

            }
        }

    }

    const validate = () => {

        if (categorie.length < 1) {
            showCategorieError();
            setIsSaving(false);
            return false;
        }

        if (imageUrl == null) {
            showImageError();
            setIsSaving(false);
            return false;
        }

        return true;
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    };

    const showSaveSuccess = () => {
        Swal.fire('Saved', t('badges.showSaveSuccess'), 'success');
    };

    const showUpdatedSuccess = () => {
        Swal.fire('Saved', t('badges.showUpdateSuccess'), 'success');
    };

    const showImageError = () => {
        Swal.fire('Error', t('badges.showImageError'), 'error');
    };

    const showCategorieError = () => {
        Swal.fire('Error', t('badges.showCategorieError'), 'error');
    };

    const handleImageUpload = (event) => {
        setChangeImage(true);
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    async function saveImage(name) {
        const storageRef = ref(FirebaseStorage, `images/badges/${name}`);
        let promise = new Promise((resolve, reject) => {
            uploadString(storageRef, imageUrl, 'data_url').then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    resolve(url);
                })
            })
        })
        let result = await promise;
        setUrlImageBadge(result);
        setUrlImageBadge((state) => {
            return state;
        })

        return result;
    }

    const saveBadge = (data) => {
        setIsSaving(true);
        let urlImagen = '';

        const {
            name,
            description,
            companyId
        } = data;

        if (imageUrl) {
            const imageRef = ref(FirebaseStorage, `images/badges/${name}`);
            uploadString(imageRef, imageUrl, 'data_url').then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setUrlImageBadge(url);
                    setUrlImageBadge((state) => {
                        return state;
                    })
                    urlImagen = url;
                })
            }).catch((error) => {
                console.log('error', error);
            })
        }

        setTimeout(() => {
            const badgeData = {
                name,
                description,
                categorie,
                imageUrl: urlImagen,
                companyId
            }
            dispatch(setActiveBadgesCatalog(badgeData));
            dispatch(startNewBadgesCatalog(badgeData));
            setIsSaving(false);
            showSaveSuccess();
            closeUpdateBadge();
        }, 5000);
    }

    const handleChangeCategorie = (event) => {
        const {
            target: { value },
        } = event;
        setCategorie(value);
        setCategorie((state) => {
            return state;
        })
    };

    const onClear = () => {
        setValue("name", "");
        setValue("description", "");
        setImageUrl("");
        setCategorie([]);

    }

    return (
        <>
            <div className='flex flex-col bg-white w-full place-items-center min-h-[780px]'>
                <div className='flex flex-row w-full pt-2 pl-2'>
                    <button onClick={() => closeUpdateBadge()}><img className="w-6 h-6 absolute rounded-mx mt-2 ml-2" src="../assets/icons/left-arrow-from-left.png" alt='' /></button>
                </div>

                <div className='flex w-full h-full bg-white  pt-20'>
                    <div className='w-3/12'>
                        <div className='flex flex-row items-center justify-center'>
                            {imageUrl &&
                                <img className="w-40" src={imageUrl} />
                            }
                            {!imageUrl &&
                                <img className="w-40" src="../assets/images/Ellipse 762.svg" />
                            }
                        </div>
                        <div className='flex flex-row items-center justify-center'>
                            {/* <input type='file' /> */}
                            <Container>
                                <Stack direction="row" alignItems="center" spacing={2} className='items-center justify-center'>
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                    >
                                        <input hidden accept="image/*" type="file" onChange={handleImageUpload} />
                                        <PhotoCamera />
                                    </IconButton>
                                </Stack>
                            </Container>
                        </div>
                    </div>

                    <div className='w-9/12'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='w-full pl-24 pr-24'>
                                <div className='w-full flex flex-row'>
                                    <TextField
                                        id='name'
                                        className='w-full'
                                        placeholder={t('badges.name')}
                                        error={errors.name?.type === "required" ? true : false}
                                        {...register('name', { required: true, minLength: 5 })}
                                    />
                                </div>
                                <div className='w-full flex flex-row pt-0'>
                                    {
                                        errors.name && errors.name.type === "required" &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{t('badges.requiredName')}</p>
                                    }
                                    {
                                        errors.name && errors.name.type === "minLength" &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{t('badges.nameCharacters')}</p>
                                    }
                                </div>
                                <div className='w-full flex flex-row pt-5'>
                                    <TextField
                                        id='description'
                                        multiline
                                        rows={4}
                                        className='w-full'
                                        placeholder={t('badges.description')}
                                        error={errors.description?.type === "required" ? true : false}
                                        {...register('description', { required: true, minLength: 10 })}
                                    />
                                </div>
                                <div className='w-full flex flex-row pt-0'>
                                    {
                                        errors.description && errors.description.type === "required" &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{t('badges.requiredDescription')}</p>
                                    }
                                    {
                                        errors.description && errors.description.type === "minLength" &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{t('badges.descriptionCharacters')}</p>
                                    }
                                </div>
                                <div className='w-full flex flex-row pt-5'>
                                    <FormControl className='w-full'>
                                        <InputLabel>Categorias</InputLabel>
                                        <Select
                                            labelId='categorie-label'
                                            id='categorie'
                                            label='Categorie'
                                            placeholder='Categorie'
                                            defaultValue=""
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            value={categorie}
                                            onChange={handleChangeCategorie}

                                        >
                                            {categories.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </div>
                                {!isSaving &&
                                    <div className='flex flex-row mt-20 justify-end pb-16'>
                                        <div>
                                            <button type="submit" className='w-56 h-14 bg-sky-900 text-white rounded-full font-medium'>{t('badges.saveButton')}</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                {isSaving &&
                    <div className='pt-20'>
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    </div>
                }
            </div>
        </>
    )
}
