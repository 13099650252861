import { Box, Button, Checkbox, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Select, Switch, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 480,
    bgcolor: 'background.paper',
    // border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const basicFitCheck = [
    'Psicometricos',
    'Númericos'
];

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return Array.isArray(a) ? a.filter((value) => b.indexOf(value) !== -1) : [];
}

export const SkillsAssesments = ({ getSkillsAssessmentsData, data }) => {
    const { companyProfile } = useSelector(state => state.companyProfile);
    const { badgesCategories } = useSelector(state => state.badgesCategorie);
    const { badgesCatalogs } = useSelector(state => state.badgesCatalog);
    const [basicName, setBasicName] = useState([]);
    const [basicChecked, setBasicChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);


    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            joblocation: "",
            startDate: "",
            endDate: ""
        }
    });

    useEffect(() => {
        let tmpCategory = '';
        if (badgesCategories.length > 0) {
            tmpCategory = badgesCategories.filter(x => x.name === "Skill-Assessment");
            if (badgesCatalogs.length > 0 && companyProfile.length > 0) {
                let tmpSkill = badgesCatalogs.filter(x => x.categorie == tmpCategory[0].id && x.companyId == companyProfile[0].idCompany);
                let tmpLeft = [];
                tmpSkill.map((item) => {
                    tmpLeft.push({ name: item.name, id: item.id, imageUrl: item.imageUrl });
                })
                setLeft(tmpLeft);
            }
        }
    }, [])

    const handleToggle = (item) => () => {
        const currentIndex = checked.indexOf(item);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items) => (
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((item) => {
                    const labelId = `transfer-list-item-${item.name}-label`;

                    return (
                        <ListItem
                            key={item.id}
                            role="listitem"
                            button
                            onClick={handleToggle(item)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <img className='w-8 h-8' src={item.imageUrl} alt='' />
                            <ListItemText id={labelId} primary={`${item.name}`} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    const handleChangeBasic = (event) => {
        const {
            target: { value },
        } = event;
        setBasicName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    };

    const handleChange = (event) => {
        setBasicChecked(event.target.checked);
    };

    const onSubmit = () => {
        console.log('llega a onSubmit');
        getSkillsAssessmentsData(right);
        setOpen(false);
    }

    return (
        <>
            <div className='flex flex-row ml-24 mr-24 mt-6'>
                <div className='flex w-8/12 justify-between'>
                    <TextField
                        id='job-title'
                        className='w-4/12 bg-gray-200'
                        label='Step #3 Skill  Assessments'
                        placeholder="Skill Assessments"
                        disabled
                    />
                    {/* <FormControl className='w-7/12 text-black'>
                        <InputLabel id='basic-assessment-label'>Select assessments</InputLabel>
                        <Select
                            labelId="basic-assessment-label"
                            id="basic-assessment"
                            multiple
                            value={basicName}
                            onChange={handleChangeBasic}
                            input={<OutlinedInput label="Select assessments" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            defaultValue=""
                        >
                            {basicFitCheck.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={basicName.indexOf(name) > -1} />
                                    <ListItem primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                    <div className='w-2/12'>
                        <Switch
                            checked={basicChecked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </div>

                    <div className='w-4/12 justify-end items-end place-content-end'>
                        {basicChecked &&
                            <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                                onClick={handleOpen}>
                                + Add Skills
                                {/* <img className="w-5 h-5 relative" src='' /> */}
                            </button>
                        }
                    </div>

                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full pl-6 text-left'>
                        <div className='w-1/2 mt-4'>
                            <p className=' text-zinc-800 font-bold text-[18px]'>Skills Assessments - Badges</p>
                        </div>
                    </div><br /><br />
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item>{customList(left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >
                                    ≫
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >
                                    &gt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >
                                    &lt;
                                </Button>
                                <Button
                                    sx={{ my: 0.5 }}
                                    variant="outlined"
                                    size="small"
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                >
                                    ≪
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>{customList(right)}</Grid>
                    </Grid>

                    <div className='flex flex-row mt-10 justify-end'>
                        <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleSubmit(onSubmit)}>Save</button>
                    </div>

                </Box>

            </Modal>
        </>
    )
}
