import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Modal } from '@mui/material'
import { SendMessage } from '../SendMessage';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    height: 492,
    bgcolor: 'background.paper',
    // border: '1px solid #000',
    boxShadow: 24,
};

export const    ActiveCandidates = () => {
    const { activejobs } = useSelector(state => state.activeJobs);
    const { employeeProfiles } = useSelector(state => state.profile);
    const [candidates, setCandidates] = useState([]);
    const { profile } = useSelector(state => state.profile);
    const { active } = useSelector(state => state.job);
    const { id } = active;
    const [open, setOpen] = useState(false)
    const [profileReceived, setProfileReceived] = useState([]);

    useEffect(() => {
        loadActiveCandidates();
    }, [activejobs]);

    const showModalMessage = (imageURL, displayName, idProfile) => {
        console.log("Entra al Modal");
        const dataProfile = {
            name: displayName,
            image: imageURL,
            id: idProfile
        }



        setProfileReceived(dataProfile);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)

    }

    const loadActiveCandidates = () => {
        let arrayV = []
        employeeProfiles.map((item) => {


            /*let boolVar = item.applyingJob.some(
                value => { return typeof value == "array" });

            console.log("voolBar", boolVar)*/


            let tmpProfile = []

            if (item.applyingJob) {
                if (item.applyingJob.length > 0) {
                    tmpProfile = item.applyingJob
                }
            }

            let tmpData = []
            let ID_Job = '';

            if (active.id.length > 0) {
                ID_Job = active.id;
            }


            if (tmpProfile.length > 0) {
                tmpData = tmpProfile.filter(x => x.jobId === ID_Job)
                if (tmpData.length > 0) {
                    arrayV.push({ id: item.id, tmpData })
                }
            }
        })

        let tmpCandidates = employeeProfiles.filter(x => arrayV.some(y => y.id === x.id))
        if (tmpCandidates.length) {
            setCandidates(tmpCandidates);
        }
    }

    return (
        <>
            <div className='w-11/12'>
                {candidates.map((item, index) => (
                    <div key={index} className='w-full flex flex-row justify-center pt-6 h-20'>
                        <div className='w-full flex flex-row bg-white rounded-3xl border opacity-90 boerder-[#818181]'>
                            <div className='w-4/12'>
                                <div className='w-full flex border-r-2'>
                                    <div className='w-1/3'>
                                        {item.pages.profileImageUrl &&
                                            <div className='pt-1 pl-1'>
                                                <img className='w-12 h-12 rounded-full' src={item.pages.profileImageUrl} alt='' />
                                            </div>
                                        }
                                    </div>
                                    <div className='w-2/3 pt-2'>
                                        <div className='flex flex-row w-full'>
                                            <p className="text-zinc-800 text-base font-medium">{item.displayName}</p>
                                        </div>
                                        <div className='flex flex-row w-full'>
                                            <p className="text-gray-600 text-xs font-normal">Location, GT</p>
                                            {/* <div className="w-12 text-right justify-end h-px origin-top-left rotate-90 border border-neutral-300 absolute pl-2" style={{ top: 8, }}></div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="w-12 ml-10 h-px origin-top-left rotate-90 border border-neutral-300 mt-1"></div> */}
                            </div>

                            <div className='w-3/12'>
                                <div className='w-full h-full border-r-2'>
                                    <div className='w-full flex flex-row h-6 pl-4'>
                                        <p className="text-zinc-800 text-xs font-medium">Relevant Badges</p>
                                    </div>
                                    <div className='w-full flex flex-row h-8 pl-4'>
                                        <img className=' w-8 h-7' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=c6f44aae-7902-45d1-b48f-fb097b8a0912" />
                                        <img className=' w-8 h-7 pl-1' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%203.png?alt=media&token=45b35fbf-9ff1-45f9-a048-63d328284a7c" />
                                        <img className=' w-8 h-7 pl-1' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%204.png?alt=media&token=1ec44765-7d7a-4d56-8e04-c0976483e366" />
                                        <img className=' w-8 h-7 pl-1' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%205.png?alt=media&token=260be5f7-9283-4104-a958-831df00d71dc" />
                                        <img className=' w-8 h-7 pl-1' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%206.png?alt=media&token=19cb9f22-4cae-4903-90c9-137dd3e3ab7c" />
                                    </div>
                                </div>
                            </div>
                            <div className='w-3/12'>
                                <div className="w-48 h-6 relative">
                                    <div className="left-0 mt-3 ml-4 absolute text-zinc-800 text-xs font-medium">Progress: On course</div>
                                    <div className="w-48 h-1 mt-3 ml-4 top-[22px] absolute bg-gray-200 rounded-lg" />
                                    <div className="w-32 h-1 mt-3 ml-4 top-[22px] absolute bg-lime-400 rounded-lg" />
                                </div>
                            </div>
                            <div className='w-2/16'>
                                <div className='w-full flex'>
                                    <div className='pt-3'>
                                        <div className="w-14 h-8 px-3.5 py-1 rounded-3xl border border-slate-800 justify-center	items-center gap-2.5 inline-flex">
                                            <button onClick={() => showModalMessage(item.pages.profileImageUrl, item.displayName, item.uid)} type='button'><img className="w-6 h-6 relative" src='../assets/icons/comment-message.png' /></button>
                                        </div>
                                    </div>
                                {/*  <div className='w-1/2 pt-3'>
                                        <div className="w-14 h-8 px-3.5 py-1 rounded-3xl border border-slate-800 justify-start items-center gap-2.5 inline-flex">
                                            <button type='button'><img className="w-6 h-6 relative" src='../assets/icons/calendar-alt.png' /></button>
                                        </div>
                                    </div>*/} 
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                }
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <SendMessage data={profileReceived} sendUpdate={handleClose} />
                </Box>
            </Modal>

        </>
    )
}
