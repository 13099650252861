import { createUserWithEmailAndPassword, GoogleAuthProvider, updateProfile, signInWithPopup, signInWithEmailAndPassword, getAdditionalUserInfo, sendEmailVerification } from 'firebase/auth';
import { FirebaseAuth, FirebaseDB } from "./config";
import { collection, doc, setDoc } from 'firebase/firestore/lite';
import Swal from 'sweetalert2';

const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(FirebaseAuth, googleProvider);
        const { displayName, email, photoURL, uid } = result.user;
        const details = getAdditionalUserInfo(result);
        let userType = sessionStorage.getItem('userType');
        const newDoc = doc(collection(FirebaseDB, `profile`))

        if (details.isNewUser) {

            if (userType === 'Employee') {

                setDoc(newDoc,
                    {
                        uid,
                        email,
                        displayName,
                        photoURL: userType,
                        typeAccount: userType,
                        name: '',
                        position: '',
                        joblocation: '',
                        bio: '',
                        currency: '',
                        interest: '',
                        language: [],
                        stage: 0,
                        experience: [],
                        education: [],
                        certificate: [],
                        achievement: [],
                        salary: '',
                        pages: { website: '', linkedin: '', github: '', twitter: '', facebook: '', behance: '', profileImageUrl: '' },
                        urlVideo: '',
                        urlVideoLocal: '',
                        phone: ''
                    })
            }


            if (userType === 'Employer') {
                const newDocProfile = doc(collection(FirebaseDB, `companyProfile`))
                let idCompany = '';

                idCompany = newDocProfile.id;

                setDoc(newDocProfile,
                    {
                        uid,
                        email,
                        companyName: '',
                        joblocation: '',
                        mision: '',
                        vision: '',
                        pages: { website: '', linkedin: '', twitter: '', facebook: '', profileImageUrl: '' },
                        urlVideo: '',
                        phone: '',
                        about: '',
                        industry: '',
                        country: '',
                        zipCode: '',
                        numberEmployees: '',
                        hiringNeeds: '',
                        interestedProfiles: [],
                        idCompany: idCompany
                        // }).then(docRef => {
                        //     console.log('docRef en setDoc', newDocProfile);
                        //     // idCompany = docRef.id;
                        //     console.log('idCompany en setDoc', idCompany);
                    })

                setDoc(newDoc,
                    {
                        uid,
                        email,
                        name: '',
                        rol: '',
                        phone: '',
                        displayName,
                        typeAccount: userType,
                        photoURL: userType,
                        profileImageUrl: '',
                        idCompany,
                        companyName: '',
                    })
            }
        }

        return {
            ok: true,
            displayName, email, photoURL, uid
        }
    } catch (error) {
        console.log(error);

        const errorMessage = error.message;

        return {
            ok: false,
            errorMessage
        }
    }

}

export const registerUserWithEmailPassword = async ({ email, password, displayName, dominio, existingDomain, existingIdCompany }) => {
    try {
        const resp = await createUserWithEmailAndPassword(FirebaseAuth, email, password)
        const { uid, photoURL, emailVerified } = resp.user;

        if (!emailVerified) {
            sendEmailVerification(FirebaseAuth.currentUser)
                .then(() => {
                    // console.log("Email verification sent!");
                    Swal.fire({
                        icon: 'info',
                        title: 'Info',
                        text: 'Se te ha enviado un correo de verificación, si no aparece, revisa tu bandeja de spam.'
                    })
                })
                .catch((error) => {
                    console.error("Failed to send email verification:", error);
                });
        }
        await updateProfile(FirebaseAuth.currentUser, { displayName });

        const newDoc = doc(collection(FirebaseDB, `profile`))
        let userType = sessionStorage.getItem('userType');
        const details = getAdditionalUserInfo(resp);
        let role = 'User';
        if (!existingDomain) {
            role = 'Admin'
        }
        if (details.isNewUser) {
            if (userType === 'Employee') {
                setDoc(newDoc,
                    {
                        uid,
                        email,
                        displayName,
                        photoURL: userType,
                        typeAccount: userType,
                        name: '',
                        position: '',
                        joblocation: '',
                        bio: '',
                        currency: '',
                        interest: '',
                        language: [],
                        stage: 0,
                        experience: [],
                        education: [],
                        certificate: [],
                        achievement: [],
                        salary: '',
                        pages: { website: '', linkedin: '', github: '', twitter: '', facebook: '', behance: '', profileImageUrl: '' },
                        urlVideo: '',
                        urlVideoLocal: '',
                        phone: ''
                    })
            }

            if (userType === 'Employer') {
                const newDocProfile = doc(collection(FirebaseDB, `companyProfile`))
                let idCompany = '';
                idCompany = newDocProfile.id;

                if (existingDomain) {
                    idCompany = existingIdCompany;
                } else {
                    setDoc(newDocProfile,
                        {
                            uid,
                            email,
                            companyName: '',
                            joblocation: '',
                            mision: '',
                            vision: '',
                            pages: { website: '', linkedin: '', twitter: '', facebook: '', profileImageUrl: '' },
                            urlVideo: '',
                            phone: '',
                            about: '',
                            industry: '',
                            country: '',
                            zipCode: '',
                            numberEmployees: '',
                            hiringNeeds: '',
                            interestedProfiles: [],
                            idCompany: idCompany,
                            dominio
                        })
                }

                // .then(docRef => {
                //     console.log('newDocProfile en setDoc', newDocProfile.id);
                //     // idCompany = docRef.id;
                //     console.log('idCompany en setDoc', idCompany);
                // })

                setDoc(newDoc,
                    {
                        uid,
                        email,
                        name: '',
                        rol: '',
                        phone: '',
                        photoURL: userType,
                        displayName,
                        typeAccount: userType,
                        profileImageUrl: '',
                        idCompany,
                        companyName: '',
                        role: role,
                        status: 'Active'
                    })
            }
        }

        return {
            ok: true,
            uid, photoURL, email, displayName, emailVerified
        }

    } catch (error) {
        console.log(error);

        if (error.code == 'auth/email-already-in-use') {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'El correo ingresado ya está registrado.'
            })
        }
        return { ok: false, errorMessage: error.message };
    }
}

export const loginWithEmailPassword = async ({ email, password }) => {
    try {
        const resp = await signInWithEmailAndPassword(FirebaseAuth, email, password)
        const { uid, photoURL, displayName, emailVerified } = resp.user;

        return {
            ok: true,
            uid, photoURL, displayName, emailVerified
        }
    } catch (error) {
        return { ok: false, errorMessage: error.message };
    }


}

export const logoutFirebase = async () => {
    return await FirebaseAuth.signOut();
}

