import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { AppMenu } from './AppMenu';
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import 'sweetalert2/dist/sweetalert2.css';
import { useCheckAuth } from './hooks/useCheckAuth';
import { LoginPage } from './auth/pages/LoginPage';
import { RegisterEmployer } from './auth/pages/RegisterEmployer';
import { DashboardEmployer } from './pages/DashboardEmployer';
import { AppTopbarAuth } from './AppTopbarAuth';
import { JobsEmployer } from './pages/JobsEmployer';
import { CandidatesEmployer } from './pages/CandidatesEmployer';
import { ResumeEmployer } from './pages/ResumeEmployer';
import { MessagesEmployer } from './pages/MessagesEmployer';
import { AlertsEmployer } from './pages/AlertsEmployer';
import { ProfileEmployer } from './pages/ProfileEmployer';
import { useTranslation } from 'react-i18next';
import { AppTopbar } from './AppTopbar';
import { AppMenuEmployee } from './AppMenuEmployee';
import { HomeEmployee } from './pages/Employee/HomeEmployee';
import { ProfileEmployee } from './pages/Employee/ProfileEmployee';
import { JobsEmployee } from './pages/Employee/JobsEmployee';
import { SalariesEmployee } from './pages/Employee/SalariesEmployee';
import { MessagesEmployee } from './pages/Employee/MessagesEmployee';
import { AlertsEmployee } from './pages/Employee/AlertsEmployee';
import { startLogout } from './store/auth/thunks';
import { HomeFindAJob } from './pages/Employee/HomeFindAJob';
import { BadgesEvaluation } from './pages/Employer/BadgesEvaluation';
import { BadgeEmployee } from './pages/Employee/BadgeEmployee';
import { BadgesEmployer } from './pages/Employer/Badges/BadgesEmployer';
import { JobDetail } from './components/JobDetail';
import { JobDetail as JobDetailEmployee } from './components/Employee/JobDetail';
import { Box, CircularProgress } from '@mui/material';
import { PreviewProfile } from './components/Employer/PreviewProfile';
import { AllCandidatesEmployer } from './components/Employer/AllCandidatesEmployer';

const App = () => {
  const authResult = useCheckAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const myQuery = location.search;
  const { i18n } = useTranslation();
  const { profile } = useSelector(state => state.profile);
  const [type, setType] = useState('');

  useEffect(() => {
    if (authResult.status === 'not-authenticated' && authResult.status === 'isVerifying') {
      navigate('/login');
    }

  }, [authResult.status])

  useEffect(() => {
    if ((authResult.status === 'authenticated' && type == '') || (authResult.status === 'authenticated' && type == null) || (authResult.status === 'authenticated' && type === '')) {
      dispatch(startLogout());
    }
  }, [type])

  useEffect(() => {

    if (profile.length > 0) {
      let typeAccount = profile[0].typeAccount;
      setType(typeAccount);
      setType((state) => {
        return state;
      })
    }
  }, [profile])

  useEffect(() => {
    let lan = myQuery.replace("?lan=", '')
    if (lan === 'es') {
      i18n.changeLanguage(lan);
    }
    if (lan === 'en') {
      i18n.changeLanguage(lan);
    }
  }, [myQuery])

  const menu = [
    {
      label: 'Menú Principal',
      items: [
        { label: 'Home', icon: 'pi pi-fw pi-check-square', to: '/home' }
      ]
    }
  ];

  return (
    <div className="App">
      {
        (authResult.status === 'authenticated') &&
        <div className="bg-[#D7DEE1] flex flex-row justify-center gap-8 relative items-start px-2 py-12" >
          {type === '' &&
            <div className='pt-20'>
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            </div>
          }
          {type === 'Employee' &&
            <AppMenuEmployee />
          }
          {type === 'Employer' &&
            <AppMenu />
          }
          <div className="flex flex-col justify-start gap-8 relative w-3/4 items-center" >
            <AppTopbar />
            {type === 'Employer' &&
              <Routes>
                <Route path="/" Component={JobsEmployer} />
                <Route path="/dashboard" Component={DashboardEmployer} /> 
                <Route path="/jobs" Component={JobsEmployer} />
                <Route path="/candidates" Component={CandidatesEmployer} />
                <Route path="/candidates/:id" element={<PreviewProfile />} /> 
                <Route path="/resume" Component={ResumeEmployer} />
                <Route path="/messages" Component={MessagesEmployer} />
                <Route path="/alerts" Component={AlertsEmployer} />
                <Route path="/profile" Component={ProfileEmployer} />
                <Route path="/badges" Component={BadgesEmployer} />
                <Route path="/badgesevaluation" Component={BadgesEvaluation} />
                <Route path="jobs/:id" element={<JobDetail />} />
              </Routes>

            }
            {type === 'Employee' &&
              <Routes>
                <Route path="/" Component={HomeFindAJob} />
                {/* <Route path="/home" Component={HomeEmployee} /> */}
                {/* <Route path="/dashboard" Component={HomeEmployee} /> */}
                <Route path="/home" Component={HomeFindAJob} />
                <Route path="/dashboard" Component={HomeFindAJob} />
                <Route path="/jobs" Component={JobsEmployee} />
                <Route path="/profile" Component={ProfileEmployee} />
                <Route path="/badges" Component={BadgeEmployee} />
                <Route path="/salaries" Component={SalariesEmployee} />
                <Route path="/messages" Component={MessagesEmployee} />
                <Route path="/alerts" Component={AlertsEmployee} />
                <Route path="jobs/:id" element={<JobDetailEmployee />} />
              </Routes>
            }
          </div>
        </div>
      }
      {(authResult.status === 'not-authenticated' || authResult.status === 'isVerifying' || authResult.status === 'checking') &&
        <>
          {/* <div className="bg-[#D7DEE1] flex flex-col justify-start gap-8 relative items-center px-10 py-12" > */}
          {/* <AppTopbarAuth /> */}
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route exact path="*" element={<LoginPage />} />
            <Route path="/login" Component={LoginPage} />
            <Route path="/login/:lan" element={<LoginPage />} />
            <Route path="/register" Component={RegisterEmployer} />
            <Route path="jobs/:id" element={<JobDetailEmployee />} />
            <Route path="/home" Component={HomeFindAJob} />
          </Routes>
          {/* </div> */}

        </>
      }

    </div>
  );
}

export default App;