import React, { useState, useMemo, useEffect } from 'react'
import { Fab, FormControl, InputLabel, MenuItem, Select, Modal, Box, Alert, TextField } from '@mui/material'
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { startCreatingUserWithEmailPassword, startGoogleSignIn } from '../../store/auth/thunks';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { startLoadingCompanyProfiles } from '../../store/slices/companyProfile/thunks.js'

const formData = {
    email: '',
    password: '',
    confirmPassword: '',
    displayName: '',
    photoURL1: ''
}

export const RegisterEmployer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [queryParameters] = useSearchParams();
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errorForm, setErrorForm] = useState('');
    const [lookingJob, setLookingJob] = useState(true);
    const [profile, setProfile] = useState('Employee');
    const [isEmployee, setIsEmployee] = useState(false);
    const { status, errorMessage } = useSelector(state => state.auth)
    const { companyProfiles } = useSelector(state => state.companyProfile);
    const isCheckingAuthentication = useMemo(() => status === 'checking', [status]);
    const dominiosNoEmpresariales = ['gmail.com', 'hotmail.com', 'outlook.com', 'yahoo.com', 'aol.com', 'icloud.com', 'yandex.com', 'mail.ru', 'protonmail.com', 'zoho.com', 'gmx.com', 'qq.com', '163.com', 'sina.com', '126.com', 'live.com', 'rocketmail.com', '139.com']; // Agrega aquí los dominios permitidos

    useEffect(() => {
        dispatch(startLoadingCompanyProfiles());
    }, [])

    useEffect(() => {
        sessionStorage.setItem('userType', '');
    }, [])

    useEffect(() => {
        let typeProfile = queryParameters.get("type");
        if (typeProfile === 'Emp') {
            setIsEmployee(true);
        } else { setIsEmployee(false) }
    }, [queryParameters])

    const validarCorreo = (correo) => {
        const partesCorreo = correo.split('@');
        const dominio = partesCorreo[1];

        if (dominiosNoEmpresariales.includes(dominio)) {
            return true; // El correo electrónico pertenece a un dominio no empresarial permitido
        } else {
            return false; // El correo electrónico no pertenece a un dominio no empresarial permitido
        }
    };

    useEffect(() => {

    }, [companyProfiles]);

    useEffect(() => {

    }, [profile])

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            email: "",
            displayName: "",
            password: "",
            confirmPassword: "",
            photoURL1: ""
        }
    })

    const handleGoogleSignIn = () => {
        setErrorForm('');
        if (lookingJob) {
            setValue('photoURL1', 'Employee')
            sessionStorage.setItem('userType', 'Employee');
        } else {
            setValue('photoURL1', 'Employer')
            sessionStorage.setItem('userType', 'Employer');
        }
        dispatch(startGoogleSignIn());
        navigate("/");
    }

    function extraerDominio(correoElectronico) {
        // Expresión regular para extraer el dominio de un correo electrónico
        var regex = /@([a-zA-Z0-9.-]+)$/;

        // Obtener coincidencias utilizando la expresión regular
        var matches = correoElectronico.match(regex);

        // Verificar si hay coincidencias y devolver el dominio si es así
        if (matches && matches.length > 1) {
            return matches[1];
        } else {
            // Devolver null si no se encuentra un dominio
            return null;
        }
    }

    const showErrorCorreo = () => {
        Swal.fire('Error', 'Para el perfil de reclutador debes registrarte con una cuenta de correo empresarial.', 'error');
    };

    const onSubmit = (data) => {

        let correo = data.email;
        let dominio = extraerDominio(correo);
        let existingProfiles = []
        let existingDomain = false;
        let existingIdCompany = '';

        if (validarCorreo(correo) && !lookingJob) {
            showErrorCorreo();
            return;
        }

        existingProfiles = companyProfiles.filter(x => x.dominio === dominio);
        if (existingProfiles.length > 0) {
            existingDomain = true;
            existingIdCompany = existingProfiles[0].idCompany;
        }

        setErrorForm('');
        if (!lookingJob) {
            setValue('photoURL1', 'Employer')
            sessionStorage.setItem('userType', 'Employer');
        } else {
            setValue('photoURL1', 'Employee')
            sessionStorage.setItem('userType', 'Employee');
        }
        if (!data) {
            setErrorForm('Verifique los datos ingresados')
            return;
        }

        if (data.password != data.confirmPassword) {
            setErrorForm('La contrasena no coincide');
            return;
        }
        setErrorForm('');


        const newData = {
            ...data,
            dominio,
            existingDomain,
            existingIdCompany
        }

        dispatch(startCreatingUserWithEmailPassword(newData));
        navigate('/login');
    }

    const handleToggle = () => {
        setLookingJob(!lookingJob);
    }

    const handleSelect = () => {
        if (lookingJob) {
            setProfile('Employee')
            setLookingJob(false)
        } else {
            setProfile('Employer');
            setLookingJob(true);
        }
    }

    return (
        <>
            <div className='flex flex-col items-center justify-center w-full min-h-screen bg-white lg:flex-row '>
                <div className='flex flex-1 w-full lg:flex-row lg:items-center lg:justify-center mb-6 ml-6 mt-6'>
                    <div className='w-5/12 hidden lg:block'>
                        {lookingJob &&
                            <img className="Steptodown3 w-[669px] h-[932px] rounded-3xl" src="../assets/images/employee.png" />
                        }
                        {!lookingJob &&
                            <img className="Steptodown3 w-[669px] h-[932px] rounded-3xl" src="../assets/images/steptodown.png" />
                        }
                    </div>
                    <div className='w-full lg:w-7/12 px-6 lg:px-20'>
                        <div className='flex flex-wrap w-full'>
                            <div className='w-full sm:w-1/2 flex justify-center sm:justify-start'>
                                <img src="https://file.rendit.io/n/azQfRkGArF4IpCUMMQE4.png" className="relative min-w-0 mb-px mt-14" alt='' />
                            </div>
                            <div className='w-full sm:w-1/2 flex justify-center sm:justify-end'>
                                {!lookingJob &&
                                    <button className="text-center font-['Poppins'] font-medium bg-[#FF7F11] text-white shadow-md rounded-[32px] w-60 h-14 mt-4 sm:mt-16" style={{ padding: '16px 37px' }} onClick={handleSelect}>
                                        {t('login.lookinghire')}
                                    </button>
                                }
                                {lookingJob &&
                                    <button className="text-center font-['Poppins'] font-medium bg-[#FF7F11] text-white shadow-md rounded-[32px] w-60 h-14 mt-4 sm:mt-16" style={{ padding: '16px 37px' }} onClick={handleSelect} disabled={isEmployee}>
                                        {t('login.lookingjob')}
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='flex flex-col mt-12 lg:mt-36 justify-center items-center text-center'>
                            <div className='w-full px-8 lg:px-32'>
                                <div className="flex flex-row text-center justify-center w-full font-['Poppins'] text-gray-900 text-2xl font-bold">{t('register.create')}</div>
                                <div className='flex flex-col w-full mt-12 justify-center items-center'>
                                    <TextField
                                        id='displayName'
                                        label={t('register.name')}
                                        className='w-full rounded-lg indent-3'
                                        placeholder={t('register.name')}
                                        {...register('displayName', { required: true, minLength: 6 })}
                                    />
                                    {errors.displayName && errors.displayName.type === "required" &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{t('register.displayName')}</p>
                                    }
                                    {errors.displayName && errors.displayName.type === "minLength" &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{t('register.characters')}</p>
                                    }
                                </div>
                                <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                    <TextField
                                        id='email'
                                        className='w-full rounded-lg indent-3'
                                        label={t('register.email')}
                                        placeholder={t('register.email')}
                                        {...register('email', { required: true, minLength: 6 })}
                                    />
                                    {errors.email && errors.email.type === "required" &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{t('register.emailRequired')}</p>
                                    }
                                </div>
                                <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                    <TextField
                                        id='password'
                                        type='password'
                                        className='w-full rounded-lg indent-3'
                                        label={t('register.password')}
                                        placeholder={t('register.password')}
                                        {...register('password', { required: true, minLength: 6 })}
                                    />
                                    {errors.password && errors.password.type === "required" &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{t('register.passwordRequired')}</p>
                                    }
                                </div>
                                <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                    <TextField
                                        id='confirmPassword'
                                        type='password'
                                        className='w-full rounded-lg indent-3'
                                        placeholder={t('register.confirm')}
                                        label={t('register.confirm')}
                                        {...register('confirmPassword', { required: true, minLength: 6 })}
                                    />
                                    {errors.confirmPassword && errors.confirmPassword.type === "required" &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{t('register.passwordConfirm')}</p>
                                    }
                                </div>
                                <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                    <FormControl className='w-full rounded-lg '>
                                        <Select
                                            labelId="looking-label"
                                            id="looking"
                                            label=''
                                            placeholder="Select Profile"
                                            defaultValue="Select Profile"
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            onChange={handleSelect}
                                            value={lookingJob}
                                            disabled={isEmployee}
                                        >
                                            <MenuItem value={true}>
                                                {t('login.lookingjob')}
                                            </MenuItem>
                                            <MenuItem value={false}>
                                                {t('login.lookinghire')}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='w-full mt-12'>
                                    <button className='w-full h-14 bg-[#0D497D] rounded-full text-white' type='button' onClick={handleSubmit(onSubmit)} disabled={isCheckingAuthentication}>{t('register.started')}</button>
                                </div>
                                {errorMessage &&
                                    <div className='pt-4'>
                                        <Alert severity="error">{t('login.errorMessage')}</Alert>
                                    </div>
                                }
                                {!isEmployee &&
                                    <div className='flex mt-4 w-full text-center'>
                                        <div className='w-full flex justify-center'>
                                            <p>{t('register.alreadyAccount')}</p>
                                            <Link to='/login'>
                                                <div className="text-sky-900 text-[16px] font-medium pl-2">{t('register.login')}</div>
                                            </Link>
                                        </div>
                                    </div>
                                }
                                {errorForm &&
                                    <div className='pt-4'>
                                        <Alert severity="error">{t('login.errorMessage')}</Alert>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    
}



// Categoria z3c6IF043OfrtA6nHpiV
// Company Id r4DLb1MZGhkve5H9DXI9
// uid roDg1en8Xgc0NUYOn2sV2xJ3cFB2
// name: Habilidades - Ventas
// id_badge: rWg7HkSFRQUwfg2MG3Ki

// earnBadges: rWg7HkSFRQUwfg2MG3Ki
// id_evaluation: JMCHTw7x5MUQNkGcOEHD

// silagarcia202@gmail.com

// kenia@xetech.group