import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore/lite"
import { addNewComment, savingNewComment, setActiveComment, setComments } from "./commentsSlice"
import { FirebaseDB } from "../../../firebase/config"


export const startNewComment = (comment) =>{
    return async (dispatch, getState)=>{
        dispatch (savingNewComment())
        const {uid} = getState().auth 
        const {
            idconversation,
            profileView,
            displayName,
            cont
        } = comment;

        const now = new Date();

        const newComment  = {
            idconversation,
            uid,
            profileView,
            displayName,
            cont,
            date: now.toLocaleDateString('en-CA'), // yyyy-mm-dd
            hour: now.toLocaleTimeString('en-GB')
        }
        const newDoc = doc(collection(FirebaseDB, 'comment'))
        await setDoc(newDoc,newComment)
        newComment.id = newDoc.id
        dispatch(addNewComment(newComment))
        dispatch(setActiveComment(newComment))
    }
}

export const startLoadingCommentsById = (idconversation) => {
    return async (dispatch) => {
      try {
        // Asegúrate de que idconversation sea una cadena o un número válido
      
        
        const q = query(collection(FirebaseDB, 'comment'), where("idconversation", "==", idconversation));
        const querySnapshot = await getDocs(q);
  
        // Verifica los datos devueltos por Firebase
       
        
        const comments = [];
        querySnapshot.forEach((doc) => {
          comments.push({ id: doc.id, ...doc.data() });
        });
  
        // Verifica los comentarios antes de despacharlos
        
  
        dispatch(setComments(comments));
        return comments;
      } catch (e) {
        console.log("Error al cargar conversación", e);
        return [];
      }
    };
  };