import { loadApplyingJob, loadCurrentProcessJob } from "../../../helpers/loadApplyingJob";
import { setApplyingJobs, setCurrentProcesssJobs, setSaving } from './applyingjobSlice'
export const startLoadingApplyingJobs = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error('el UID del usuario no existe');
        const jobs = await loadApplyingJob(uid);
        dispatch(setApplyingJobs(jobs));
    }
}

// export const startLoadingCurrentProcessJobs = (idCompany = '') => {
//     return async (dispatch) => {

//         const currentProcessJobs = await loadCurrentProcessJob(idCompany);
//         dispatch(setCurrentProcesssJobs(currentProcessJobs));
//     }
// }

// export const startLoadingCurrentProcessJobs = (idCompany = '') => {
//     return async (dispatch, getState) => {
//         const { uid } = getState().auth;

//         if (!uid) throw new Error("El UID del usuario no existe");
//         const companyProfile = await loadCurrentProcessJob(idCompany);
//         dispatch(setCurrentProcesssJobs(companyProfile));
//     }
// }

// export const startSaveApplyingJob = () => {
//     return async (dispatch, getState) => {

//         dispatch(setSaving());

//         const { uid } = getState().auth;
//         const { active } = getState().active;
//         const jobToFireStore = { ...active };
//         delete jobToFireStore.id;

//         const docRef = doc(FirebaseDB, `applyingJob/${active.id}`)
//         await setDoc(docRef, jobToFireStore, { merge: true });

//         dispatch(updateJob(job));
//     }
// }