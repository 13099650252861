import { Switch, TextField } from '@mui/material';
import React, { useState } from 'react'


export const FinalInterview = ({ getFinalInterviewData, data }) => {
    const [interviewChecked, setInterviewChecked] = useState(false);

    const handleChange = (event) => {
        setInterviewChecked(event.target.checked);
        getFinalInterviewData(event.target.checked);
    };

    return (
        <>
            <div className='flex flex-row ml-24 mr-24 mt-6'>
                <div className='flex w-8/12 justify-between'>
                    <TextField
                        id='job-title'
                        className='w-4/12 bg-gray-200'
                        label='Step #6 Final InterView'
                        placeholder="Final Inerview"
                        disabled
                    />
                    <div className='w-2/12'>
                        <Switch
                            checked={interviewChecked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </div>
                    <div className='w-4/12 justify-end items-end place-content-end'></div>

                </div>
            </div>
        </>
    )
}
