import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    status: 'checking', // 'not-authenticated', 'authenticated', 'checking'
    uid: null,
    email: null,
    displayName: null,
    photoURL: null,
    emailVerified: null,
    errorMessage: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, { payload }) => {
            state.status = 'authenticated';
            state.uid = payload.uid;
            state.email = payload.email;
            state.displayName = payload.displayName;
            state.photoURL = payload.photoURL;
            state.emailVerified = payload.emailVerified;
            state.errorMessage = null;
        },
        logout: (state, { payload }) => {
            state.status = 'not-authenticated';
            state.uid = null;
            state.email = null;
            state.displayName = null;
            state.photoURL = null;
            state.errorMessage = payload?.errorMessage;
        },
        isVerifying: (state) => {
            state.status = 'isVerifying';
            state.uid = null;
            state.email = null;
            state.displayName = null;
            state.photoURL = null;
            state.errorMessage = "No ha sido verificado";
        },
        checkingCredentials: (state) => {
            state.status = 'checking';
        }
    }
})
// Action creators are generated for each case reducer function
export const { login, logout, isVerifying, checkingCredentials } = authSlice.actions