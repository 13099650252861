import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";


export const loadCompanyProfile = async (idCompany = '') => {
    //if (!idCompany) throw new Error('El id de la empresa no existe');

    const q = query(
        collection(FirebaseDB, 'companyProfile'),
        where('idCompany', '==', idCompany)
    );

    const docs = await getDocs(q);

    const profile = [];
    docs.forEach(doc => {
        profile.push({ id: doc.id, ...doc.data() });
    })

    return profile;
}

export const loadCompanyProfiles = async () => {
    const q = query(
        collection(FirebaseDB, 'companyProfile')
    );

    const docs = await getDocs(q);

    const profiles = [];
    docs.forEach(doc => {
        profiles.push({ id: doc.id, ...doc.data() });
    })
    return profiles;
}