import React, { useEffect, useState } from 'react'
import { Fab } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import ReactPlayer from 'react-player'
import Moment from 'react-moment';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startNewMessage } from '../../store/slices/messages/thunks';
import {  useNavigate, useParams } from 'react-router-dom';
import { startLoadingProfileById } from '../../store/slices/profile/thunks';
import Swal from 'sweetalert2';
import { Coments, Comments } from '../../pages/Employer/coments';

export const PreviewProfile = ({ sendUpdate, onClick, data }) => {
    const dispatch = useDispatch();
    const { profile } = useSelector(state => state.profile);
    const navigate = useNavigate();
    const { id } = useParams();
    const [profileData, setProfileData] = useState(null);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            summary: ""
        }
    });
    
    let idCompany;
    if (profile && profile.length > 0) {
        idCompany = profile[0].idCompany;
    }

    const idconversation = idCompany+id

    let displayName
    if (profile && profile.length > 0){
        displayName = profile[0].displayName
    }

    const closeUpdateProfile = () => {
        navigate('/candidates');
    }
    
        useEffect(() => {
            if (id) {
                dispatch(startLoadingProfileById(id))
                    .then(data => {
                        setProfileData(data);
                    })
                    .catch(error => {
                        console.error('Error al cargar el perfil:', error);
                    });
            }
        }, [dispatch, id]);


    const shareProfile = () => {
        const currentUrl = window.location.href;

    navigator.clipboard.writeText(currentUrl)
        .then(() => {
            Swal.fire({
                title: 'Compartir',
                text: '¡Enlace del perfil copiado correctamente!',
                icon: 'success'
            });
        })
        .catch((error) => {
            console.error('Error al copiar al Portapapeles:', error);
            Swal.fire({
                title: 'Error',
                text: 'Hubo un error al copiar el enlace al Portapapeles',
                icon: 'error'
            });
        });
};


    return (
        <>
        {profileData && (
        <div className='bg-white flex flex-col w-full'>
            <div className='flex flex-row w-full pl-6 justify-end pr-14'>
                {/* <div className='w-52 flex h-9'>

                    <button onClick={closeUpdateProfile} className='w-full h-full bg-white flex-wrap justify-start text-left pl-10 rounded-[32px] border border-slate-800 text-[16px] font-medium text-slate-800'>
                        Edit Profile
                    </button>
                    <img className="w-5 h-5 ml-36 mt-2 absolute pointer-events-none" src='../assets/icons/edit.png' />
                </div> */}
                <div className='flex flex-row w-full'>
                    <button type='button' onClick={closeUpdateProfile}><img className="w-6 h-6 absolute rounded-mx mt-2 ml-2" src="../assets/icons/left-arrow-from-left.png" alt='' /></button>
                    {/* <p className=" text-2xl font-bold text-gray-800 ml-1 mt-2" style={{ left: 86 }}>Preview Profile</p> */}
                </div>
                <div className='pl-6 flex w-36 h-9 mt-4'>
                    <button className='w-full h-full bg-orange-500 rounded-3xl justify-start text-left pl-6 text-white text-[14px] font-medium' onClick={shareProfile}>
                        Share
                    </button>
                            <img className="w-6 h-6 ml-20 mt-1 absolute" src='../assets/icons/share.png' />
                </div>
            </div>
            <div className='flex flex-row w-full mt-12 pl-8'>
                <div className='flex flex-1 w-full'>
                    <div className='w-3/12'>
                        <div className='border-r h-2/5'>
                        {profileData.pages && (
                            <div className='flex flex-row'>
                                {profileData.pages.profileImageUrl ? (
                                    <img className="w-48 h-48 mx-auto rounded-full object-cover" src={profileData.pages.profileImageUrl} alt="Profile" />
                                ) : (
                                    <img className="w-48" src="../assets/images/login-user-default.png" alt="Default Profile" />
                                )}
                            </div>
                                )}
                            <div className='flex flex-row mt-10 pl-2'>
                                <p className="text-zinc-800 text-[18px] font-bold">Contact Info</p>
                            </div>
                            <div className='flex flex-row mt-4 pl-2'>
                                    <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                                    <div className="pl-1 text-sky-900 text-[12px] font-normal">
                                        {profileData.email}
                                    </div>
                            </div>
                            <div className='flex flex-row mt-4 pl-2'>
                                <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                                {profileData.pages && profileData.pages.website ? (
                                    <div className="pl-1 text-sky-900 text-[12px] font-normal">
                                        {profileData.pages.website}
                                    </div>
                                ) : (
                                    <div className="pl-1 text-sky-900 text-[12px] font-normal">Add phone</div>
                                )}
                            </div>
                            <div className='flex flex-row mt-10 pl-2'>
                                <p className="text-zinc-800 text-[18px] font-bold">Pages</p>
                            </div>
                            <div className='flex flex-row mt-4 pl-2 overflow-x-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                                {profileData.pages && profileData.pages.website ? (
                                    <p className="pl-1 text-sky-900 text-[12px] font-normal text-left">
                                        <a href={profileData.pages.website} target="_blank">{profileData.pages.website}</a>
                                    </p>
                                ) : (
                                    <div className="pl-1 text-sky-900 text-[12px] font-normal">
                                        <a href={(profileData.pages && profileData.pages.website) ? profileData.pages.website : 'Add website'} target="_blank">
                                            {(profileData.pages && profileData.pages.website) ? profileData.pages.website : 'Add website'}
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/linkedin-alt.png" alt='' />
                                {profileData.pages && profileData.pages.linkedin ? (
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal text-left">
                                        <a href={profileData.pages.linkedin} target="_blank">{profileData.pages.linkedin}</a>
                                    </div>
                                ) : (
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal">
                                        <a href={(profileData.pages && profileData.pages.linkedin) ? profileData.pages.linkedin : 'Add LinkedIn'} target="_blank">
                                            {(profileData.pages && profileData.pages.linkedin) ? profileData.pages.linkedin : 'Add LinkedIn'}
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/twitter.png" alt='' />
                                {profileData.pages && profileData.pages.twitter ? (
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal text-left">
                                        <a href={profileData.pages.twitter} target="_blank">{profileData.pages.twitter}</a>
                                    </div>
                                ) : (
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal">
                                        <a href={(profileData.pages && profileData.pages.twitter) ? profileData.pages.twitter : 'Add Twitter'} target="_blank">
                                            {(profileData.pages && profileData.pages.twitter) ? profileData.pages.twitter : 'Add Twitter'}
                                        </a>
                                    </div>
                                )}
                                    </div>
                                    <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                        <img className='w-5 h-5' src="../assets/icons/facebook.png" alt='' />
                                        {profileData.pages && profileData.pages.facebook ? (
                                            <div className="pl-2 text-sky-900 text-[12px] font-normal text-left">
                                                <a href={profileData.pages.facebook} target="_blank">{profileData.pages.facebook}</a>
                                            </div>
                                        ) : (
                                            <div className="pl-2 text-sky-900 text-[12px] font-normal">
                                                <a href={(profileData.pages && profileData.pages.facebook) ? profileData.pages.facebook : 'Add Facebook'} target="_blank">
                                                    {(profileData.pages && profileData.pages.facebook) ? profileData.pages.facebook : 'Add Facebook'}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                    <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                        <img className='w-5 h-5' src="../assets/icons/behance.png" alt='' />
                                        {profileData.pages && profileData.pages.behance ? (
                                            <div className="pl-2 text-sky-900 text-[12px] font-normal text-left">
                                                <a href={profileData.pages.behance} target="_blank">{profileData.pages.behance}</a>
                                            </div>
                                        ) : (
                                            <div className="pl-2 text-sky-900 text-[12px] font-normal">
                                                <a href={(profileData.pages && profileData.pages.behance) ? profileData.pages.behance : 'Add Behance'} target="_blank">
                                                    {(profileData.pages && profileData.pages.behance) ? profileData.pages.behance : 'Add Behance'}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                    <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                        <img className='w-5 h-5' src="../assets/icons/github.png" alt='' />
                                        {profileData.pages && profileData.pages.github ? (
                                            <div className="pl-2 text-sky-900 text-[12px] font-normal text-left">
                                                <a href={profileData.pages.github} target="_blank">{profileData.pages.github}</a>
                                            </div>
                                        ) : (
                                            <div className="pl-2 text-sky-900 text-[12px] font-normal">
                                                <a href={(profileData.pages && profileData.pages.github) ? profileData.pages.github : 'Add GitHub'} target="_blank">
                                                    {(profileData.pages && profileData.pages.github) ? profileData.pages.github : 'Add GitHub'}
                                                </a>
                                            </div>
                                        )}
                            </div>
                        </div>
                    </div>
                    <div className='w-9/12 pl-6 pr-6'>
                        <div className='flex flex-row w-full'>
                            <div className='w-1/2'>
                                <div className='flex flex-row w-full'>
                                    <p className="text-[#2B2C2B] font-['Poppins'] text-lg">{profileData.name}</p>
                                </div>
                                <div className='flex flex-row w-full'>
                                    <p className="text-[#0D497D] text-xs font-['Poppins'] font-bold">{profileData.joblocation}</p>
                                </div>
                            </div>
                            <div className='w-1/2 justify-end'>
                                <div className='flex flex-row w-11/12 h-16 justify-between' style={{ background: 'white', borderRadius: 42, borderLeft: '0.50px #818181 solid', borderTop: '0.50px #818181 solid', borderRight: '0.50px #818181 solid', borderBottom: '0.50px #818181 solid' }}>
                                    <div className='flex w-4/12'>
                                        {/* <div style={{ left: 29, top: 20, position: 'absolute', color: '#2B2C2B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '500', wordWrap: 'break-word' }}>Top Badges</div> */}
                                        <p className="pt-5 pl-4 text-[#2B2C2B] text-[14px] font-['Poppins'] font-medium">Top badges</p>
                                    </div>
                                    <div className='flex w-8/12'>
                                        <div className='flex w-2/12 pt-1 pl-1'>
                                            <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=c6f44aae-7902-45d1-b48f-fb097b8a0912" />
                                        </div>
                                        <div className='flex w-2/12 pt-1 pl-1'>
                                            <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%203.png?alt=media&token=45b35fbf-9ff1-45f9-a048-63d328284a7c" />
                                        </div>
                                        <div className='flex w-2/12 pt-1 pl-1'>
                                            <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%204.png?alt=media&token=1ec44765-7d7a-4d56-8e04-c0976483e366" />
                                        </div>
                                        <div className='flex w-2/12 pt-1 pl-1'>
                                            <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%205.png?alt=media&token=260be5f7-9283-4104-a958-831df00d71dc" />
                                        </div>
                                        <div className='flex w-2/12 pt-1 pl-1'>
                                            <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%206.png?alt=media&token=19cb9f22-4cae-4903-90c9-137dd3e3ab7c" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row w-full pt-9 justify-center'>
                            <div className='w-[640px] h-[360px] bg-gray-400'>
                                {profileData.urlVideo &&
                                    <ReactPlayer url={profileData.urlVideo} controls={true} />
                                }
                                {/* {!urlVideo && !urlVideoLocal &&
                                    <ReactPlayer url='https://www.youtube.com/watch?v=LVbUNRwpXzw' />
                                } */}
                                {/* {!urlVideo && urlVideoLocal &&
                                    <ReactPlayer url={urlVideoLocal} controls={true} />
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='flex w-full mt-14 pl-6'> */}
            <div className='flex flex-row w-full mt-14 pl-6 text-left'>
                <p className="text-sm/[16px] font-['Poppins'] font-medium w-full">About Me</p>
            </div>
            <div className='flex flex-row w-full mt-4 pl-6 pr-6'>
                <p className=" font-['Poppins'] w-full text-justify text-[#818181] text-xs/[12px] font-normal leading-5 min-h-[50px]">
                    {profileData.bio}
                </p>
            </div>
            <div className='flex flex-row w-full text-left pl-6 mt-14'>
                <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full">Work Experience</p>
            </div>
            {profileData.experience.map((item, index) => (
                <>
                    <div key={index} className='flex flex-row w-full mt-8 pl-6'>
                        {item.currentJob &&
                            <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">
                                <Moment format='YYYY'>{Date(item.startDate)}</Moment>  |
                                {' '}To Date  |
                                {' '}{item.jobTitle}

                            </p>
                        }
                        {!item.currentJob &&
                            <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">
                                <Moment format='YYYY'>{Date(item.startDate)}</Moment>  |
                                {' '}<Moment format='YYYY'>{Date(item.endDate)}</Moment>  |
                                {' '}{item.jobTitle}

                            </p>
                        }
                    </div>
                    <div className='flex flex-row w-full pl-6 mt-3'>
                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">Summary</p>
                    </div>
                    <div className='flex flex-row w-full mt-2 pl-6 pr-6'>
                        <p className=" font-['Poppins'] w-full text-justify text-[#818181] text-xs/[12px] font-normal leading-5 min-h-[90px]">
                            {item.summary}
                        </p>
                    </div>
                    <div className='flex flex-row w-full pl-6 mt-4 justify-start'>
                        <div className='w-7/12'>
                            <div className='flex flex-row w-full'>
                                <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">Achievements</p>
                            </div>
                            {item.Achievements.map((item, index) => (
                                <div key={index} className='flex flex-row w-full pt-2'>
                                    <p className="text-[#818181] text-xs font-['Poppins'] font-normal leading-5 break-words">{item}</p>
                                </div>
                            ))}
                            {/* {item.Achievements[0] &&
                                <div className='flex flex-row w-full pt-2'>
                                    <p className="text-[#818181] text-xs font-['Poppins'] font-normal leading-5 break-words">{item.Achievements[0]}</p>
                                </div>
                            } */}
                        </div>
                        <div className='w-5/12'>
                            <div className='flex flex-row w-full'>
                                <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">Applied Badges</p>
                            </div>
                            <div className='flex flex-row w-full justify-start'>
                                <div className='pt-1'>
                                    <img className='flex w-9 h-7 mt-3' src="../assets/images/badge_default.svg" />
                                </div>
                                <div className='pt-1 pl-2'>
                                    <img className='flex w-9 h-7 mt-3' src="../assets/images/badge_default.svg" />
                                </div>
                                <div className='pt-1 pl-2'>
                                    <img className='flex w-9 h-7 mt-3' src="../assets/images/badge_default.svg" />
                                </div>
                                <div className='pt-1 pl-2'>
                                    <img className='flex w-9 h-7 mt-3' src="../assets/images/badge_default.svg" />
                                </div>
                                <div className='pt-1 pl-2'>
                                    <img className='flex w-9 h-7 mt-3' src="../assets/images/badge_default.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ))
            }
            <div className='flex flex-row w-full text-left pl-6 mt-11'>
                <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full">Education</p>
            </div>
            {profileData.education.map((item, index) => (
                <div key={index} className='flex flex-row w-full pl-6 mt-5'>
                    <p className="text-[#0D497D] text-xs font-normal break-words">
                        <Moment format='YYYY'>{Date(item.startDate)}</Moment>-
                        <Moment format='YYYY'>{Date(item.endDate)}</Moment>
                    </p>
                    <p className='text-[#0D497D] text-xs font-normal break-words pl-3'>|</p>
                    <p className="text-[#0D497D] text-xs font-normal break-words pl-3">{item.title}</p>
                    <p className='text-[#0D497D] text-xs font-normal break-words pl-3'>|</p>
                    <p className="text-[#0D497D] text-xs font-normal break-words pl-3">{item.institution[0]}</p>
                </div>
            ))

            }
            <div className='flex flex-row w-full text-left pl-6 mt-10'>
                <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full">Certificates</p>
            </div>
            {profileData.certificate.map((item, index) => (
                <div key={index} className='flex flex-row w-full pl-6 mt-1'>
                    <div className='flex flex-row w-full mt-3'>
                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-normal break-words">{item.certificateName}</p>
                    </div>
                </div>
            ))
            }

            <div className='flex flex-row w-full text-left pl-6 mt-8'>
                <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full">Badges</p>
            </div>
            <div className='flex flex-row row-span-1 w-full min-h-[100px pl-6'>
                <div className='flex flex-wrap w-full justify-start'>

                    <img className='w-9 h-7 mt-3 pl-2' src="../assets/images/badge_default.svg" />
                    <img className='w-9 h-7 mt-3 pl-2' src="../assets/images/badge_default.svg" />
                    <img className='w-9 h-7 mt-3 pl-2' src="../assets/images/badge_default.svg" />

                    {/* <div className='w-1/12'>
                        <img className='w-9 h-7 mt-3 pl-1' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=bbc1b273-e6b3-4a11-ad86-58b89611de36" />
                    </div> */}
                </div>
            </div>
            <div className='flex flex-row w-full pl-6 mt-12 justify-start'>
                <div className='w-10/12'>
                    <div className='flex flex-row w-full'>
                        <p className="text-[#2B2C2B] text-lg font-['Poppins'] font-bold break-words">Salary Expectation</p>
                    </div>
                    <div className='flex flex-row w-full'>
                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-normal break-words">$ {profileData.salary} Anual</p>
                    </div>
                </div>
            </div>
            {/*<div className='w-full'>
                <div>
                    <div className='w-full pt-4 pl-10 pr-4'>
                    </div>
                    <div className='flex flex-row mt-4 pl-6'>
                        <div className='w-full pl-6 pr-6'>
                            <TextField
                                multiline
                                rows={4}
                                placeholder='Agrega un comentario sobre este Perfil'
                                className='w-full'
                                {...register("summary")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='w-full justify-center items-center text-center pt-8'>
                            <button className="w-72 h-14 px-8 py-1 bg-sky-900 rounded-3xl justify-center items-center gap-2.5 inline-flex text-white text-base font-medium font-['Poppins']" onClick={handleSubmit(onSubmit)}>
                                Add Comment
                            </button>
                        </div>
                    </div>
                </div>
            </div>*/}
             {idCompany && (
                <Comments idconversation={idconversation} displayName={displayName} profileview={id} />
            )}
            </div>
            )}
        </>
    )
}
