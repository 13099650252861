import { collection, doc, setDoc } from "firebase/firestore/lite";
import { FirebaseDB } from "../../../firebase/config";
import { loadBadgesCatalog } from "../../../helpers/loadBadgesCatalog";
import { addNewEmptyEarnBadge, setActiveEarnBadge, setEarnBadges, updateEarnBadge, setSaving } from "./earnBadgeSlice";
import { loadEarnBadge, loadEarnBadges } from "../../../helpers/loadEarnBadge";


export const startNewEarnBadge = (badge) => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const {
            id_badge,
            id_evaluation,
            result
        } = badge;

        const newEarnBadge = {
            id_badge,
            id_evaluation,
            result,
            uid,
            creationDate: new Date().getTime(),
        }

        const newDoc = doc(collection(FirebaseDB, `earnBadge`));
        await setDoc(newDoc, newEarnBadge);
        newEarnBadge.id = newDoc.id;
        dispatch(addNewEmptyEarnBadge(newEarnBadge));
        dispatch(setActiveEarnBadge(newEarnBadge));
    }
}

export const startLoadingEarnBadge = (idCompany = '') => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        // if (!uid) throw new Error("El UID del usuario no existe");
        const earnBadge = await loadEarnBadge(uid);
        dispatch(setEarnBadges(earnBadge));
    }
}

export const startLoadingEarnBadges = (idCompany = '') => {
    return async (dispatch, getState) => {
        // const { uid } = getState().auth;

        // if (!uid) throw new Error("El UID del usuario no existe");
        const earnBadge = await loadEarnBadges();
        dispatch(setEarnBadges(earnBadge));
    }
}

export const startSaveEarnBadge = () => {
    return async (dispatch, getState) => {
        dispatch(setSaving());

        const { active: earnBadge } = getState().earnBadge;
        const earnBadgeToFireStore = { ...earnBadge };
        delete earnBadgeToFireStore.id;

        const docRef = doc(FirebaseDB, `earnBadge/${earnBadge.id}`)
        await setDoc(docRef, earnBadgeToFireStore, { merge: true });
        dispatch(updateEarnBadge(earnBadge));
    }
}
