import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { startLoadingEvaluations } from '../../../store/slices/evaluation/thunks';
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { startDeleteBadge } from '../../../store/slices/badgesCatalog/thunks';
import Swal from 'sweetalert2';

export const BadgePreviewEmployer = ({ onClick, onClickUpdate }) => {
    const dispatch = useDispatch();
    const { active } = useSelector(state => state.badgesCatalog);
    const { evaluations } = useSelector(state => state.evaluation);
    const [badgeEvaluations, setBadgeEvaluations] = useState([]);
    const { t } = useTranslation();

    const closePreviewBadge = () => {
        onClick(true);
    }

    const openUpdateBadge = () => {
        onClickUpdate(true);
    }

    useEffect(() => {

        dispatch(startLoadingEvaluations());
        let tmpBadge = [];
        let id = active.id;
        if (evaluations.length > 0) {
            evaluations.map((item) => {
                let tmpBadge2 = item.badge.filter(x => x === id);
                if (tmpBadge2.length > 0) {
                    tmpBadge.push({ id: item.id, badges: item.badge });
                }
            })
        }

        let tmpData = evaluations.filter(x => tmpBadge.some(y => y.id === x.id));
        setBadgeEvaluations(tmpData);
    }, [])

    const handleDeleteBadge = () => {
        if (active && active.id) {
            Swal.fire({
                title: "¿Estás seguro?",
                text: "¡No podrás deshacer esta acción!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, eliminar",
                cancelButtonText: "Cancelar"
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(startDeleteBadge(active.id));
                    Swal.fire({
                        title: "¡Eliminado!",
                        text: "Tu evaluación ha sido eliminada.",
                        icon: "success"
                    });
                    closePreviewBadge()
                }
            });
        }
    }
    
    return (
        <div className='flex flex-col bg-white w-full place-items-center min-h-[780px]'>
            <div className='flex flex-row w-full pt-2 pl-2'>
                <button onClick={closePreviewBadge}>
                    <img className="w-6 h-6 absolute rounded-mx mt-2 ml-2" src="../assets/icons/left-arrow-from-left.png" alt='' />
                </button>
            </div>
            <div className='flex flex-row w-full pl-6 justify-end pr-14'>
                <div className='flex space-x-4'>
                    <div className='w-52 flex h-9 relative'>
                        <button onClick={openUpdateBadge} className='w-full h-full bg-white flex items-center justify-center rounded-[32px] border border-slate-800 text-[16px] font-medium text-slate-800 relative'>
                            {t("badges.edit")}
                            <img className="w-5 h-5 ml-2" src='../assets/icons/edit.png' alt='' />
                        </button>
                    </div>
                    <div className='w-52 flex h-9'>
                        <button onClick={handleDeleteBadge} className='w-full h-full bg-red-500 text-white flex items-center justify-center rounded-[32px] border border-red-700 text-[16px] font-medium'>
                            {t("badges.delete")}
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex flex-row w-full mt-12 pl-2'>
                <div className='flex flex-1 w-full'>
                    <div className='w-3/12'>
                        <div className='border-r h-2/5'>
                            <div className='flex flex-row'>
                                {active?.imageUrl ?
                                    <img className="w-48 h-48 mx-auto rounded-full object-cover" src={active.imageUrl} alt='' /> :
                                    <img className="w-48" src="../assets/images/profile_default.png" alt='' />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='w-9/12 pl-6 pr-6'>
                        <div className='flex flex-row w-full'>
                            <div className='w-1/2'>
                                <div className='flex flex-row w-full'>
                                    <p className="text-[#2B2C2B] font-['Poppins'] text-lg">{active?.name}</p>
                                </div>
                                <div className='flex flex-row w-full'>
                                    <p className="text-[#0D497D] text-xs font-['Poppins'] font-bold">{active?.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full text-left pl-32 pr-32'>
                <div className='flex flex-row w-full mt-14 pl-6 text-left'>
                    <p className="text-sm/[16px] font-['Poppins'] font-medium w-full"> {t("badges.description")} </p>
                </div>
                <div className='flex flex-row w-full mt-4 pl-6 pr-6'>
                    <p className="font-['Poppins'] w-full text-justify text-[#818181] text-xs/[12px] font-normal leading-5 min-h-[50px]">
                        {active?.description}
                    </p>
                </div>
            </div>
            <div className='w-full text-left pl-32 pr-32'>
                <div className='flex flex-row w-full text-left pl-6 mt-14'>
                    <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full"> {t("badges.evaluation")} </p>
                </div>
                <div className='w-full flex space-x-4 pt-10'>
                    <div className='w-full flex flex-row pr-20 pl-20'>
                        {badgeEvaluations.map((item, index) => (
                            <div key={index} className='w-1/3 flex flex-wrap'>
                                <Card sx={{ minWidth: 240 }}>
                                    <CardMedia
                                        component="img"
                                        alt=""
                                        height="140"
                                        image="/static/images/cards/contemplative-reptile.jpg"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant='h5' component='div'>
                                            {item.evaluationName}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {item.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
