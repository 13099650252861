import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    notifications: [],
    active: null
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        savingNewNotification: (state) => {
            state.isSaving = true;
        },
        addNewNotification: (state, action) => {
            const newNotification = action.payload;
            state.notifications.push(newNotification);
            state.isSaving = false;
        },
        setActiveNotification: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setNotification: (state, action) => {
            state.notifications = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateNotification: (state, action) => {
            state.isSaving = false;
            state.notifications = state.notifications.map(notification => {
                if (notification.id === action.payload.id) {
                    return action.payload;
                }
                return notification;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        
        
    }
})

export const {
    savingNewNotification,
    addNewNotification,
    setActiveNotification,
    setNotification,
    setSaving,
    updateNotification,
} = notificationSlice.actions