import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setActiveJob } from '../../store/slices/job/jobSlice';

function currencySymbol(currency) {
    switch (currency) {
    case 'E':
        return '€';
    case 'D':
        return '$';
    default:
        return ''; 
    }
}


export const DraftJobs = ({ data, onClickPreviewJob }) => {
    const dispatch = useDispatch();
    const { profile } = useSelector(state => state.profile);
    const jobs = data;
    const [jobDetail, setJobDetail] = useState(false);
    const [selectedProfile, setSelectedJob] = useState([]);
    const [previewJob, setPreviewJob] = useState(false);
    const [filterData, setFilterData] = useState([]);

    const onSelectJob = (id, companyName, maximum, minimum, customJobTitle, currency, jobModel, jobtitle, jobType, description, joblocation, tagsJob, department, urlVideo, item) => {
        dispatch(setActiveJob({ id, companyName, maximum, minimum, customJobTitle, currency, jobModel, jobtitle, jobType, description, joblocation, tagsJob, department, urlVideo}));
        setJobDetail(true);
        setPreviewJob(true);
        onClickPreviewJob();
    }

    useEffect(() => {
        if (profile.length > 0) {
            let tmpData = jobs.filter(item => item.companyId === profile[0].idCompany);
            setFilterData(tmpData);
            setFilterData((state) => {
                return state;
            })
        }
    }, [jobs])

    const onSelect = (data) => {
        setSelectedJob(data);
        setPreviewJob(true);
        onClickPreviewJob();
    }

    const handleSelectedJob = () => {
        setPreviewJob(false);
    }

    return (
        <>
            {!previewJob &&
                filterData.filter(item => item.status === 'Draft').map((item, index) => (
                    <>
                        <div key={index} className='w-full flex flex-row justify-center pt-6 h-20' onClick={() => onSelectJob(item.id, item.companyName, item.maximum, item.minimum, item.customJobTitle, item.currency, item.jobModel, item.jobtitle, item.jobType, item.description, item.joblocation, item.tagsJob, item.department, item.urlVideo,  item)}>
                            <div className='w-full flex flex-row bg-white rounded-3xl border opacity-90 border-[#818181]' >
                                <div className='w-4/12 flex'>
                                    <div className='w-1/3'>
                                        <div className='pt-1 pl-1'>
                                            <img className=' w-12 h-12' src='../assets/icons/company.png' alt='' />
                                        </div>
                                    </div>
                                    <div className='w-2/3 pt-2'>
                                        <div className='flex flex-row w-full'>
                                            <p className="text-gray-600 text-xs font-normal">{item.companyName}</p>
                                        </div>
                                        <div className='flex flex-row w-full'>
                                            <p className="text-zinc-800 text-base font-medium">{item.jobtitle}</p>
                                            {/* <div className="w-12 text-right justify-end h-px origin-top-left rotate-90 border border-neutral-300 absolute pl-2" style={{ top: 8, }}></div> */}
                                        </div>
                                    </div>
                                    <div className="w-12 h-px pl-2 origin-top-left rotate-90 border border-neutral-300 mt-1"></div>
                                </div>
                                <div className='w-3/12 flex'>
                                    <div className='w-full flex flex-row pt-2'>
                                        <div className='1/6 pt-2 justify-start items-start text-left'>
                                            <img className='w-6 h-6' src='../assets/icons/money-stack.png' alt='' />
                                        </div>
                                        <div className='5/6 pl-2'>
                                            <div className='w-full flex flex-row'>
                                                <p className="text-black text-base font-medium">
                                                {`${currencySymbol(item.currency)}${item.minimum} to ${currencySymbol(item.currency)}${item.maximum}`}
                                                </p>
                                            </div>
                                            <div className='w-full flex flex-row'>
                                                <p className="text-gray-600 text-xs font-normal">{item.jobType}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-12 h-px pl-2 origin-top-left rotate-90 border border-neutral-300 mt-1"></div>
                                </div>
                                <div className='w-3/12 flex'>
                                    <div className='flex'>
                                        <div className='pt-1'>
                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=c6f44aae-7902-45d1-b48f-fb097b8a0912" />
                                        </div>
                                        <div className='pt-1 pl-1'>
                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%203.png?alt=media&token=45b35fbf-9ff1-45f9-a048-63d328284a7c" />
                                        </div>
                                        <div className='pt-1 pl-1'>
                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%204.png?alt=media&token=1ec44765-7d7a-4d56-8e04-c0976483e366" />
                                        </div>
                                        <div className='pt-1 pl-1'>
                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%205.png?alt=media&token=260be5f7-9283-4104-a958-831df00d71dc" />
                                        </div>
                                        <div className='pt-1 pl-1 pr-6'>
                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%206.png?alt=media&token=19cb9f22-4cae-4903-90c9-137dd3e3ab7c" />
                                        </div>
                                        <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 mt-1"></div>
                                    </div>
                                </div>
                                <div className='w-2/12 flex'>
                                    <div className='w-1/3 pt-4'>
                                        <img className='w-6 h-6' src='../assets/icons/users-alt.png' alt='' />
                                    </div>
                                    <div className='w-2/3 pt-2 justify-start'>
                                        <div className='w-full flex flex-row'>
                                            <p className="text-zinc-800 text-sm font-medium">1</p>
                                        </div>
                                        <div className='w-full flex flex-row'>
                                            <p className="text-gray-600 text-xs font-normal">Applicants</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div key={index} className='inline-flex' onClick={() => onSelectJob(item.id, item.companyName, item.maximum, item.minimum, item.jobModel, item.jobtitle, item.jobType, item.joblocation)}>
                            <div className="relative bg-white border rounded-full border-[#818181] w-[900px] h-20">
                                <div className="absolute" style={{ width: 56, height: 56, left: 10, top: 10, }}>
                                    <div className="w-14 h-14 bg-gray-100 rounded-full" />
                                    <img className="w-11 h-4 absolute m-auto inset-0" src="../assets/icons/company.png" />
                                </div>

                                <p className="absolute text-xs text-gray-600" style={{ left: 86, top: 20, }}>{item.companyName}</p>
                                {item.jobType}
                                <p className="absolute text-xs text-gray-600" style={{ left: 803, top: 39, }}>Applicants</p>
                                <div className="inline-flex space-x-2.5 items-center justify-end w-48 h-8 absolute" style={{ left: 532, top: 24, }}>
                                    <img className="w-1/5 h-full" src="../assets/icons/clear-badge.png" />
                                    <img className="w-7 h-full" src="../assets/icons/clear-badge.png" />
                                    <img className="w-8 h-full" src="../assets/icons/clear-badge.png" />
                                    <img className="w-7 h-full" src="../assets/icons/clear-badge.png" />
                                    <img className="w-8 h-full" src="../assets/icons/clear-badge.png" />
                                </div>
                                {item.jobtitle}
                                <p className="absolute text-sm font-medium text-gray-800" style={{ left: 396, top: 23, }}>${item.minimum} to {item.maximum}K</p>
                                <p className="absolute text-sm font-medium text-gray-800" style={{ left: 803, top: 23, }}>0</p>

                                <img className="w-6 h-6 absolute rounded-lg" style={{ left: 361, top: 27, }} src="../assets/icons/money-stack.png" />
                                <img className="w-6 h-6 absolute rounded-lg" style={{ left: 768, top: 27, }} src="../assets/icons/users-alt.png" />
                                
                            </div>
                        </div> */}
                    </>
                ))
            }
        </>
    )
}
