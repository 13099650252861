import { FirebaseDB } from '../../../firebase/config';
import { collection, deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore/lite';
import { addNewEmptyProfile, deleteProfileById, savingNewProfile, setActiveProfile, setProfiles, setEmployeeProfiles, setProfile, setSaving, updateProfile, loadJobById } from './profileSlice';
import { loadProfiles, loadProfile, loadEmployeeProfiles } from '../../../helpers/loadProfiles';
import Swal from 'sweetalert2';

export const startNewClearProfile = (profile) => {
    return async (dispatch, getState) => {
        dispatch(savingNewProfile());
        const { uid } = getState().auth;

        const {
            email,
            displayName,
            userType
        } = profile;

        const newProfile = {
            uid,
            email,
            displayName,
            userType,
            fechaCreaction: new Date().getTime(),
            fechaModificacion: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `profile`))
        await setDoc(newDoc, newProfile);
        newProfile.id = newDoc.id;
        dispatch(addNewEmptyProfile(newProfile));
        dispatch(setActiveProfile(newProfile));
    }
}

export const startNewProfile = (profile) => {

    return async (dispatch, getState) => {
        dispatch(savingNewProfile());

        const { uid } = getState().auth;
        const {
            bio,
            currency,
            interest,
            joblocation,
            locationCity,
            name,
            salary,
            experience,
            education,
            certificate,
            pages,
            urlVideo,
            phone,
            language,
            urlVideoLocal
        } = profile

        const newProfile = {
            bio,
            currency,
            interest,
            joblocation,
            locationCity,
            name,
            salary,
            uid,
            experience,
            education,
            certificate,
            pages,
            urlVideo,
            phone,
            language,
            urlVideoLocal,
            fechaCreaction: new Date().getTime(),
            fechaModificacion: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `profile`))
        await setDoc(newDoc, newProfile);
        newProfile.id = newDoc.id;
        dispatch(addNewEmptyProfile(newProfile));
        dispatch(setActiveProfile(newProfile));
    }
}

export const startLoadingProfiles = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const profiles = await loadProfiles(uid);
        dispatch(setProfiles(profiles));
    }
}

export const startLoadingEmployeeProfiles = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const profiles = await loadEmployeeProfiles(uid);
        dispatch(setEmployeeProfiles(profiles));
    }
}

export const startLoadingProfile = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        if (!uid) throw new Error("El UID del usuario no existe");
        const profile = await loadProfile(uid);
        dispatch(setProfile(profile));
    }
}

export const startDeletingProfile = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: profile } = getState().profile;

        const docRef = doc(FirebaseDB, `profile/${profile.id}`);
        await deleteDoc(docRef);

        dispatch(deleteProfileById(profile.id));
    }
}

export const startSaveProfile = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: profile } = getState().profile;

        const profileToFireStore = { ...profile };
        delete profileToFireStore.id;

        const docRef = doc(FirebaseDB, `profile/${profile.id}`)
        await setDoc(docRef, profileToFireStore, { merge: true });
        dispatch(updateProfile(profile));
    }
}

export const formUpdateProfile = ({ id, formdata }) => {
    return async (dispatch, getState) => {
        console.log(formdata);
        dispatch(setSaving());
        try {
            const docRef = doc(FirebaseDB, `profile/${id}`);
            const editedProfile = { ...formdata };
            await setDoc(docRef, editedProfile, { merge: true });
            dispatch(updateProfile({ id, formdata }));
        } catch (e) {
            console.log('error de thunks', e);
        }
    };
};

export const loadProfileById = async (profileId) => {
    try {
        const docRef = doc(FirebaseDB, `profile/${profileId}`);
        const profileSnapshot = await getDoc(docRef);

        if (profileSnapshot.exists()) {
            const profileData = profileSnapshot.data();
            profileData.id = profileSnapshot.id;


            return profileData;
        } else {
            console.log(`El perfil con ID ${profileId} no existe.`);
            return null;
        }
    } catch (error) {
        console.error('Error al cargar el perfil:', error);
        throw error; // Propaga el error para manejarlo en otro lugar si es necesario
    }
};

export const startLoadingProfileById = (profileId) => {
    return async (dispatch) => {
        try {
            const profileData = await loadProfileById(profileId);

            if (profileData) {
                dispatch(setActiveProfile(profileData));
                return profileData; // Importante: Devuelve los datos cargados
            } else {
                console.log(`El perfil con ID ${profileId} no existe.`);
                return null;
            }
        } catch (error) {
            console.error('Error al cargar el perfil:', error);
            throw error;
        }
    };
};



