import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Container, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Modal, Box, Fab } from '@mui/material';
import { Delete, PhotoCamera } from '@mui/icons-material';
import { FirebaseStorage } from '../../../firebase/config';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes, uploadString } from 'firebase/storage';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { startLoadingCompanyProfile, startSaveCompanyProfile } from '../../../store/slices/companyProfile/thunks';
import { setActiveCompanyProfile } from '../../../store/slices/companyProfile/companyProfileSlice';
import Swal from 'sweetalert2';

const countrys = [
    { name: '🇬🇹 Guatemala', value: '🇬🇹 Guatemala' },
    { name: '🇺🇸 Estados Unidos', value: '🇺🇸 Estados Unidos' },
    { name: '🇲🇽 Mexico', value: '🇲🇽 Mexico' },
    { name: '🇨🇦 Canadá', value: '🇨🇦 Canadá' },
    { name: '🇭🇳 Honduras', value: '🇭🇳 Honduras' },
    { name: '🇸🇻 El Salvador', value: '🇸🇻 El Salvador' },
    { name: '🇳🇮 Nicaragua', value: '🇳🇮 Nicaragua' },
    { name: '🇨🇷 Costa Rica', value: '🇨🇷 Costa Rica' },
]

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const UpdateCompanyProfile = ({ onClick, data }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { uid } = useSelector(state => state.auth);
    const { profile } = useSelector(state => state.profile);
    const { errorMessage } = useSelector(state => state.auth);
    const [location, setLocation] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [pages, setPages] = useState({});
    const [urlVideo, setUrlVideo] = useState('');
    const [urlImageProfile, setUrlImageProfile] = useState('');
    const [videoSaved, setVideoSaved] = useState(false);
    const [selected, setIsSelected] = useState(false);
    const [file, setFile] = useState("");
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            companyName: "",
            mission: "",
            vision: "",
            website: "",
            linkedin: "",
            twitter: "",
            facebook: ""
        }
    });

    useEffect(() => {
        //setValue("companyName", data[0].companyName);
        if (data) {
            setLocation(data[0].location);
            setValue("mission", data[0].mission);
            setValue("vision", data[0].vision);
            setUrlVideo(data[0].urlVideo);
            setValue("website", data[0].pages.website);
            setValue("twitter", data[0].pages.twitter);
            setValue("linkedin", data[0].pages.linkedin);
            setValue("facebook", data[0].pages.facebook);
            setImageUrl(data[0].pages.profileImageUrl);
            setValue("companyName", data[0]?.companyName);
        }
    }, [data])

    const closeUpdateProfile = () => {
        onClick(true);
    }

    useEffect(() => {
        setVideoSaved(true);
    }, [urlVideo])

    useEffect(() => {
        saveLocalVideo();
    }, [file])

    useEffect(() => {
        if (profile.length > 0) {
            dispatch(startLoadingCompanyProfile(profile[0].idCompany));
        }
    }, [])

    const showSuccess = () => {
        Swal.fire('Saved', 'Profile updated successfully', 'success');
    };

    const saveLocalVideo = () => {
        let idVideo = uid;
        if (profile.length > 0) {
            idVideo = profile[0].idCompany
        }
        if (file) {
            const storageRef = ref(FirebaseStorage, `videos/companyProfile/${idVideo}`)

            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setUrlVideo(url);
                    setUrlVideo((state) => {
                        return state;
                    });
                })
            })
        }
    }

    const savePages = async (dataProfile) => {
        let idCompany = profile ? profile[0].idCompany : '';
        let updatedUrlImagen = urlImageProfile;
        
        if (imageUrl) {
            try {
                const imageRef = ref(FirebaseStorage, `images/companyProfile/${idCompany}`);
                const snapshot = await uploadString(imageRef, imageUrl, 'data_url');
                updatedUrlImagen = await getDownloadURL(snapshot.ref);
                setUrlImageProfile(updatedUrlImagen);
            } catch (error) {
                console.log('Error uploading image:', error);
            }
        }
    
        const {
            companyName = '',
            website = '',
            linkedin = '',
            twitter = '',
            facebook = ''
        } = dataProfile;
    
        const profilePage = {
            profileImageUrl: updatedUrlImagen|| (pages && pages.profileImageUrl) || '',
            website: website || (pages && pages.website) || '',
            linkedin: linkedin || (pages && pages.linkedin) || '',
            twitter: twitter || (pages && pages.twitter) || '',
            facebook: facebook || (pages && pages.facebook) || '',
            companyName: companyName || (pages && pages.companyName) || ''
        };
    
        setPages(profilePage);
        setOpen(false);
    };
    
    const onSubmit = (dataProfile) => {
        let idCompany = profile ? profile[0].idCompany : '';
        let updatedUrlImagen = urlImageProfile;
    
        const {
            companyName = '',
            mission = '',
            vision = '',
            website = '',
            linkedin = '',
            twitter = '',
            facebook = ''
        } = dataProfile;
    
        const profilePage = {
            profileImageUrl: imageUrl || (pages && pages.profileImageUrl) || '',
            website: website || (pages && pages.website) || '',
            linkedin: linkedin || (pages && pages.linkedin) || '',
            twitter: twitter || (pages && pages.twitter) || '',
            facebook: facebook || (pages && pages.facebook) || '',
            companyName: companyName || (pages && pages.companyName) || ''
        };
    
        let id = data ? data[0].id : '';
    
        const newData = {
            id,
            uid,
            companyName,
            mission,
            vision,
            pages: profilePage,
            location: location || '',
            urlVideo: urlVideo || '',
            idCompany
        };
    
        dispatch(setActiveCompanyProfile(newData));
    
        if (newData) {
            onUpdateCompanyProfile(newData);
            if (!errorMessage) {
                showSuccess();
                closeUpdateProfile();
            }
        }
    };
    
    
    const onUpdateCompanyProfile = () => {
        dispatch(startSaveCompanyProfile());
    };
    
    
    

    const handleChangeLocation = (event) => {
        const {
            target: { value },
        } = event;
        setLocation(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const handleChangeFile = (event) => {
        setFile(event.target.files[0]);
        setIsSelected(true);
    }

    const handleDeleteFile = () => {
        setFile("");
        setUrlVideo('');
        setOpen(false);
        let idVideo = uid;
        if (profile) {
            idVideo = profile[0].idCompany
        }
        const desertRef = ref(FirebaseStorage, `videos/companyProfile/${idVideo}`);
        deleteObject(desertRef).then(() => {
        }).catch((error) => {
            console.log(error);
        })
        // setUrlVideoLocal('');

    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <>
            <div className='flex flex-row w-full'>
                <button onClick={() => closeUpdateProfile()}><img className="w-6 h-6 absolute rounded-mx mt-2 ml-2" src="../assets/icons/left-arrow-from-left.png" alt='' /></button>
            </div>

            <div className='flex w-full h-full bg-white  pt-10'>
                <div className='w-3/12'>
                    <div className='flex flex-row justify-center '>
                        <img className='w-48 h-48 bg-gray-200 rounded-full' src={imageUrl} alt='' />
                    </div>
                    <div className='flex flex-row mt-10 pl-4'>
                        <div className='w-1/2 flex'>
                            <p className="text-zinc-800 text-[18px] font-bold">{t('profileEmployer.pages')}</p>
                        </div>
                        <div className='flex w-1/2 justify-end items-end pr-5'>
                            <button onClick={() => handleOpen()}><img className=" w-5 h-5" src="../assets/icons/edit.png" alt='' /></button>
                        </div>
                    </div>
                    <div className='flex flex-row pt-7 pl-4'>
                        <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                        {pages.website &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal">{pages.website}</div>
                        }
                        {!pages.website &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal">{t('profileEmployer.website')}</div>
                        }
                    </div>
                    <div className='flex flex-row pt-4 pl-4'>
                        <img className='w-5 h-5' src="../assets/icons/linkedin-alt.png" alt='' />
                        {!pages.linkedin &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal">{t('profileEmployer.linkedin')}</div>
                        }
                        {pages.linkedin &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal">{pages.linkedin}</div>
                        }
                    </div>
                    <div className='flex flex-row pt-4 pl-4'>
                        <img className='w-5 h-5' src="../assets/icons/twitter.png" alt='' />
                        {!pages.twitter &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal">{t('profileEmployer.twitter')}</div>
                        }
                        {pages.twitter &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal">{pages.twitter}</div>
                        }
                    </div>
                    <div className='flex flex-row pt-4 pl-4'>
                        <img className='w-5 h-5' src="../assets/icons/facebook.png" alt='' />
                        {pages.facebook &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal">{pages.facebook}</div>
                        }
                        {!pages.facebook &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal">{t('profileEmployer.facebook')}</div>
                        }
                    </div>
                </div>

                <div className='w-9/12'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='w-full pl-24 pr-24'>

                            <div className='w-full flex flex-row'>
                                <TextField
                                    id='companyName'
                                    className='w-full'
                                    placeholder={t('profileEmployer.company')}
                                    {...register('companyName')}
                                />
                            </div>

                            <div className='w-full flex flex-row pt-5'>
                                <FormControl className='w-full'>
                                    <InputLabel>{t('profileEmployer.location')}</InputLabel>
                                    <Select
                                        labelId='location-label'
                                        id='location'
                                        label='Location'
                                        placeholder='Location'
                                        defaultValue=""
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        value={location}
                                        onChange={handleChangeLocation}
                                    >
                                        {countrys.map((name) => (
                                            <MenuItem key={name.value} value={name.name}>
                                                {name.name}
                                            </MenuItem>
                                        ))

                                        }
                                    </Select>

                                </FormControl>
                            </div>
                            <div className="w-full pt-20 text-left">
                                <p className='text-black text-base font-medium'>{t('profileEmployer.mission')}</p>
                            </div>
                            <div className='w-full flex flex-row pt-5'>
                                <TextField
                                    id='mission'
                                    multiline
                                    rows={4}
                                    className='w-full'
                                    placeholder={t('profileEmployer.mission')}
                                    {...register('mission')}
                                />
                            </div>
                            <div className="w-full pt-5 text-left">
                                <p className='text-black text-base font-medium'>{t('profileEmployer.vision')}</p>
                            </div>
                            <div className='w-full flex flex-row pt-5'>
                                <TextField
                                    id='vision'
                                    multiline
                                    rows={4}
                                    className='w-full'
                                    placeholder={t('profileEmployer.vision')}
                                    {...register('vision')}
                                />
                            </div>
                            <div className="w-full pt-5 text-left">
                                <p className='text-black text-base font-medium'>Video</p>
                            </div>

                            <div className='w-full pt-5 flex justify-start'>
                                <div className='w-10'>
                                    <Container>
                                        <Stack direction="row" alignItems="center" spacing={2} className='items-leftjustify-left'  >
                                            <IconButton
                                                color="primary"
                                                aria-label="upload picture"
                                                component="label"
                                            >
                                                <input hidden type="file" onChange={handleChangeFile} disabled={urlVideo} />
                                                {/* <PhotoCamera /> */}
                                                <img src='../assets/images/upload.svg' alt='' disabled={urlVideo} />
                                            </IconButton>
                                        </Stack>
                                    </Container>
                                </div>
                                <p className="text-sky-900 pl-6 pt-4 text-left text-xs font-normal">{t('profileEmployer.uploadVideo')}</p>
                            </div>
                            <div className='flex flex-row w-full pt-4'>
                                {urlVideo &&
                                    <div className='w-1/2  mt-6 pl-6'>
                                        <div className='w-3/5 justify-end items-end text-right pl-2 pr-2'>
                                            <Fab className='pl-2' color='error' aria-label="edit" placeholder='Eliminar video pl-4' disabled={!urlVideo}>
                                                <Delete onClick={handleDeleteFile} />
                                            </Fab>
                                        </div>
                                    </div>
                                }
                                {urlVideo && <div className='w-1/2 items-center justify-between'>
                                    <div className='w-[320px] h-[180px] items-start justify-left'>

                                        <ReactPlayer width={320} height={180} url={urlVideo} controls={true} />

                                    </div>
                                </div>
                                }
                            </div>
                            <div className='flex flex-row mt-20 justify-end pb-16'>
                                {/* <div className='pr-2'>
                                    <button type="submit" className='w-56 h-14 bg-white text-slate-800 border border-slate-800 rounded-full font-medium'>{t('profileEmployer.buttonSaveDraft')}</button>
                                </div> */}
                                <div>
                                    <button type="submit" className='w-56 h-14 bg-sky-900 text-white rounded-full font-medium'>{t('profileEmployer.buttonSave')}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full mt-2 pl-6 text-left justify-center'>
                        <div className='w-full mt-2 justify-center text-center'>
                            <p className=' text-zinc-800 font-bold text-[18px]'>{t('profileEmployer.profilePages')}</p>
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-center'>
                        {imageUrl &&
                            <img className="w-40" src={imageUrl} />
                        }
                        {!imageUrl &&
                            <img className="w-40" src="../assets/images/Ellipse 762.svg" />
                        }
                    </div>
                    <div className='flex flex-row items-center justify-center'>
                        {/* <input type='file' /> */}
                        <Container>
                            <Stack direction="row" alignItems="center" spacing={2} className='items-center justify-center'>
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                >
                                    <input hidden accept="image/*" type="file" onChange={handleImageUpload} />
                                    <PhotoCamera />
                                </IconButton>
                            </Stack>
                        </Container>
                    </div>
                    <div className='flex flex-row mt-6 pl-6 justify-between'>
                        <div className='w-full'>
                            <TextField
                                id='Website'
                                className='w-11/12'
                                placeholder='Website'
                                {...register("website")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-6 justify-between'>
                        <div className='w-full'>
                            <TextField
                                id='Linkedin'
                                className='w-11/12'
                                placeholder='Linkedin'
                                {...register("linkedin")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-6 justify-between'>
                        <div className='w-full'>
                            <TextField
                                id='Twitter'
                                className='w-11/12'
                                placeholder='Twitter'
                                {...register("twitter")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-6 justify-between'>
                        <div className='w-full'>
                            <TextField
                                id='Facebook'
                                className='w-11/12'
                                placeholder='Facebook'
                                {...register("facebook")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-10 justify-end pr-6'>
                        <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleSubmit(savePages)}>{t('profileEmployer.save')}</button>
                    </div>
                </Box>
            </Modal>

        </>
    )
}
