import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    earnBadge: [],
    earnBadges: [],
    active: null
}

export const earnBadgeSlice = createSlice({
    name: 'earnBadge',
    initialState,
    reducers: {
        savingNewEarnBadge: (state) => {
            state.isSaving = true;
        },
        addNewEmptyEarnBadge: (state, action) => {
            const newEarnBadge = action.payload;
            state.earnBadges.push(newEarnBadge);
            state.isSaving = false;
        },
        setActiveEarnBadge: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setEarnBadges: (state, action) => {
            state.earnBadges = action.payload;
        },
        setEarnBadge: (state, action) => {
            state.earnBadge = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateEarnBadge: (state, action) => {
            state.isSaving = false;
            state.earnBadges = state.earnBadges.map(badge => {
                if (badge.id === action.payload.id) {
                    return action.payload;
                }
                return badge;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        }

    }
})

export const {
    savingNewEarnBadge,
    addNewEmptyEarnBadge,
    setActiveEarnBadge,
    setEarnBadge,
    setEarnBadges,
    setSaving,
    updateEarnBadge
} = earnBadgeSlice.actions