import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Modal, OutlinedInput, Paper, Select, Switch, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { FinalStage } from './Jobs/Stages/FinalStage';
import { VideoInterViewStage } from './Jobs/Stages/VideoInterViewStage';
import { ManualFiltering } from './Jobs/ManualFiltering';
import { ManualFilteringStage } from './Jobs/Stages/ManualFilteringStage';
import { startLoadingCurrentProcessJobs } from '../../store/slices/applyingJobs/thunks';
import { setActiveApplyingJob } from '../../store/slices/applyingJobs/applyingjobSlice';
import { setActiveProfile } from '../../store/slices/profile/profileSlice';
import { startLoadingEmployeeProfiles, startSaveProfile } from '../../store/slices/profile/thunks';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import { startLoadingEarnBadge, startLoadingEarnBadges } from '../../store/slices/earnBadge/thunks';
import { startLoadingCompanyProfile } from '../../store/slices/companyProfile/thunks';
import moment from 'moment';
import { starLoadingNotification, starLoadingNotificationByUid, startNewNotification } from '../../store/slices/notification/thunks';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    height: 512,
    bgcolor: 'background.paper',
    // border: '1px solid #000',
    boxShadow: 24,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const basicFitCheck = [
    'Psicometricos',
    'Númericos'
];

export const CurrentProcessesTab = () => {
    const dispatch = useDispatch();
    const { active } = useSelector(state => state.job);
    const { activejobs } = useSelector(state => state.activeJobs);
    const { employeeProfiles } = useSelector(state => state.profile);
    const { badgesCatalogs } = useSelector(state => state.badgesCatalog);
    const { badgesCategories } = useSelector(state => state.badgesCategorie);
    const { earnBadges } = useSelector(state => state.earnBadge)
    const { profile } = useSelector(state => state.profile);
    const { companyProfile } = useSelector(state => state.companyProfile);
    const { jobs } = useSelector(state => state.job);
    const [candidates, setCandidates] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedStage, setSelectedStage] = useState(0);
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [selectdJob, setSelectedJob] = useState();
    const { id, companyName, maximum, minimum, customJobTitle, currency, jobModel, jobtitle, description, joblocation } = active || {}
    const [basicFitBadges, setBasicFitBadges] = useState([]);
    const [compatibilityBadges, setCompatibilityBadges] = useState([]);
    const [skillBadges, setSkillBadges] = useState([]);
    const [applyingEmployees, setApplyingEmployees] = useState([]);
    const [notifiedCandidates, setNotifiedCandidates] = useState([]);
    const notifications = useSelector((state) => state.notification.notifications);
    const [notifiedSteps, setNotifiedSteps] = useState(new Set());

    useEffect(() => {
        if (profile.length > 0) {
            dispatch(startLoadingCompanyProfile(profile[0].idCompany));
        }
    }, [])

    useEffect(() => {
        dispatch(startLoadingEarnBadges())
    }, [])

    useEffect(() => {
        dispatch(starLoadingNotificationByUid());
    }, [dispatch]); 
    
   

    useEffect(() => {
        dispatch(startLoadingEmployeeProfiles());
    }, [open])

    useEffect(() => {
        loadActiveCandidates();
    }, [])


    useEffect(() => {

    }, [candidates])

    useEffect(() => {
        let tmpBasicFit = [];
        let tmpSkillAssesment = [];
        let tmpCompatility = [];
        let idCompany = '';
        if (companyProfile.length > 0) {
            idCompany = companyProfile[0].idCompany;
        }
        if (badgesCatalogs && badgesCategories) {
            let tmpBasicFitCategorie = badgesCategories.filter(x => x.name === 'Basic Fit');
            let tmpSkillAssesmentCategorie = badgesCategories.filter(x => x.name === 'Skill-Assessment');
            let tmpCompatibilityCategorie = badgesCategories.filter(x => x.name === 'Compatibility');
            if (tmpBasicFitCategorie.length > 0) {
                tmpBasicFit = badgesCatalogs.filter(x => x.categorie === tmpBasicFitCategorie[0].id && x.companyId === idCompany);
            }
            if (tmpSkillAssesmentCategorie.length > 0) {
                tmpSkillAssesment = badgesCatalogs.filter(x => x.categorie === tmpSkillAssesmentCategorie[0].id && x.companyId === idCompany);
            }
            if (tmpCompatibilityCategorie.length > 0) {
                tmpCompatility = badgesCatalogs.filter(x => x.categorie === tmpCompatibilityCategorie[0].id && x.companyId === idCompany);
            }

            let tmpBasicFitPrueba = [];
            let tmpCompatibilityPrueba = [];
            let tmpSkillsPrueba = [];

            tmpBasicFitPrueba = active.basicAssessments;
            tmpCompatibilityPrueba = active.compatibility;
            tmpSkillsPrueba = active.skillsAssessments;

            // setBasicFitBadges(tmpBasicFit);
            // setSkillBadges(tmpSkillAssesment);
            // setCompatibilityBadges(tmpCompatility);

            setBasicFitBadges(tmpBasicFitPrueba);
            setSkillBadges(tmpCompatibilityPrueba);
            setCompatibilityBadges(tmpSkillsPrueba);

        }
    }, [badgesCatalogs])

    const handleNotification = (candidate, step) => {
        // Verificar si ya existe una notificación idéntica
        const existingNotification = notifications.find(notification => 
            notification.title === jobtitle && 
            notification.content === `¡Felicidades! Has avanzado al paso ${step} del proceso de contratación` &&
            notification.uidUser === candidate.uid
        );
    
        // Si no existe una notificación idéntica, crear una nueva
        if (!existingNotification) {
            dispatch(startNewNotification({
                uidUser: candidate.uid,
                title: jobtitle,
                content: `¡Felicidades! Has avanzado al paso ${step} del proceso de contratación`
            }));
            setNotifiedCandidates(prevState => [...prevState, candidate.uid]);
            setNotifiedSteps(prevSteps => new Set(prevSteps).add(step));
        }
    };
    

    const loadActiveCandidates = () => {
        if (active.id) {
            const tmpCandidates = employeeProfiles.filter(x => x.applyingJob?.some(y => y.jobId === active.id));
            setApplyingEmployees(tmpCandidates);
            let arrayV = [];
            tmpCandidates.map(item => {
                const jobsApplication = item.applyingJob?.find(job => job.jobId === active.id && job.status != 'Rejected');
                const earnBasicFitBadges = earnBadges.filter(x => basicFitBadges?.some(y => y.id === x.id_badge && x.uid === item.uid));
                const earnSkillBadges = earnBadges.filter(x => skillBadges?.some(y => y.id === x.id_badge && x.uid === item.uid));
                const earnCompatibilityBadges = earnBadges.filter(x => compatibilityBadges?.some(y => y.id === x.id_badge && x.uid === item.uid));
                if (jobsApplication) {
                    let finalInterviewEmailSend = jobsApplication.finalInterviewEmailSend;
                    arrayV.push({ displayName: item.displayName, uid: item.uid, profileImageUrl: item.pages.profileImageUrl, jobId: jobsApplication.jobId, stage: jobsApplication.stage, earnBasicFitBadges, earnSkillBadges, earnCompatibilityBadges, finalInterviewEmailSend })
                }
            })
            if (arrayV.length > 0) {
                const stageOne = arrayV.map((candidate) => {
                    let stage = 1;
                    if (candidate.stage === 1) {
                        let totalBasicBadgesRequired = basicFitBadges?.length;
                        let totalEarnBasicBadgesEarn = candidate.earnBasicFitBadges.length;
                        let totalCompatibilityBadgesRequired = compatibilityBadges?.length;
                        let totalEarnCompatibilityBadges = candidate.earnCompatibilityBadges.length;
                        let totalSkillsBadgesRequired = skillBadges?.length;
                        let totalEarnSkillsBadges = candidate.earnSkillBadges.length
                        if ((totalBasicBadgesRequired === totalEarnBasicBadgesEarn) && (totalBasicBadgesRequired > 0)) {
                            stage = 2;
                            if ((totalCompatibilityBadgesRequired === totalEarnCompatibilityBadges) && (stage === 2) && (totalCompatibilityBadgesRequired > 0)) {
                                stage = 3;
                                if ((totalSkillsBadgesRequired === totalEarnSkillsBadges) && (stage === 3) && (totalSkillsBadgesRequired > 0)) {
                                    stage = 4;
                                }
                            }
                        } else {
                            if (totalCompatibilityBadgesRequired < 1) {
                                stage = 2; //Porque no habia badges required

                            }
                        }
                        if ((totalCompatibilityBadgesRequired === totalEarnCompatibilityBadges) && (stage === 2) && (totalCompatibilityBadgesRequired > 0)) {
                            stage = 3;
                            if ((totalSkillsBadgesRequired === totalEarnSkillsBadges) && (stage === 3) && (totalSkillsBadgesRequired > 0)) {
                                stage = 4;
                            }
                        } else {
                            if (totalCompatibilityBadgesRequired < 1) {
                                stage = 3;
                            }
                        }
                        if ((totalSkillsBadgesRequired === totalEarnSkillsBadges) && (stage === 3) && (totalSkillsBadgesRequired > 0)) {
                            stage = 4;
                        } else {
                            if (totalSkillsBadgesRequired < 1) {
                                stage = 4;
                                handleNotification(candidate, 4)
                            }
                        }



                        return { ...candidate, stage: stage }
                    }
                    return candidate;
                })
                setCandidates(stageOne);
            }

        }
    }


    const showStageModal = (stage, item) => {
        setSelectedEmployee(item);
        setSelectedEmployee(state => {
            return state;
        })
        let tmpData = item.applyingJob;
        setSelectedStage(stage);
        setOpen(true);
    }

    const updateManualStage = (stage, data, status) => {

        let actualStage = stage;
        if (status === 'Approved') {
            const stageFour = candidates.map((candidate) => {
                if (candidate.stage === 4) {
                    actualStage = 6;
                    {
                        dispatch(startNewNotification({
                            uidUser: candidate.uid,
                            title: jobtitle,
                            content: '¡Felicidades! haz avanzado a la etapa 5 del proceso de contración, ¡Quedate pendiente al correo!'
                        }));
                    }
                    return { ...candidate, stage: actualStage }
                }
                return candidate
            })
            setCandidates(stageFour);
        }

        const tmpEmployee = applyingEmployees.filter(x => x.uid === selectedEmployee.uid);
        let tmpData = [];
        tmpData = tmpEmployee[0].applyingJob;
        const newArr = tmpData.map(obj => {
            if (obj.jobId == id) {
                return { ...obj, stage: actualStage, summaryManualFiltering: data.summary, status: status }
            }
            return obj;
        })
        const newData = {
            id: tmpEmployee[0]?.id,
            uid: tmpEmployee[0]?.uid,
            applyingJob: newArr,
            // stage: 5
        }

        dispatch(setActiveProfile(newData));
        if (newData) {
            dispatch(startSaveProfile());
        }

        dispatch(startLoadingEmployeeProfiles());
        loadActiveCandidates();

        setOpen(false);
    }

    const sendEmail = (data) => {
        emailjs.send('service_i8oanrr', 'template_k1fbtkj', data, 'user_ZX1BFz0awK4x68kNH5vl7')
            .then(function (response) {
                showSuccess();
            }, function (error) {
                showError();
            });
    }

    const sendZoomEmail = (data) => {
        // emailjs.send('service_i8oanrr', 'template_jf5uohp', data, 'user_ZX1BFz0awK4x68kNH5vl7')
        emailjs.send('service_i8oanrr', 'template_6s65zp9', data, 'user_ZX1BFz0awK4x68kNH5vl7')
            .then(function (response) {
                showSuccess();
            }, function (error) {
                // showError();
                console.log(error);
            });
    }

    const sendInterviewDate = (stage, data) => {
        if (data.$d === null) {
            console.log('debe selecionar fecha');
        }

        let interviewDate = data.date.$d
        const tmpEmployee = applyingEmployees.filter(x => x.uid === selectedEmployee.uid);
        let tmpData = [];
        tmpData = tmpEmployee[0].applyingJob;

        const newArr = tmpData.map(obj => {
            if (obj.jobId == id) {
                return { ...obj, dateFinalinterview: moment(interviewDate).format("DD/MM/YYYY"), finalInterviewStartDate: data.startHour1, finalInterviewEndDate: data.endHour1, finalInterviewEmailSend: true, FinalLinkInterview: data.link }
            }
            return obj;
        })

        const newData = {
            id: tmpEmployee[0]?.id,
            uid: tmpEmployee[0]?.uid,
            applyingJob: newArr,
            // stage: 6
        }

        const emailData = {
            name: tmpEmployee[0]?.name,
            url: 'https://us04web.zoom.us/j/74294975239?pwd=77b2GKSYEN8aS4ELkrcV4Vcb4iyDtM.1',
            companyName: companyName,
            jobtitle: jobtitle,
            interviewDate: moment(interviewDate).format("DD/MM/YYYY"),
            email: tmpEmployee[0]?.email,
            link: data.link,
            startDate: data.startHour1,
            endDate: data.endHour1
        }
        dispatch(setActiveProfile(newData));
        if (newData) {
            dispatch(startSaveProfile());
            sendZoomEmail(emailData);
        }
        dispatch(startLoadingEmployeeProfiles());
        loadActiveCandidates();
        setOpen(false);

    }

    const rejectFinalInterview = (stage, data, status) => {
        let actualStage = stage;
        if (status === 'Approved') {
            const stageFour = candidates.map((candidate) => {
                if (candidate.stage === 6) {
                    actualStage = 7;
                    {
                        dispatch(startNewNotification({
                            uidUser: candidate.uid,
                            title: jobtitle,
                            content: '¡Felicidades! haz avanzado a la etapa 7 del proceso de contración'
                        }));
                    }
                    return { ...candidate, stage: actualStage }
                }
                return candidate
            })
            setCandidates(stageFour);
        }

        const tmpEmployee = applyingEmployees.filter(x => x.uid === selectedEmployee.uid);
        let tmpData = [];
        tmpData = tmpEmployee[0].applyingJob;
        const newArr = tmpData.map(obj => {
            if (obj.jobId == id) {
                return { ...obj, stage: actualStage, summaryFinalInterview: data.summary, status: status }
            }
            return obj;
        })
        const newData = {
            id: tmpEmployee[0]?.id,
            uid: tmpEmployee[0]?.uid,
            applyingJob: newArr,
            // stage: 5
        }

        dispatch(setActiveProfile(newData));
        if (newData) {
            dispatch(startSaveProfile());
        }

        dispatch(startLoadingEmployeeProfiles());
        loadActiveCandidates();

        setOpen(false);
    }

    const updateFinalInterviewStage = (stage, data) => {

        if (data.$d === null) {
            console.log('debe selecionar fecha');
        }

        // let interviewDate = data.date.$d
        const tmpEmployee = applyingEmployees.filter(x => x.uid === selectedEmployee.uid);
        let tmpData = [];
        tmpData = tmpEmployee[0].applyingJob;

        const newArr = tmpData.map(obj => {
            if (obj.jobId == id) {
                // return { ...obj, stage: 7, dateFinalinterview: moment(interviewDate).format("DD/MM/YYYY"), finalInterviewStartDate: data.startHour1, finalInterviewEndDate: data.endHour1 }
                return { ...obj, stage: 7 }
            }
            return obj;
        })

        const newData = {
            id: tmpEmployee[0]?.id,
            uid: tmpEmployee[0]?.uid,
            applyingJob: newArr,
            // stage: 6
        }

        // const emailData = {
        //     name: tmpEmployee[0]?.name,
        //     url: 'https://us04web.zoom.us/j/74294975239?pwd=77b2GKSYEN8aS4ELkrcV4Vcb4iyDtM.1',
        //     companyName: companyName,
        //     jobtitle: jobtitle,
        //     interviewDate: moment(interviewDate).format("DD/MM/YYYY"),
        //     email: tmpEmployee[0]?.email
        // }
        dispatch(setActiveProfile(newData));
        if (newData) {
            dispatch(startSaveProfile());
            // sendZoomEmail(emailData);
        }
        dispatch(startLoadingEmployeeProfiles());
        loadActiveCandidates();
        setOpen(false);
    }

    const showSuccess = () => {
        Swal.fire('Success', 'Se ha enviado el link de la entrevista correctamente', 'success');
    };

    const showError = () => {
        Swal.fire('Error', 'Error en el envío de correo', 'error');
    };


    const updateVideoStage = (stage, data) => {

        if (data.$d === null) {
            console.log('debe selecionar fecha');
        }

        let maxDate = data.date.$d

        const tmpEmployee = applyingEmployees.filter(x => x.uid === selectedEmployee.uid);

        let tmpData = [];
        tmpData = tmpEmployee[0].applyingJob;

        const newArr = tmpData.map(obj => {
            if (obj.jobId == id) {
                return { ...obj, stage: 6, maxDateVideointerview: moment(maxDate).format("DD/MM/YYYY") }
            }
            return obj;
        })
        const newData = {
            id: tmpEmployee[0]?.id,
            uid: tmpEmployee[0]?.uid,
            applyingJob: newArr,
            // stage: 6
        }


        const emailData = {
            name: tmpEmployee[0]?.name,
            url: 'https://xrecruit-5c93b.web.app/jobs/video/p=' + id + 'p2=' + tmpEmployee[0]?.uid,
            companyName: companyName,
            jobtitle: jobtitle,
            date: moment(maxDate).format("DD/MM/YYYY"),
            email: tmpEmployee[0]?.email
        }

        dispatch(setActiveProfile(newData));
        if (newData) {
            dispatch(startSaveProfile());
            sendEmail(emailData);
        }
        dispatch(startLoadingEmployeeProfiles());
        loadActiveCandidates();

        setOpen(false);
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <>
            <div className='w-full'>
                {/* HEADER COLUMNS */}
                <div className='w-full flex flex-row'>
                    <div className='w-1/6 h-9 bg-[#e0e4e9] text-center'>
                        <p className="text-slate-800 text-xs font-medium pt-2">Basic Fit Check</p>
                    </div>
                    <div className='w-1/6 h-9 bg-[#e0e4e9] text-center'>
                        <p className="text-slate-800 text-xs font-medium pt-2">Compatibility</p>
                    </div>
                    <div className='w-1/6 h-9 bg-[#e0e4e9] text-center'>
                        <p className="text-slate-800 text-xs font-medium pt-2">Skill Assessments</p>
                    </div>
                    <div className='w-1/6 h-9 bg-[#e0e4e9] text-center'>
                        <p className="text-slate-800 text-xs font-medium pt-2">Manual Filtering</p>
                    </div>
                    {/* <div className='w-1/6 h-9 bg-[#e0e4e9] text-center'>
                        <p className="text-slate-800 text-xs font-medium pt-2">Video Interview</p>
                    </div> */}
                    <div className='w-1/6 h-9 bg-[#e0e4e9] text-center'>
                        <p className="text-slate-800 text-xs font-medium pt-2">Final Interview</p>
                    </div>
                    <div className='w-1/6 h-9 bg-[#e0e4e9] text-center'>
                        <p className="text-slate-800 text-xs font-medium pt-2">Contract</p>
                    </div>
                </div>

                {/* const tmpCandidates = employeeProfiles.filter(x => x.applyingJob?.some(y => y.jobId === active.id)); */}


                {candidates.map((item, index) => (
                    <div key={index} className='w-full flex flex-row pt-12'>
                        <div className='w-1/6 h-9 bg-white '>
                            {item.stage == 1 &&
                                <>
                                    <div className='flex justify-center'>
                                        <img className="w-14 h-14 rounded-full" src={item.profileImageUrl !== '' ? item.profileImageUrl : '../assets/images/login-user-default.png'} alt='' />
                                    </div>
                                    <p className="text-zinc-800 text-xs font-medium pt-1">{item.displayName}</p>
                                </>
                            }
                            {item.stage != 1 &&
                                <>
                                    <div className='flex justify-center pt-4'>
                                        <div className="w-10 h-10 bg-gray-200 rounded-full">
                                            <div className='pl-3 pt-3'>
                                                <img className="w-4 h-4" src='../assets/icons/check.png' />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className='w-1/6 h-9 bg-white text-center'>
                            {item.stage == 2 &&
                                <>
                                    <div className='flex justify-center'>
                                        <img className="w-14 h-14 rounded-full" src={item.profileImageUrl !== '' ? item.profileImageUrl : '../assets/images/login-user-default.png'} alt='' />
                                    </div>
                                    <p className="text-zinc-800 text-xs font-medium pt-1">{item.displayName}</p>
                                </>
                            }
                            {item.stage < 2 &&
                                <>

                                </>
                            }
                            {item.stage > 2 &&
                                <>
                                    <div className='flex justify-center pt-4'>
                                        <div className="w-10 h-10 bg-gray-200 rounded-full">
                                            <div className='pl-3 pt-3'>
                                                <img className="w-4 h-4" src='../assets/icons/check.png' />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className='w-1/6 h-9 bg-white text-center'>
                            {item.stage == 3 &&
                                <>
                                    <div className='flex justify-center'>
                                        <img className="w-14 h-14 rounded-full" src={(item.profileImageUrl !== '') ? item.profileImageUrl : '../assets/images/login-user-default.png'} alt='' />
                                    </div>
                                    <p className="text-zinc-800 text-xs font-medium pt-1">{item.displayName}</p>
                                </>
                            }
                            {item.stage < 3 &&
                                <>

                                </>
                            }
                            {item.stage > 3 &&
                                <>
                                    <div className='flex justify-center pt-4'>
                                        <div className="w-10 h-10 bg-gray-200 rounded-full">
                                            <div className='pl-3 pt-3'>
                                                <img className="w-4 h-4" src='../assets/icons/check.png' />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className='w-1/6 h-9 bg-white text-center'>
                            {item.stage == 4 &&
                                <>
                                    <button type='button' onClick={() => showStageModal(item.stage, item)}><div className='flex justify-center'>
                                        <img className="w-14 h-14 rounded-full" src={item.profileImageUrl !== '' ? item.profileImageUrl : '../assets/images/login-user-default.png'} alt='' />
                                    </div>
                                    </button>
                                    <p className="text-zinc-800 text-xs font-medium pt-1">{item.displayName}</p>
                                </>
                            }
                            {item.stage < 4 &&
                                <>

                                </>
                            }
                            {item.stage > 4 &&
                                <>
                                    <div className='flex justify-center pt-4'>
                                        <div className="w-10 h-10 bg-gray-200 rounded-full">
                                            <div className='pl-3 pt-3'>
                                                <img className="w-4 h-4" src='../assets/icons/check.png' />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        {/* <div className='w-1/6 h-9 bg-white text-center'>
                            {item.stage == 5 &&
                                <>
                                    <button type='button' onClick={() => showStageModal(item.stage, item)}>
                                        <div className='flex justify-center'>
                                            <img className="w-14 h-14 rounded-full" src={item.profileImageUrl !== '' ? item.profileImageUrl : '../assets/images/login-user-default.png'} alt='' />
                                        </div>
                                    </button>
                                    <p className="text-zinc-800 text-xs font-medium pt-1">{item.displayName}</p>
                                </>
                            }
                            {item.stage < 5 &&
                                <>

                                </>
                            }
                            {item.stage > 5 &&
                                <>
                                    <div className='flex justify-center pt-4'>
                                        <div className="w-10 h-10 bg-gray-200 rounded-full">
                                            <div className='pl-3 pt-3'>
                                                <img className="w-4 h-4" src='../assets/icons/check.png' />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div> */}
                        <div className='w-1/6 h-9 bg-white text-center'>
                            {item.stage == 6 &&
                                <>
                                    <button type='button' onClick={() => showStageModal(item.stage, item)}>
                                        <div className='flex justify-center'>
                                            <img className="w-14 h-14 rounded-full" src={(item.profileImageUrl !== '') ? item.profileImageUrl : '../assets/images/login-user-default.png'} alt='' />
                                        </div></button>
                                    <p className="text-zinc-800 text-xs font-medium pt-1">{item.displayName}</p>
                                </>
                            }
                            {item.stage < 6 &&
                                <>

                                </>
                            }
                            {item.stage > 6 &&
                                <>
                                    <div className='flex justify-center pt-4'>
                                        <div className="w-10 h-10 bg-gray-200 rounded-full">
                                            <div className='pl-3 pt-3'>
                                                <img className="w-4 h-4" src='../assets/icons/check.png' />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        <div className='w-1/6 h-9 bg-white text-center'>
                            {item.stage == 7 &&
                                <>
                                    <div className='flex justify-center'>
                                        <img className="w-14 h-14 rounded-full" src={(item.profileImageUrl !== '') ? item.profileImageUrl : '../assets/images/login-user-default.png'} alt='' />
                                    </div>
                                    <p className="text-zinc-800 text-xs font-medium pt-1">{item.displayName}</p>
                                </>
                            }
                            {item.stage < 7 &&
                                <>

                                </>
                            }
                            {item.stage > 7 &&
                                <>
                                    <div className='flex justify-center pt-4'>
                                        <div className="w-10 h-10 bg-gray-200 rounded-full">
                                            <div className='pl-3 pt-3'>
                                                <img className="w-4 h-4" src='../assets/icons/check.png' />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                ))
                }
            </div >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {selectedStage == 4 &&
                        <ManualFilteringStage sendUpdate={updateManualStage} data={selectedEmployee} />
                    }
                    {selectedStage == 5 &&
                        <VideoInterViewStage sendUpdate={updateVideoStage} data={selectedEmployee} />
                    }
                    {selectedStage == 6 &&
                        <FinalStage sendUpdate={updateFinalInterviewStage} sendInterview={sendInterviewDate} sendRejected={rejectFinalInterview} data={selectedEmployee} />
                    }
                </Box>
            </Modal>
        </>
    )
}