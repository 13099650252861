
import { useState } from "react";

export const ChatListItem = ({ pages, displayName, lastMessageTime, lastMessagePreview, isSelected, onClick, totalItems }) => {
  const flexBasis = `calc(100% / ${totalItems})`;
  // Crear un componente en donde vaya al estado y traer los profile de los candidatos
  // llamar al useState

  return (

    <div className={`flex items-center cursor-pointer border resize-none h-[14%] w-full ${isSelected ? 'bg-white text-black' : 'bg-[#edededff] text-[#0D497D]'}`} style={{ flexBasis }} onClick={onClick}>
      <div className="w-12 h-12 bg-white rounded-full overflow-hidden mr-6 center">
        <img src={pages?.profileImageUrl} alt={`Foto de ${displayName}`} className="padding-left w-full h-full object-cover center" />
      </div>
      <div className="flex flex-col flex-grow">
        <div className="flex items-center justify-between mb-1">
          <div className={`text-lg font-semibold ${isSelected ? 'text-black' : ''}`}>{displayName}</div>
          <div className="text-sm  text-gray-500  ">{lastMessageTime}</div>
        </div>
        <div className={`text-sm ${isSelected ? 'text-black' : 'text-gray-600'} truncate`}>{lastMessagePreview}</div>
      </div>
    </div>
  );
};

