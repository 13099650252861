import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    companyProfile: [],
    companyProfiles: [],
    active: null
}

export const companyProfileSlice = createSlice({
    name: 'companyProfile',
    initialState,
    reducers: {
        savingNewCompanyProfile: (state) => {
            state.isSaving = true;
        },
        addNewEmptyCompanyProfile: (state, action) => {
            const newCompanyProfile = action.payload;
            state.companyProfiles.push(newCompanyProfile);
            state.isSaving = false;
        },
        setActiveCompanyProfile: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setCompanyProfiles: (state, action) => {
            state.companyProfiles = action.payload;
        },
        setCompanyProfile: (state, action) => {
            state.companyProfile = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateCompanyProfile: (state, action) => {
            state.isSaving = false;
            state.companyProfiles = state.companyProfiles.map(profile => {
                if (profile.id === action.payload.id) {
                    return action.payload;
                }
                return profile;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        }
    }
})

export const {
    savingNewCompanyProfile,
    addNewEmptyCompanyProfile,
    setActiveCompanyProfile,
    setCompanyProfiles,
    setCompanyProfile,
    setSaving,
    updateCompanyProfile
} = companyProfileSlice.actions