import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Card, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, SpeedDial, SpeedDialAction, SpeedDialIcon, Switch, TextField, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { MultipleOneOption } from './EvaluationBadges/MultipleOneOption';
import { MultipleOptions } from './EvaluationBadges/MultipleOptions';
import { MultipleManyAnswers } from './EvaluationBadges/MultipleManyAnswers';
import { TotalDetail } from './EvaluationBadges/TotalDetail';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveEvaluation } from '../../store/slices/evaluation/evaluationSlice';
import { startNewEvaluation, startSaveEvaluation } from '../../store/slices/evaluation/thunks';
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2';
import { FileCopyOutlined, PrintOutlined, SaveOutlined, ShareOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import { Questions } from './EvaluationBadges/Questions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const actions = [
    { icon: <FileCopyOutlined />, name: 'Copy' },
    { icon: <SaveOutlined />, name: 'Save' },
    { icon: <PrintOutlined />, name: 'Print' },
    { icon: <ShareOutlined />, name: 'Share' },
];

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
    },
}));

export const CreateBadgeEvaluation = ({ onClick }) => {
    const { t } = useTranslation();
    const [multipleOneOptionCheck, setMultipleOneOptionCheck] = useState([]);
    const [multipleOptionsCheck, setMultipleOptionsCheck] = useState([]);
    const [multipleManyAnswersCheck, setMultipleManyAnswersCheck] = useState([]);
    const [badge, setBadge] = useState([]);
    const [evaluationPeriod, setEvaluationPeriod] = useState(1);
    const [validez, setValidez] = useState(1);
    const [evaluationAttempts, setEvaluationAttempts] = useState('');
    const [evaluationTime, setEvaluationTime] = useState('');
    const [attemptsTime, setAttemptsTime] = useState('');
    const [subtotalMultipleOneOption, setSubtotalMultipleOneOption] = useState(0);
    const [subtotalMultipleOption, setSubtotalMultipleOption] = useState(0);
    const [subtotalManyOption, setSubtotalManyOption] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const { badgesCatalogs } = useSelector(state => state.badgesCatalog);
    const { companyProfile } = useSelector(state => state.companyProfile);
    const [companyBadges, setCompanyBadges] = useState([]);
    const [percentage1, setPercentage1] = useState(0);
    const [percentage2, setPercentage2] = useState(0);
    const [percentage3, setPercentage3] = useState(0);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            evaluationName: "",
            description: ""
        }
    });

    const closeJob = () => {
        onClick(false);
    }

    useEffect(() => {
        if (companyProfile.length > 0) {
            let idCompany = '';
            idCompany = companyProfile[0].id;
            setCompanyBadges(idCompany);
        }
    })

    const validate = (data) => {
        if (badge.length < 1) {
            showError();
            return false;
        }
        if (data.evaluationName.length == 0) {
            showError();
            return false;
        }
        if (data.durationTime.length == 0) {
            showError();
            return false;
        }

        return true;
    }

    const onSubmit = (data) => {
        if (total == 100) {
            if (validate(data)) {
                setIsSaving(true);

                let companyId = '';
                if (companyProfile.length > 0) {
                    companyId = companyProfile[0].id;
                }

                const newData = {
                    evaluationName: data.evaluationName,
                    description: data.description,
                    badge: badge,
                    // Tiempo validez Evaluación
                    evaluationPeriod: evaluationPeriod,
                    evaluationTime: evaluationTime,
                    durationTime: data.durationTime,
                    companyId: companyId,
                    // Max. intentos
                    evaluationAttempts: evaluationAttempts,
                    //Tiempo validez intentos
                    attemptsTime: attemptsTime,
                    validez: validez,
                    // Data Componentes    
                    multipleTrueOrFalse: multipleOneOptionCheck,
                    multipleOneAnswer: multipleOptionsCheck,
                    multipleManyAnswers: multipleManyAnswersCheck
                }

                dispatch(setActiveEvaluation(newData));

                setTimeout(() => {
                    if (newData) {
                        dispatch(startNewEvaluation(newData))
                        setIsSaving(false);
                        showSaveSuccess();
                        onClick(false);
                    }
                }, 4000);
            }
        } else {
            showErrorTotalMessage();
        }
    }

    const showSaveSuccess = () => {
        Swal.fire('Saved', 'Evaluation saved successfully.', 'success');
    };

    const showErrorTotalMessage = () => {
        Swal.fire('Error', 'El total de la evaluación debe ser de 100', 'error');
    };

    const showError = () => {
        Swal.fire('Error', 'Debe seleccionar todos los campos para continuar', 'error');
    };

    const loadSubtotalMultipleOneOption = (data) => {
        // setSubtotalMultipleOneOption(data);
        // setSubtotalMultipleOneOption((state) => {
        //     return state;
        // })
        let Subtotal = parseInt(data);

        setSubtotalMultipleOneOption(Subtotal);
        setSubtotalMultipleOneOption((state) => {
            return state;
        })
        // setTotal(Subtotal);
        // setTotal((state) => {
        //     return state;
        // })
    }

    const loadSubtotalMultipleOption = (data) => {
        let subtotal = parseInt(data);

        setSubtotalMultipleOption(subtotal);
        setSubtotalMultipleOption((state) => {
            return state;
        })
        // setTotal(subtotal);
        // setTotal((state) => {
        //     return state;
        // })
    }

    // const loadSubtotalManyOptions = (data) => {
    //     let subtotal = parseInt(data);

    //     setSubtotalManyOption(subtotal);
    //     setSubtotalManyOption((state) => {
    //         return state;
    //     })

    // }

    // useEffect(() => {
    //     setTotal(subtotalManyOption + subtotalMultipleOneOption + subtotalMultipleOption);
    // }, [subtotalManyOption, subtotalMultipleOneOption, subtotalMultipleOption])

    useEffect(() => {
    }, [multipleOneOptionCheck, multipleOneOptionCheck, multipleManyAnswersCheck])

    const loadMultipleManyAnswersData = (data) => {
        setMultipleManyAnswersCheck(data);
    }

    const loadMultipleOneOptionData = (data) => {
        setMultipleOneOptionCheck(data);
    }

    const loadMultipleOptionsData = (data) => {
        setMultipleOptionsCheck(data);
    }


    const handleChangeBadge = (event) => {
        const {
            target: { value },
        } = event;
        setBadge(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleEvaluationAttempts = (event) => {
        const {
            target: { value },
        } = event;
        setEvaluationAttempts(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleAttemptsTime = (event) => {
        const {
            target: { value },
        } = event;
        setAttemptsTime(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleEvaluationTime = (event) => {
        const {
            target: { value },
        } = event;
        setEvaluationTime(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleEvaluationPeriod = (event) => {
        const {
            target: { value },
        } = event;
        setEvaluationPeriod(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeValidez = (event) => {
        const {
            target: { value },
        } = event;
        setValidez(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleOption = (option) => {
        if (option === 'Copy') {

        }
    }

    const handlePercentage1 = (event) => {
        const {
            target: { value },
        } = event;
        setPercentage1(
            typeof value === 'string' ? value.split(',') : value,
        );
        setPercentage1((state) => {
            return state;
        })
    }

    const handlePercentage2 = (event) => {
        const {
            target: { value },
        } = event;
        setPercentage2(
            typeof value === 'string' ? value.split(',') : value,
        );
        setPercentage2((state) => {
            return state;
        })
    }

    const handlePercentage3 = (event) => {
        const {
            target: { value },
        } = event;
        setPercentage3(
            typeof value === 'string' ? value.split(',') : value,
        );
        setPercentage3((state) => {
            return state;
        })
    }

    useEffect(() => {
        setTotal(parseInt(percentage1 ? percentage1 : 0) + parseInt(percentage2 ? percentage2 : 0) + parseInt(percentage3 ? percentage3 : 0))
    }, [percentage1, percentage2, percentage3])

    return (
        <div className='flex flex-col bg-white w-full h-full'>
            <button onClick={() => closeJob()}><img className="w-6 h-6 absolute rounded-mx mt-11 ml-12" src="../assets/icons/left-arrow-from-left.png" alt='' /></button>
            <p className="absolute text-2xl font-bold text-gray-800 ml-1 mt-[38px]" style={{ left: 86 }}> {t('evaluations.create')} </p>
            <div className='w-full mt-28 pl-20 pr-20'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='w-full flex flex-row'>
                        <div className='w-1/2'>
                            <TextField
                                id='job-title'
                                className='w-full'
                                placeholder="Evaluation Name"
                                {...register("evaluationName")}
                            />
                        </div>
                        <div className='w-1/2'>
                            <FormControl className='w-11/12'>
                                <InputLabel id="badge-label">{t('badges.badge')}</InputLabel>
                                <Select
                                    labelId="badge-label"
                                    id="Badge"
                                    label='Badge'
                                    multiple
                                    placeholder='Badge'
                                    // defaultValue=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleChangeBadge}
                                    value={badge}
                                >
                                    {!badgesCatalogs &&
                                        <MenuItem value="" disabled>
                                            {t('badges.badgeSelect')}
                                        </MenuItem>
                                    }
                                    {badgesCatalogs.filter(x => x.companyId === companyBadges).map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-4 pr-4'>
                        <div className='w-full'>
                            <TextField
                                id='description'
                                multiline
                                rows={3}
                                placeholder='Description'
                                className='w-full'
                                {...register("description")}
                            />
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-4 pr-4'>
                        <div className='w-1/2'>
                            <TextField
                                id='duration-time'
                                className='w-full'
                                placeholder="Duración Evaluación (mins)"
                                {...register("durationTime")}
                                type='number'
                            />
                        </div>
                    </div>
                    <br />
                    {/* <hr /> */}
                    <Divider textAlign="left"><Tooltip title='En esta opción puedes seleccionar la cantidad en números ya sea de meses o años. 1. Tiempo validez: Indica por cuanto tiempo estará vigente la evaluación'>Temporalización</Tooltip></Divider>
                    <br />
                    <div className='w-full flex flex-row pt-4'>
                        <div className='w-1/2'>
                            <FormControl className='w-full'>
                                <Tooltip title='En esta opción puedes seleccionar la cantidad en números ya sea de meses o años que vayas a seleccionar.'><InputLabel>Tiempo Validez Evaluación</InputLabel></Tooltip>
                                <Select
                                    defaultValue='1'
                                    label="Tiempo Validez Evaluación"
                                    value={evaluationPeriod}
                                    onChange={handleEvaluationPeriod}
                                >
                                    <MenuItem value='1'>1</MenuItem>
                                    <MenuItem value='2'>2</MenuItem>
                                    <MenuItem value='3'>3</MenuItem>
                                    <MenuItem value='4'>4</MenuItem>
                                    <MenuItem value='5'>5</MenuItem>
                                </Select>
                            </FormControl>

                        </div>
                        <div className='w-1/2 pl-4 pr-4'>

                            <FormControl className='w-full'>
                                <InputLabel>Tiempo Validez Evaluación</InputLabel>
                                <Select
                                    defaultValue='1'
                                    label="Tiempo Validez Evaluación"
                                    value={evaluationTime}
                                    onChange={handleEvaluationTime}
                                >
                                    <MenuItem value='A'>Años</MenuItem>
                                    <MenuItem value='M'>Meses</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-4'>
                        <div className='w-1/3 pr-2'>
                            <FormControl className='w-full'>
                                <InputLabel>Máximo Intentos Permitidos</InputLabel>
                                <Select
                                    defaultValue='1'
                                    label="Máximo Intentos Permitidos"
                                    value={evaluationAttempts}
                                    onChange={handleEvaluationAttempts}

                                >
                                    <MenuItem value='1'>1</MenuItem>
                                    <MenuItem value='2'>2</MenuItem>
                                    <MenuItem value='3'>3</MenuItem>
                                    <MenuItem value='4'>4</MenuItem>
                                    <MenuItem value='5'>5</MenuItem>
                                </Select>
                            </FormControl>

                        </div>
                        <div className='w-1/2 pr-2'>
                            <FormControl className='w-full'>
                                <InputLabel>Plazo Validez Intentos</InputLabel>
                                <Select
                                    defaultValue='1'
                                    label="Tiempo Validez Intentos"
                                    value={validez}
                                    onChange={handleChangeValidez}
                                >
                                    <MenuItem value='1'>1</MenuItem>
                                    <MenuItem value='2'>2</MenuItem>
                                    <MenuItem value='3'>3</MenuItem>
                                    <MenuItem value='4'>4</MenuItem>
                                    <MenuItem value='5'>5</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-1/3'>
                            <FormControl className='w-full'>
                                <InputLabel>Tiempo Validez Intentos</InputLabel>
                                <Select
                                    defaultValue='1'
                                    label="Tiempo Validez Intentos"
                                    value={attemptsTime}
                                    onChange={handleAttemptsTime}
                                >
                                    <MenuItem value='A'>Años</MenuItem>
                                    <MenuItem value='M'>Meses</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {/* <div className='w-full flex flex-row pt-4'>
                        <div className='w-1/2'>



                        </div>
                        <div className='w-1/2'>


                        </div>
                    </div> */}
                    {/* <MultipleOneOption getMultipleOneOptionData={loadMultipleOneOptionData} getSubtotalMultipleOneOption={loadSubtotalMultipleOneOption} /> */}

                    <br />
                    <Divider textAlign="left">Preguntas</Divider>
                    <br />

                    <Questions getMultipleManyAnswersData={loadMultipleManyAnswersData} getMultipleOptionsData={loadMultipleOptionsData} getMultipleOneOptionData={loadMultipleOneOptionData} />


                    {multipleManyAnswersCheck.length > 0 &&
                        <>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    Preguntas Opcion Multiple - Varias Respuestas ({multipleManyAnswersCheck.length} Preguntas)
                                </AccordionSummary>
                                <AccordionDetails>
                                    {multipleManyAnswersCheck.map((item, index) => (
                                        <>
                                            <Card key={index} className='w-full justify-start text-left pl-2 mb-2'>
                                                <p dangerouslySetInnerHTML={{ __html: item.question }}></p>
                                            </Card>
                                        </>
                                    ))

                                    }

                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                    {multipleOptionsCheck.length > 0 &&
                        <>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                >
                                    Multiple Opción - Una Respuesta ({multipleOptionsCheck.length} Preguntas)
                                </AccordionSummary>
                                <AccordionDetails>
                                    {multipleOptionsCheck.map((item, index) => (
                                        <>
                                            <Card key={index} className='w-full justify-start text-left pl-2 mb-2'>
                                                <p dangerouslySetInnerHTML={{ __html: item.question }}></p>
                                            </Card>
                                        </>
                                    ))
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                    {multipleOneOptionCheck.length > 0 &&
                        <>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3-content"
                                    id="panel3-header"
                                >
                                    Verdadero/Falso ({multipleOneOptionCheck.length} Preguntas)
                                </AccordionSummary>
                                <AccordionDetails>
                                    {multipleOneOptionCheck.map((item, index) => (
                                        <>
                                            <Card key={index} className='w-full justify-start text-left pl-2 mb-2'>
                                                <p dangerouslySetInnerHTML={{ __html: item.question }}></p>
                                            </Card>
                                        </>
                                    ))
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                    <br />
                    <Divider textAlign="left"><Tooltip title='En esta opción se debe asignar el 100% del valor de la evaluación distribuida en los tipos de pregunta utilizados.'>Sumatoria</Tooltip></Divider>
                    <br />
                    <div className='w-full flex flex-row pt-4'>
                        {multipleManyAnswersCheck.length > 0 &&
                            <div className='w-4/12'>
                                <TextField
                                    id='percentage'
                                    className='w-11/12 pl-2'
                                    label='Multiple Opción Varias Respuestas'
                                    placeholder='Multiple Opción Varias Respuestas'
                                    //  disabled={!multipleChecked}
                                    value={percentage1}
                                    // onChange={(e) => setPercentage(e.target.value)}
                                    onChange={handlePercentage1}
                                />
                            </div>
                        }
                        {multipleOptionsCheck.length > 0 &&
                            <div className='w-4/12'>
                                <TextField
                                    id='percentage'
                                    className='w-11/12 pl-2'
                                    label='Multiple Opción Una Respuesta'
                                    placeholder='Multiple Opción Una Respuesta'
                                    //  disabled={!multipleChecked}
                                    value={percentage2}
                                    // onChange={(e) => setPercentage(e.target.value)}
                                    onChange={handlePercentage2}
                                />
                            </div>
                        }
                        {multipleOneOptionCheck.length > 0 &&
                            <div className='w-4/12'>
                                <TextField
                                    id='percentage'
                                    className='w-11/12 pl-2'
                                    label='Falso/Verdadero'
                                    placeholder='Falso/Verdadero'
                                    //  disabled={!multipleChecked}
                                    value={percentage3}
                                    // onChange={(e) => setPercentage(e.target.value)}
                                    onChange={handlePercentage3}
                                />
                            </div>
                        }
                    </div>


                    {/* <MultipleManyAnswers getMultipleManyAnswersData={loadMultipleManyAnswersData} getSubtotalManyOptions={loadSubtotalManyOptions} />

                    <MultipleOptions getMultipleOptionsData={loadMultipleOptionsData} getSubtotalMultipleOption={loadSubtotalMultipleOption} /> */}

                    <TotalDetail totalData={total} />

                    <div className='flex flex-row w-full mt-14 mr-24 justify-end items-end place-content-end pb-28'>
                        <div className='w-9/12'>

                        </div>
                        <div className='w-3/12 mr-20 ml-14'>
                            {/* <button className=" justify-center w-64 px-14 py-4 bg-white border rounded-full border-gray-800 text-base font-medium text-gray-800">
                                Save to Drafts
                            </button> */}
                            {!isSaving &&
                                <button type='submit' className="justify-end w-64 px-14 py-4 bg-blue-900 rounded-full text-base font-medium text-white ml-2">
                                    {t('badges.save')}
                                </button>
                            }
                            {isSaving &&
                                <div className='pt-20'>
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
