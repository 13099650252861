import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardContent, CardHeader, CardMedia, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { startLoadingBadgeCategories } from '../../../store/slices/badgesCategories/thunks';
import { setActiveBadgesCatalog } from '../../../store/slices/badgesCatalog/badgesCatalogSlice';
import { BadgePreviewByCategory} from './BadgePreviewByCategory'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export const BadgesByCategory = ({ onClick, onClick2 }) => {
    const dispatch = useDispatch();
    const { evaluations } = useSelector(state => state.evaluation);
    const { badgesCategories } = useSelector(state => state.badgesCategorie);
    const { badgesCatalogs } = useSelector(state => state.badgesCatalog);
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);
    const [basicFitBadges, setBasicFitBadges] = useState([]);
    const [compatibilityBadges, setCompatibilityBadges] = useState([]);
    const [skillBadges, setSkillBadges] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [enableStart, setEnableStart] = useState(true);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getBadgeDetail = (item) => {
        const {
            id,
            categorie,
            imageUrl,
            name,
            description,
            creationDate,
            companyId
        } = item;
        dispatch(setActiveBadgesCatalog({ id, name, description, imageUrl, categorie, creationDate, companyId }))
        setShowPreview(true);
        onClick(true)
    }

    useEffect(() => {
        dispatch(startLoadingBadgeCategories());
    }, [])

    useEffect(() => {
        let tmpBasicFit = [];
        let tmpSkillAssesment = [];
        let tmpCompatility = [];
        if (badgesCatalogs && badgesCategories) {
            let tmpBasicFitCategorie = badgesCategories.filter(x => x.name === 'Basic Fit');
            let tmpSkillAssesmentCategorie = badgesCategories.filter(x => x.name === 'Skill-Assessment');
            let tmpCompatibilityCategorie = badgesCategories.filter(x => x.name === 'Compatibility');
            if (tmpBasicFitCategorie.length > 0) {
                tmpBasicFit = badgesCatalogs.filter(x => x.categorie === tmpBasicFitCategorie[0].id);
                setBasicFitBadges(tmpBasicFit);
            }
            if (tmpSkillAssesmentCategorie.length > 0) {
                tmpSkillAssesment = badgesCatalogs.filter(x => x.categorie === tmpSkillAssesmentCategorie[0].id);
                setSkillBadges(tmpSkillAssesment);
            }
            if (tmpCompatibilityCategorie.length > 0) {
                tmpCompatility = badgesCatalogs.filter(x => x.categorie === tmpCompatibilityCategorie[0].id);
                setCompatibilityBadges(tmpCompatility);
            }
        }
    }, [badgesCatalogs])

    const changePreviewBadge = () => {
        setShowPreview(false);
        onClick2(false);
    }

    return (
        <>
            {showPreview &&
                <BadgePreviewByCategory onClick={changePreviewBadge} enableStart={true}/>
            }
            {!showPreview &&
                                    // <div className='flex flex-col bg-white w-full place-items-center'>
                                    // <div className='w-full flex flex-row'>
                                    //     <p className="absolute text-2xl font-bold text-gray-800 ml-1 mt-[38px]" style={{ left: 86 }}>{t('badgesEmployee.category')}</p>
                                    // </div>
                                    // <div className='w-full flex flex-row'>
                <div className='flex flex-col bg-white w-full place-items-center min-h-[780px] pt-20'>
                    <Box sx={{ width: '80%', marginLeft: '60px' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Basic Fit" {...a11yProps(0)} />
                                <Tab label="Compatibility" {...a11yProps(1)} />
                                <Tab label="Skills" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <div className='w-full flex flex-wrap pt-10'>
                                {basicFitBadges.map((item, index) => (
                                    <div key={index} className='w-1/3 h-[390px] pl-4'>
                                        <Button onClick={() => getBadgeDetail(item)}>
                                            <Card className='w-64 h-[380px] hover:transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...'>
                                                <CardHeader
                                                    title={item.name}
                                                />
                                                <div className='w-full flex justify-center place-content-center items-center text-center'>
                                                    <CardMedia
                                                        component="img"
                                                        // height="154"
                                                        sx={{ height: 140, width: 140, justifyContent: 'center', textAlign: 'center', alignContent: 'center', placeContent: 'center' }}
                                                        image={item.imageUrl}
                                                    />
                                                </div>
                                                <CardContent>
                                                    <Typography fontSize={10} variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </CardContent>
                                            </Card></Button>
                                    </div>
                                ))

                                }
                            </div>

                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className='w-full flex flex-wrap pt-10'>
                                {compatibilityBadges.map((item, index) => (
                                    <div key={index} className='w-1/3 h-[390px] pl-4'>
                                        <Button onClick={() => getBadgeDetail(item)}>
                                            <Card className='w-60 h-[330px] hover:transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...'>
                                                <CardHeader
                                                    title={item.name}
                                                />
                                                <div className='w-full flex justify-center place-content-center items-center text-center'>
                                                    <CardMedia
                                                        component="img"
                                                        // height="154"
                                                        sx={{ height: 140, width: 140, justifyContent: 'center', textAlign: 'center', alignContent: 'center', placeContent: 'center' }}
                                                        image={item.imageUrl}
                                                    />
                                                </div>
                                                <CardContent>
                                                    <Typography fontSize={12} variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Button>
                                    </div>
                                ))

                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className='w-full flex flex-wrap pt-10'>
                                {skillBadges.map((item, index) => (
                                    <div key={index} className='w-1/3 h-[390px] pl-4'>
                                        <Button onClick={() => getBadgeDetail(item)}>
                                            <Card className='w-60 h-[330px] hover:transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...'>
                                                <CardHeader
                                                    title={item.name}
                                                />
                                                <div className='w-full flex justify-center place-content-center items-center text-center'>
                                                    <CardMedia
                                                        component="img"
                                                        // height="154"
                                                        sx={{ height: 140, width: 140, justifyContent: 'center', textAlign: 'center', alignContent: 'center', placeContent: 'center' }}
                                                        image={item.imageUrl}
                                                    />
                                                </div>
                                                <CardContent>
                                                    <Typography fontSize={12} variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Button>
                                    </div>
                                ))

                                }
                            </div>
                        </TabPanel>
                    </Box>
                </div>
                // </div>
                // </div>
            }
        </>
    )
}
