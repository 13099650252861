import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    profiles: [],
    employeeProfiles: [],
    profile: [],
    active: null
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        savingNewProfile: (state) => {
            state.isSaving = true;
        },
        addNewEmptyProfile: (state, action) => {
            const newProfile = action.payload;
            state.profiles.push(newProfile);
            state.isSaving = false;
        },
        setActiveProfile: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setProfiles: (state, action) => {
            state.profiles = action.payload;
        },
        setEmployeeProfiles: (state, action) => {
            state.employeeProfiles = action.payload;
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateProfile: (state, action) => {
            state.isSaving = false;
            state.profiles = state.profiles.map(profile => {
                if (profile.id === action.payload.id) {
                    return action.payload;
                }
                return profile;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteProfileById: (state, action) => {

        },
        
    }
})

export const {
    savingNewProfile,
    addNewEmptyProfile,
    setActiveProfile,
    setProfiles,
    setEmployeeProfiles,
    setProfile,
    setSaving,
    updateProfile,
    deleteProfileById,
} = profileSlice.actions