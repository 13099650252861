import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingCommentsById, startNewComment } from '../../store/slices/comments/thunks';

export const Comments = ({ idconversation, profileview, displayName }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector(state => state.profile);
  const [commentText, setCommentText] = useState('');
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userIds, setUserIds] = useState([]);

  useEffect(() => {
    const loadComments = async () => {
      setLoading(true);
      setError(null);
      try {
        const comments = await dispatch(startLoadingCommentsById(idconversation));
        setComments(comments);
        const userIds = comments.map(comment => comment.uid);
        setUserIds(userIds);
      } catch (err) {
        setError('Error al cargar los comentarios');
      } finally {
        setLoading(false);
      }
    };
    loadComments();
  }, [dispatch, idconversation]);

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleCommentSubmit = async () => {
    if (commentText.trim()) {
      const newComment = {
        idconversation,
        profileView: profileview,
        displayName: displayName,
        cont: commentText
      };
      setLoading(true);
      setError(null);
      try {
        await dispatch(startNewComment(newComment));
        const updatedComments = await dispatch(startLoadingCommentsById(idconversation));
        setComments(updatedComments);
        const userIds = updatedComments.map(comment => comment.uid);
        setUserIds(userIds);
        setCommentText('');
      } catch (err) {
        setError('Error al enviar el comentario');
      } finally {
        setLoading(false);
      }
    }
  };

  const sortCommentsByDateAndTime = (comments) => {
    return [...comments].sort((a, b) => new Date(a.date + ' ' + a.hour) - new Date(b.date + ' ' + b.hour));
  };

  return (
    <div className="bg-gray-100 p-4">
      <div className="mt-4">
        {error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          comments.length > 0 ? (
            <div className="bg-white rounded-md p-4">
              {sortCommentsByDateAndTime(comments).map((comment, index) => (
                <div key={comment.id} className="mb-4">
                  <div className="flex justify-between items-start mb-2">
                    <div className="flex items-center">
                      <img
                        className="w-10 h-10 rounded-full mr-2"
                        src="https://via.placeholder.com/50"
                        alt="Profile"
                      />
                      <p className="font-semibold">{comment.displayName}</p>
                    </div>
                    <span className="text-gray-500 text-sm">{comment.date} {comment.hour}</span>
                  </div>
                  <p className="text-left ml-12">{comment.cont}</p>
                </div>
              ))}
            </div>
          ) : (
            <p>No hay comentarios aún.</p>
          )
        )}
      </div>

      <div className="flex items-start space-x-4 mt-4">
        <img
          className="w-12 h-12 rounded-full"
          src="https://via.placeholder.com/50"
          alt="Profile"
        />
        <div className="flex-1">
          <textarea
            className="w-full h-20 bg-gray-200 rounded-md p-2 resize-none"
            placeholder="Escribe un comentario..."
            value={commentText}
            onChange={handleCommentChange}
            aria-label="Escribir un comentario"
          ></textarea>
          <button
            className="bg-blue-500 text-white px-4 py-2 mt-2 rounded-md"
            onClick={handleCommentSubmit}
            disabled={loading}
            aria-label="Enviar comentario"
          >
            Comentar
          </button>
        </div>
      </div>
    </div>
  );
};
