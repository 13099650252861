import { Box, Button, Card, CardActions, CardContent, CardMedia, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { CreateBadgeEvaluation } from './CreateBadgeEvaluation';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingEvaluations, startDeleteEvaluation } from '../../store/slices/evaluation/thunks'; // Importamos startDeleteEvaluation
import { startLoadingCompanyProfile } from '../../store/slices/companyProfile/thunks';
import Swal from 'sweetalert2';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const BadgesEvaluation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { profile } = useSelector(state => state.profile);
    const { companyProfile } = useSelector(state => state.companyProfile);
    const { evaluations } = useSelector(state => state.evaluation);
    const { uid } = useSelector(state => state.auth);
    const [newEvaluation, setNewEvaluation] = useState(false);
    const [value, setValue] = React.useState(0);
    const [evaluationDetail, setEvaluationDetail] = useState(false);
    const [badgeEvaluations, setBadgeEvaluations] = useState([]);
    const [imageCompany, setImageCompany] = useState('');
    const [idCompany, setIdCompany] = useState('');

    useEffect(() => {
        if (profile.length > 0) {
            dispatch(startLoadingCompanyProfile(profile[0].idCompany));
            dispatch(startLoadingEvaluations());
        }
    }, [])

    useEffect(() => {
        if (companyProfile.length > 0) {
            let imgUrl = '';
            let tmpPages = companyProfile[0].pages;
            if (tmpPages) {
                setImageCompany(tmpPages.profileImageUrl)
            }
        }
    }, []);

    useEffect(() => {
        if (evaluations.length > 0) {
            let idComp = '';
            let tmpEvaluations = [];

            if (companyProfile.length > 0) {
                idComp = companyProfile[0].id;
                setIdCompany(idComp);
                tmpEvaluations = evaluations.filter(x => x.companyId == idComp);
            }

            setBadgeEvaluations(tmpEvaluations);
        }
    }, [evaluations])

    const onCreateEvaluation = () => {
        setNewEvaluation(true);
    }

    const onCloseEvaluation = () => {
        setNewEvaluation(false);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onPreview = () => {
        setEvaluationDetail(true);
    }

    

    const onDeleteEvaluation = (evaluationId) => {
        Swal.fire({
            title: "¿Estás seguro?",
            text: "¡No podrás deshacer esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDeleteEvaluation(evaluationId))
                .then(() => {
                    Swal.fire({
                        title: "¡Eliminado!",
                        text: "Tu evaluación ha sido eliminada.",
                        icon: "success"
                    });
                })
                .catch((error) => {
                    console.error("Error al eliminar la evaluación:", error);
                    Swal.fire({
                        title: "Error",
                        text: "Ocurrió un error al eliminar la evaluación. Por favor, intenta de nuevo más tarde.",
                        icon: "error"
                    });
                });
            }
        });
    };

    return (
        <>
            {newEvaluation &&
                <CreateBadgeEvaluation onClick={onCloseEvaluation} />
            }
            {!newEvaluation &&
                <div className='flex flex-col bg-white w-full place-items-center h-[780px]'>
                    <div className="inline-flex items-start justify-start pl-20 pr-24 pt-14 pb-4 bg-white rounded-xl" >
                        <div className="inline-flex flex-col space-y-6 place-content-center items-center px-20 pt-8 pb-5 bg-gray-100 rounded-xl">
                            <p className="text-2xl font-bold text-center text-gray-800">{t('evaluations.title')}</p>
                            <button className='inline-flex  px-14 py-4 bg-blue-900 rounded-full items-center justify-center w-58 text-white text-base font-medium ' onClick={() => onCreateEvaluation()}>{t('evaluations.create')}</button>
                        </div>
                    </div>
                    <Box sx={{ width: '80%', marginLeft: '60px' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Evaluations" {...a11yProps(0)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <div className='w-full flex space-x-4 pt-10'>
                                <div className='w-full flex flex-wrap pr-10 pl-10'>
                                    {badgeEvaluations.map((item, index) => (
                                        <div key={index} className='w-1/3 p-2'>
                                            <Card sx={{ minWidth: 240 }}>
                                                <CardMedia
                                                    component="img"
                                                    alt=""
                                                    height="140"
                                                    image="/static/images/cards/contemplative-reptile.jpg"
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant='h5' component='div'>
                                                        {item.evaluationName}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions sx={{ alignContent: 'flex-end', justifyContent: 'end' }}>
                                                    <Button size="small" onClick={() => onDeleteEvaluation(item.id)} sx={{ color: 'red' }}>
                                                        {t("badges.delete")}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </TabPanel>
                    </Box>
                </div >
            }
        </>
    )
}
