import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    evaluations: [],
    evaluation: [],
    active: null
}

export const evaluationSlice = createSlice({
    name: 'evaluation',
    initialState,
    reducers: {
        savingNewEvaluation: (state) => {
            state.isSaving = true;
        },
        addNewEmptyEvaluation: (state, action) => {
            const newEvaluation = action.payload;
            state.evaluations.push(newEvaluation);
            state.isSaving = false;
        },
        setActiveEvaluation: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setEvaluations: (state, action) => {
            state.evaluations = action.payload;
        },
        setEvaluation: (state, action) => {
            state.evaluation = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateEvaluation: (state, action) => {
            state.isSaving = false;
            state.evaluations = state.evaluations.map(evaluation => {
                if (evaluation.id === action.payload.id) {
                    return action.payload;
                }
                return evaluation;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteEvaluationById: (state, action) => {
            state.evaluations = state.evaluations.filter(evaluation => evaluation.id !== action.payload);
        }
    }
})

export const {
    savingNewEvaluation,
    addNewEmptyEvaluation,
    setActiveEvaluation,
    setEvaluations,
    setEvaluation,
    setSaving,
    updateEvaluation,
    deleteEvaluationById
} = evaluationSlice.actions