import React, { useEffect } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

export const ManualFilteringStage = ({ sendUpdate, data }) => {
    const { applyingJobs } = useSelector(state => state.applyingJobs);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            summary: ""
        }
    });

    useEffect(() => {

    }, [data])

    const onSubmit = (data) => {

        sendUpdate(4, data, 'Approved');

    }

    const onReject = (data) => {
        sendUpdate(4, data, 'Rejected');
    }

    return (
        <>
            <div className="w-full h-24 bg-zinc-100 flex">
                <div className='p-4'>
                    <img className="w-14 h-14 rounded-full" src={(data.profileImageUrl.length > 0) ? data.profileImageUrl : "https://via.placeholder.com/59x59"} />
                </div>
                <div className='pt-6'>
                    <div className="text-slate-800 text-lg font-bold font-['Poppins']">Manual Filtering Stage</div>
                    <div className="text-gray-600 text-sm font-medium font-['Poppins']">{data?.displayName}</div>
                </div>
            </div>
            <div className='w-full mb-10'>
                <div >
                    <div className='w-full pt-4 pl-10 pr-4'>
                        <p className="text-gray-600 text-sm font-medium font-['Poppins']">El candidato ha pasado el filtro manual cumpliendo los requisitos establecidos para esta etapa?, agrega un comentario ya sea que haya aprobado o declinado. </p>
                    </div>
                    <div className='flex flex-row mt-4 pl-6'>
                        <div className='w-full pl-6 pr-6'>
                            <TextField
                                multiline
                                rows={4}
                                placeholder='Agregar Comentario'
                                className='w-full'
                                error={errors.summary?.type === "required" ? true : false}
                                {...register("summary", { required: true, minLength: 5 })}
                            />
                            <div className='w-full flex flex-row pt-0'>
                                {
                                    errors.summary && errors.summary.type === "required" &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">Comment is required</p>
                                }
                                {
                                    errors.summary && errors.summary.type === "minLength" &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">Must have at least 5 characters</p>
                                }
                            </div>
                        </div>

                    </div>

                    <div className='flex flex-row'>
                        <div className='w-full justify-center items-center text-center pt-8'>
                            <button className="w-72 h-14 px-8 py-1 bg-sky-900 rounded-3xl justify-center items-center gap-2.5 inline-flex text-white text-base font-medium font-['Poppins']" onClick={handleSubmit(onSubmit)}>
                                Approve
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-row pb-10'>
                        <div className='w-full justify-center items-center text-center pt-2'>
                            <button className="w-72 h-14 px-8 py-1 bg-orange-500 rounded-3xl justify-center items-center gap-2.5 inline-flex text-white text-base font-medium font-['Poppins']" onClick={handleSubmit(onReject)}>
                                Reject
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
