
import { collection, deleteDoc, doc, setDoc, getDoc } from 'firebase/firestore/lite';
import { addNewEmptyJob, deleteJobById, loadJobById, savingNewJob, setActiveJob, setJobs, setSaving, updateJob } from './jobSlice';
import { FirebaseDB } from '../../../firebase/config';
import { loadClosedJobs, loadDraftJobs, loadJobs } from '../../../helpers/loadJobs';


export const startNewJob = (job) => {
    return async (dispatch, getState) => {
        dispatch(savingNewJob());

        const newDoc = doc(collection(FirebaseDB, `job`))
        //newJob.id = newDoc.id;

        const { uid } = getState().auth;
        const {
            basicAssessments,
            compatibility,
            manualFiltering,
            videoInterview,
            finalInterview,
            currency,
            description,
            frequency,
            jobType,
            joblocation,
            locationCity,
            jobtitle,
            maximum,
            minimum,
            customJobTitle,
            benefits,
            payType,
            payment,
            plusComission,
            skillsAssessments,
            typeInterview,
            jobModel,
            companyId,
            urlVideo,
            tagsJob,
            department,
            companyName
        } = job;

        const newJob = {
            id: newDoc.id,
            basicAssessments,
            compatibility,
            manualFiltering,
            videoInterview,
            finalInterview,
            currency,
            description,
            frequency,
            jobType,
            joblocation,
            locationCity,
            jobtitle,
            maximum,
            minimum,
            customJobTitle,
            benefits,
            payType,
            payment,
            plusComission,
            skillsAssessments,
            typeInterview,
            jobModel,
            uid,
            companyId,
            urlVideo,
            tagsJob,
            department,
            companyName,
            status: 'Created',
            fecha: new Date().getTime()
        }

        //const newDoc = doc(collection(FirebaseDB, `job`))
        await setDoc(newDoc, newJob);
        //newJob.id = newDoc.id;
        dispatch(addNewEmptyJob(newJob));
        dispatch(setActiveJob(newJob));
    }
}

export const applyToJob = (job) => {
    return async (dispatch, getState) => {

        const {
            uid,
            jobId,
            companyId
        } = job

        const newJobApply = {
            uid,
            jobId,
            companyId,
            status: 'Applying',
            stage: 1,
            date: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `applyingJob`));
        await setDoc(newDoc, newJobApply);
        newJobApply.id = newDoc.id;

    }
}

export const startLoadingJobs = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

       
        const jobs = await loadJobs(uid);
        dispatch(setJobs(jobs));
    }
}

export const startLoadingDraftJobs = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const jobs = await loadDraftJobs(uid);
        dispatch(setJobs(jobs));
    }
}

export const startLoadingClosedJobs = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const jobs = await loadClosedJobs(uid);
        dispatch(setJobs(jobs));
    }
}

export const startDeletingJob = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        const { active: job } = getState().job;

        const docRef = doc(FirebaseDB, `job/${job.id}`);
        await deleteDoc(docRef);

        dispatch(deleteJobById(job.id));
    }
}


export const startEditJob = ({ jobId, editedJob }) => {
    return async (dispatch, getState) => {
        dispatch(setSaving());
        try {
            const docRef = doc(FirebaseDB, `job/${jobId}`);
            const editedJobToFirestore = { ...editedJob };
            await setDoc(docRef, editedJobToFirestore, { merge: true });
            dispatch(updateJob({ jobId, editedJob }));
        } catch (error) {
            console.error("Error al editar el trabajo:", error);
        } finally {
            dispatch(setSaving(false));
        }
    };
};


export const startLoadingJobById = (jobId) => {
    return async (dispatch, getState) => {
        try {
            const docRef = doc(FirebaseDB, `job/${jobId}`);
            const jobSnapshot = await getDoc(docRef);
            
            if (jobSnapshot.exists()) {
                const jobData = jobSnapshot.data();
                dispatch(loadJobById(jobData));
            } else {
                console.error(`No se encontró ningún trabajo con el ID: ${jobId}`);
            }
        } catch (error) {
            console.error("Error al cargar el trabajo por ID:", error);
        }
    };
};
