import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    badgesCategorie: [],
    badgesCategories: [],
    active: null
}

export const badgesCategorieSlice = createSlice({
    name: 'badgesCatagories',
    initialState,
    reducers: {
        savingNewBadgesCategorie: (state) => {
            state.isSaving = true;
        },
        addNewEmptyBadgesCategorie: (state, action) => {
            const newBadgesCategorie = action.payload;
            state.badgesCategories.push(newBadgesCategorie);
            state.isSaving = false;
        },
        setActiveBadgesCategorie: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setBadgesCategories: (state, action) => {
            state.badgesCategories = action.payload;
        },
        setBadgesCategorie: (state, action) => {
            state.badgesCategorie = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateBadgesCategorie: (state, action) => {
            state.isSaving = false;
            state.badgesCategories = state.badgesCategories.map(badge => {
                if (badge.id === action.payload.id) {
                    return action.payload;
                }
                return badge;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        }

    }
})

export const {
    savingNewBadgesCategorie,
    addNewEmptyBadgesCategorie,
    setActiveBadgesCategorie,
    setBadgesCategories,
    setBadgesCategorie,
    setSaving,
    updateBadgesCategorie
} = badgesCategorieSlice.actions