// ... Otras importaciones
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { ChatListItem } from './ChatListItem';
import { startNewMessage, startLoadingMessages, startSaveMessage } from '../store/slices/messages/thunks';
import { setActiveMessage } from '../store/slices/messages/messagesSlice';

export const MessagesEmployer = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { profile } = useSelector(state => state.profile);
  const [selectedChatIndex, setSelectedChatIndex] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const dispatch = useDispatch();
  const { uid, displayName, photoURL } = useSelector(state => state.auth);
  const { messages } = useSelector(state => state.message);
  const { employeeProfiles } = useSelector(state => state.profile);
  const [userConversation, setUserConversation] = useState([]);
  const [conversation, setConversation] = useState([]);


  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    dispatch(startLoadingMessages(uid));
  }, [uid]);

  useEffect(() => {
    getUserMessages();
  }, [messages]);

  const getUserMessages = () => {
    let tmpUserM = messages.filter(x => x.uidSender === uid);
    if (employeeProfiles.length > 0) {
      let usersProfiles = employeeProfiles.filter(x => tmpUserM.some(y => y.uidReceived === x.uid || y.uidSender === x.uid));
      setUserConversation(usersProfiles);

    };
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSendMessage = async () => {
    if (!inputText.trim()) {
      return; // Handle empty message case (optional)
    }

    let tmpConversation = messages.filter(x => x.uidSender === uid || x.uidReceived === uid);
    let tmpMessages = tmpConversation.filter(x => x.uidSender === uid || x.uidReceived === uid);

    if (tmpMessages.length < 1) {
      const dataMessage = {
        uidSender: uid,
        uidReceived: messages[selectedChatIndex]?.uidReceived,
        messages: [{
          message: inputText,
          date: new Date().getTime(),
          uidSender: uid,
          uidReceived: messages[selectedChatIndex]?.uidReceived,
          senderName: displayName,
          senderProfileImage: photoURL,
        }],
        senderName: displayName,
        senderProfileImage: photoURL,
      };

      dispatch(startNewMessage(dataMessage));

    } else {

      let extractMessages = [];
      tmpMessages[0].messages.map((item) => {
        extractMessages.push(item)
      })
      extractMessages.push({ message: inputText, date: new Date().getTime(), uidSender: uid, uidReceived: uid, senderName: displayName, senderProfileImage: "" });
      const newData = {
        id: tmpMessages[0].id,
        messages: extractMessages
      }

      dispatch(setActiveMessage(newData));
      dispatch(startSaveMessage());

      /*setConversation([...conversation, {
        message: inputText,
        date: new Date().getTime(),
        uidSender: uid,
        uidReceived: messages[selectedChatIndex]?.uidReceived,
        senderName: displayName,
        senderProfileImage: photoURL,
        position: 'right',
    }]);*/

      setInputText('');

    };
  }

  useEffect(() => {
    let tmpUidSelected = userConversation[selectedChatIndex]?.uid;
    let tmpMessages = messages.filter(x => x.uidSender === tmpUidSelected || x.uidReceived === tmpUidSelected);
    let dataM = tmpMessages[0]?.messages;
    setConversation(dataM);
  }, [selectedChatIndex]);


  useEffect(() => {
    let tmpUidSelected = userConversation[selectedChatIndex]?.uid;
    let tmpMessages = messages.filter(x => x.uidSender === tmpUidSelected || x.uidReceived === tmpUidSelected);
    let dataM = tmpMessages[0]?.messages;
    setConversation(dataM);
  }, [conversation]);

  return (
    // <div className='flex flex-col bg-white w-full h-screen'>
    //   {/* Barra Superior */}
    //   <div className="header bg-[#edededff] h-20 p-4 flex justify-between items-center">
    //     <div className="flex items-center ml-auto"> {/* Mover los elementos al lado derecho */}
    //     <img src="/assets/icons/videollamda.png" alt="Icono de videollamada" className="w-10 h-10 mr-2 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12" />
    //       <img src="/assets/icons/llamada.png" alt="Icono de llamada" className="w-12 h-12 mr-2 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-14 lg:h-14" />
    //       <div className="relative">
    //         <img
    //           src="/assets/icons/options.png"
    //           alt="Icono de más opciones"
    //           className="cursor-pointer w-10 h-10 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12"
    //           onClick={handleMenuToggle}
    //         />
    //         {isMenuOpen && (
    //           <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg">
    //             <div className="py-2 px-4 hover:bg-gray-100 cursor-pointer">Archivar</div>
    //             <div className="py-2 px-4 hover:bg-gray-100 cursor-pointer">Agregar a Favoritos</div>
    //             <div className="py-2 px-4 hover:bg-gray-100 cursor-pointer">Borrar Chat</div>
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   </div>

    //   {/* Contenido */}
    //   <div className="flex flex-grow overflow-hidden">
    //     {/* Lista de Conversaciones */}
    //     <div className="w-[25%] h-full overflow-y-auto">
    //       <div className="mr-2 overflow-y-auto h-[90%] bg-white resize-none" style={{ maxHeight: 'calc(100% - 40px)' }}>
    //         {userConversation.map((chatItem, index) => (
    //           <ChatListItem
    //             key={index}
    //             {...chatItem}
    //             isSelected={index === selectedChatIndex}
    //             onClick={() => setSelectedChatIndex(index)}
    //           />
    //         ))}
    //       </div>
    //     </div>

    //     {/* Panel de Mensajes */}
    //     <div className="w-[75%] h-full overflow-y-auto">
    //       <div className="overflow-y-auto h-[80%] bg-white p-4">
    //         {conversation && conversation.map((allMessages, index) => (
    //           <div key={index} className={allMessages.position === 'right' ? 'my-message' : 'other-person'}>
    //             <div className="flex mb-2 ml-2">
    //             <img
    //                 src={allMessages.senderProfileImage}
    //                 alt="Foto de perfil"
    //                 className="w-14 h-14 object-cover rounded-full mr-2"
    //               />
    //               <div className="flex flex-col">
    //                 <div className="flex items-center">
    //                   <span className="font-semibold text-lg">{allMessages.senderName}</span>
    //                   <div className="ml-2 text-sm text-gray-500">{new Date(allMessages.date).toLocaleString()}</div>
    //                 </div>
    //                 <div className="message-text text-[#818181] whitespace-pre-line">{allMessages.message}</div>
    //               </div>
    //             </div>
    //           </div>
    //         ))}
    //       </div>

    //       {/* Barra de mensajes */}
    //       <div className="flex items-center justify-center h-[20%] p-2 bg-white"> {/* Ajustar la altura de la barra de mensajes */}
    //         <textarea
    //           className="flex-grow resize-none border rounded p-4 h-full mr-4"
    //           placeholder="Escribe tu mensaje..."
    //           value={inputText}
    //           onChange={(e) => setInputText(e.target.value)}
    //         />
    //         <label htmlFor="fileInput" className="cursor-pointer">
    //           <img
    //             src="/assets/icons/adjuntar-archivo.png"
    //             alt="Icono de adjuntar archivo"
    //             className="w-10 h-10 mr-2 sm:w-8 sm:h-8 md:w-10 md:h-10 lg:w-12 lg:h-12"
    //           />
    //         </label>
    //         <input
    //           id="fileInput"
    //           type="file"
    //           style={{ display: 'none' }}
    //           onChange={(e) => {
    //             const file = e.target.files[0];
    //             if (file) {
    //               console.log('Archivo seleccionado:', file);
    //             }
    //           }}
    //         />
    //         <img
    //           src="/assets/icons/boton-enviar.png"
    //           alt="Botón de enviar"
    //           className="cursor-pointer w-12 h-12"
    //           onClick={handleSendMessage}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className='flex flex-col bg-white w-full place-items-center h-[780px] relative'>
      <div className="bg-white w-[25%] h-[100%] absolute top-0 left-0 z-10">
        <div className="header bg-[#edededff] h-[10%] p-4 flex justify-between items-center">
          <label className="text-sm font-medium text-gray-600">Ressent Mesagge</label>
          <div className="flex items-center">
            <img
              src="/assets/icons/message.png"
              alt="Icono de Mensaje"
              className="w-6 h-6 mr-2"
            />
          </div>
        </div>
        <div className="overflow-y-auto h-[700px] bg-white border" >
          {userConversation.map((chatItem, index) => (
            <ChatListItem
              key={index}
              {...chatItem}
              isSelected={index === selectedChatIndex}
              onClick={() => setSelectedChatIndex(index)}
            />
          ))}
        </div>
      </div>
      <div className="bg-white w-[75%] h-[100%] absolute top-0 right-0 z-20">
        <div className="header bg-[#edededff] h-[10%] p-4 flex justify-between items-center">
          <div className="flex items-center">
            <div className="relative">
              <div className="w-3 h-3 bg-green-500 rounded-full border-2 border-white absolute bottom-0 right-0"></div>
              <div className="w-12 h-12 bg-white rounded-full overflow-hidden">
                <img src={profile[0]?.pages?.profileImageUrl ? profile[0]?.pages?.profileImageUrl : "/assets/images/login-user-default.png"} alt="Foto de perfil" className="w-full h-full object-cover" />
              </div>
              <div className="absolute bottom-0 right-[-40px] text-xs text-green-500">Activo</div>
            </div>
            <div className="ml-auto flex items-center">
              <div className="text-lg font-semibold">{profile[0].displayName}</div>
            </div>
          </div>
          <div className="flex items-center">
            <img src="/assets/icons/llamada.png" alt="Icono de llamada" className="w-7 h-7 mr-2 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
            <img src="/assets/icons/videollamda.png" alt="Icono de videollamada" className="w-7 h-7 mr-2 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7" />
            <div className="relative">
              <img
                src="/assets/icons/options.png"
                alt="Icono de más opciones"
                className="cursor-pointer w-6 h-7 sm:w-5 sm:h-5 md:w-6 md:h-6 lg:w-7 lg:h-7"
                onClick={handleMenuToggle}
              />
              {isMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg">
                  <div className="py-2 px-4 hover:bg-gray-100 cursor-pointer">Archivar</div>
                  <div className="py-2 px-4 hover:bg-gray-100 cursor-pointer">Agregar a Favoritos</div>
                  <div className="py-2 px-4 hover:bg-gray-100 cursor-pointer">Borrar Chat</div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <div className="overflow-y-auto h-[80%] bg-white ">
          {messages.map((message, index) => (
              <div key={index} className={message.position === 'right' ? 'my-message' : 'other-person'}>
                  <div className="flex mb-2 ml-2">
                      <img
                          src={message.position === 'right' ? '/assets/icons/howard.png' : '/assets/images/profile1.png'}
                          alt="Foto de perfil"
                          className="w-10 h-10 object-cover rounded-full mr-2"
                      />
                      <div className="flex flex-col">
                          <div className="flex items-center">
                              <span className="font-semibold text-sm">
                                  {message.position === 'right' ? 'Tu Nombre' : 'Nombre del Otro'}
                              </span>
                              <div className="ml-2 text-xs text-gray-500">
                                  {message.position === 'right' ? '3:23pm' : '3:23pm'}
                              </div>
                          </div>
                          <div className="message-text text-[#818181] whitespace-pre-line">{message.text}</div>
                      </div>
                  </div>
              </div>
          ))}
        </div> */}
        <div className="overflow-y-auto h-[80%] bg-white ">
          <div className="overflow-y-auto h-[80%] bg-white p-4">
            {conversation && conversation.map((item, index) => (

              <>
                {item.uidSender === uid ? (
                  <div key={index} className='my-message'>
                    <div className="flex mb-4 ml-2">
                      <img
                        src={profile[0]?.pages?.profileImageUrl ? profile[0]?.pages?.profileImageUrl : "/assets/images/login-user-default.png"}
                        alt="Foto de perfil"
                        className="w-14 h-14 object-cover rounded-full mr-2"
                      />
                      <div className="flex flex-col">
                        <div className="flex items-center">
                          {/* <span className="font-semibold text-lg">{item.senderName}</span> */}
                          <span className="font-semibold text-lg">Yo</span>
                          <div className="ml-2 text-sm text-gray-500">{new Date(item.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</div>
                        </div>
                        <div className="message-text text-[#818181] whitespace-pre-line">{item.message}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div key={index} className='other-person'>
                    <div className="flex mb-4 ml-2">
                      <img
                        // src='/assets/images/login-user-default.png'
                        src={item.senderProfileImage ? item.senderProfileImage : "/assets/images/login-user-defaultt.png"}
                        alt="Foto de perfil"
                        className="w-12 h-12 object-cover rounded-full mr-2"
                      />

                      <div className="flex flex-col">
                        <div className="flex items-center">
                          <span className="font-semibold text-lg">{item.senderName}</span>
                          <div className="ml-2 text-sm text-gray-500">{new Date(item.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</div>
                        </div>
                        <div className="message-text text-[#818181] whitespace-pre-line">{item.message}</div>
                      </div>
                    </div>
                  </div>
                )}
              </>


              // <div key={index} className={allMessages.position === 'right' ? 'my-message' : 'other-person'}>
              //   <div className="flex mb-4 ml-2">
              //     <img
              //       src={allMessages.senderProfileImage}
              //       alt="Foto de perfil"
              //       className="w-14 h-14 object-cover rounded-full mr-2"
              //     />
              //     <div className="flex flex-col">
              //       <div className="flex items-center">
              //         <span className="font-semibold text-lg">{allMessages.senderName}</span>
              //         <div className="ml-2 text-sm text-gray-500">{new Date(allMessages.date).toLocaleString()}</div>
              //       </div>
              //       <div className="message-text text-[#818181] whitespace-pre-line">{allMessages.message}</div>
              //     </div>
              //   </div>
              // </div>
            ))}
          </div></div>
        <div className="flex items-center justify-center h-[10%] p-2 bg-white space-x-4">

          <textarea
            className="w-5/6 resize-none border rounded p-4 h-full"
            placeholder="Escribe tu mensaje..."
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />
          <label htmlFor="fileInput" className="cursor-pointer">
            <img
              src="/assets/icons/adjuntar-archivo.png"
              alt="Icono de adjuntar archivo"
              className="w-6 h-6 mr-2"
            />
          </label>
          <input
            id="fileInput"
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                console.log('Archivo seleccionado:', file);
              }
            }}
          />
          <img
            src="/assets/icons/boton-enviar.png"
            alt="Botón de enviar"
            className="cursor-pointer w-8 h-8 "
            onClick={handleSendMessage}
          />
        </div>
      </div>
    </div>

  );

};
