import { Box, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { CreateNewJob } from '../components/CreateNewJob';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingClosedJobs, startLoadingDraftJobs, startLoadingJobs } from '../store/slices/job/thunks';
import { setActiveJob } from '../store/slices/job/jobSlice';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { JobDetail } from '../components/JobDetail';
import { ActiveJobs } from '../components/Employer/ActiveJobs';
import { ClosedJobs } from '../components/Employer/ClosedJobs';
import { DraftJobs } from '../components/Employer/DraftJobs';
import { CreateAJob } from '../components/Employer/Jobs/AddAJob';
import { startLoadingEmployeeProfiles } from '../store/slices/profile/thunks';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const JobsEmployer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [newJob, setNewJob] = useState(false);
    const [jobDetail, setJobDetail] = useState(false);
    const { active, jobs, messageSaved, isSaving } = useSelector(state => state.job);
    const { employeeProfiles } = useSelector(state => state.profile);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(startLoadingEmployeeProfiles());
    }, [])

    useEffect(() => {
        if (active && active.id) {
            setJobDetail(true);
        }
    }, [active])
    
    useEffect(() => {
        if (jobDetail && active && active.id) {
            navigate(`/jobs/${active.id}`);
        }
    }, [jobDetail, active?.id])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onCreateJob = () => {
        setNewJob(true);
        
    }

    const onCloseJob = () => {
        setNewJob(false);
        
    }
    

    const onSelectJob = (id, maximum, minimum, customJobTitle, currency, jobModel, jobtitle, jobType, description, joblocation, locationCity, tagsJob, department, urlVideo) => {
        dispatch(setActiveJob({ id, maximum, minimum, customJobTitle, currency, jobModel, jobtitle, jobType, description, joblocation, locationCity, tagsJob, department, urlVideo }));
    };
    
    const onCloseDetail = () => {
        setJobDetail(false);
        navigate('/jobs');
        
    }
    const onPreviewProfile = () => {
        setJobDetail(true);
    }

    return (
        <>
            {newJob &&
                // Componente para crear jobs original
                // <CreateNewJob onClick={onCloseJob} />
                <CreateAJob onClick={onCloseJob} />

            }
            {jobDetail &&
                <JobDetail onClick={()=> onCloseDetail} />
            }
            {!newJob && !jobDetail &&
                <div className='flex flex-col bg-white w-full place-items-center min-h-screen'>
                    <div className="inline-flex items-start justify-start pl-20 pr-24 pt-14 pb-4 bg-white rounded-xl" >
                        <div className="inline-flex flex-col space-y-6 place-content-center items-center px-20 pt-8 pb-5 bg-gray-100 rounded-xl">
                            <p className="text-2xl font-bold text-center text-gray-800">{t('jobs.title')}</p>
                            <button className='inline-flex  px-14 py-4 bg-blue-900 rounded-full items-center justify-center w-58 text-white text-base font-medium ' onClick={() => onCreateJob()}>{t('jobs.create')}</button>
                        </div>
                    </div>
                    <Box sx={{ width: '80%', marginLeft: '60px' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={t("jobs.active")} {...a11yProps(0)} />
                                <Tab label={t("jobs.closed")} {...a11yProps(1)} />
                                {/*  <Tab label={t("jobs.draft")}{...a11yProps(2)} /> */} 
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <ActiveJobs onClickPreviewJob={onPreviewProfile} data={jobs} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ClosedJobs data={jobs} onClickPreviewJob={onPreviewProfile} />
                        </TabPanel>
                      {/*  <TabPanel value={value} index={2}>
                            <DraftJobs data={jobs} onClickPreviewJob={onPreviewProfile} />
                        </TabPanel> */} 
                    </Box>
                </div >
            }
        </>
    )
} 