import { Box, Modal, SpeedDial, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Switch, SpeedDialAction, SpeedDialIcon, FormControl, InputLabel, Select, MenuItem, Tooltip, Container, Stack, IconButton } from '@mui/material';
import React, { useState, useId, useEffect } from 'react'
import { Add, CodeOutlined, FileCopyOutlined, Image, ImageAspectRatio, ImageAspectRatioRounded, PhotoCamera, PrintOutlined, RestartAltOutlined, SaveOutlined, ShareOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next'
import ImageIcon from '@mui/icons-material/Image';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { FirebaseStorage } from '../../../firebase/config';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 825,
    height: 660,
    bgcolor: 'background.paper',
    // border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const actions = [
    { icon: <Add />, name: 'Crear' },
    // { icon: <RestartAltOutlined />, name: 'Contraer' },
    // { icon: <PrintOutlined />, name: 'Expandir' }
    // { icon: <ShareOutlined />, name: 'Share' },
];



const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
    },
}));

export const Questions = ({ getMultipleManyAnswersData, getMultipleOptionsData, getMultipleOneOptionData }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [question, setQuestion] = useState('');
    const [response1, setResponse1] = useState('');
    const [response2, setResponse2] = useState('');
    const [response3, setResponse3] = useState('');
    const [response4, setResponse4] = useState('');
    const [response1Checked, setResponse1Checked] = useState(true);
    const [response2Checked, setResponse2Checked] = useState(false);
    const [response3Checked, setResponse3Checked] = useState(false);
    const [response4Checked, setResponse4Checked] = useState(false);
    const [questionOption, setQuestionOption] = useState('1');
    const [questionResult, setQuestionResult] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [file, setFile] = useState(null);
    const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
    const [code, setCode] = useState('');
    const [interviewQuestions, setInterviewQuestions] = useState([]);
    const [interviewQuestions2, setInterviewQuestions2] = useState([]);
    const [interviewQuestions3, setInterviewQuestions3] = useState([]);
    const [value1, setValue1] = useState('');
    const idtmp = useId();

    const questionActions = [
        { icon: <Image />, name: 'Imagen', onClick: () => document.getElementById('fileInput').click() },
        { icon: <CodeOutlined />, name: 'Código', onClick: () => setIsCodeModalOpen(true) }
        // { icon: <ShareOutlined />, name: 'Share' },
    ];

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    };

    useEffect(() => {

    }, [questionOption])

    const handleOption = (option) => {
        if (option === 'Crear') {
            handleOpen();
        }
    }

    const handleCodeSave = () => {
        // Aquí puedes manejar el código ingresado
        setIsCodeModalOpen(false);
    };

    // const handleChange = (event) => {
    //     setMultipleChecked(event.target.checked);
    // };

    const handleChangeResponse1Checked = (event) => {
        if (questionOption[0] === '1') {
            setResponse1Checked(event.target.checked);
        }
        if (questionOption[0] === '2') {
            setResponse1Checked(event.target.checked);
            setResponse2Checked(false);
            setResponse3Checked(false);
            setResponse4Checked(false);
        }
    }

    const handleChangeResponse2Checked = (event) => {
        if (questionOption[0] === '1') {
            setResponse2Checked(event.target.checked);
        }
        if (questionOption[0] === '2') {
            setResponse2Checked(event.target.checked);
            setResponse1Checked(false);
            setResponse3Checked(false);
            setResponse4Checked(false);
        }
    }

    const handleChangeResponse3Checked = (event) => {
        if (questionOption[0] === '1') {
            setResponse3Checked(event.target.checked);
        }
        if (questionOption[0] === '2') {
            setResponse3Checked(event.target.checked);
            setResponse1Checked(false);
            setResponse2Checked(false);
            setResponse4Checked(false);
        }
    }

    const handleChangeResponse4Checked = (event) => {
        if (questionOption[0] === '1') {
            setResponse4Checked(event.target.checked);
        }
        if (questionOption[0] === '2') {
            setResponse4Checked(event.target.checked);
            setResponse1Checked(false);
            setResponse2Checked(false);
            setResponse3Checked(false);
        }
    }

    const handleQuestionOption = (event) => {
        const {
            target: { value },
        } = event;

        setQuestionOption(
            typeof value === 'string' ? value.split(',') : value,
        );
        setQuestionOption((state) => {
            return state;
        })
        if (value === '2') {
            setResponse1Checked(true);
            setResponse2Checked(false);
            setResponse3Checked(false);
            setResponse4Checked(false);
        }
    };

    const handleChangeQuestionResult = (event) => {
        setQuestionResult(event.target.value);
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };

    async function saveImage(idtmp, id) {

        const storageRef = ref(FirebaseStorage, `images/evaluations/${idtmp + '-' + id}`);
        let promise = new Promise((resolve, reject) => {
            uploadString(storageRef, imageUrl, 'data_url').then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    resolve(url);
                })
            })
        })
        let result = await promise;
        // setUrlImageBadge(result);
        // setUrlImageBadge((state) => {
        //     return state;
        // })

        return result;
    }

    const handleAddAchievement = async () => {

        let urlTmpImage = '';
        let id = '';

        if (questionOption[0] === '1') {
            if (interviewQuestions.length > 4) return;
            id = interviewQuestions.length;
        }

        if (questionOption[0] === '2') {
            if (interviewQuestions2.length > 4) return;
            id = interviewQuestions2.length;
        }

        if (questionOption[0] === '3') {
            if (interviewQuestions3.length > 4) return;
            id = interviewQuestions3.length;
        }


        let answers = [];
        let responses = [];
        let type = '';
        let typeEvaluation = '';
        //Validar si hay imagen
        if (imageUrl) {
            urlTmpImage = await saveImage(idtmp, id);
            type = 'image';
        } else {
            if (code) {
                type = 'code';
            }
        }

        if (response1) {
            answers.push(response1);
            responses.push(response1Checked);
        }

        if (questionOption[0] === '1') {
            typeEvaluation = 'MultipleManyAnswers';
        }

        if (questionOption[0] === '2') {
            typeEvaluation = 'MultipleOptions';
        }

        if (questionOption[0] === '3') {
            typeEvaluation = 'TrueOrFalse';
        }

        let questionTmp = {
            question: question,
            answers: answers,
            reponses: responses,
            urlImage: urlTmpImage,
            type: type,
            input: value1,
            code: code,
            typeEvaluation: typeEvaluation
        }

        if (questionOption[0] === '1') {
            setInterviewQuestions([...interviewQuestions, questionTmp]);
            setInterviewQuestions((state) => {
                return state;
            })
        }
        if (questionOption[0] === '2') {
            setInterviewQuestions2([...interviewQuestions2, questionTmp]);
            setInterviewQuestions2((state) => {
                return state;
            })
        }
        if (questionOption[0] === '3') {
            setInterviewQuestions3([...interviewQuestions3, questionTmp]);
            setInterviewQuestions3((state) => {
                return state;
            })
        }
        setQuestion('');
        setResponse1('');
        setResponse2('');
        setResponse3('');
        setResponse4('');
        setImageUrl('');
        setCode('');
        setValue1('');
        // setQuestionOption('1')
        // onUpdateParent();
    }

    useEffect(() => {
        onUpdateParent();
    }, [interviewQuestions, interviewQuestions2, interviewQuestions3])

    const onUpdateParent = () => {
        if (questionOption[0] === '1') {
            getMultipleManyAnswersData(interviewQuestions);

        }
        if (questionOption[0] === '2') {
            getMultipleOptionsData(interviewQuestions2);

        }
        if (questionOption[0] === '3') {
            getMultipleOneOptionData(interviewQuestions3);

        }

        setOpen(false)
    }

    return (
        <>

            <Box sx={{ position: 'relative', mt: 2, height: 120 }}>
                <StyledSpeedDial
                    ariaLabel="SpeedDial playground example"
                    hidden={false}
                    icon={<SpeedDialIcon />}
                    direction='right'
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={() => handleOption(action.name)}
                        />
                    ))}
                </StyledSpeedDial>
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full pl-6 text-left'>
                        <div className='w-1/2'>
                            <p className=' text-zinc-800 text-[18px]'>Agregar Pregunta</p>
                        </div>
                    </div>
                    {/* <Box sx={{ position: 'relative', height: 80 }}>
                        <StyledSpeedDial
                            ariaLabel="SpeedDial playground example"
                            hidden={false}
                            icon={<SpeedDialIcon />}
                            direction='right'
                        >
                            {questionActions.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={() => handleOption(action.name)}
                                />
                            ))}
                        </StyledSpeedDial>
                    </Box> */}
                    <br />
                    <div className='flex flex-row w-full'>
                        {/* <input type='file' /> */}

                    </div>
                    <div className='w-full flex flex-row pl-6 pr-6 pb-12 mt-1 '>
                        <div className='w-full'>
                            <ReactQuill
                                theme="snow"
                                // value={value1}
                                // onChange={setValue1}
                                value={question}
                                onChange={setQuestion}
                                className='h-16'
                            />
                        </div>
                        {/* <div className='w-3/12'>

                        </div> */}
                    </div>
                    <div className='w-full flex flex-row pl-6 pr-6 pb-2 mt-1'>
                        <div className='w-1/2'>
                            <FormControl className='w-full'>
                                {/* <InputLabel id="badge-label">{t('badges.badge')}</InputLabel> */}
                                <Select
                                    labelId="badge-label"
                                    id="Badge"
                                    // label='Badge'
                                    // multiple
                                    placeholder='Badge'
                                    // defaultValue=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleQuestionOption}
                                    value={questionOption}
                                >
                                    <MenuItem value='1'>Opción Multiple - Varias válidas</MenuItem>
                                    <MenuItem value='2'>Opción Multiple - Una válida</MenuItem>
                                    <MenuItem value='3'>Verdadero o Falso</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className='pt-0 px-0 py-0'>
                            {/* <Tooltip title='Agregar Imagen'><Container>
                                <Stack direction="row" >
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                    >
                                        <input hidden accept="image/*" type="file" onChange={handleFileUpload} />
                                        <ImageIcon />
                                    </IconButton>
                                </Stack>
                            </Container></Tooltip> */}
                            <Box sx={{ position: 'relative', height: 50 }}>
                                <StyledSpeedDial
                                    ariaLabel="SpeedDial playground example"
                                    hidden={false}
                                    icon={<SpeedDialIcon />}
                                    direction='right'
                                >
                                    {questionActions.map((action) => (
                                        <SpeedDialAction
                                            key={action.name}
                                            icon={action.icon}
                                            tooltipTitle={action.name}
                                            onClick={action.onClick}
                                        />
                                    ))}
                                </StyledSpeedDial>
                                <input type='file' id='fileInput' style={{ display: 'none' }} onChange={handleFileUpload} />
                                <Dialog open={isCodeModalOpen} onClose={() => setIsCodeModalOpen(false)}>
                                    <DialogTitle>Enter Code</DialogTitle>
                                    <DialogContent>
                                        {/* <TextField
                                            autoFocus
                                            margin="dense"
                                            id="code"
                                            label="Code"
                                            type="text"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                        /> */}
                                        <Editor
                                            value={code}
                                            onValueChange={code => setCode(code)}
                                            highlight={code => highlight(code, languages.js)}
                                            padding={10}
                                            style={{
                                                fontFamily: '"Fira code", "Fira Mono", monospace',
                                                fontSize: 12,
                                                border: "0.5px solid",
                                                height: "200px",
                                                width: '500px'
                                            }}

                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setIsCodeModalOpen(false)}>Cancelar</Button>
                                        <Button onClick={handleCodeSave}>Guardar</Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </div>
                    </div>
                    <div className='w-full pl-6 pt-4'>
                        <p>Respuestas</p>
                    </div>
                    {questionOption[0] === '3' &&
                        <>
                            <div className='flex flex-row mt-4 pl-6 pr-6 pb-40'>
                                <div className='w-full'>
                                    <FormControl className='w-full'>
                                        <Select
                                            labelId='result'
                                            id='result'
                                            label='result'
                                            defaultValue='Select Result'
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            value={questionResult}
                                            onChange={handleChangeQuestionResult}
                                        >
                                            <MenuItem value='true'>true</MenuItem>
                                            <MenuItem value='false'>false</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                        </>
                    }
                    {questionOption[0] !== '3' && <>
                        <div className='flex flex-row mt-2 pl-6'>
                            <div className='w-10/12'>
                                <TextField
                                    id='question1'
                                    className='w-full'
                                    placeholder='Write response 1'
                                    value={response1}
                                    onChange={(e) => setResponse1(e.target.value)}
                                    inputProps={{ maxLength: 70 }}
                                />
                            </div>
                            <div className='w-2/12'>
                                <Switch
                                    checked={response1Checked}
                                    onChange={handleChangeResponse1Checked}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />

                            </div>
                        </div>
                        <div className='flex flex-row mt-2 pl-6'>
                            <div className='w-10/12'>
                                <TextField
                                    id='question2'
                                    className='w-full'
                                    placeholder='Write response 2'
                                    value={response2}
                                    onChange={(e) => setResponse2(e.target.value)}
                                    inputProps={{ maxLength: 70 }}
                                />
                            </div>
                            <div className='w-2/12'>
                                <Switch
                                    checked={response2Checked}
                                    onChange={handleChangeResponse2Checked}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />

                            </div>
                        </div>
                        <div className='flex flex-row mt-2 pl-6'>
                            <div className='w-10/12'>
                                <TextField
                                    id='question3'
                                    className='w-full'
                                    placeholder='Write response 3'
                                    value={response3}
                                    onChange={(e) => setResponse3(e.target.value)}
                                    inputProps={{ maxLength: 70 }}
                                />
                            </div>
                            <div className='w-2/12'>
                                <Switch
                                    checked={response3Checked}
                                    onChange={handleChangeResponse3Checked}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />

                            </div>
                        </div>
                        <div className='flex flex-row mt-2 pl-6'>
                            <div className='w-10/12'>
                                <TextField
                                    id='question4'
                                    className='w-full'
                                    placeholder='Write response 4'
                                    value={response4}
                                    onChange={(e) => setResponse4(e.target.value)}
                                    inputProps={{ maxLength: 70 }}
                                />
                            </div>
                            <div className='w-2/12'>
                                <Switch
                                    checked={response4Checked}
                                    onChange={handleChangeResponse4Checked}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />

                            </div>
                        </div>
                    </>
                    }
                    <div className='flex flex-row mt-4 justify-end'>
                        <button type='submit' className="justify-end w-64 px-14 py-4 bg-blue-900 rounded-full text-base font-medium text-white ml-2"
                            onClick={handleAddAchievement} disabled={(interviewQuestions.length > 4 && interviewQuestions2.length > 4 && interviewQuestions3.length > 4) ? true : false}>
                            {t('badges.save')}
                        </button>
                    </div>
                </Box >
            </Modal >

        </>
    )
}
