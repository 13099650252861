import React, { useEffect, useState, useTransition } from 'react'
import { Fab, Container, IconButton, Stack, FormControl, InputLabel, MenuItem, Select, TextField, Modal, Box, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useForm } from 'react-hook-form';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { FirebaseStorage } from '../../firebase/config';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const ProfilePages = ({ getPages }) => {
    const [open, setOpen] = React.useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [pages, setPages] = useState([])
    const { uid } = useSelector(state => state.auth);
    const [urlImageProfile, setUrlImageProfile] = useState('');
    const { t } = useTranslation();

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            website: "",
            linkedin: "",
            twitter: "",
        }
    });

    useEffect(() => {
        if (pages && urlImageProfile) {
            savePages(pages, urlImageProfile)
        }


    }, [pages, urlImageProfile])

    const savePages = (pages, urlImageProfile) => {
        const {
            behance,
            facebook,
            github,
            linkedin,
            twitter,
            website
        } = pages

        const profilePage = {
            behance,
            facebook,
            github,
            linkedin,
            twitter,
            website,
            profileImageUrl: urlImageProfile
        }
        getPages(profilePage);
    }

    const onSubmit = (data) => {
        let urlImagen = '';
        if (imageUrl) {
            const imageRef = ref(FirebaseStorage, `images/profile/${uid}`);
            uploadString(imageRef, imageUrl, 'data_url').then((snapshot) => {
                getDownloadURL(snapshot.ref).then((url) => {
                    setUrlImageProfile(url);
                    urlImagen = url;
                })
            }).catch((error) => {
                console.log('error', error);
            })

        }

        if (data) {
            setPages(data);
            handleClose();
            // limpiar();
        }
    }

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImageUrl(reader.result);
        };

        reader.readAsDataURL(file);
    };

    const limpiar = () => {
        setValue('website', "")
        setValue('twitter', "")
        setValue('linkedin', "")
        setValue('github', "")
        setValue('fabebook', "")
        setValue('behance', "")
    }

    return (
        <>
            <div className='flex flex-row mt-10 pl-4'>
                <div className='w-1/2 flex'>
                    <p className="text-zinc-800 text-[18px] font-bold">{t('pagesEmployee.pages')}</p>
                </div>
                <div className='flex w-1/2 justify-end items-end pr-5'>
                    <button onClick={() => handleOpen()}><img className=" w-5 h-5" src="../assets/icons/edit.png" alt='' /></button>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full mt-2 pl-6 text-left justify-center'>
                        <div className='w-full mt-2 justify-center text-center'>
                            <p className=' text-zinc-800 font-bold text-[18px]'>{t('pagesEmployee.pages')}</p>
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-center'>
                        {imageUrl &&
                            <img className="w-40" src={imageUrl} />
                        }
                        {!imageUrl &&
                            <img className="w-40" src="../assets/images/profile_default.png" />
                        }
                    </div>
                    <div className='flex flex-row items-center justify-center'>
                        {/* <input type='file' /> */}
                        <Container>
                            <Stack direction="row" alignItems="center" spacing={2} className='items-center justify-center'>
                                <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="label"
                                >
                                    <input hidden accept="image/*" type="file" onChange={handleFileUpload} />
                                    <PhotoCamera />
                                </IconButton>
                            </Stack>
                        </Container>
                    </div>
                    <div className='flex flex-row mt-6 pl-6 justify-between'>
                        <div className='w-full'>
                            <TextField
                                id='Website'
                                className='w-11/12'
                                placeholder={t('pagesEmployee.website')}
                                {...register("website")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-6 justify-between'>
                        <div className='w-full'>
                            <TextField
                                id='Linkedin'
                                className='w-11/12'
                                placeholder={t('pagesEmployee.linkedin')}
                                {...register("linkedin")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-6 justify-between'>
                        <div className='w-full'>
                            <TextField
                                id='Twitter'
                                className='w-11/12'
                                placeholder={t('pagesEmployee.twitter')}
                                {...register("twitter")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-6 justify-between'>
                        <div className='w-full'>
                            <TextField
                                id='Facebook'
                                className='w-11/12'
                                placeholder={t('pagesEmployee.facebook')}
                                {...register("facebook")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-6 justify-between'>
                        <div className='w-full'>
                            <TextField
                                id='Behance'
                                className='w-11/12'
                                placeholder={t('pagesEmployee.behance')}
                                {...register("behance")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-6 justify-between'>
                        <div className='w-full'>
                            <TextField
                                id='Github'
                                className='w-11/12'
                                placeholder={t('pagesEmployee.behance')}
                                {...register("github")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-10 justify-end'>
                        <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleSubmit(onSubmit)}>{t('pagesEmployee.save')}</button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
