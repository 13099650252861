import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";

export const loadEvaluations = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'evaluation'),
        // where('status', '==', 'Created')
    );

    const docs = await getDocs(q);

    const evaluations = [];
    docs.forEach(doc => {
        evaluations.push({ id: doc.id, ...doc.data() });
    })

    return evaluations;
}

export const loadEvaluationsCompany = async (idCompany = '') => {
    // if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'evaluation')
        // where('photoURL', '==', 'Employee')
    );

    const docs = await getDocs(q);

    const evaluations = [];
    docs.forEach(doc => {
        evaluations.push({ id: doc.id, ...doc.data() });
    })
    return evaluations;
}