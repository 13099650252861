import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setActiveJob } from '../../store/slices/job/jobSlice';
import { applyToJob, startLoadingJobById } from '../../store/slices/job/thunks';
import ReactPlayer from 'react-player'
import { setActiveProfile } from '../../store/slices/profile/profileSlice';
import { startLoadingProfile, startSaveProfile } from '../../store/slices/profile/thunks';
import { VideoInterview } from './VideoInterview';
import Chip from '@mui/material/Chip';
import Swal from 'sweetalert2';
import { startLoadingBadgesCatalog } from '../../store/slices/badgesCatalog/thunks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';


function currencySymbols(currency) {
    switch (currency) {
        case 'E':
            return '€';
        case 'D':
            return '$';
        case 'Q':
            return 'Q';
        default:
            return '';
    }
}


export const JobDetail = ({ onClick }) => {
    const { profile } = useSelector(state => state.profile);
    const { active } = useSelector(state => state.job);
    const [openInterview, setOpenInterview] = useState(false);
    const dispatch = useDispatch();
    const { id, companyName, description, companyId, maximum, minimum, currency, customJobTitle, jobModel, jobtitle, jobType, joblocation, locationCity, tagsJob, department, type, urlVideo, videoInterview, combinedArray } = active || {};
    const requiredBadges = active.combinedArray;
    const { uid } = useSelector(state => state.auth);
    const [applied, setApplied] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);
    const jobIdFromURL = params.get('id');
    const { id: jobIdFromParams } = useParams();
    const jobId = jobIdFromParams || jobIdFromURL;
    const Symbol = currencySymbols(currency);

    useEffect(() => {
        if (uid) {
            dispatch(startLoadingBadgesCatalog());

        }
    }, [])

    const closeDetail = () => {
        dispatch(setActiveJob({}));
        navigate('/home');
    }

    useEffect(() => {
        validateApplyNav();
    }, [profile, jobId]);

    useEffect(() => {
        if (jobId) {
            dispatch(startLoadingJobById(jobId));
            validateApply();
        }
    }, [dispatch, jobId]);

    const validateApply = () => {
        if (!profile || !profile[0]) {
            setApplied(false);
            return;
        }

        let tmpData = profile[0].applyingJob || [];
        let tmpData2 = tmpData.filter(item => item.jobId == jobId);
        setApplied(tmpData2.length > 0);
    }


    const validateApplyNav = () => {
        if (!profile || !profile[0]) {
            setApplied(false);
            return;
        }

        let tmpData = profile[0].applyingJob || [];
        let tmpData2 = tmpData.filter(item => item.jobId == id);
        setApplied(tmpData2.length > 0);
    }

    const onApply = () => {
        if (!uid) {
            navigate('/login')
            return
        }

        let tmpJobId = jobId;

        if (tmpJobId === null) {
            tmpJobId = active.id;
        }

        let applyingJobTmp = [];
        const addApplyingJob = {
            jobId: tmpJobId,
            companyId: companyId,
            date: Date.now(),
            stage: 1,
            status: 'Active',
            summaryManualFiltering: '',
            urlVideoInterview: '',

        }

        if (profile && profile[0].applyingJob) {
            let tmpData = profile[0].applyingJob;
            tmpData.map(item => {
                applyingJobTmp.push(item);
            });
        }
        applyingJobTmp.push(addApplyingJob);

        const newData = {
            id: profile[0].id,
            uid,
            applyingJob: applyingJobTmp
        }


        dispatch(setActiveProfile(newData))
        if (newData) {
            dispatch(startSaveProfile());
            Swal.fire('¡Has aplicado a este trabajo de manera exitosa!', 'Para revisar el estado del empleo, dirígete al menú Jobs.', 'success')
        }
        dispatch(startLoadingProfile());

    }

    const handleOpenInterview = () => {
        if (!uid) {
            navigate('/login')
            return
        }
        setOpenInterview(true);
    }

    const onCloseDetailVideoInterview = () => {
        setOpenInterview(false);
    }


    return (
        <>
            {openInterview &&
                <VideoInterview onClickVideo={onCloseDetailVideoInterview} data={videoInterview} />
            }
            {!openInterview &&
                <div className='w-full bg-white  min-h-[720px]'>
                    <div className='w-full flex flex-row pt-4 h-20'>
                        <div className='w-1/12 pt-2'>
                            <button onClick={() => closeDetail()}><img className="w-6 h-6 rounded-mx" src="../assets/icons/left-arrow-from-left.png" alt='' /></button>
                        </div>
                        <div className='w-8/12'>
                            <div className='w-full flex flex-row'>
                                <p className="absolute text-2xl font-bold text-gray-800">{jobtitle}</p>
                            </div>
                            <div className='w-full flex flex-row pt-8'>
                                <p className="text-gray-600 text-xs font-normal"> {customJobTitle}, {jobType} || {joblocation}, {locationCity} </p>
                            </div>
                        </div>
                        <div className='w-3/12 flex '>
                            <div className='w-1/5 justify-end pt-1 pl-4 items-end text-right'>{/* <img className='w-6 h-6 rounded-full border' src='../assets/icons/star.png' alt='' />*/}</div>
                            <div className="w-4/5  items-left justify-left text-left">
                                {applied &&
                                    <button className=" w-28 h-8 text-sm bg-[#bebebe] font-medium rounded-full text-center text-white" disabled>Applied</button>
                                }
                                {!applied &&
                                    <button className=" w-28 h-8 text-sm bg-[#FF7F11] font-medium rounded-full text-center text-white" onClick={onApply}>Apply</button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-2 h-16 pl-24'>
                        <div className='w-1/2'>
                            <div className='w-full flex flex-row'>
                                <div className='2/6 pt-2'>
                                    <img src='../assets/icons/money-stack.png' alt='' />
                                </div>
                                <div className='4/6 pl-2'>
                                    <div className='w-full flex flex-row'>
                                        <p className="text-black text-base font-medium">
                                            {`${Symbol}${minimum} to ${Symbol}${maximum}`}
                                        </p>
                                    </div>
                                    <div className='w-full flex flex-row'>
                                        <p className="text-grey-800 text-xm font-normal">{companyName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <div className='w-full flex flex-row justify-center'>
                                <div className="w-96 h-16 flex opacity-90 bg-white rounded-3xl border border-zinc-500">
                                    <div className='flex flex-wrap w-1/3 pt-6 pl-3'>
                                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">Required Badges</p>
                                    </div>
                                    <div className='flex  w-2/3 justify-end pr-4'>
                                        {requiredBadges?.map((job, index) => (
                                            <div key={index} className='pt-1 pr-1'>
                                                <Tooltip title={job.name}><img className=' w-9 h-9 mt-3 rounded-full' src={job.imageUrl} /></Tooltip>
                                            </div>
                                        ))
                                        }
                                    </div>
                                    {/* <div className='flex  w-2/3 justify-end pr-4'>
                                        <div className='pt-1'>
                                            <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=c6f44aae-7902-45d1-b48f-fb097b8a0912" />
                                        </div>
                                        <div className='pt-1 pl-2'>
                                            <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%203.png?alt=media&token=45b35fbf-9ff1-45f9-a048-63d328284a7c" />
                                        </div>
                                        <div className='pt-1 pl-2'>
                                            <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%204.png?alt=media&token=1ec44765-7d7a-4d56-8e04-c0976483e366" />
                                        </div>
                                        <div className='pt-1 pl-2'>
                                            <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%205.png?alt=media&token=260be5f7-9283-4104-a958-831df00d71dc" />
                                        </div>
                                        <div className='pt-1 pl-2'>
                                            <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%206.png?alt=media&token=19cb9f22-4cae-4903-90c9-137dd3e3ab7c" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {tagsJob && Array.isArray(tagsJob) && tagsJob.length > 0 && (
                        <div className='mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20 2xl:mx-24 mt-4'>
                            <div className="text-black text-base font-medium text-left">tags del puesto</div>
                            <div className='flex flex-wrap gap-2'>
                                {tagsJob.map((tag, index) => (
                                    <Chip
                                        key={index}
                                        label={tag}
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    <div className='w-full flex flex-row pt-8 justify-center'>
                        <div className='w-[640px] h-[360px] bg-gray-400'>
                            {urlVideo ? (
                                <ReactPlayer url={urlVideo} controls={true} />
                            ) : (
                                <img src="../assets/images/no-video.png" className="w-full h-full object-cover"></img>
                            )}
                        </div>
                    </div>

                    {/* {videoInterview &&
                        // <VideoInterview data={videoInterview} />
                        <div className='w-full justify-center items-center pt-4'>
                            <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                                onClick={handleOpenInterview}>
                                Hacer entrevista
                            </button>
                        </div>
                    } */}
                    <div className='w-full flex flex-row pt-8 pl-20'>
                        <div className="text-black text-base font-medium">Job description:</div>
                    </div>
                    <div className='w-full flex flex-row pt-3 pl-20 pr-20'>
                        <div className=" font-['Poppins'] w-full text-justify text-[#818181] text-xs/[12px] font-normal leading-5 min-h-[50px] " dangerouslySetInnerHTML={{ __html: description }}>
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-8 pl-20'></div>

                    {/* <div className='w-full flex flex-row pt-8 pl-20'>
                        <div className="text-black text-base font-medium">Benefits</div>
                    </div>
                   <div className='w-full flex flex-row pt-3 pl-20 pr-20'>
                        <p className=" font-['Poppins'] w-full text-justify text-[#818181] text-xs/[12px] font-normal leading-5">
                            
                        </p>
                    </div>
                    <div className='w-full flex flex-row pt-8 pl-20'>
                        <div className="text-black text-base font-medium">Why Company</div>
                    </div>
                    <div className='w-full flex flex-row pt-3 pl-20 pr-20 pb-20'>
                        <p className=" font-['Poppins'] w-full text-justify text-[#818181] text-xs/[12px] font-normal leading-5 min-h-[50px]">

                        </p>
                    </div>
                */}
                </div>
            }
        </>
    )
}