import { collection, deleteDoc, doc, setDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from "../../../firebase/config";
import { addNewEmptyEvaluation, deleteEvaluationById, savingNewEvaluation, setActiveEvaluation, setEvaluations, setSaving, updateEvaluation } from "./evaluationSlice";
import { loadEvaluationsCompany } from '../../../helpers/loadEvaluation';


export const startNewClearEvaluation = (evaluation) => {
    return async (dispatch, getState) => {
        dispatch(savingNewEvaluation());
        const { uid } = getState().auth;

        const {
            evaluationName,
            description,
            badge,
            // Tiempo validez Evaluación
            evaluationPeriod,
            evaluationTime,
            durationTime,
            // Max. intentos
            evaluationAttempts,
            //Tiempo validez intentos
            attemptsTime,
            validez,
            // Data Componentes    
            multipleTrueOrFalse,
            multipleOneAnswer,
            multipleManyAnswers
        } = evaluation;

        const newEvaluation = {
            uid,
            evaluationName,
            description,
            badge,
            evaluationPeriod,
            evaluationTime,
            durationTime,
            evaluationAttempts,
            attemptsTime,
            validez,
            multipleTrueOrFalse,
            multipleOneAnswer,
            multipleManyAnswers,
            fechaCreaction: new Date().getTime(),
            fechaModificacion: new Date().getTime()
        }
        const newDoc = doc(collection(FirebaseDB, `evaluation`))
        await setDoc(newDoc, newEvaluation);
        newEvaluation.id = newDoc.id;
        dispatch(addNewEmptyEvaluation(newEvaluation));
        dispatch(setActiveEvaluation(newEvaluation));
    }
}

export const startNewEvaluation = (evaluation) => {

    return async (dispatch, getState) => {
        dispatch(savingNewEvaluation());
        const { uid } = getState().auth;

        const {
            evaluationName,
            description,
            badge,
            evaluationPeriod,
            evaluationTime,
            durationTime,
            companyId,
            evaluationAttempts,
            attemptsTime,
            validez,
            multipleTrueOrFalse,
            multipleOneAnswer,
            multipleManyAnswers
        } = evaluation

        const newEvaluation = {
            uid,
            evaluationName,
            description,
            badge,
            evaluationPeriod,
            evaluationTime,
            durationTime,
            companyId,
            evaluationAttempts,
            attemptsTime,
            validez,
            multipleTrueOrFalse,
            multipleOneAnswer,
            multipleManyAnswers,
            fechaCreaction: new Date().getTime(),
            fechaModificacion: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `evaluation`))
        await setDoc(newDoc, newEvaluation);
        newEvaluation.id = newDoc.id;
        dispatch(addNewEmptyEvaluation(newEvaluation));
        dispatch(setActiveEvaluation(newEvaluation));
    }
}


export const startSaveEvaluation = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        
        const { active: evaluation } = getState().evaluation;

        const evaluationToFireStore = { ...evaluation };
        delete evaluationToFireStore.id;

        const docRef = doc(FirebaseDB, `evaluation/${evaluation.id}`)
        await setDoc(docRef, evaluationToFireStore, { merge: true });
        dispatch(updateEvaluation(evaluation));
    }
}

export const startLoadingEvaluations = (idCompany = '') => {
    return async (dispatch, getState) => {

        const evaluations = await loadEvaluationsCompany(idCompany);
        dispatch(setEvaluations(evaluations));
    }

}



export const startDeleteEvaluation = (evaluationId) => {
    return async (dispatch) => {
        try {
            // Eliminar la evaluación de Firestore
            await deleteDoc(doc(FirebaseDB, `evaluation/${evaluationId}`));

            // Despachar la acción para actualizar el estado
            dispatch(deleteEvaluationById(evaluationId));

            // Si necesitas actualizar la lista de evaluaciones después de la eliminación, puedes cargarlas nuevamente
            // Por ejemplo, si la evaluación eliminada era parte de una lista de evaluaciones, es posible que desees recargarlas
            // dispatch(startLoadingEvaluations());
        } catch (error) {
            console.error("Error al eliminar la evaluación:", error);
        }
    }
}
