import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Fab, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import ReactPlayer from 'react-player'
import Moment from 'react-moment';
import Chip from '@mui/material/Chip';
import { startLoadingBadgeCategories } from '../../store/slices/badgesCategories/thunks';
import moment from 'moment';
import { startLoadingEarnBadge } from '../../store/slices/earnBadge/thunks';
import { startLoadingEmployeeProfiles, startLoadingProfile } from '../../store/slices/profile/thunks';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

export const PreviewProfile = ({ onClick, data }) => {
    const { badgesCatalogs } = useSelector(state => state.badgesCatalog);
    const { badgesCategories } = useSelector(state => state.badgesCategorie);
    const { earnBadges } = useSelector(state => state.earnBadge)
    const dispatch = useDispatch();
    const { email } = useSelector(state => state.auth);
    const { id } = useSelector(state => state.auth);
    const [name, setName] = useState('');
    const [jobLocation, setJobLocation] = useState('');
    const [bio, setBio] = useState('');
    const [experience, setExperience] = useState([]);
    const [education, setEducation] = useState([]);
    const [certificate, setCertificate] = useState([]);
    const [achievement, setAchievement] = useState([]);
    const [salary, setSalary] = useState('');
    const [page, setPage] = useState({});
    const [urlVideo, setUrlVideo] = useState('');
    const [basicFitBadges, setBasicFitBadges] = useState([]);
    const [compatibilityBadges, setCompatibilityBadges] = useState([]);
    const [skillBadges, setSkillBadges] = useState([]);
    // const [urlVideoLocal, setUrlVideoLocal] = useState('');
    const [phone, setPhone] = useState('');
    const [tagsEmployee, setTagsEmployee] = useState([]);
    const [myEarnBadges, setMyEarnBadges] = useState([]);
    const [myTopFiveEarnBadges, setMyTopFiveEarnBadges] = useState([]);
    const { t } = useTranslation();


    const closeUpdateProfile = () => {
        onClick(false);
    }

    useEffect(() => {
    }, [urlVideo])

    useEffect(() => {
        if (data.length) {
            setName(data[0].name);
            setJobLocation(data[0].joblocation)
            setBio(data[0].bio);
            setExperience(data[0].experience);
            setPage(data[0].pages);
            setEducation(data[0].education);
            setCertificate(data[0].certificate);
            setSalary(data[0].salary);
            setUrlVideo(data[0].urlVideo)
            // setUrlVideoLocal(data[0].urlVideoLocal);
            setPhone(data[0].phone);
            setTagsEmployee(data[0].tagsEmployee)
        }
    }, [data])

    useEffect(() => {
        dispatch(startLoadingBadgeCategories());
        dispatch(startLoadingEarnBadge());
        dispatch(startLoadingEmployeeProfiles());
        dispatch(startLoadingProfile());
    }, [])

    useEffect(() => {
        let tmpBadges = [];
        let tmpEarnBadges = [];

        tmpEarnBadges = badgesCatalogs.filter(x => earnBadges.some(y => y.id_badge == x.id));
        setMyEarnBadges(tmpEarnBadges);
        tmpBadges = badgesCatalogs.filter(x => x.id)
    }, [badgesCatalogs])

    useEffect(() => {
        const sortedBadges = myEarnBadges.sort((a, b) => (b.imageUrl ? 1 : -1));
        const tmpTopFiveBadges = sortedBadges.slice(0, 5);

        setMyTopFiveEarnBadges(tmpTopFiveBadges);
    }, [myEarnBadges])

    useEffect(() => {
        let tmpBasicFit = [];
        let tmpSkillAssesment = [];
        let tmpCompatility = [];
        if (badgesCatalogs && badgesCategories) {
            let tmpBasicFitCategorie = badgesCategories.filter(x => x.name === 'Basic Fit');
            let tmpSkillAssesmentCategorie = badgesCategories.filter(x => x.name === 'Skill-Assessment');
            let tmpCompatibilityCategorie = badgesCategories.filter(x => x.name === 'Compatibility');
            if (tmpBasicFitCategorie.length > 0) {
                tmpBasicFit = badgesCatalogs.filter(x => x.categorie === tmpBasicFitCategorie[0].id);
                setBasicFitBadges(tmpBasicFit);
            }
            if (tmpBasicFitCategorie.length > 0) {
                tmpSkillAssesment = badgesCatalogs.filter(x => x.categorie === tmpSkillAssesmentCategorie[0].id);
                setSkillBadges(tmpSkillAssesment);
            }
            if (tmpBasicFitCategorie.length > 0) {
                tmpCompatility = badgesCatalogs.filter(x => x.categorie === tmpCompatibilityCategorie[0].id);
                setCompatibilityBadges(tmpCompatility);
            }
        }
    }, [badgesCatalogs])


    const shareProfile = () => {
        const currentUrl = window.location.href;
        if (currentUrl.includes("#/profile")) {
            const newUrl = currentUrl.replace("#/profile", `#/candidates/${data[0].id}`);
            navigator.clipboard.writeText(newUrl)
                .then(() => {
                    Swal.fire({
                        title: 'Compartir',
                        text: '¡Enlace del perfil copiado correctamente!',
                        icon: 'success'
                    });
                })
                .catch(err => {
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un error al copiar el enlace al Portapapeles',
                        icon: 'error'
                    });

                });
        } else {

            console.log("La URL actual no contiene '#/profile'.");
        }
    };

    return (
        <>
            <div className='flex flex-row w-full pl-6 justify-end pr-14'>
                <div className='w-52 flex h-9'>
                    <button onClick={closeUpdateProfile} className='w-full h-full bg-white flex-wrap justify-start text-left pl-10 rounded-[32px] border border-slate-800 text-[16px] font-medium text-slate-800'>
                        {t('profileEmployee.edit')}
                    </button>
                    <img className="w-5 h-5 ml-36 mt-2 absolute pointer-events-none" src='../assets/icons/edit.png' alt='' />
                </div>
                <div className='pl-4 flex w-36 h-9'>
                    <button onClick={shareProfile} className='w-full h-full bg-orange-500 rounded-3xl justify-start text-left pl-6 text-white text-[14px] font-medium'>
                        Share
                    </button>
                    <img className="w-6 h-6 ml-20 mt-1 absolute" src='../assets/icons/share.png' alt='' />
                </div>
            </div>
            <div className='flex flex-row w-full mt-12 pl-2'>
                <div className='flex flex-1 w-full'>
                    <div className='w-3/12'>
                        <div className='border-r h-2/5'>
                            <div className='flex flex-row'>
                                {page.profileImageUrl &&
                                    <img className="w-48 h-48 mx-auto rounded-full object-cover" src={page.profileImageUrl} alt='' />
                                }
                                {!page.profileImageUrl &&
                                    <img className="w-48" src="../assets/images/profile_default.png" alt='' />
                                }
                            </div>
                            <div className='flex flex-row mt-10 pl-2'>
                                <p className="text-zinc-800 text-[18px] font-bold">{t('profileEmployee.info')}</p>
                            </div>
                            <div className='flex flex-row mt-4 pl-2'>
                                <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                                <div className="pl-1 text-sky-900 text-[12px] font-normal">{email}</div>
                            </div>
                            <div className='flex flex-row mt-4 pl-2'>
                                <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                                {page.website &&
                                    <div className="pl-1 text-sky-900 text-[12px] font-normal">{phone}</div>
                                }
                                {!page.website &&
                                    <div className="pl-1 text-sky-900 text-[12px] font-normal">{t('profileEmployee.phone')}</div>
                                }
                            </div>
                            <div className='flex flex-row mt-10 pl-2'>
                                <p className="text-zinc-800 text-[18px] font-bold">{t('profileEmployee.pages')}</p>
                            </div>
                            <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                                {page.website &&
                                    <div className="pl-1 text-sky-900 text-[12px] font-normal text-left"><a href={(page.website) ? page.website : 'Add website'} target="_blank">{page.website}</a></div>
                                }
                                {!page.website &&
                                    <div className="pl-1 text-sky-900 text-[12px] font-normal"><a href={(page.website) ? page.website : 'Add website'} target="_blank">{t('profileEmployee.website')}</a></div>
                                }
                            </div>
                            <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/linkedin-alt.png" alt='' />
                                {page.linkedin &&
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal text-left"><a href={page.linkedin} target="_blank">{page.linkedin}</a></div>
                                }
                                {!page.linkedin &&
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.linkedin} target="_blank">{t('profileEmployee.linkedin')}</a></div>
                                }
                            </div>
                            <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/twitter.png" alt='' />
                                {page.twitter &&
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal text-left"><a href={page.twitter} target="_blank">{page.twitter}</a></div>
                                }
                                {!page.twitter &&
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.twitter} target="_blank">{t('profileEmployee.twitter')}</a></div>
                                }
                            </div>
                            <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/facebook.png" alt='' />
                                {page.facebook &&
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal text-left"><a href={page.facebook} target="_blank">{page.facebook}</a></div>
                                }
                                {!page.facebook &&
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.facebook} target="_blank">{t('profileEmployee.facebook')}</a></div>
                                }
                            </div>
                            <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/behance.png" alt='' />
                                {page.behance &&
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal text-left"><a href={page.behance} target="_blank">{page.behance}</a></div>
                                }
                                {!page.behance &&
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.behance} target="_blank">{t('profileEmployee.behance')}</a></div>
                                }
                            </div>
                            <div className='flex flex-row mt-4 pl-2 overflow-hidden'>
                                <img className='w-5 h-5' src="../assets/icons/github.png" alt='' />
                                {page.github &&
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal text-left"><a href={page.github} target="_blank">{page.github}</a></div>
                                }
                                {!page.github &&
                                    <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.github} target="_blank">{t('profileEmployee.github')}</a></div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='w-9/12 pl-6 pr-6'>
                        <div className='flex flex-row w-full'>
                            <div className='w-1/2'>
                                <div className='flex flex-row w-full'>
                                    <p className="text-[#2B2C2B] font-['Poppins'] text-lg">{name}</p>
                                </div>
                                <div className='flex flex-row w-full'>
                                    <p className="text-[#0D497D] text-xs font-['Poppins'] font-bold">{jobLocation}</p>
                                </div>
                            </div>
                            <div className='w-1/2 justify-end'>
                                <div className='flex flex-row w-11/12 h-16 justify-between' style={{ background: 'white', borderRadius: 42, borderLeft: '0.50px #818181 solid', borderTop: '0.50px #818181 solid', borderRight: '0.50px #818181 solid', borderBottom: '0.50px #818181 solid' }}>
                                    <div className='flex w-4/12'>
                                        {/* <div style={{ left: 29, top: 20, position: 'absolute', color: '#2B2C2B', fontSize: 14, fontFamily: 'Poppins', fontWeight: '500', wordWrap: 'break-word' }}>Top Badges</div> */}
                                        <p className="pt-5 pl-4 text-[#2B2C2B] text-[14px] font-['Poppins'] font-medium">{t('profileEmployee.topbadges')}</p>
                                    </div>
                                    <div className='flex w-8/12'>
                                        {myTopFiveEarnBadges?.map((item, index) =>

                                            <div key={index} className='flex w-2/12 pt-3 pl-1'>
                                                <Tooltip title={item.name}><img className='flex w-9 h-9 rounded-full' src={item?.imageUrl} alt='' /></Tooltip>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row w-full pt-9 justify-center'>
                            <div className='w-[640px] h-[360px] bg-gray-400'>
                                {!urlVideo &&
                                    <img src='../assets/images/Video.png' alt='' />
                                }
                                {urlVideo &&
                                    <ReactPlayer url={urlVideo} controls={true} />
                                }
                                {/* {!urlVideo && !urlVideoLocal &&
                                    <ReactPlayer url='https://www.youtube.com/watch?v=LVbUNRwpXzw' />
                                } */}
                                {/* {!urlVideo && urlVideoLocal &&
                                    <ReactPlayer url={urlVideoLocal} controls={true} />
                                } */}
                            </div>
                        </div>
                        {tagsEmployee && tagsEmployee.length > 0 && (
                            <div className='mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20 2xl:mx-24 mt-4'>
                                <div className="text-black text-base font-medium text-left">{t('profileEmployee.tags')}</div>
                                <div className='flex flex-wrap gap-2'>
                                    {tagsEmployee.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            label={tag}
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* <div className='flex w-full mt-14 pl-6'> */}
            <div className='flex flex-row w-full mt-14 pl-6 text-left'>
                <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full">{t('profileEmployee.about')}</p>
            </div>
            <div className='flex flex-row w-full mt-4 pl-6 pr-6'>
                <p className=" font-['Poppins'] w-full text-justify text-[#818181] text-xs/[12px] font-normal leading-5 min-h-[50px]">
                    {bio}
                </p>
            </div>
            <div className='flex flex-row w-full text-left pl-6 mt-14'>
                <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full">{t('profileEmployee.experience')}</p>
            </div>
            {experience.map((item, index) => (
                <div key={index}>
                    <p className="text-black text-left pl-6 mt-4">
                        {item.company}
                    </p>
                    <div key={index} className='flex flex-row w-full mt-3 pl-6'>
                        {item.currentJob &&

                            <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">
                                {item.startDate && <Moment format='YYYY'>{moment(item.startDate, 'DD/MM/YYYY')}</Moment>}  |
                                {' '}To Date  | {' '}{item.jobTitle}

                            </p>
                        }
                        {!item.currentJob &&
                            <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">
                                {item.startDate && <Moment format='YYYY'>{moment(item.startDate, 'DD/MM/YYYY')}</Moment>}  |
                                {' '}{item.endDate && <Moment format='YYYY'>{moment(item.endDate, 'DD/MM/YYYY')}</Moment>}  |
                                {' '}{item.jobTitle}
                            </p>
                        }
                    </div>
                    <div className='flex flex-row w-full pl-6 mt-3'>
                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">{t('profileEmployee.summary')}</p>
                    </div>
                    <div className='flex flex-row w-full mt-2 pl-6 pr-6'>
                        <p className=" font-['Poppins'] w-full text-justify text-[#818181] text-xs/[12px] font-normal leading-5 min-h-[90px]">
                            {item.summary}
                        </p>
                    </div>
                    <div className='flex flex-row w-full pl-6 justify-start'>
                        <div className='w-7/12'>
                            <div className='flex flex-row w-full'>
                                <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">{t('profileEmployee.logros')}</p>
                            </div>
                            {Array.isArray(item.Achievements) && item.Achievements.map((logro, index) => (
                                <div key={index} className='flex flex-row w-full pt-2'>
                                    <p className="text-[#818181] text-xs font-['Poppins'] font-normal leading-5 break-words">{logro}</p>
                                </div>
                            ))}
                            {/* {item.Achievements[0] &&
                                <div className='flex flex-row w-full pt-2'>
                                    <p className="text-[#818181] text-xs font-['Poppins'] font-normal leading-5 break-words">{item.Achievements[0]}</p>
                                </div>
                            } */}
                        </div>
                        {/*<div className='w-5/12'>
                            <div className='flex flex-row w-full'>
                                <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">Applied Badges</p>
                            </div>
                            <div className='flex flex-row w-full justify-start'>
                                <div className='pt-1'>
                                    <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=bbc1b273-e6b3-4a11-ad86-58b89611de36" alt='' /> 
                                </div>
                                <div className='pt-1 pl-2'>
                                    <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=bbc1b273-e6b3-4a11-ad86-58b89611de36" alt='' /> 
                                </div>
                                <div className='pt-1 pl-2'>
                                    <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=bbc1b273-e6b3-4a11-ad86-58b89611de36" alt='' /> 
                                </div>
                                <div className='pt-1 pl-2'>
                                    <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=bbc1b273-e6b3-4a11-ad86-58b89611de36" alt='' /> 
                                </div>
                                <div className='pt-1 pl-2'>
                                    <img className='flex w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=bbc1b273-e6b3-4a11-ad86-58b89611de36" alt='' /> 
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            ))
            }
            <div className='flex flex-row w-full text-left pl-6 mt-11'>
                <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full">{t('profileEmployee.education')}</p>
            </div>
            {education.map((item, index) => (
                <div key={index} className='flex flex-row w-full pl-6 mt-5'>
                    <p className="text-[#0D497D] text-xs font-normal break-words">
                        {item.startDate && <Moment format='YYYY'>{moment(item.startDate, 'DD/MM/YYYY')}</Moment>}-
                        {item.endDate && <Moment format='YYYY'>{moment(item.endDate, 'DD/MM/YYYY')}</Moment>}
                    </p>
                    <p className='text-[#0D497D] text-xs font-normal break-words pl-3'>|</p>
                    <p className="text-[#0D497D] text-xs font-normal break-words pl-3">{item.title}</p>
                    <p className='text-[#0D497D] text-xs font-normal break-words pl-3'>|</p>
                    <p className="text-[#0D497D] text-xs font-normal break-words pl-3">{item.institution[0]}</p>
                </div>
            ))

            }
            <div className='flex flex-row w-full text-left pl-6 mt-10'>
                <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full">{t('profileEmployee.certificates')}</p>
            </div>
            {certificate.map((item, index) => (
                <div key={index} className='flex flex-row w-full pl-6 mt-1'>
                    <div className='flex flex-row w-full mt-3'>
                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-normal break-words">{item.certificateName}</p>
                        <p className='text-[#0D497D] text-xs font-normal break-words pl-3'>|</p>
                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-normal break-words pl-3">{item.institutionName}</p>
                    </div>
                </div>
            ))
            }

            <div className='flex flex-row w-full text-left pl-6 mt-8'>
                <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full">{t('profileEmployee.badges')}</p>
            </div>
            <div className='flex flex-row row-span-1 w-full min-h-[100px pl-6'>
                <div className='flex flex-wrap w-full justify-start'>
                    {myEarnBadges.map((item, index) => (

                        <div key={index} className='mt-3 '>
                            <img className='w-9 h-7 pl-2 rounded-full' src={item.imageUrl} alt={item.name} />
                        </div>

                    ))}
                </div>
            </div>
            {/* <div className='flex flex-row row-span-1 w-full min-h-[100px pl-6'>
                <div className='flex flex-wrap w-full justify-start'>
                    <img className='w-9 h-7 mt-3 pl-2' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=bbc1b273-e6b3-4a11-ad86-58b89611de36" />
                    <img className='w-9 h-7 mt-3 pl-2' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=bbc1b273-e6b3-4a11-ad86-58b89611de36" />
                    <img className='w-9 h-7 mt-3 pl-2' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=bbc1b273-e6b3-4a11-ad86-58b89611de36" />
                </div>
            </div> */}
            <div className='flex flex-row w-full pl-6 mt-12 justify-start'>
                <div className='w-10/12'>
                    <div className='flex flex-row w-full'>
                        <p className="text-[#2B2C2B] text-lg font-['Poppins'] font-bold break-words">{t('profileEmployee.salary')}</p>
                    </div>
                    <div className='flex flex-row w-full'>
                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-normal break-words">$ {salary} Anual</p>
                    </div>
                </div>
            </div>
        </>
    )
}
