import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux';
import { TimerEvaluation } from '../../components/Employee/Evaluation/TimerEvaluation';
import { Question } from './Question';
import Summary from './Summary';


export const Quiz = ({ onClick }) => {
    const [userAnswers, setUserAnswers] = useState([]);
    const [userResponses, setUserResponses] = useState([]);
    const { active } = useSelector(state => state.evaluation);
    const arrayTmp = [...active.multipleManyAnswers, ...active.multipleOneAnswer];
    const activeQuestionIndex = userAnswers.length;
    const quizIsComplete = activeQuestionIndex === arrayTmp.length;
    const [showTimer, setShowTimer] = useState(false);
    const [step, setStep] = useState(0);

    console.log('arrayTmp', arrayTmp);

    const handleSelectAnswer = useCallback(
        function handleSelectAnswer(selectedAnswer) {
            setUserAnswers((prevUserAnswers) => {
                return [...prevUserAnswers, selectedAnswer];
            });

        }, []);

    const handleSelectResponse = useCallback(
        function handleSelectResponse(selectedResponse) {
            setUserResponses((prevUserResponse) => {
                return [...prevUserResponse, selectedResponse];
            });

        }, []);

    const handleSkipAnswer = useCallback(
        () => handleSelectAnswer(null),
        [handleSelectAnswer]
    );

    const finalizeEvaluation = () => {
        // setStep(6);
        setShowTimer(false);
        onClick(true);
    }

    if (quizIsComplete) {
        return <Summary userAnswers={userAnswers} userResponses={userResponses} onClick={finalizeEvaluation} />
    }

    const startInterview = () => {
        setStep(1);
        setShowTimer(true);
        setStep((state) => {
            return state;
        })
    }

    return (
        <>
            <div className='flex flex-col bg-white w-full place-items-center h-[900px]'>

                {showTimer &&
                    <TimerEvaluation evaluationTime={active?.durationTime} onClick={finalizeEvaluation} />
                }
                {step == 0 &&
                    <div className='pt-20'>
                        <p className='pt-10'>Estas por iniciar la evaluación, asegurate de disponer del tiempo para completarla, al darle iniciar ya no podrás pausar la evaluación. </p>
                        <div className='flex flex-row mt-10 justify-center'>
                            <button type="button" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => startInterview()}>Iniciar</button>
                        </div>

                    </div>
                }
                {step == 1 &&
                    <>
                        {/* <Question
                            key={activeQuestionIndex}
                            index={activeQuestionIndex}
                            onSelectAnswer={handleSelectAnswer}
                            onSkipAnswer={handleSkipAnswer}

                        /> */}
                        <Question
                            key={activeQuestionIndex}
                            index={activeQuestionIndex}
                            onSelectAnswer={handleSelectAnswer}
                            onSelectResponse={handleSelectResponse}
                            onSkipAnswer={handleSkipAnswer}

                        />
                    </>
                }
                {/* {step == 2 &&
                    <>
                        <div className='pt-20'>
                            <p className='pt-10'>Estas por iniciar las evaluaciones de Selección multiple.</p>
                            <div className='flex flex-row mt-10 justify-center'>
                                <button type="button" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => startInterview()}>Iniciar</button>
                            </div>

                        </div>
                    </>
                } */}
            </div>
        </>
    )
}
