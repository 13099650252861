import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";


export const loadProfiles = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'profile'),
        // where('status', '==', 'Created')
    );

    const docs = await getDocs(q);

    const profiles = [];
    docs.forEach(doc => {
        profiles.push({ id: doc.id, ...doc.data() });
    })

    return profiles;
}

export const loadEmployeeProfiles = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'profile'),
        where('photoURL', '==', 'Employee')
    );

    const docs = await getDocs(q);

    const profiles = [];
    docs.forEach(doc => {
        profiles.push({ id: doc.id, ...doc.data() });
    })
    return profiles;
}

export const loadProfile = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'profile'),
        where('uid', '==', uid)
    );

    const docs = await getDocs(q);

    const profile = [];
    docs.forEach(doc => {
        profile.push({ id: doc.id, ...doc.data() });
    })
    return profile;
}