import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import { CompanyProfile } from '../components/Employer/Profile/CompanyProfile';
import { TeamMembers } from '../components/Employer/Profile/TeamMembers';
import { Billing } from '../components/Employer/Profile/Billing';
import { UpdateCompanyProfile } from '../components/Employer/Profile/UpdateCompanyProfile';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingCompanyProfile } from '../store/slices/companyProfile/thunks';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ProfileEmployer = () => {
    const dispatch = useDispatch();
    const { profile } = useSelector(state => state.profile);
    const { companyProfile } = useSelector(state => state.companyProfile)
    const [value, setValue] = React.useState(0);
    const [previewProfile, setPreviewProfile] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        if (profile.length > 0) {
            let idCompany = profile[0].idCompany;
            if (idCompany.length > 0) {
                dispatch(startLoadingCompanyProfile(idCompany));
            }
        }
    }, [profile])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const changetoPreviewProfile = () => {
        setPreviewProfile(true);
    }

    const changetoUpdateProfile = () => {
        setPreviewProfile(false);
    }


    return (
        <div className='flex flex-col bg-white w-full place-items-center min-h-[780px]'>
            <div className='w-full mt-8 ml-12'>
                <Box sx={{ width: '95%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={t('profileEmployer.companyProfile')} {...a11yProps(0)} />
                            <Tab label={t('profileEmployer.teamMembers')} {...a11yProps(1)} />
                            {/*<Tab label={t('profileEmployer.billing')} {...a11yProps(2)} />*/}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {previewProfile &&
                            <CompanyProfile onClick={changetoUpdateProfile} data={companyProfile} />
                        }
                        {!previewProfile &&
                            <UpdateCompanyProfile onClick={changetoPreviewProfile} data={companyProfile} />
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <TeamMembers />
                    </TabPanel>
                    {/*<TabPanel value={value} index={2}>
                        <Billing />
                    </TabPanel>*/}
                </Box>
            </div>
        </div>
    )
}
