import { configureStore } from '@reduxjs/toolkit'
import { authSlice } from './auth/authSlice'
import { jobSlice } from './slices/job/jobSlice'
import { profileSlice } from './slices/profile/profileSlice'
import { applyingjobSlice } from './slices/applyingJobs/applyingjobSlice'
import { activeJobsSlice } from './slices/activeJobs/activeJobsSlice'
import { companyProfileSlice } from './slices/companyProfile/companyProfileSlice'
import { badgesCatalogSlice } from './slices/badgesCatalog/badgesCatalogSlice'
import { evaluationSlice } from './slices/evaluation/evaluationSlice'
import { badgesCategorieSlice } from './slices/badgesCategories/badgesCategoriesSlice'
import { messageSlice } from './slices/messages/messagesSlice'
import { earnBadgeSlice } from './slices/earnBadge/earnBadgeSlice'
import { notificationSlice } from './slices/notification/notificationSlice'
import { commentsSlice } from './slices/comments/commentsSlice'

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        job: jobSlice.reducer,
        profile: profileSlice.reducer,
        companyProfile: companyProfileSlice.reducer,
        applyingJobs: applyingjobSlice.reducer,
        activeJobs: activeJobsSlice.reducer,
        badgesCatalog: badgesCatalogSlice.reducer,
        earnBadge: earnBadgeSlice.reducer,
        evaluation: evaluationSlice.reducer,
        badgesCategorie: badgesCategorieSlice.reducer,
        message: messageSlice.reducer,
        notification: notificationSlice.reducer,
        comment: commentsSlice.reducer
    },
})
