import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";


export const loadJobs = async (uid = '') => {
    

    const q = query(
        collection(FirebaseDB, 'job'),
        // where('status', '==', 'Created')
    );

    const docs = await getDocs(q);

    const jobs = [];
    docs.forEach(doc => {
        jobs.push({ id: doc.id, ...doc.data() });
    })

    return jobs;
}

export const loadDraftJobs = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'job'),
        where('status', '==', 'Draft')
    );

    const docs = await getDocs(q);

    const jobs = [];
    docs.forEach(doc => {
        jobs.push({ id: doc.id, ...doc.data() });
    })

    return jobs;
}

export const loadClosedJobs = async (uid = '') => {
    if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'job'),
        where('status', '==', 'Closed')
    );

    const docs = await getDocs(q);

    const jobs = [];
    docs.forEach(doc => {
        jobs.push({ id: doc.id, ...doc.data() });
    })

    return jobs;
}