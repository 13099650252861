import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    badgesCatalog: [],
    badgesCatalogs: [],
    active: null
}

export const badgesCatalogSlice = createSlice({
    name: 'badgesCatalog',
    initialState,
    reducers: {
        savingNewBadgesCatalog: (state) => {
            state.isSaving = true;
        },
        addNewEmptyBadgesCatalog: (state, action) => {
            const newBadgesCatalog = action.payload;
            state.badgesCatalogs.push(newBadgesCatalog);
            state.isSaving = false;
        },
        setActiveBadgesCatalog: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setBadgesCatalogs: (state, action) => {
            state.badgesCatalogs = action.payload;
        },
        setBadgesCatalog: (state, action) => {
            state.badgesCatalog = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateBadgesCatalog: (state, action) => {
            state.isSaving = false;
            state.badgesCatalogs = state.badgesCatalogs.map(badge => {
                if (badge.id === action.payload.id) {
                    return action.payload;
                }
                return badge;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
        deleteBadgeById: (state, action) => {
            state.badgesCatalogs = state.badgesCatalogs.filter(badge => badge.id !== action.payload);
        }

    }
})

export const {
    savingNewBadgesCatalog,
    addNewEmptyBadgesCatalog,
    setActiveBadgesCatalog,
    setBadgesCatalogs,
    setBadgesCatalog,
    setSaving,
    updateBadgesCatalog,
    deleteBadgeById 
} = badgesCatalogSlice.actions;