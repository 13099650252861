import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux';
import { setActiveJob } from '../../store/slices/job/jobSlice';
import { JobDetail } from '../../components/Employee/JobDetail';
import { useNavigate } from 'react-router-dom';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const JobsEmployee = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const { jobs } = useSelector(state => state.job);
    const { applyingJobs } = useSelector(state => state.applyingJobs);
    const [jobDetail, setJobDetail] = useState(false);
    const [appliedJobs, setAppliedJobs] = useState([]);
    const { profile } = useSelector(state => state.profile);
    const [availableJobs, setAvailableJobs] = useState([]);
    const { active } = useSelector(state => state.job);
    const navigate = useNavigate();


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const jobTitleResult = (type) => {
        let typeTmp = '';
        if (type === 'FS') {
            typeTmp = 'Full Stack Developer'
        }
        if (type === 'PM') {
            typeTmp = 'Project Manager'
        }
        if (type === 'FD') {
            typeTmp = 'Front End Developer'
        }
        if (type === 'BD') {
            typeTmp = 'Back End Developer'
        }
        if (typeTmp === '') {
            typeTmp = type;
        }
        return (
            <>
                <p className="absolute text-base font-medium text-gray-800" style={{ left: 86, top: 33, }}>{typeTmp}</p>
            </>
        )
    }

    useEffect(() => {
    }, [appliedJobs])

    useEffect(() => {
    }, [availableJobs]);

    useEffect(() => {
        if (applyingJobs) {
            loadAvailableJobs();
        }
    }, [applyingJobs]);

    useEffect(() => {
        loadActiveJobs();
    }, [])

    useEffect(() => {
        if (active && active.id) {
            setJobDetail(true);
        }
    }, [active])

    useEffect(() => {
        if (jobDetail && active && active.id) {
            navigate(`/jobs/${active.id}`);
        }
    }, [jobDetail, active?.id])

    const loadActiveJobs = () => {
        if (profile.length > 0) {
            const userJobs = profile[0].applyingJob;
            if (Array.isArray(userJobs)) {
                // Crear una lista temporal de trabajos con sus stages
                const tmpData = userJobs.map((item) => ({
                    jobId: item.jobId,
                    stage: item.stage
                }));

                // Filtrar los trabajos en 'jobs' que coinciden con los jobIds en 'tmpData'
                const tmpData2 = jobs.filter(x => tmpData.some(y => y.jobId === x.id))
                    .map(job => {
                        // Encontrar el stage correspondiente
                        const matchedJob = tmpData.find(y => y.jobId === job.id);
                        // Unir la información del job con el stage correspondiente
                        return {
                            ...job,
                            stage: matchedJob ? matchedJob.stage : null
                        };
                    });

                // Asignar la información unificada al estado appliedJobs
                setAppliedJobs(tmpData2);
            } else {
                console.error('userJobs no es un array:', userJobs);
            }
        }
    };

    const tagsMatch = (tagsEmployee, tagsJob) => {
        if (!tagsJob || !tagsEmployee) {
            return false;
        }
        return tagsEmployee.some(tag => tagsJob.includes(tag));
    };

    const loadAvailableJobs = () => {
        if ((profile.length > 0) && applyingJobs && jobs) {
            const userTags = profile[0].tagsEmployee;
            const filteredJobs = jobs.filter(job => !applyingJobs.some(appJob => appJob.jobId === job.id));
            const matchingJobs = filteredJobs.filter(job => tagsMatch(userTags, job.tagsJob));
            if (matchingJobs.length) {
                setAvailableJobs(matchingJobs);
            } else {
                console.log('No se encontraron trabajos disponibles que coincidan con las etiquetas.');
            }
        }
        // const tmpJobs = jobs.filter(x => applyingJobs.some(y => y.jobId === x.id))
        // if (tmpJobs.length) {
        //     setAppliedJobs(tmpJobs);
        // }
    }

    const onSelectJob = (id, companyName, companyId, maximum, minimum, customJobTitle, jobModel, jobtitle, jobType, description, joblocation, locationCity, tagsJob, department, urlVideo, type, videoInterview) => {
        dispatch(setActiveJob({ id, companyName, companyId, maximum, minimum, customJobTitle, jobModel, jobtitle, jobType, description, joblocation, locationCity, tagsJob, department, urlVideo, type, videoInterview }));
        setJobDetail(true);
    }

    const onCloseDetail = () => {
        setJobDetail(false);
    }

    return (
        <>
            {jobDetail &&
                <JobDetail onClick={onCloseDetail} />
            }
            {!jobDetail &&
                <div className='flex flex-col bg-white w-full h-full pl-4 pt-6'>
                    <div className='flex flex-row w-full'>
                        <div className="w-full text-zinc-800 text-2xl font-bold text-left pl-8">{t('jobsEmployee.progress')}</div>
                    </div>
                    <div className='flex flex-row w-full min-h-[690px] pt-24'>
                        <Box sx={{ width: '95%' }}>
                            {/* <div className="text-zinc-800 text-[18px] font-bold">Profile</div> */}
                            <Box sx={{ fontFamily: 'Poppins', fontSize: 18 }}>
                                <Tabs value={value} onChange={handleChange}
                                    // aria-label="basic tabs example"
                                    TabIndicatorProps={{ sx: { backgroundColor: '#FF7F11', height: 2 } }}
                                    sx={{
                                        "& button": { color: '#77A7C1', fontFamily: 'Poppins', textTransform: 'none', fontWeight: '600', fontSize: 18, wordWrap: 'break-word' },
                                        "& button:hover": { color: '#2B2C2B', fontFamily: 'Poppins', textTransform: 'none', fontWeight: '600', fontSize: 18, wordWrap: 'break-word' },
                                        "& button:focus": { color: '#2B2C2B', fontFamily: 'Poppins', textTransform: 'none', fontWeight: '600', fontSize: 18, wordWrap: 'break-word' }
                                    }}>
                                    <Tab label={t('jobsEmployee.activeApp')} {...a11yProps(0)} />
                                    {/*<Tab label="Saved Jobs" {...a11yProps(1)} />*/}
                                    <Tab label={t('jobsEmployee.recommended')} {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                {appliedJobs.map((item, index) =>
                                    <div key={index} className='w-full ml-8 mr-8 mt-6' onClick={() => onSelectJob(item.id, item.companyName, item.description, item.companyId, item.maximum, item.minimum, item.customJobTitle, item.jobModel, item.jobtitle, item.jobType, item.description, item.joblocation, item.tagsJob, item.department, item.urlVideo, 'Active', item.videoInterview)}>
                                        <div className='flex w-full h-20 opacity-90 bg-white rounded-full border border-[#818181]'>
                                            <div className='w-1/12'>
                                                <div className='w-full pt-6 justify-center'>
                                                    {/*<button className='border rounded-full w-7 h-7'><img className='w-6 h-6' src='../assets/icons/star.png' alt='' /></button>*/}
                                                </div>
                                            </div>
                                            <div className='w-2/12 border-r-2'>
                                                <div className='w-full pt-5'>
                                                    <div className='w-full'>
                                                        <p className="text-gray-600 text-xs font-normal">{item.companyName}</p>
                                                    </div>
                                                    <div className='w-full'>
                                                        <p className="text-zinc-800 text-base font-medium">{item.jobtitle}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-8/12'>
                                                <div className='w-full flex'>
                                                    {/* <div className='w-1/12'>
                                                        <div className='w-full pt-6 pl-2'>
                                                            <img className=' w-9 h-9' src="../assets/steps/check-circle.png" alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='w-1/12'>
                                                        <div className='w-full pt-10'>
                                                            <div className="w-10 h-0  rotate-180 border border-neutral-300"></div>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className='w-3/12 '>
                                                        <div className='flex pt-3 pl-2'>
                                                            <div className='pt-1'>
                                                                <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=c6f44aae-7902-45d1-b48f-fb097b8a0912" />
                                                            </div>
                                                            <div className='pt-1 pl-1'>
                                                                <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%203.png?alt=media&token=45b35fbf-9ff1-45f9-a048-63d328284a7c" />
                                                            </div>
                                                            <div className='pt-1 pl-1'>
                                                                <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%204.png?alt=media&token=1ec44765-7d7a-4d56-8e04-c0976483e366" />
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* Etapas de Jobs */}

                                                    {/* <div className='w-1/12'>
                                                        <div className='w-full pt-10'>
                                                            <div className="w-10 h-0  rotate-180 border border-neutral-300"></div>
                                                        </div>
                                                    </div> */}
                                                    {item.stage >= 1 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/check-green.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    {item.stage < 1 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/file-search-alt.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='w-1/12'>
                                                        <div className='w-full pt-10'>
                                                            <div className="w-10 h-0  rotate-180 border border-neutral-300"></div>
                                                        </div>
                                                    </div>
                                                    {item.stage >= 2 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/check-green.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    {item.stage < 2 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/comment-notes.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='w-1/12'>
                                                        <div className='w-full pt-10'>
                                                            <div className="w-10 h-0  rotate-180 border border-neutral-300"></div>
                                                        </div>
                                                    </div>
                                                    {item.stage >= 3 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/check-green.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    {item.stage < 3 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/comment-notes.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='w-1/12'>
                                                        <div className='w-full pt-10'>
                                                            <div className="w-10 h-0  rotate-180 border border-neutral-300"></div>
                                                        </div>
                                                    </div>
                                                    {item.stage >= 4 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/check-green.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    {item.stage < 4 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/comment-notes.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className='w-1/12'>
                                                        <div className='w-full pt-10'>
                                                            <div className="w-10 h-0  rotate-180 border border-neutral-300"></div>
                                                        </div>
                                                    </div>
                                                    {item.stage >= 5 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/check-green.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    {item.stage < 5 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/comment-notes.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='w-1/12'>
                                                        <div className='w-full pt-10'>
                                                            <div className="w-10 h-0  rotate-180 border border-neutral-300"></div>
                                                        </div>
                                                    </div>
                                                    {item.stage >= 6 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/check-green.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    {item.stage < 6 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/comment-notes.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='w-1/12'>
                                                        <div className='w-full pt-10'>
                                                            <div className="w-10 h-0  rotate-180 border border-neutral-300"></div>
                                                        </div>
                                                    </div>
                                                    {item.stage == 7 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/check-green.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }
                                                    {item.stage < 7 &&
                                                        <div className='w-1/12'>
                                                            <div className='w-full pt-6 pl-2'>
                                                                <img className=' w-9 h-9' src="../assets/steps/comment-notes.png" alt='' />
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </TabPanel>
                            {/*<TabPanel value={value} index={1}>
                                {jobs.map((item, index) =>
                                    <>
                                        <div key={index} className='flex flex-row w-full h-20 bg-white border rounded-full border-[#818181] opacity-90 mt-6' onClick={() => onSelectJob(item.id, item.companyName, item.companyId, item.maximum, item.minimum, item.customJobTitle, item.jobModel, item.jobtitle, item.jobType, item.description, item.joblocation, item.tagsJob, item.urlVideo, 'Saved')}>
                                            <div className='w-1/2 flex'>
                                                <div className='w-2/12 flex  justify-center pt-3'>
                                                    <div className='bg-gray-100 rounded-full h-14 w-14'>
                                                        <img className="w-14 h-14 rounded-full m-auto inset-0" src="../assets/icons/company.png" />
                                                    </div>
                                                </div>
                                                <div className='w-5/12 flex flex-wrap justify-center pt-3 '>
                                                    <div className='w-full flex flew-row px-2 py-2'>
                                                        <p className="text-xs text-gray-600" style={{ left: 86, top: 20, }}>{item.companyName}</p>
                                                    </div>
                                                    <div className='w-full flex flew-row pt-0 px-2 py-7'>
                                                        <p>{item.jobtitle}</p>
                                                    </div>
                                                </div>
                                                <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 mt-4"></div>
                                                <div className='w-5/12'>
                                                    <div className='flex flex-row'>
                                                        <div className='w-1/5 pt-6'>
                                                            <img src='../assets/icons/money-stack.png' />
                                                        </div>
                                                        <div className='w-4/5'>
                                                            <div className='w-full flex flex-row pt-5'>
                                                                <p className="text-zinc-800 text-sm font-medium">$ {item.minimum} to {item.maximum}K</p>
                                                            </div>
                                                            <div className='w-full flex flex-row'>
                                                                <p className='text-gray-600 text-xs font-normal'>Yearly</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 mt-4"></div>
                                            </div>
                                            <div className='w-1/2 flex'>
                                                <div className='w-4/6 flex justify-center pr-6'>
                                                    <div className='w-1/3 flex-wrap pt-6'>
                                                        <div className='w-24 h-5 bg-white rounded-full border border-lime-400 justify-center items-center gap-1 inline-flex'>
                                                            <img className='w-3 h-3' src='../assets/icons/map-marker.png' alt='' />
                                                            <p className="text-slate-800 text-xs font-normal leading-none">Chicago, IL</p>
                                                        </div>
                                                    </div>
                                                    <div className='w-1/3 pt-6 pl-1'>
                                                        <div className='w-24 h-5 bg-white rounded-full border border-lime-400 justify-center items-center gap-1 inline-flex'>
                                                            <img className='w-3 h-3' src='../assets/icons/estate.png' alt='' />
                                                            <p className="text-slate-800 text-xs font-normal leading-none">{item.jobModel}</p>
                                                        </div>
                                                    </div>
                                                    <div className='w-1/3 pt-6 pl-1'>
                                                        <div className='w-24 h-5 bg-white rounded-full border border-lime-400 justify-center items-center gap-1 inline-flex'>
                                                            <img className='w-3 h-3' src='../assets/icons/clock.png' alt='' />
                                                            <p className="text-slate-800 text-xs font-normal leading-none">{item.jobType}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 mt-4"></div>
                                                <div className='w-2/6 flex'>
                                                    <div className='w-1/5 pt-6'>
                                                        <div className='border rounded-full w-7 h-7'><img className='w-6 h-6' src='../assets/icons/star.png' alt='' /></div>
                                                    </div>
                                                    <div className='w-4/5 pt-6 pr-2'>
                                                        <button type='button' className="text-slate-800 w-28 h-8 text-sm font-normal rounded-3xl border border-slate-800">Job Details</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </TabPanel>
                            */}
                            <TabPanel value={value} index={1}>
                                {availableJobs.length > 0 ? (
                                    availableJobs.map((item, index) => (
                                        <>
                                            <div key={index} className='w-full flex flex-row justify-center pt-4 h-20' onClick={() => onSelectJob(item.id, item.companyName, item.companyId, item.maximum, item.minimum, item.customJobTitle, item.jobModel, item.jobtitle, item.jobType, item.description, item.joblocation, item.tagsJob, item.department, item.urlVideo, 'Recommended')}>
                                                <div className='w-full flex flex-row bg-white rounded-full border opacity-90 border-[#818181]' >
                                                    <div className='w-4/12 flex border-r-2'>
                                                        <div className='w-1/3'>
                                                            <div className='pt-1 pl-1'>
                                                                <img className=' w-12 h-12' src='../assets/icons/company.png' alt='' />
                                                            </div>
                                                        </div>
                                                        <div className='w-2/3 pt-2'>
                                                            <div className='flex flex-row w-full'>
                                                                <p className="text-gray-600 text-xs font-normal">{item.companyName}</p>
                                                            </div>
                                                            <div className='flex flex-row w-full'>
                                                                <p className="text-zinc-800 text-base font-medium">{item.jobtitle}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-5/12 flex border-r-2'>
                                                        <div className='w-2/5 pt-4'>
                                                            <p className="w-full text-xs font-medium text-zinc-800">60% Advanced</p>
                                                            <p className="text-gray-600 w-full text-xs font-normal pt-1">2 badges missed</p>
                                                        </div>
                                                        <div className='w-3/5 flex'>
                                                            <div className='flex'>
                                                                <div className='pt-1'>
                                                                    <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=c6f44aae-7902-45d1-b48f-fb097b8a0912" />
                                                                </div>
                                                                <div className='pt-1 pl-1'>
                                                                    <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%203.png?alt=media&token=45b35fbf-9ff1-45f9-a048-63d328284a7c" />
                                                                </div>
                                                                <div className='pt-1 pl-1'>
                                                                    <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%204.png?alt=media&token=1ec44765-7d7a-4d56-8e04-c0976483e366" />
                                                                </div>
                                                                <div className='pt-1 pl-1'>
                                                                    <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%205.png?alt=media&token=260be5f7-9283-4104-a958-831df00d71dc" />
                                                                </div>
                                                                <div className='pt-1 pl-1 pr-6'>
                                                                    <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%206.png?alt=media&token=19cb9f22-4cae-4903-90c9-137dd3e3ab7c" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-3/12 flex '>
                                                        <div className='w-1/3 pt-4 justify-end text-right'>
                                                            {/* <button className='border rounded-full w-7 h-7'><img className='w-6 h-6' src='../assets/icons/star.png' alt='' /></button>*/}
                                                        </div>
                                                        <div className='w-2/3 pt-4'>
                                                            <button className="text-slate-800 w-40 h-8 text-sm font-normal rounded-3xl border border-slate-800">{t('homeEmployee.buttom')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                ) : (
                                    <p>{t('jobsEmployee.jobsTags')}</p>
                                )}
                            </TabPanel>
                        </Box >
                    </div>
                </div>
            }
        </>
    )
}
