import { TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { JobDetail } from '../../components/Employee/JobDetail';
import { setActiveJob } from '../../store/slices/job/jobSlice';
import { useTranslation } from 'react-i18next';
import { startLoadingJobs } from '../../store/slices/job/thunks';
import { useNavigate } from 'react-router-dom';

export const HomeFindAJob = () => {
    const { jobs } = useSelector(state => state.job);
    const [updatedJobs, setUpdatedJobs] = useState([]);
    const dispatch = useDispatch();
    const [initialScreen, setInitialScreen] = useState(false);
    const [search, setSearch] = useState('');
    const [searchByLocation, setSearchByLocation] = useState('');
    const [selectedJob, setSelectedJob] = useState([]);
    const [previewJob, setPreviewJob] = useState(false);
    const [jobDetail, setJobDetail] = useState(false);
    const { active } = useSelector(state => state.job);
    const { t } = useTranslation();
    const navigate = useNavigate();
    // console.log(jobs)

    const onSelect = (data) => {
        // console.log('data', data);
        const { id, companyName, description, maximum, minimum, customJobTitle, jobModel, jobtitle, jobType, joblocation, locationCity, tagsJob, department, companyId, urlVideo, combinedArray } = data;
        dispatch(setActiveJob({ id, companyName, description, maximum, minimum, customJobTitle, jobModel, jobtitle, jobType, joblocation, locationCity, tagsJob, department, companyId, urlVideo, combinedArray }));
        setJobDetail(true);
    }

    const handleSelectedJob = () => {
        setPreviewJob(false);
    }

    const onCloseDetail = () => {
        setJobDetail(false);
    }

    const onPreviewProfile = () => {
        setJobDetail(true);
    }

    // console.log('updatedJobs', updatedJobs);

    useEffect(() => {
        const mergeArrays = (jobs) => {
            return jobs.map(job => {
                const { basicAssessments, compatibility, skillsAssessments } = job;

                const combinedArray = [
                    ...basicAssessments,
                    ...compatibility,
                    ...skillsAssessments
                ].filter(item => item.id && item.imageUrl && item.name); // Asegúrate de filtrar solo los items válidos

                return {
                    ...job,
                    combinedArray
                };
            });
        };

        if (jobs && jobs.length > 0) {
            const newJobs = mergeArrays(jobs);
            setUpdatedJobs(newJobs);
        }
    }, [jobs]);

    useEffect(() => {
        dispatch(startLoadingJobs());
    }, [dispatch]);

    useEffect(() => {
        if (active && active.id) {
            setJobDetail(true);
        }
    }, [active])

    useEffect(() => {
        if (jobDetail && active && active.id) {
            navigate(`/jobs/${active.id}`);
        }
    }, [jobDetail, active?.id])

    return (
        <>
            {jobDetail &&
                <JobDetail onClick={onCloseDetail} />
            }
            {!jobDetail &&
                <div className='bg-white w-full min-h-[720px]'>
                    <div className='flex flex-row w-full pt-20 justify-center'>
                        <p className="text-center text-zinc-800 text-2xl font-bold">{t('homeEmployee.find')}</p>
                    </div>

                    <div className='flex flex-row w-full pt-8 pl-20 pr-20'>
                        <div className='w-1/12'></div>
                        <div className='w-4/12'>
                            <div className='w-full flex flex-row'>
                                <TextField
                                    id='search'
                                    className='w-full'
                                    placeholder={t('homeEmployee.search')}
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                    InputProps={{
                                        style: {
                                            borderRadius: '32px',
                                            border: '1px solid #D7DEE1',
                                            background: '#FFF'
                                        }
                                    }}
                                />
                                <img className='absolute ml-[26%] mt-4 w-6 h-6' src='../assets/icons/search.png' alt='' />
                            </div>
                        </div>
                        <div className='w-1/12'></div>
                        <div className='w-4/12'>
                            <div className='w-full flex flex-row pl-4'>
                                <TextField
                                    id='search'
                                    className='w-full'
                                    placeholder={t('homeEmployee.location')}
                                    onChange={(e) => setSearchByLocation(e.target.value)}
                                    value={searchByLocation}
                                    InputProps={{
                                        style: {
                                            borderRadius: '32px',
                                            border: '1px solid #D7DEE1',
                                            background: '#FFF'
                                        }
                                    }}
                                />
                                <img className='absolute ml-[25%] mt-4 w-6 h-6' src='../assets/icons/map-marker.png' alt='' />
                            </div>
                        </div>
                        <div className='w-1/12'></div>
                        {/* <div className='w-3/12 justify-left items-start'>
                            <div className='w-full justify-left'>
                                <button className='text-white text-base font-medium w-40 h-14 bg-sky-900 rounded-full justify-center items-center inline-flex'>Search</button>
                            </div>
                        </div> */}
                    </div>
                    {(search || searchByLocation) &&
                        <>
                            <div className=' pl-20 pr-20'>
                                {updatedJobs.filter((item) => {
                                    // if (search.length > 0 && searchByLocation.length > 0) {
                                    //     return search.toLowerCase() === '' ? item : (item.jobtitle.toLowerCase().includes(search) || item.joblocation.toLowerCase().includes(search))
                                    // }
                                    const jobTitle = item.jobtitle || '';
                                    const jobLocation = item.joblocation || '';
                                    if ((search.length > 0 && jobTitle.toLowerCase().includes(search)) ||
                                        (searchByLocation.length > 0 && jobLocation.toLowerCase().includes(searchByLocation))) {
                                        return true;
                                    }
                                    // return search.toLowerCase() === '' ? item : item.jobtitle.toLowerCase().includes(search) 
                                    return false;
                                }).map((item, index) => (
                                    <div key={index} className='flex flex-row w-full h-20 bg-white border rounded-full border-[#818181] opacity-90 mt-6' onClick={() => onSelect(item)}>
                                        <div className='w-1/2 flex'>
                                            <div className='w-2/12 flex  justify-center pt-3'>
                                                <div className='bg-gray-100 rounded-full h-14 w-14'>
                                                    <img className="w-14 h-14 rounded-full m-auto inset-0" src="../assets/icons/company.png" />
                                                </div>
                                            </div>
                                            <div className='w-5/12 flex flex-wrap justify-center pt-3 '>
                                                <div className='flex flex-row w-full'>
                                                    <p className="text-gray-600 text-xs font-normal">{item.companyName}</p>
                                                </div>
                                                <div className='flex flex-row w-full'>
                                                    <p className="text-zinc-800 text-base font-medium break-words truncate overflow-visible">{item.jobtitle}</p>
                                                </div>
                                            </div>
                                            <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 mt-4"></div>
                                            <div className='w-5/12'>
                                                <div className='flex flex-row'>
                                                    <div className='w-1/5 pt-6'>
                                                        <img src='../assets/icons/money-stack.png' />
                                                    </div>
                                                    <div className='w-4/5'>
                                                        <div className='w-full flex flex-row pt-5'>
                                                            <p className="text-zinc-800 text-sm font-medium">$ {item.minimum} to {item.maximum}K</p>
                                                        </div>
                                                        <div className='w-full flex flex-row'>
                                                            <p className='text-gray-600 text-xs font-normal'>Yearly</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 mt-4"></div>
                                        </div>

                                        <div className='w-1/2 flex'>
                                            <div className='w-4/6 flex justify-center pr-6'>
                                                <div className='w-1/3 flex-wrap pt-6'>
                                                    <div className='w-24 h-5 bg-white rounded-full border border-lime-400 justify-center items-center gap-1 inline-flex'>
                                                        <img className='w-3 h-3' src='../assets/icons/map-marker.png' alt='' />
                                                        <p className="text-slate-800 text-xs font-normal leading-none">{item.jobLocation}</p>
                                                    </div>
                                                </div>
                                                <div className='w-1/3 pt-6 pl-1'>
                                                    <div className='w-24 h-5 bg-white rounded-full border border-lime-400 justify-center items-center gap-1 inline-flex'>
                                                        <img className='w-3 h-3' src='../assets/icons/estate.png' alt='' />
                                                        <p className="text-slate-800 text-xs font-normal leading-none">{item.jobModel}</p>
                                                    </div>
                                                </div>
                                                <div className='w-1/3 pt-6 pl-1'>
                                                    <div className='w-24 h-5 bg-white rounded-full border border-lime-400 justify-center items-center gap-1 inline-flex'>
                                                        <img className='w-3 h-3' src='../assets/icons/clock.png' alt='' />
                                                        <p className="text-slate-800 text-xs font-normal leading-none">{item.jobType}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 mt-4"></div>
                                            <div className='w-2/6 flex'>
                                                {/*<div className='w-1/5 pt-6'>
                                                    <div className='border rounded-full w-7 h-7'><img className='w-6 h-6' src='../assets/icons/star.png' alt='' /></div>
                                                </div>*/}
                                                <div className='w-3/5 pt-6 pr-2'>
                                                    <button type='button' className="text-slate-800 w-28 h-8 text-sm font-normal rounded-3xl border border-slate-800">{t('homeEmployee.buttom')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </>
                    }
                    {!search && !searchByLocation &&
                        <>
                            <div className='flex flex-row w-full'>
                                <div className='w-full justify-center pt-8'>
                                    <p className="text-sky-900 text-base font-semibold">{t('homeEmployee.descEmployee')}</p>
                                </div>
                            </div>

                            <div className='flex-row w-full pt-14 pl-24 pr-24'>
                                <div className="w-full flex-row h-44 bg-neutral-100 rounded-xl ">
                                    <div className='pt-6'>
                                        <p className='text-center text-zinc-800 text-2xl font-bold'>{t('homeEmployee.myBadges')}</p>
                                    </div>
                                    <div className='flex flex-row w-full pt-1 justify-center'>
                                        <p className="text-center text-gray-600 text-sm font-normal">{t('homeEmployee.descMyBadges')}<br />{t('homeEmployee.descMyBadges1')}</p>
                                    </div>
                                    <div className='w-full pt-4'>

                                    </div>
                                </div>
                            </div>

                            <div className='w-full flex flex-row pt-16 pl-32 pr-32'>
                                <div className='w-fulltext-left'>
                                    <p className="text-zinc-800 text-2xl font-bold">{t('homeEmployee.recommended')}</p>
                                </div>
                            </div>

                            <div className='w-full pl-32 pr-32 pb-11 pt-8'>
                                {updatedJobs.map((item, index) => (

                                    <div key={index} className='w-full flex flex-row justify-center pt-4 h-20' onClick={() => onSelect(item)}>
                                        <div className='w-full flex flex-row bg-white rounded-full border opacity-90 border-[#818181]' >

                                            <div className='w-4/12 flex border-r-2'>
                                                <div className='w-1/3'>
                                                    <div className='pt-1 pl-1'>
                                                        <img className=' w-12 h-12' src='../assets/icons/company.png' alt='' />
                                                    </div>
                                                </div>
                                                <div className='w-2/3 pt-2'>
                                                    <div className='flex flex-row w-full'>
                                                        <p className="text-gray-600 text-xs font-normal">{item.companyName}</p>
                                                    </div>
                                                    <div className='flex flex-row w-full'>
                                                        <p className="text-zinc-800 text-base font-medium break-words truncate overflow-visible">{item.jobtitle}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-5/12 flex border-r-2'>
                                                <div className='w-2/5 pt-4'>
                                                    <p className="w-full text-xs font-medium text-zinc-800">% Advanced</p>
                                                    <p className="text-gray-600 w-full text-xs font-normal pt-1">badges missed</p>
                                                </div>
                                                <div className='w-3/5 flex'>
                                                    <div className='flex'>
                                                        {item?.combinedArray.map((job, index) => (
                                                            <div key={index} className='pt-1 pr-1'>
                                                                <Tooltip title={job.name}><img className=' w-9 h-9 mt-3 rounded-full' src={job.imageUrl} /></Tooltip>
                                                            </div>
                                                        ))

                                                        }
                                                        {/* <div className='pt-1'>
                                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=c6f44aae-7902-45d1-b48f-fb097b8a0912" />
                                                        </div>
                                                        <div className='pt-1 pl-1'>
                                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%203.png?alt=media&token=45b35fbf-9ff1-45f9-a048-63d328284a7c" />
                                                        </div>
                                                        <div className='pt-1 pl-1'>
                                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%204.png?alt=media&token=1ec44765-7d7a-4d56-8e04-c0976483e366" />
                                                        </div>
                                                        <div className='pt-1 pl-1'>
                                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%205.png?alt=media&token=260be5f7-9283-4104-a958-831df00d71dc" />
                                                        </div>
                                                        <div className='pt-1 pl-1 pr-6'>
                                                            <img className=' w-9 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%206.png?alt=media&token=19cb9f22-4cae-4903-90c9-137dd3e3ab7c" />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-3/12 flex justify-center items-center'>
                                                {/*<div className='w-1/3 pt-4 justify-end text-right'>
                                                    <button className='border rounded-full w-7 h-7'><img className='w-6 h-6' src='../assets/icons/star.png' alt='' /></button>
                                                </div>*/}
                                                <div className='w-2/3 '>
                                                    <button className="text-slate-800 w-40 h-8 text-sm font-normal rounded-3xl border border-slate-800">{t('homeEmployee.buttom')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </div>
            }
        </>
    )
}
