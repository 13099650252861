import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore/lite"
import { addNewNotification, savingNewNotification, setActiveNotification, setNotification, setSaving, updateNotification } from "./notificationSlice"
import { FirebaseDB } from "../../../firebase/config"
import { loadNoitification } from "../../../helpers/loadNotification"


export const startNewNotification = (notification)=>{
    return async (dispatch, getState)=>{
        dispatch(savingNewNotification())
        const { uid } = getState().auth
        const {
            uidUser,
            title,
            content
        } = notification
        const newNotification = {
            uid,
            title,
            content,
            uidUser,
            fechaCreaction: new Date().getTime()
        }

        const newDoc = doc(collection(FirebaseDB, `notification`))
        await setDoc(newDoc, newNotification)
        newNotification.id = newDoc.id
        dispatch(addNewNotification(newNotification))
        dispatch(setActiveNotification(newNotification))
    }
}

export const starLoadingNotification = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth
        if (!uid) throw new Error("El UID del usuario no existe");
        const notifications = await loadNoitification(uid)
        dispatch(setNotification(notifications))
    }
}

export const UpdateNOti = ({ id, formdata }) => {
    return async (dispatch, getState) => {
        dispatch(setSaving());
        try {
            const docRef = doc(FirebaseDB, `notification/${id}`);
            const editedNotification = { ...formdata };
            await setDoc(docRef, editedNotification, { merge: true });
            dispatch(updateNotification({ id, formdata }));
        } catch (e) {
            console.log('error de thunks', e);
        }
    };
};


export const starLoadingNotificationByUid = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;
        if (!uid) throw new Error("El UID del usuario no existe");

        try {
            const q = query(collection(FirebaseDB, "notification"), where("uid", "==", uid)); 
            const querySnapshot = await getDocs(q);
            const notifications = [];
            querySnapshot.forEach((doc) => {
                notifications.push({ id: doc.id, ...doc.data() });
            });
            dispatch(setNotification(notifications));
        } catch (error) {
            console.error("Error al cargar las notificaciones: ", error);
        }
    };
};