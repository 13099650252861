import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    applyingjobs: [],
    currentProcess: [],
    active: null
}

export const applyingjobSlice = createSlice({
    name: 'applyingjob',
    initialState,
    reducers: {
        updateApplyingJob: (state, action) => {
        },
        setActiveApplyingJob: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setApplyingJobs: (state, action) => {
            state.applyingJobs = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        setCurrentProcesssJobs: (state, action) => {
            state.currentProcess = action.payload;
        }
    }
})

export const {
    updateApplyingJob,
    setActiveApplyingJob,
    setApplyingJobs,
    setSaving,
    setCurrentProcesssJobs
} = applyingjobSlice.actions