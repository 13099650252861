import { loadBadgeCategories } from "../../../helpers/loadBadgesCategorie";
import { setBadgesCategories } from "./badgesCategoriesSlice";


export const startNewClearBadgeCategorie = (badgeCategorie) => {

}

export const startNewBadgeCategorie = (badgeCategorie) => {

}

export const startLoadingBadgeCategories = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        // if (!uid) throw new Error("El UID del usuario no existe");
        const badgesCategories = await loadBadgeCategories();
        dispatch(setBadgesCategories(badgesCategories));

    }
}

export const startLoadingBadgeCategorie = () => {

}

export const startDeletingBadgeCategorie = () => {

}

export const startSaveBadgeCategorie = () => {

}