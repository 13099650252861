import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Popover, Select } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router';
import { UpdateNOti } from './store/slices/notification/thunks';


export const AppTopbar = () => {
    const dispatch = useDispatch();
    const { jobs } = useSelector(state => state.job);
    const { notifications } = useSelector(state => state.notification);
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const [language, setLanguage] = useState('es');
    const { displayName, uid } = useSelector(state => state.auth);
    const [anchorEl, setAnchorEl] = useState(null);
    const { profile } = useSelector(state => state.profile);
    const { companyProfile } = useSelector(state => state.companyProfile);
    const [profileImage, setProfileImage] = useState('');
    const [profileCompletion, setProfileCompletion] = useState(0);
    const [popoverStates, setPopoverStates] = useState({});

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const navigateToProfile = () => {
        navigate('/profile');
        handleClose();
    }

    useEffect(() => {
        if (profile.length > 0) {
            let pages = profile[0].pages;
            if (pages) {
                let imageUrl = pages.profileImageUrl;
                setProfileImage(imageUrl);
                setProfileImage((state) => {
                    return state;
                })
            }
            const completedFields = Object.keys(profile[0]).filter(field => profile[0][field]);
            const totalFields = Object.keys(profile[0]).length;
            const percentage = Math.round((completedFields.length / totalFields) * 100);
            setProfileCompletion(percentage);

        }
    }, [profile])

    useEffect(() => {
        if (companyProfile.length > 0) {
            let pages = companyProfile[0].pages;

            if (pages) {
                let imageUrl = pages.profileImageUrl;
                setProfileImage(imageUrl);
                setProfileImage((state) => {
                    return state;
                })
            }
        }
    }, [companyProfile])

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [language])

    const handleChange = (lan) => {
        setLanguage(lan);
        i18n.changeLanguage(lan);
    }

    const filteredNotifications = notifications.filter(notification => {
        return notification.uidUser === uid && (!notification.received || notification.received === false);
    });


    const handleCloseNotification = (notificationId) => {
        handleClosePopover(notificationId);
    };

    useEffect(() => {
        const initialPopoverStates = {};
        notifications.forEach(notification => {
            initialPopoverStates[notification.id] = true;
        });
        setPopoverStates(initialPopoverStates);
    }, [notifications]);

    const handleClosePopover = async (notificationId) => {

        setPopoverStates(prevState => ({
            ...prevState,
            [notificationId]: false
        }));

        const formdata = { received: true };
        await dispatch(UpdateNOti({ id: notificationId, formdata: formdata }))
    };

    return (
        <>
            <div
                className='w-full flex flex-row pl-10 pr-2 h-20 bg-white'
            >
                <div className='w-2/6 justify-start text-left pt-8 '>
                    <div className="whitespace-nowrap font-['Poppins'] text-gray-400 relative text-base" >
                        {moment(Date.now()).format("dddd") + ', ' + moment(Date.now()).format("MMM Do YYYY")}
                    </div>
                </div>
                <div className='w-2/6'>
                </div>
                <div className='w-2/6 flex'>
                    <div className='w-3/5 pl-10 flex justify-end'>
                        <div className='w-1/2 pt-4 pr-2 flex justify-end'>
                            <FormControl className='w-20'>
                                <Select
                                    id="language-dropdown"
                                    value={language}
                                    label='language'
                                    defaultValue='es'
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    input={<OutlinedInput />}
                                    displayEmpty
                                >
                                    <MenuItem value="en" onClick={() => handleChange('en')}>🇺🇸</MenuItem>
                                    <MenuItem value="es" onClick={() => handleChange('es')} >🇪🇸</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {profileImage &&
                            <div className='w-1/2 justify-end flex pr-2'>
                                <div className='w-14 h-14 pt-4' data-popover-target='popover-user-profile' onClick={handleClick}>
                                    <CircularProgressbarWithChildren
                                        value={profileCompletion}
                                        className='w-14 h-14'
                                        backgroundPadding={0}
                                        styles={buildStyles({ pathColor: "#8BC34A", textColor: '#525967', textSize: 20 })}                                    >
                                        <p className="text-center text-xs text-gray-500"></p>
                                        <img className='w-12 h-12 rounded-full' src={profileImage || '/assets/images/profile_default.png'} alt="profile" />
                                    </CircularProgressbarWithChildren>
                                </div>
                            </div>
                        }
                        {!profileImage &&
                            <div className='w-1/2 justify-end flex pr-2'>
                                <div className='w-14 h-14 pt-4' data-popover-target='popover-user-profile' onClick={handleClick}>
                                    <CircularProgressbarWithChildren
                                        value={profileCompletion}
                                        className='w-14 h-14'
                                        backgroundPadding={0}
                                        styles={buildStyles({ pathColor: "#8BC34A", textColor: '#525967', textSize: 20 })}>
                                        <img className='w-12 h-12 rounded-full' src={profileImage || '/assets/images/profile_default.png'} alt="profile" />
                                    </CircularProgressbarWithChildren>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='w-2/5 hidden lg:block'>
                        <div className='w-full flex flex-row pt-6'>
                            <p className=" text-xs text-center text-gray-500 ">{t('homepage.welcome')}</p>
                        </div>
                        <div className='w-full flex flex-row'>
                            <p className=" text-base font-semibold text-center text-gray-500" >{displayName}</p>
                        </div>
                    </div>
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    className='rounded-xl border border-neutral-300'
                >
                    {profileCompletion < 100 && (
                        <div className='w-[455px] h-[194px] rounded-xl'>
                            <div className='flex w-full'>
                                <div className='flex flex-wrap w-full'>
                                    <div className='w-4/12'>
                                        <div className='w-full pt-9 pl-6'>
                                            <CircularProgressbar
                                                className='w-20 h-20'
                                                value={profileCompletion}
                                                text={profileCompletion + '%'}
                                                styles={buildStyles({ pathColor: "#8BC34A", textColor: '#525967', textSize: 20 })}
                                            />
                                        </div>
                                    </div>
                                    <div className='w-8/12'>
                                        <div className='w-full pr-8 pl-2'>
                                            <p className="text-left text-gray-600 text-base font-semibold pt-9">Profile Progress</p>
                                            <p className="w-full text-black text-xs font-normal pt-1">Complete the information in your profile to opt for greater benefits such as access to vacancies, among other features.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex flex-row pt-8 pl-8'>
                                <button className=" text-white px-8 py-1 w-96 h-8 bg-sky-900 text-sm rounded-3xl font-medium inline-flex justify-center items-center" onClick={navigateToProfile}>Complete My Profile</button>
                            </div>
                        </div>
                    )}
                </Popover>
                {filteredNotifications.map(notification => (
                    <Popover
                        key={notification.id}
                        id={notification.id}
                        open={popoverStates[notification.id] || false}
                        onClose={() => handleClosePopover(notification.id)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        className='rounded-xl border border-neutral-300'>
                        <div className='w-[455px] h-[194px] rounded-xl flex flex-col justify-center items-center p-4'>
                            <p className="text-center text-gray-600 text-lg font-semibold pb-2"> {notification.title} </p>
                            <p className="text-black text-sm font-normal pb-4"> {notification.content} </p>
                            <div>
                                <button className="text-white px-8 py-1 bg-sky-900 text-sm rounded-3xl font-medium inline-flex justify-center items-center" onClick={() => handleCloseNotification(notification.id)}>Visto</button>
                            </div>
                        </div>
                    </Popover>
                ))}
            </div>
        </>
    )
}
