import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';

import { FirebaseAuth } from '../firebase/config';
import { isVerifying, login, logout } from '../store/auth/authSlice';
import { startLoadingJobs } from '../store/slices/job/thunks';
import { startLoadingEmployeeProfiles, startLoadingProfile } from '../store/slices/profile/thunks';
import { startLoadingApplyingJobs } from '../store/slices/applyingJobs/thunks';
import { startLoadingActiveJobs } from '../store/slices/activeJobs/thunks';
import { startLoadingBadgesCatalog } from '../store/slices/badgesCatalog/thunks';
import { startLoadingBadgeCategories } from '../store/slices/badgesCategories/thunks';
import { starLoadingNotification } from '../store/slices/notification/thunks';
import { startLoadingCompanyProfiles } from '../store/slices/companyProfile/thunks';

export const useCheckAuth = () => {

    const { status, photoURL, emailVerified } = useSelector(state => state.auth);
    const dispatch = useDispatch();


    useEffect(() => {

        onAuthStateChanged(FirebaseAuth, async (user) => {
            if (!user) return dispatch(logout());

            const { uid, email, displayName, emailVerified } = user;

            if (emailVerified) {
                dispatch(login({ uid, email, displayName, photoURL }));
                dispatch(startLoadingJobs());
                dispatch(startLoadingApplyingJobs());
                dispatch(startLoadingProfile());
                dispatch(startLoadingEmployeeProfiles());
                dispatch(startLoadingBadgesCatalog());
                dispatch(startLoadingBadgeCategories());
                dispatch(starLoadingNotification());
                dispatch(startLoadingCompanyProfiles());
            } else {
                console.log('Not verified');
            }
        })
    }, [emailVerified]);

    const authResult = {
        status,
        photoURL,
        emailVerified
    }

    return authResult;
}