import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs, Modal, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { AllCandidatesEmployer } from '../components/Employer/AllCandidatesEmployer';
import { useDispatch, useSelector } from 'react-redux';
import { PreviewProfile } from '../components/Employee/PreviewProfile';
import { setActiveProfile } from '../store/slices/profile/profileSlice';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 0,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const CandidatesEmployer = () => {
    const [value, setValue] = React.useState(0);
    const { employeeProfiles } = useSelector(state => state.profile);
    const [previewProfile, setPreviewProfile] = useState(false);
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        let countTmp = 0;
        if (employeeProfiles) {
            employeeProfiles.map((item) => {
                countTmp += 1;
            })
        }
        setCount(countTmp);
    }, [employeeProfiles])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>

            <div className='flex flex-col bg-white w-full place-items-center min-h-[720px] pl-4 pt-6'>
                <Box sx={{ width: '100%' }}>
                    {/* <div className="text-zinc-800 text-[18px] font-bold">Profile</div> */}
                    <Box sx={{ fontFamily: 'Poppins', fontSize: 18 }}>
                        <Tabs value={value} onChange={handleChange}
                            // aria-label="basic tabs example"
                            TabIndicatorProps={{ sx: { backgroundColor: '#FF7F11', height: 2 } }}
                            sx={{
                                "& button": { color: '#77A7C1', fontFamily: 'Poppins', textTransform: 'none', fontWeight: '600', fontSize: 18, wordWrap: 'break-word' },
                                "& button:hover": { color: '#2B2C2B', fontFamily: 'Poppins', textTransform: 'none', fontWeight: '600', fontSize: 18, wordWrap: 'break-word' },
                                "& button:focus": { color: '#2B2C2B', fontFamily: 'Poppins', textTransform: 'none', fontWeight: '600', fontSize: 18, wordWrap: 'break-word' }
                            }}>
                            <Tab label={t("candidate.allcandidates")} {...a11yProps(0)} />
                            {/* <Tab label="New ()" {...a11yProps(1)} />
                            <Tab label="Great Match ()" {...a11yProps(2)} />
                            <Tab label="Unrated ()" {...a11yProps(3)} />
                            <Tab label="Interested ()" {...a11yProps(4)} /> */}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <AllCandidatesEmployer employeeProfiles={employeeProfiles} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                    </TabPanel>
                </Box>
            </div>
        </>
    )
}
