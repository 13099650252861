import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { EvaluationsEmployee } from '../../../pages/Employee/EvaluationsEmployee';

export const BadgePreviewByCategory = ({ onClick, enableStart }) => {
    const { active } = useSelector(state => state.badgesCatalog);
    const [evaluation, setEvaluation] = useState(false);
    const allowTakeEvaluation = enableStart;

    const closePreviewBadge = () => {
        onClick(true);
    }

    const changeToBadges = () => {
        setEvaluation(false);
    }

    const changeToOpenEvaluation = () => {
        setEvaluation(true);
    }

    const changeToCloseEvaluation = () => {
        setEvaluation(false);
    }

    return (

        <div className='flex flex-col bg-white w-full place-items-center min-h-[780px]'>
            {!evaluation &&
                <>
                    <div className='flex flex-row w-full pt-2 pl-2'>
                        <button onClick={() => closePreviewBadge()}><img className="w-6 h-6 absolute rounded-mx mt-2 ml-2" src="../assets/icons/left-arrow-from-left.png" alt='' /></button>
                    </div>
                    <div className='flex flex-row w-full mt-12 pl-2'>
                        <div className='flex flex-1 w-full'>
                            <div className='w-3/12'>
                                <div className='border-r h-2/5'>
                                    <div className='flex flex-row'>
                                        {active.imageUrl &&
                                            <img className="w-48 h-48 mx-auto rounded-full object-cover" src={active.imageUrl} />
                                        }
                                        {!active.imageUrl &&
                                            <img className="w-48" src="../assets/images/profile_default.png" />
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className='w-9/12 pl-6 pr-6'>
                                <div className='flex flex-row w-full'>
                                    <div className='w-full text-center place-content-center'>
                                        <div className='flex flex-row w-full'>
                                            <p className="text-[#2B2C2B] font-['Poppins'] text-lg text-center w-full">{active.name}</p>
                                        </div>
                                        <div className='flex flex-row w-full'>
                                            <p className="text-[#0D497D] text-xs font-['Poppins'] font-bold text-center w-full">{active.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className='w-full text-left pl-32 pr-32'>
                        <div className='flex flex-row w-full mt-14 pl-6 text-left'>
                            <p className="text-sm/[16px] font-['Poppins'] font-medium w-full">Description</p>
                        </div>
                        <div className='flex flex-row w-full mt-4 pl-6 pr-6'>
                            <p className=" font-['Poppins'] w-full text-justify text-[#818181] text-xs/[12px] font-normal leading-5 min-h-[50px]">
                                {active.description}
                            </p>
                        </div>
                    </div>

                    <div className='w-full text-left pl-32 pr-32'>
                        <div className='flex flex-row w-full text-left pl-6 mt-14'>
                            <p className="text-[#2B2C2B] text-lg font-bold font-['Poppins'] w-full">Evaluations</p>
                        </div>
                    </div>
                </>
            }

            <EvaluationsEmployee onClick={changeToBadges} onClickEvaluation={changeToOpenEvaluation} onClickCloseEvaluation={changeToCloseEvaluation} showBegin={allowTakeEvaluation} />


        </div>
    )
}
