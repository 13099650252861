import { Box, CircularProgress, FormControl, Radio, circularProgressClasses } from '@mui/material';
import React, { useState, useRef, useCallback, useEffect } from 'react'
import Swal from 'sweetalert2';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { TimerEvaluation } from '../../components/Employee/Evaluation/TimerEvaluation';
import { useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import Quiz from '../Employer/Evaluations/Quiz';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

export const TakeEvaluation = ({ onClick }) => {
    const [step, setStep] = useState(0);
    const [selectedValueQ1, setSelectedValueQ1] = useState();
    const [selectedValueQ2, setSelectedValueQ2] = useState();
    const [selectedValueQ3, setSelectedValueQ3] = useState();
    const [selectedValueQ4, setSelectedValueQ4] = useState();
    const [totalQuestions, setTotalQuestions] = useState(0);
    const { active } = useSelector(state => state.evaluation);
    const [percentage, setPercentage] = useState(0);
    const [showTimer, setShowTimer] = useState(false);

    const startInterview = () => {
        setStep(1);
        setShowTimer(true);
        setStep((state) => {
            return state;
        })
        // setVideoSaved1(false);
        //  handleStartCaptureClick;
    }

    useEffect(() => {
        if (active) {
            let questions = active.multipleTrueOrFalse;
            if (questions.length > 0) {

            }
        }
    }, [active])

    const nextStep = (id) => {
        setStep(id);
        setStep((state) => {
            return state
        })
        if (id == 5) {
            setShowTimer(false);
        }
    }

    const prevStep = (id) => {
        setStep(id);
        setStep((state) => {
            return state
        })
    }

    const finalizeEvaluation = () => {
        setStep(6);
        setShowTimer(false);
    }

    const closeEvaluation = () => {
        onClick(true);
    }

    const handleChangeQ1 = (event) => {
        setSelectedValueQ1(event.target.value);
    };

    const handleChangeQ2 = (event) => {
        setSelectedValueQ2(event.target.value);
    };

    const handleChangeQ3 = (event) => {
        setSelectedValueQ3(event.target.value);
    };

    const handleChangeQ4 = (event) => {
        setSelectedValueQ4(event.target.value);
    };

    const endEvaluation = () => {
        showSuccess();
        onClick(true);
    }

    const showSuccess = () => {
        Swal.fire('Ha finalizado la evaluación correctamente', 'Saved', 'success');
    };

    return (
        <>

            <div className='flex flex-col bg-white w-full place-items-center h-[900px]'>
                {(step == 0 || step == 5) &&
                    <div className='flex flex-row w-full pl-2 pt-2'>
                        <button onClick={() => closeEvaluation()}><img className="w-6 h-6 absolute rounded-mx mt-2 ml-2" src="../assets/icons/left-arrow-from-left.png" alt='' /></button>
                    </div>
                }
                {showTimer &&
                    <TimerEvaluation evaluationTime={active?.durationTime} onClick={finalizeEvaluation} />
                }
                {step == 0 &&
                    <div className='pt-20'>
                        <p className='pt-10'>Estas por iniciar la evaluación, asegurate de disponer del tiempo para completarla, al darle iniciar ya no podrás pausar la evaluación. </p>
                        <div className='flex flex-row mt-10 justify-center'>
                            <button type="button" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => startInterview()}>Iniciar</button>
                        </div>

                    </div>
                }
                {step == 1 &&
                    <>
                        <Quiz />
                        {/* <div className='w-full pl-20 pr-20 pt-20'>
                            <BorderLinearProgress variant="determinate" value={20} />
                            <p className='w-full text-center pt-10 pl-4 pr-4'>¿El propósito principal de Redux es gestionar el estado en una aplicación React?</p>
                        </div>
                        <div className='w-2/3 pt-20 pl-20 pr-20 items-center justify-center align-middle'>
                            <div className='w-full flex flex-row justify-center items-center h-20 border-2'>
                                <Radio
                                    checked={selectedValueQ1 === 'a'}
                                    onChange={handleChangeQ1}
                                    name="radio-buttons"
                                    value="a"
                                    inputProps={{ 'aria-label': 'A' }}
                                />true
                            </div>
                            <div className='w-full flex flex-row justify-center items-center h-20 border-2 mt-4'>
                                <Radio
                                    checked={selectedValueQ1 === 'b'}
                                    onChange={handleChangeQ1}
                                    name="radio-buttons"
                                    value="b"
                                    inputProps={{ 'aria-label': 'A' }}
                                />false
                            </div>

                        </div>
                        <div className='w-full flex flex-row justify-center pt-20'>
                            <div className='w-1/3'></div>
                            <div className='w-1/3'>


                                <div style={{ textAlign: 'center' }}>
                                    <div className='flex flex-row mt-10 justify-center'>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => nextStep(2)}>Siguiente</button>
                                    </div>
                                </div>

                            </div>
                            <div className='w-1/3'></div>
                        </div> */}
                    </>
                }
                {step == 2 &&
                    <>
                        <div className='w-full  pl-20 pr-20 pt-20'>
                            <BorderLinearProgress variant="determinate" value={40} />
                            <p className='w-full text-center pl-4 pr-4 pt-10'>Es esta sintaxis una estructura correcta de React?</p>
                        </div>
                        <div className='w-full flex flex-row items-center justify-center text-center pt-10 h-60'>
                            <div className='justify-center items-center'>
                                <img className='justify-center items-center text-center max-w-none' src='/assets/images/react1.png' alt='' />
                            </div>
                        </div>
                        <div className='w-2/3 pt-20 pl-20 pr-20 items-center justify-center align-middle'>
                            <div className='w-full flex flex-row justify-center items-center h-20 border-2'>
                                <Radio
                                    checked={selectedValueQ2 === 'a'}
                                    onChange={handleChangeQ2}
                                    name="radio-buttons"
                                    value="a"
                                    inputProps={{ 'aria-label': 'A' }}
                                />true
                            </div>
                            <div className='w-full flex flex-row justify-center items-center h-20 border-2 mt-4'>
                                <Radio
                                    checked={selectedValueQ2 === 'b'}
                                    onChange={handleChangeQ2}
                                    name="radio-buttons"
                                    value="b"
                                    inputProps={{ 'aria-label': 'A' }}
                                />false
                            </div>

                        </div>
                        <div className='w-full flex flex-row justify-center pt-20'>
                            <div className='w-1/3'></div>
                            <div className='w-1/3'>
                                <div style={{ textAlign: 'center' }}>
                                    <div className='flex flex-row mt-10 justify-between'>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => prevStep(1)}>Anterior</button>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => nextStep(3)}>Siguiente</button>
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/3'></div>
                        </div>
                    </>
                }
                {step == 3 &&
                    <>
                        <div className='w-full  pl-20 pr-20 pt-20'>
                            <BorderLinearProgress variant="determinate" value={60} />
                            <p className='w-full text-center pt-10 pl-4 pr-4'>Es necesario importar React en cada archivo que contenga componentes?</p>
                        </div>
                        <div className='w-2/3 pt-20 pl-20 pr-20 items-center justify-center align-middle'>
                            <div className='w-full flex flex-row justify-center items-center h-20 border-2'>
                                <Radio
                                    checked={selectedValueQ3 === 'a'}
                                    onChange={handleChangeQ3}
                                    name="radio-buttons"
                                    value="a"
                                    inputProps={{ 'aria-label': 'A' }}
                                />true
                            </div>
                            <div className='w-full flex flex-row justify-center items-center h-20 border-2 mt-4'>
                                <Radio
                                    checked={selectedValueQ3 === 'b'}
                                    onChange={handleChangeQ3}
                                    name="radio-buttons"
                                    value="b"
                                    inputProps={{ 'aria-label': 'A' }}
                                />false
                            </div>

                        </div>
                        <div className='w-full flex flex-row justify-center pt-20'>
                            <div className='w-1/3'></div>
                            <div className='w-1/3'>
                                <div style={{ textAlign: 'center' }}>
                                    <div className='flex flex-row mt-10 justify-between'>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => prevStep(2)}>Anterior</button>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => nextStep(4)}>Siguiente</button>
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/3'></div>
                        </div>
                    </>
                }

                {step == 4 &&
                    <>
                        <div className='w-full  pl-20 pr-20 pt-20'>
                            <BorderLinearProgress variant="determinate" value={80} />
                            <p className='w-full text-center pt-10 pl-4 pr-4'>Pueden los componentes de React tener estado (state)?</p>
                        </div>
                        <div className='w-2/3 pt-20 pl-20 pr-20 items-center justify-center align-middle'>
                            <div className='w-full flex flex-row justify-center items-center h-20 border-2'>
                                <Radio
                                    checked={selectedValueQ4 === 'a'}
                                    onChange={handleChangeQ4}
                                    name="radio-buttons"
                                    value="a"
                                    inputProps={{ 'aria-label': 'A' }}
                                />true
                            </div>
                            <div className='w-full flex flex-row justify-center items-center h-20 border-2 mt-4'>
                                <Radio
                                    checked={selectedValueQ4 === 'b'}
                                    onChange={handleChangeQ4}
                                    name="radio-buttons"
                                    value="b"
                                    inputProps={{ 'aria-label': 'A' }}
                                />false
                            </div>

                        </div>
                        <div className='w-full flex flex-row justify-center pt-20'>
                            <div className='w-1/3'></div>
                            <div className='w-1/3'>
                                <div style={{ textAlign: 'center' }}>
                                    <div className='flex flex-row mt-10 justify-between'>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => prevStep(3)}>Anterior</button>
                                        <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => nextStep(5)}>Siguiente</button>
                                    </div>
                                </div>
                            </div>
                            <div className='w-1/3'></div>
                        </div>
                    </>
                }

                {step == 5 &&
                    <div className='w-full  pl-20 pr-20 pt-20'>
                        <BorderLinearProgress variant="determinate" value={100} />
                        <p className='pt-20'>Has finalizado la evaluación satisfactoriamente, puedes consultar tu resultado en Ver Resultados.</p>

                        <div className='flex flex-row mt-10 justify-center'>
                            <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => finalizeEvaluation()}>Ver Resultados</button>
                        </div>
                    </div>
                }

                {step == 6 &&
                    <div className='w-full  pl-20 pr-20 pt-20'>
                        <p className='pt-20'>Has obtenido el siguiente resultado:</p>

                        <div className='w-full flex flex-row pt-10'>
                            <CircularProgressbar
                                className='w-20 h-20'
                                value={100}
                                text='100%'
                            // styles={buildStyles({ pathColor: "#8BC34A", textColor: '#525967', textSize: 20 })}
                            />
                        </div>


                        <div className='flex flex-row mt-10 justify-center'>
                            <button type="button" className='w-4/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={() => endEvaluation()}>Finalizar</button>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
