import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { startLoadingCompanyProfile } from '../../../store/slices/companyProfile/thunks';
import { useDispatch, useSelector } from 'react-redux';

export const CompanyProfile = ({ onClick, data }) => {
    const { t } = useTranslation();
    const { profile } = useSelector(state => state.profile);
    const [page, setPage] = useState({});
    const [companyName, setCompanyName] = useState('');
    const [mission, setMission] = useState('');
    const [vision, setVision] = useState('');
    const [urlVideo, setUrlVideo] = useState('');
    const [location, setLocation] = useState('')
    const dispatch = useDispatch();

    useEffect(() => {
        if (data.length > 0) {
            setCompanyName(data[0].companyName);
            setMission(data[0].mission);
            setVision(data[0].vision);
            setPage(data[0].pages);
            setUrlVideo(data[0].urlVideo);
            setLocation(data[0].location)
        }
    }, [data])

    useEffect(() => {
        if (profile.length > 0) {
            dispatch(startLoadingCompanyProfile(profile[0].idCompany));
        }
    }, [])

    const closeUpdateProfile = () => {
        onClick(false);
    }

    return (
        <>
            <div className='flex flex-row w-full pl-6 justify-end pr-14'>
                <div className='w-52 flex h-9'>
                    <button onClick={closeUpdateProfile} className='w-full h-full bg-white flex-wrap justify-start text-left pl-10 rounded-[32px] border border-slate-800 text-[16px] font-medium text-slate-800'>
                        {t('profileEmployer.edit')}
                    </button>
                    <img className="w-5 h-5 ml-36 mt-2 absolute pointer-events-none" src='../assets/icons/edit.png' />
                </div>
            </div>
            <div className='mt-12 flex'>
                <div className='w-3/12'>
                    <div className='flex flex-row'>
                        {!page.profileImageUrl &&
                            <img className='w-48 h-48 bg-gray-200 rounded-full' src='../assets/images/Ellipse 762.svg' alt='' />
                        }
                        {page.profileImageUrl &&
                            <img className="w-48 h-48 rounded-full object-cover mx-auto" src={page.profileImageUrl} />
                        }
                    </div>
                    <div className='flex flex-row mt-10 pl-4'>
                        <p className="text-zinc-800 text-[18px] font-bold"> {t('profileEmployer.pages')} </p>
                    </div>
                    <div className='flex flex-row mt-4 pl-4'>
                        <img className='w-5 h-5' src="../assets/icons/link-alt.png" alt='' />
                        {page.website &&
                            <div className="pl-1 text-sky-900 text-[12px] font-normal"><a href={(page.website) ? page.website : 'Add website'} target="_blank">{page.website}</a></div>
                        }
                        {!page.website &&
                            <div className="pl-1 text-sky-900 text-[12px] font-normal"><a href={(page.website) ? page.website : 'Add website'} target="_blank">{t('profileEmployer.website')}</a></div>
                        }
                    </div>
                    <div className='flex flex-row mt-4 pl-4'>
                        <img className='w-5 h-5' src="../assets/icons/linkedin-alt.png" alt='' />
                        {page.linkedin &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.linkedin} target="_blank">{page.linkedin}</a></div>
                        }
                        {!page.linkedin &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.linkedin} target="_blank">{t('profileEmployer.linkedin')}</a></div>
                        }
                    </div>
                    <div className='flex flex-row mt-4 pl-4'>
                        <img className='w-5 h-5' src="../assets/icons/twitter.png" alt='' />
                        {page.twitter &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.twitter} target="_blank">{page.twitter}</a></div>
                        }
                        {!page.twitter &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.twitter} target="_blank">{t('profileEmployer.twitter')}</a></div>
                        }
                    </div>
                    <div className='flex flex-row mt-4 pl-4'>
                        <img className='w-5 h-5' src="../assets/icons/facebook.png" alt='' />
                        {page.facebook &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.facebook} target="_blank">{page.facebook}</a></div>
                        }
                        {!page.facebook &&
                            <div className="pl-2 text-sky-900 text-[12px] font-normal"><a href={page.facebook} target="_blank">{t('profileEmployer.facebook')}</a></div>
                        }
                    </div>
                    <div className='flex flex-row pt-16'>
                       {/**  <button className='w-40 h-8 bg-orange-500 rounded-full justify-center items-center text-white text-sm font-medium'>{t('profileEmployer.button')}</button> */}
                    </div>
                </div>
                <div className='w-9/12'>
                    <div className='flex flex-row w-full'>
                    {companyName && (
                        <p className="text-[#2B2C2B] font-['Poppins'] text-2xl">{companyName}</p>
                    )}
                    </div>
                    <div className='flex flex-row w-full'>
                    {location && (
                        <p className="text-[#0D497D] text-sm font-['Poppins'] font-bold">{location}</p>
                    )}
                    </div>
                    <div className='w-full flex flex-row justify-center'>
                        <div className='w-[640px] h-[360px] bg-white pt-6' >
                            {!urlVideo &&
                                <img src='../assets/images/Video.png' alt='' />
                            }
                            {urlVideo &&
                                <ReactPlayer url={urlVideo} controls={true} />
                            }
                        </div>
                    </div>
                    <div className=" mt-20 w-full text-left flex flex-row">
                        <p className='text-black text-base font-medium'>{t('profileEmployer.mission')}</p>
                    </div>
                    <div className='w-full flex flex-row text-justify mt-5'>
                        <div className="h-24 text-zinc-500 text-xs font-normal leading-tight">{mission}</div>
                    </div>
                    <div className=" mt-20 w-full text-left flex flex-row">
                        <p className='text-black text-base font-medium'>{t('profileEmployer.vision')}</p>
                    </div>
                    <div className='w-full flex flex-row text-justify mt-5'>
                        <div className="h-24 text-zinc-500 text-xs font-normal leading-tight">{vision}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
