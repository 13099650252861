import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setActiveJob } from '../store/slices/job/jobSlice';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';
import { ActiveCandidates } from './Employer/ActiveCandidates';
import { startLoadingActiveJobs } from '../store/slices/activeJobs/thunks';
import { CurrentProcessesTab } from './Employer/CurrentProcessesTab';
import { startLoadingEmployeeProfiles } from '../store/slices/profile/thunks';
import { CreateAJob } from '../components/Employer/Jobs/AddAJob';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { startLoadingJobById } from '../store/slices/job/thunks';
import ReactPlayer from 'react-player';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function currencySymbols(currency) {
    switch (currency) {
        case 'E':
            return '€';
        case 'D':
            return '$';
        case 'Q':
            return 'Q';
        default:
            return '';
    }
}

export const JobDetail = ({ onClick }) => {
    const { active } = useSelector(state => state.job);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const { id, companyName, maximum, minimum, customJobTitle, currency, jobModel, jobtitle, jobType, description, joblocation, locationCity, urlVideo, tagsJob, department } = active || []
    const [newJob, setNewJob] = useState(false);
    const { t } = useTranslation()
    const Symbol = currencySymbols(currency);
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);
    const jobIdFromURL = params.get('id');
    const { id: jobIdFromParams } = useParams();
    const navigate = useNavigate();
    const jobId = jobIdFromParams || jobIdFromURL;

    const onCreateJob = () => {
        setNewJob(true);
    }

    const onCloseJob = () => {
        setNewJob(false);
    }

    const closeDetail = () => {
        dispatch(setActiveJob({}));
        navigate('/jobs');
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(startLoadingEmployeeProfiles());
    }, [value])

    useEffect(() => {
        if (jobId) {
            dispatch(startLoadingJobById(jobId));
        }
    }, [dispatch, jobId]);


    return (
        <>
            {newJob &&
                <CreateAJob onClick={onCloseJob} jobEdit={true} jobDetail={active} jobId={jobId} />
            }
            {!newJob &&
                <div className='w-full bg-white  min-h-[720px]'>
                    <div className='w-full flex flex-row pt-4 h-20'>
                        <div className='w-1/12 pt-2'>
                            <button onClick={closeDetail}>
                                <img className="w-6 h-6 rounded-mx" src="../assets/icons/left-arrow-from-left.png" alt='' />
                            </button>
                        </div>
                        <div className='w-8/12'>
                            <div className='w-full flex flex-col'>
                                <p className="text-2xl font-bold text-gray-800 text-left">{jobtitle}</p>
                                <p className="text-gray-500 text-sm font-medium text-left">{customJobTitle} || {joblocation} , {locationCity}</p>
                            </div>
                        </div>
                        <div className='w-3/12 flex '>
                            <div className="w-4/5 items-left justify-left text-left">
                                <button className="w-28 h-8 text-sm bg-[#FF7F11] font-medium rounded-full text-center text-white" onClick={() => onCreateJob()}>{t("jobDetail.btn")}</button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-2 h-16 pl-24'>
                        <div className='w-1/2'>
                            <div className='w-full flex flex-row'>
                                <div className='2/6 pt-2'>
                                    <img src='../assets/icons/money-stack.png' alt='' />
                                </div>
                                <div className='4/6 pl-2'>
                                    <div className='w-full flex flex-row'>
                                        <p className="text-black text-base font-medium">
                                            {`${Symbol}${minimum} to ${Symbol}${maximum}`}
                                        </p>
                                    </div>
                                    <div className='w-full flex flex-row'>
                                        <p className="text-grey-800 text-xm font-normal">{companyName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/2'>
                            <div className='w-full flex flex-row justify-center'>
                                <div className="w-96 h-16 flex opacity-90 bg-white rounded-full border border-zinc-500">
                                    <div className='flex flex-wrap w-1/3 pt-6 pl-3'>
                                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-semibold break-words">{t("jobDetail.badges")} </p>
                                    </div>
                                    <div className='flex  w-2/3 justify-end pr-10'>
                                        <div className='pt-1'>
                                            <img className='flex w-7 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%202.png?alt=media&token=c6f44aae-7902-45d1-b48f-fb097b8a0912" />
                                        </div>
                                        <div className='pt-1 pl-2'>
                                            <img className='flex w-7 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%203.png?alt=media&token=45b35fbf-9ff1-45f9-a048-63d328284a7c" />
                                        </div>
                                        <div className='pt-1 pl-2'>
                                            <img className='flex w-7 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%204.png?alt=media&token=1ec44765-7d7a-4d56-8e04-c0976483e366" />
                                        </div>
                                        <div className='pt-1 pl-2'>
                                            <img className='flex w-7 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%205.png?alt=media&token=260be5f7-9283-4104-a958-831df00d71dc" />
                                        </div>
                                        <div className='pt-1 pl-2'>
                                            <img className='flex w-7 h-7 mt-3' src="https://firebasestorage.googleapis.com/v0/b/xrecruit-5c93b.appspot.com/o/badges%2Fimage%206.png?alt=media&token=19cb9f22-4cae-4903-90c9-137dd3e3ab7c" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/3 flex-row flex pl-20'>
                        <div className='w-full flex justify-center pr-6'>
                            <div className='w-1/3 flex-wrap pt-6'>
                                <div className='w-24 h-5 bg-white rounded-full border border-lime-400 justify-center items-center gap-1 inline-flex'>
                                    <img className='w-3 h-3' src='../assets/icons/map-marker.png' alt='' />
                                    <p className="text-slate-800 text-xs font-normal leading-none"></p>
                                </div>
                            </div>
                            <div className='w-1/3 pt-6 pl-1'>
                                <div className='w-24 h-5 bg-white rounded-full border border-lime-400 justify-center items-center gap-1 inline-flex'>
                                    <img className='w-3 h-3' src='../assets/icons/estate.png' alt='' />
                                    <p className="text-slate-800 text-xs font-normal leading-none"> {jobModel} </p>
                                </div>
                            </div>
                            <div className='w-1/3 pt-6 pl-2'>
                                <div className='w-24 h-5 bg-white rounded-full border border-lime-400 justify-center items-center gap-1 inline-flex'>
                                    <img className='w-3 h-3' src='../assets/icons/clock.png' alt='' />
                                    <p className="text-slate-800 text-xs font-normal leading-none">{jobType}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-row pt-8 justify-center'>
                        <div className='w-[640px] h-[360px] bg-gray-400'>
                            {urlVideo ? (
                                <ReactPlayer url={urlVideo} controls={true} />
                            ) : (
                                <img src="../assets/images/add-video.png" className="w-full h-full object-cover" onClick={() => onCreateJob()}></img>
                            )}
                        </div>
                    </div>
                    <div className='mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20 2xl:mx-24'>
                        <div className='max-w-screen-lg mx-auto'>
                            <h2 className='text-2xl font-bold text-gray-800 text-center mt-4'>{t("jobDetail.description")}</h2>
                            <div className='bg-white rounded-lg shadow-md p-4 mt-4' dangerouslySetInnerHTML={{ __html: description }} />
                        </div>
                    </div>
                    {tagsJob && tagsJob.length > 0 && (
                        <div className='mx-4 sm:mx-8 md:mx-12 lg:mx-16 xl:mx-20 2xl:mx-24 mt-4'>
                            <h3 className='text-2l font-bold text-gray-800 text-left'>tags del puesto</h3>
                            <div className='flex flex-wrap gap-2'>
                                {tagsJob.map((tag, index) => (
                                    <Chip
                                        key={index}
                                        label={tag}
                                        variant="outlined"
                                        size="small"
                                        color="primary"
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    <div className='pt-20 min-h-[720px]'>
                        <Box sx={{ width: '90%', marginLeft: '60px' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label={t("jobDetail.current")} {...a11yProps(0)} />
                                    <Tab label={t("jobDetail.active")} {...a11yProps(1)} />
                                    <Tab label={t("jobDetail.recommended")} {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <CurrentProcessesTab />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ActiveCandidates />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                            </TabPanel>
                        </Box>
                    </div>
                </div>
            }
        </>
    )
}
