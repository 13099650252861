


export function extraerDominio(correoElectronico) {
    // Expresión regular para extraer el dominio de un correo electrónico
    var regex = /@([a-zA-Z0-9.-]+)$/;

    // Obtener coincidencias utilizando la expresión regular
    var matches = correoElectronico.match(regex);

    // Verificar si hay coincidencias y devolver el dominio si es así
    if (matches && matches.length > 1) {
        return matches[1];
    } else {
        // Devolver null si no se encuentra un dominio
        return null;
    }
}