import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { Select, Option } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { saveCompanyProfile } from '../store/slices/companyProfile/thunks';
import { useDispatch, useSelector } from 'react-redux';

export const TellUsAboutEmployer = ({ onClick }) => {
    const { t } = useTranslation();
    const [company, setCompany] = useState('');
    const [title, setTitle] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [webSite, setWebSite] = useState('');
    const dispatch = useDispatch()
    const { profile } = useSelector(state => state.profile);
    const idCompany = profile[0].idCompany

    const submit = async () => {        
        if (company.length > 0) {
            onClick('2');
            const formdata = {
                company,
                title,
                phone,
                country,
                zipCode,
                webSite
            };
            await dispatch(saveCompanyProfile({ idCompany: idCompany, formdata: formdata }));
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error...',
                text: 'Por favor revisa los datos ingresados'
            })
        }
    }

    return (
        <>
            <div className='flex flex-col bg-white w-full place-items-center h-[780px] ml-80 mr-80'>
                <div className='w-full flex flex-row text-center justify-center mt-14'>
                    <p className="text-center text-zinc-800 text-2xl font-bold">{t('tellus.title')}</p>
                </div>
                <div className='flex flex-col w-4/12 my-14'>
                    <div className='flex flex-col w-full my-4'>
                        <input className='w-full rounded-lg indent-3' type='text' value={company} onChange={(e) => setCompany(e.target.value)} placeholder={t('tellus.company')} style={{ border: '1px solid #D7DEE1', height: '56px' }} />
                    </div>
                    <div className='flex flex-col w-full my-2'>
                        <select className='w-full rounded-lg indent-3' type='text' defaultValue={'1'} onChange={(e) => setTitle(e.target.value)} placeholder={t('tellus.current')} style={{ border: '1px solid #D7DEE1', height: '56px' }}>
                            <option value="1" disabled>{t('tellus.current')}</option>
                            <option value="D">Developer</option>
                            <option value="E">Engineer</option>
                        </select>
                    </div>
                    <div className='flex flex-col w-full my-4'>
                        <input className='w-full rounded-lg indent-3' type='text' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder={t('tellus.phone')} style={{ border: '1px solid #D7DEE1', height: '56px' }} />
                    </div>
                    <div className='flex my-4'>
                        <div className='w-9/12 mr-4'>
                            {/* <input className='w-full rounded-lg indent-3' type='text' value={country} onChange={(e) => setCountry(e.target.value)} placeholder={t('tellus.country')} style={{ border: '1px solid #D7DEE1', height: '56px' }} /> */}
                            <select className='w-full rounded-lg indent-3' type='text' defaultValue={'1'} onChange={(e) => setCountry(e.target.value)} placeholder={t('tellus.current')} style={{ border: '1px solid #D7DEE1', height: '56px' }}>
                                <option value="1" disabled>{t('tellus.country')}</option>
                                <option value="GT">🇬🇹 Guatemala</option>
                                <option value="USA">🇺🇸 USA</option>
                                <option value="MX">🇲🇽 Mexico</option>
                                <option value="SAL">🇸🇻 El Salvador</option>
                            </select>
                        </div>
                        <div className='w-3/12 space-x-4'>
                            <input className='w-full rounded-lg indent-3' type='text' value={zipCode} onChange={(e) => setZipCode(e.target.value)} placeholder={t('tellus.zipcode')} style={{ border: '1px solid #D7DEE1', height: '56px' }} />
                        </div>
                    </div>
                    <div className='flex my-4'>
                        <input className='w-full rounded-lg indent-3' type='text' value={webSite} onChange={(e) => setWebSite(e.target.value)} placeholder={t('tellus.website')} style={{ border: '1px solid #D7DEE1', height: '56px' }} />
                    </div>
                    <div className='my-2'>
                        <button className=' h-14 bg-[#0D497D] rounded-full w-full text-white' type='submit' onClick={() => submit()}>{t('tellus.continue')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
