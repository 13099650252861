import { FirebaseDB } from "../firebase/config";
import { query, collection, getDocs } from "firebase/firestore/lite";

export const loadBadgeCategories = async () => {
    // if (!uid) throw new Error('El UID del usuario no existe');

    const q = query(
        collection(FirebaseDB, 'badgeCategories')
    )

    const docs = await getDocs(q);

    const badgesCategories = [];
    docs.forEach(doc => {
        badgesCategories.push({ id: doc.id, ...doc.data() });
    })
    return badgesCategories;
}