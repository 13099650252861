import React, { useEffect, useState, useTransition } from 'react'
import { Fab, TextField, Modal, Box } from '@mui/material'
import { useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


export const ProfileBio = ({ getBio, data }) => {
    const [bio, setBio] = useState('');
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            aboutme: ""
        }
    });

    useEffect(() => {
        setBio(data);
        if (data) {
            setValue('aboutme', data);
        }
    }, [data])

    useEffect(() => {
        getBio(bio);
    }, [bio])

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    const onSubmit = (dataBio) => {
        const {
            aboutme
        } = dataBio;

        setBio(aboutme);
        handleClose();
        // limpiar();

    }

    const limpiar = () => {
        setBio('');
    }

    return (
        <>
            <div className='flex flex-row w-full mt-8 pl-6'>
                <div className='w-full text-left'>
                    <p className='inline-flex text-zinc-800 pt-1 font-bold text-[18px]'>{t('profileTabEmployee.about')}</p>
                </div>
                <div className='w-1/2 justify-end items-end place-content-end'>
                    <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                        onClick={handleOpen}>
                        {t('profileTabEmployee.addAbout')}
                        {/* <img className="w-5 h-5 relative" src='' /> */}
                    </button>
                </div>
            </div>
            <div className='flex flex-row w-full mt-4 pl-6 pr-6'>
                <p className=" font-['Poppins'] w-full text-justify text-[#818181] text-xs/[12px] font-normal leading-5">
                    {bio}
                </p>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full mt-2 pl-6 text-left'>
                        <div className='w-1/2 mt-4'>
                            <p className=' text-zinc-800 font-bold text-[18px]'>{t('profileTabEmployee.about')}</p>
                        </div>
                    </div>
                    <div className='flex flex-row mt-6'>
                        <div className='w-full pl-5 pr-2'>
                            <TextField
                                id='aboutme'
                                multiline
                                rows={6}
                                placeholder={t('profileTabEmployee.bio')}
                                className='w-full'
                                {...register("aboutme")}
                            />
                        </div>
                    </div>

                    <div className='flex flex-row mt-10 justify-end'>
                        <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleSubmit(onSubmit)}>{t('pagesEmployee.save')}</button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
