import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from './store/auth/thunks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AppMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(true);
    const [selectedOption, setSelectedOption] = useState(0);
    const { t } = useTranslation();
    const { profile } = useSelector(state => state.profile);
    const phone = profile[0].phone

    const Menus = [
        { title: "menu.dashboard", src: "apps", path: "/dashboard", index: "0" },
        { title: "menu.jobs", src: "file-alt", path: "/jobs", index: "0" },
        { title: "menu.candidates", src: "users-alt", path: "/candidates", index: "1" },
        { title: "menu.profile", src: "users-alt", path: "/profile", index: "2" },
        { title: "menu.badges", src: "star", path: "/badges", index: "3" },
        { title: "menu.messages", src: "message", path: "/messages", index: "4" },
        { title: "menu.badges_evaluation", src: "file-alt", path: "/badgesevaluation", index: "5" }
    ]

    {/** parte de menu pero genera conlficto  { title: "menu.resume", src: "money-withdraw", path: "/resume", index: "3" },
        { title: "menu.alerts", src: "bell", path: "/alerts", index: "5" }, */}


    const onLogout = () => {
        dispatch(startLogout());
    }

    const setMenuOption = (option, index) => {
        setSelectedOption(index);
        navigate(option);
    }

    return (
        <>

            <div className=' hidden md:block'>
                <div className={`${open ? " w-72" : "w-20"} duration-300  p-5 pt-6 bg-[#F1F3F4] relative font-['Poppins'] text-sm`}>
                    {/* <img src="https://file.rendit.io/n/pIoIr9wNEOvY0b5hnyDd.png"
                        className={`absolute cursor-pointer rounded-full -right-3 top-9 w-7 border-2 border-sidebar-color ${!open && "rotate-180"}`} alt=''
                        onClick={() => setOpen(!open)} /> */}
                    <div className='flex gap-x-4 items-center'>
                        <img src="https://file.rendit.io/n/pIoIr9wNEOvY0b5hnyDd.png" alt='' className={`cursor-pointer duration-500 ${open && "rotate-[360deg]"}`} />
                    </div>
                    <hr className='mt-6' />
                    <ul className='pt-6'>
                        {Menus.map((menu, index) => (
                            // Verificar si formComplete tiene información y el título del menú es "dashboard"
                            ((phone && menu.title === "menu.dashboard") ? null : (
                                <li key={index}
                                    className={` text-sm flex items-center gap-x-4 cursor-pointer p-6 hover:bg-[#0D497D] hover:text-white rounded-full h-14
                ${menu.gap ? "mt-9" : "mt-2"} ${index === selectedOption && ('bg-[#0D497D]')}  ${index !== selectedOption && 'text-[#1F2A3B]'} ${index === selectedOption && ('text-white')}`} onClick={() => setMenuOption(menu.path, index)}>
                                    <img src={`${index === selectedOption ? `../assets/icons/${menu.src}-white.png` : `../assets/icons/${menu.src}.png`}`} />
                                    <span className={`${!open && 'hidden'} origin-left duration-200 `}>{t(menu.title)}</span>
                                </li>
                            ))
                        ))}
                    </ul>
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                    <div>
                        <hr />
                        <ul >
                            <li
                                className={`text-[#1F2A3B] text-sm flex items-center justify-center gap-x-4 cursor-pointer p-6 hover:bg-[#0D497D] hover:text-white rounded-full h-14`} onClick={onLogout}>
                                <img src={`../assets/icons/exit.png`} />
                                <span className={`${!open && 'hidden'} origin-left duration-200 `}>{t('menu.logout')}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
