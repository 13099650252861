import { useState } from "react";
import { useSelector } from 'react-redux';
import Answers from "./Answers";
import AnswersTrueOrFalse from "./AnswersTrueOrFalse";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

export const Question = ({
    index,
    onSelectAnswer,
    onSelectResponse,
    onSkipAnswer
}) => {
    const { active } = useSelector(state => state.evaluation);
    const arrayTmp = [...active.multipleManyAnswers, ...active.multipleOneAnswer];
    const percent = ((index * 100) / arrayTmp.length);

    const [answer, setAnswer] = useState({
        selectedAnswer: '',
        isCorrect: null,
        isCorrectResponse: null
    });

    function handleSelectAnswer(answer) {

        let position = arrayTmp[index].answers.indexOf(answer);

        setAnswer({
            selectedAnswer: answer,
            isCorrect: null,
            isCorrectResponse: null
        })

        setTimeout(() => {
            setAnswer({
                selectedAnswer: answer,
                isCorrect: arrayTmp[index].reponses[position] === true
            })

            setTimeout(() => {
                onSelectAnswer(answer);
                onSelectResponse(arrayTmp[index].reponses[position]);
            }, 1200);
        }, 1000);
    }

    let answerState = '';

    if (answer.selectedAnswer && answer.isCorrect === true) {
        answerState = answer.isCorrect ? 'correct' : 'wrong';
    } else if (answer.selectedAnswer) {
        answerState = 'answered';
    }


    return (
        <div className='flex flex-col bg-white w-full place-items-center h-[900px]'>

            <div className="w-full pl-20 pr-20 pt-2">
                <BorderLinearProgress variant="determinate" value={percent} />
            </div>
            {(arrayTmp[index]?.urlImage !== '') &&
                <div className='w-full flex flex-row items-center justify-center text-center pt-10 h-60 place-content-center'>

                    <div className='justify-center items-center place-content-center'>

                        <img className="w-auto h-44" src={arrayTmp[index]?.urlImage} alt="" />

                    </div>
                </div>
            }
            <div className='w-full  pl-20 pr-20 pt-12'>

                <p className='w-full text-center pl-4 pr-4 pt-10' dangerouslySetInnerHTML={{ __html: arrayTmp[index].question }}>
                    {/* <h2>{active.multipleOneAnswer[index].question}</h2> */}
                </p>
            </div>
            <div className='w-2/3 pt-20 pl-20 pr-20 items-center justify-center align-middle'>
                {arrayTmp[index].typeEvaluation === 'TrueOrFalse' &&
                    <AnswersTrueOrFalse
                        answers={arrayTmp[index].answers}
                        selectedAnswer={answer.selectedAnswer}
                        answerState={answerState}
                        onSelect={handleSelectAnswer}
                    />
                }
                {arrayTmp[index].typeEvaluation !== 'TrueOrFalse' &&
                    <Answers
                        answers={arrayTmp[index].answers}
                        selectedAnswer={answer.selectedAnswer}
                        answerState={answerState}
                        onSelect={handleSelectAnswer}
                    />
                }
            </div>
        </div>
    )
}
