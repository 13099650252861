import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    jobs: [],
    active: null
}

export const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {
        savingNewJob: (state) => {
            state.isSaving = true;
        },
        addNewEmptyJob: (state, action) => {
            const newJob = action.payload;
            state.jobs.push(newJob);
            state.isSaving = false;
        },
        setActiveJob: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setJobs: (state, action) => {
            state.jobs = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
       
        updateJob: (state, action) => {
            const { jobId, editedJob } = action.payload;
            console.log("ASdasfsa", action.payload);
            const index = state.jobs.findIndex(job => job.id === jobId);
            if (index !== -1) {
              state.jobs[index] = editedJob;
            }
          },
        
        deleteJobById: (state, action) => {

        },

        loadJobById: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        }
        
    }
})

export const {
    savingNewJob,
    addNewEmptyJob,
    setActiveJob,
    setJobs,
    setSaving,
    updateJob,
    deleteJobById,
    loadJobById
} = jobSlice.actions