import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    messages: [],
    active: null
}

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        savingNewMessage: (state) => {
            state.isSaving = true;
        },
        addNewEmptyMessage: (state, action) => {
            const newMessage = action.payload;
            state.messages.push(newMessage);
            state.isSaving = false;
        },
        setActiveMessage: (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setMessages: (state, action) => {
            state.messages = action.payload;
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateMessage: (state, action) => {
            state.isSaving = false;
            state.messages = state.messages.map(message => {
                if (message.id === action.payload.id) {
                    return action.payload;
                }
                return message;
            })
            state.messageSaved = `${action.payload.title}, actualizada correctamente`
        },
    }
})

export const {
    savingNewMessage,
    addNewEmptyMessage,
    setActiveMessage,
    setMessages,
    setSaving,
    updateMessage,
} = messageSlice.actions