import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Fab, FormControl, InputLabel, MenuItem, Checkbox, Select, TextField, Modal, Box, OutlinedInput, ListItemText, FormControlLabel, FormGroup, Avatar } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import Moment from 'react-moment';
import { RemoveCircle, RemoveCircleOutline } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
// import ReactTags from 'react-tag-autocomplete'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 780,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const badgesData = [
    'React',
    'Javascript',
    'Java',
    'C#',
    'Python'
];

const jobsLevelData = [
    'Senior',
    'Junior',
    'Team Leader',
    'Project Manager'
]

export const WorkExperienceEmployee = ({ getExperiencie, data }) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [works, setWorks] = useState([]);
    const [worksTmp, setWorksTmp] = useState([]);
    const [achievement, setAchievement] = useState([]);
    const [textAchievement, setTextAchievement] = useState('');
    const [badges, setBadges] = useState([]);
    const [jobLevel, setJobLevel] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [tags, setTags] = useState([])
    const [currentJob, setCurrentJob] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const reactTags = useRef();
    const [suggestions, setSuggestions] = useState([
        { id: 1, name: "Apples" },
        { id: 2, name: "Pears" },
        { id: 3, name: "Bananas" },
        { id: 4, name: "Mangos" },
        { id: 5, name: "Lemons" },
        { id: 6, name: "Apricots" }
    ])
    const { t } = useTranslation();

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            joblocation: "",
            startDate: "",
            endDate: ""
        }
    });

    useEffect(() => {
        if (data) {
            setWorks(data);
        }
    }, [data])

    // useEffect(() => {
    //     getExperiencie(works);
    // }, [works])

    useEffect(() => {
        if (worksTmp) {
            setIsSaving(true);
            const {
                company,
                jobTitle,
                joblocation,
                name,
                summary
            } = worksTmp;
    
            let endDateTmp = '';
    
            if (!currentJob) {
                endDateTmp = moment(endDate).format("DD/MM/YYYY")
            } else {
                endDateTmp = ''
            }
    
            const formattedStartDate = startDate ? moment(startDate).format("DD/MM/YYYY") : null;
            const formattedEndDate = endDate ? moment(endDate).format("DD/MM/YYYY") : null;
    
            const data = {
                Achievements: achievement,
                company,
                jobTitle,
                joblocation,
                name,
                summary,
                jobLevel: jobLevel,
                currentJob: currentJob,
                startDate: formattedStartDate,
                endDate: formattedEndDate
            }
            setWorks([...works, data]);
    
        }
    }, [worksTmp])
    

    useEffect(() => {
        if (works && isSaving) {
            saveWorks(works);
        }
    }, [works])

    const saveWorks = (dataWorks) => {
        getExperiencie(dataWorks);
        handleClose();
        limpiar();
    }

    
    const onSubmit = (dataWorkExperience) => {
        setWorksTmp(dataWorkExperience);
    }

    const handleChangeBadges = (event) => {
        const {
            target: { value },
        } = event;
        setBadges(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeJobLevel = (event) => {
        const {
            target: { value },
        } = event;
        setJobLevel(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeCurrentJob = (event) => {
        let current = event.target.checked;
        setCurrentJob(current);
        if (current) {
            setEndDate("");
        }
    }

    const handleAddAchievement = () => {
        if (achievement.length > 4) return;
        let achievementTmp = textAchievement;
        setAchievement([...achievement, achievementTmp]);
        setTextAchievement('');
    }

    const handleRemoveAchievement = (index) => {
        setAchievement(oldValues => {
            return oldValues.filter((_, i) => i !== index)
        })
        setAchievement((state) => {
            return state;
        })
    }

    const onDelete = useCallback((tagIndex) => {
        setTags(tags.filter((_, i) => i !== tagIndex))
    }, [tags])

    const onAddition = useCallback((newTag) => {
        setTags([...tags, newTag])
    }, [tags])

    const limpiar = () => {
        setValue('company', "")
        setValue('jobTitle', "")
        setValue('jobLevel', "")
        // setValue('Achievements', "")
        setValue('summary', "")
        setBadges([]);
        setJobLevel([]);
        setStartDate(null);
        setEndDate(null);
        setTextAchievement('');
        setAchievement('');
        setIsSaving(false);
    }

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    const handleStartDateChange = (newValue) => {
        if (newValue && typeof newValue === 'object' && newValue.$d) {
            setStartDate(newValue.$d);
        } else {
            console.error("Formato de objeto newValue no válido:", newValue);
        }
    };
    
    const handleEndDateChange = (newValue) => {
        if (newValue && typeof newValue === 'object' && newValue.$d) {
            setEndDate(newValue.$d);
        } else {
            console.error("Formato de objeto newValue no válido:", newValue);
        }
    };
    
    return (
        <>
            <div className='flex flex-row w-full mt-8 pl-6'>
                <div className='w-full text-left'>
                    <p className='inline-flex text-zinc-800 pt-1 font-bold text-[18px]'>{t('profileTabEmployee.experience')}</p>
                </div>
                <div className='w-1/2 justify-end items-end place-content-end'>
                    <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                        onClick={handleOpen}>
                        + {t('profileTabEmployee.addExperience')}
                        {/* <img className="w-5 h-5 relative" src='' /> */}
                    </button>
                </div>
            </div>
            {(works.length > 0) && (
    <div className='w-full pt-8 justify-start text-left pl-6'>
        <table className='w-full'>
            {works.map((work, index) => (
                <tr key={index}>
                    <td className='text-gray-600 text-[16px] font-semibold '>{work.jobTitle}</td>
                    <td className='text-gray-600 text-[16px] font-normal '>{work.jobLevel}</td>
                    <td className='text-gray-600 text-[16px] font-normal '>{work.company}</td>
                    <td className='pl-10 '>
                    {work.startDate && <Moment format='YYYY'>{moment(work.startDate, 'DD/MM/YYYY')}</Moment>}
                    </td>
                    {work.currentJob ?
                        <td className='pl-10 '>To Date</td>
                        :
                        <td className='pl-10 '>
                            {work.endDate && <Moment format='YYYY'>{moment(work.endDate, 'DD/MM/YYYY')}</Moment>}

                        </td>
                    }
                   {/*<td><button className='' type='button' onClick={() => handleOpen()}><img className=" w-5 h-5" src="../assets/icons/edit.png" alt='' /></button></td>} */}
                </tr>
            ))}
        </table>
    </div>
)}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full pl-6 text-left'>
                        <div className='w-1/2 mt-4'>
                            <p className=' text-zinc-800 font-bold text-[18px]'>{t('profileTabEmployee.experience')}</p>
                        </div>
                    </div>
                    <div className='flex flex-row mt-4 pl-6 justify-between'>
                        <div className='w-4/12'>
                            <TextField
                                id='Company'
                                className='w-11/12'
                                placeholder={t('experienceEmployee.company')}
                                {...register("company")}
                            />
                        </div>
                        <div className='w-4/12'>
                            <TextField
                                id='Job Title'
                                className='w-11/12'
                                placeholder={t('experienceEmployee.jobTitle')}
                                {...register("jobTitle")}
                            />
                        </div>
                        <div className='w-4/12'>
                            <FormControl className='w-11/12'>
                                <InputLabel id="job-level-label">{t('experienceEmployee.jobLevel')}</InputLabel>
                                <Select
                                    labelId="job-level-label"
                                    id="job-level"
                                    label='Job Level'
                                    placeholder={t('experienceEmployee.jobLevel')}
                                    defaultValue=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleChangeJobLevel}
                                    value={jobLevel}
                                >
                                    {jobsLevelData.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='flex flex-row mt-4  pl-6'>
                        <div className='w-4/12'>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker value={startDate} onChange={handleStartDateChange} className='w-11/12' />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <div className='w-4/12'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker value={endDate} onChange={handleEndDateChange} className='w-11/12' disabled={currentJob} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <div className='4/12'>
                            <FormGroup className='justify-center pt-6 pl-2'>
                                <FormControlLabel
                                    control={<Checkbox checked={currentJob}
                                        onChange={handleChangeCurrentJob} />}
                                    label={t('experienceEmployee.current')} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className='flex flex-row mt-4 pl-6'>
                        <div className='w-full pr-2'>
                            <TextField
                                multiline
                                rows={4}
                                placeholder={t('experienceEmployee.summary')}
                                className='w-full'
                                {...register("summary")}
                            />
                        </div>
                    </div>
                    {/* <div className='flex flex-row mt-4 pl-6'>
                        <FormControl className='w-full'>
                            <InputLabel id='applied-badges-label'>Applied Badges</InputLabel>
                            <Select
                                labelId="applied-badges-label"
                                id="applied-badges"
                                multiple
                                value={badges}
                                onChange={handleChangeBadges}
                                input={<OutlinedInput label="Applied Badges" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {badgesData.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={badges.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div> */}
                    <div className='flex flex-row mt-4 pl-6'>
                        <div className='w-11/12'>
                            <TextField
                                id='name'
                                className='w-full'
                                placeholder={t('experienceEmployee.achievement')}
                                value={textAchievement}
                                onChange={(e) => setTextAchievement(e.target.value)}
                                inputProps={{ maxLength: 70 }}
                            />
                        </div>
                        <div className='w-1/2 pt-3 pl-4 justify-end items-end place-content-end'>
                            <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                                onClick={handleAddAchievement} disabled={(achievement.length > 4) ? true : false}>
                                + {t('experienceEmployee.achievement')}
                            </button>
                        </div>
                    </div>
                    {(achievement.length > 0) &&
                        <div className='w-full'>
                            <div className='w-full pt-2 text-left pl-20'>
                                <p className='inline-flex text-zinc-800 pt-1 font-bold text-[18px]'>{t('experienceEmployee.achievement')}</p>
                            </div>
                            <div className='w-10/12 pt-2 justify-center text-left pl-20'>
                                <table className='w-full'>
                                    {achievement.map((achieve, index) => (
                                        <tr key={index}>
                                            <td className='text-gray-600 text-[16px] font-semibold '>{achieve}</td>
                                            <td>
                                                {/* <button className='' type='button' onClick={() => handleOpen()}><img className=" w-5 h-5" src="../assets/icons/edit.png" alt='' /></button> */}

                                                <Avatar sx={{ bgcolor: '#f44336', width: 20, height: 20 }}>
                                                    <RemoveCircle sx={{ bgcolor: '#f44336', width: 20, height: 20 }} onClick={() => handleRemoveAchievement(index)} />
                                                </Avatar>
                                                {/* <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                                                    onClick={handleRemoveAchievement(index)}>
                                                    - Remove Achievement
                                                </button> */}
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                    }
                    <div className='flex flex-row mt-10 justify-end'>
                        <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleSubmit(onSubmit)}>{t('pagesEmployee.save')}</button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
