import React, { useEffect, useState } from 'react'
import { UpdateEmployerProfile } from './UpdateEmployerProfile';
import { useSelector, useDispatch } from 'react-redux';

export const DashboardEmployer = () => {
    const [firstTime, setFirstTime] = useState(true);
    const { profile } = useSelector(state => state.profile);
    const dispatch = useDispatch();

    useEffect(() => {
        // if (!profile) {

        // }
    }, [profile])

    useEffect(() => {

    }, [firstTime])

    const onStateUpdate = () => {
        setFirstTime(false);
    }

    return (
        <>
            {firstTime && <UpdateEmployerProfile updateState={onStateUpdate} />}
            {!firstTime &&
                <div className='flex flex-col bg-white w-full place-items-center h-[780px]'>
                    {/* <h1>Dashboard</h1> */}
                </div>
            }
        </>


    )
}
