import { Fab, FormControl, InputLabel, Switch, MenuItem, Checkbox, Select, TextField, Modal, Box, OutlinedInput, ListItemText, FormControlLabel, FormGroup, Avatar } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { RemoveCircle } from '@mui/icons-material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 480,
    bgcolor: 'background.paper',
    // border: '1px solid #000',
    boxShadow: 24,
    p: 4,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const VideoInterview = ({ getVideoInterviewData, data }) => {
    const [interviewChecked, setInterviewChecked] = useState(false);
    const [open, setOpen] = useState(false);
    const [question, setQuestion] = useState('');
    const [questionTime, setQuestionTime] = useState('');
    const [interviewQuestions, setInterviewQuestions] = useState([]);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            joblocation: "",
            startDate: "",
            endDate: ""
        }
    });

    const handleAddAchievement = () => {
        if (interviewQuestions.length > 4) return;
        // let questionTmp = question;
        let questionTmp = {
            question: question,
            questionTime: questionTime
        }
        setInterviewQuestions([...interviewQuestions, questionTmp]);
        setQuestion('');
        setQuestionTime('');
    }

    const handleRemoveAchievement = (index) => {
        setInterviewQuestions(oldValues => {
            return oldValues.filter((_, i) => i !== index)
        })
        setInterviewQuestions((state) => {
            return state;
        })
    }

    const handleChange = (event) => {
        setInterviewChecked(event.target.checked);
        const videoInterview = {
            interviewVideo: event.target.checked,
            questionsInterview: interviewQuestions
        }
        // getVideoInterviewData(videoInterview);
    };

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false)
    };

    const onSubmit = (data) => {
        getVideoInterviewData(interviewQuestions);
        setOpen(false);
    }

    return (
        <>
            <div className='flex flex-row ml-24 mr-24 mt-6'>
                <div className='flex w-8/12 justify-between'>
                    <TextField
                        id='job-title'
                        className='w-4/12 bg-gray-200'
                        label='Step #5 Video InterView'
                        placeholder="Video Inerview"
                        disabled
                    />
                    <div className='w-2/12'>
                        <Switch
                            checked={interviewChecked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </div>
                    <div className='w-4/12 justify-end items-end place-content-end'>
                        {interviewChecked &&
                            <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                                onClick={handleOpen}>
                                + Add Questions
                                {/* <img className="w-5 h-5 relative" src='' /> */}
                            </button>
                        }
                    </div>

                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full pl-6 text-left'>
                        <div className='w-1/2 mt-4'>
                            <p className=' text-zinc-800 font-bold text-[18px]'>Video Interview Questions</p>
                        </div>
                    </div><br /><br />

                    <div className='flex flex-row mt-4 pl-6'>
                        <div className='w-8/12'>
                            <TextField
                                id='name'
                                className='w-full'
                                placeholder='Write a question'
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                inputProps={{ maxLength: 70 }}
                            />
                        </div>
                        <div className='w-3/12 pl-2'>
                            <TextField
                                id='time'
                                className='w-full'
                                placeholder='Time per question'
                                value={questionTime}
                                onChange={(e) => setQuestionTime(e.target.value)}
                                inputProps={{ maxLength: 70 }}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row mt-4 pl-6'>
                        <div className='w-1/2 pt-3 pl-4 justify-end items-end place-content-end'>
                            <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                                onClick={handleAddAchievement} disabled={(interviewQuestions.length > 4) ? true : false}>
                                + Add Question
                            </button>
                        </div>
                    </div>
                    {(interviewQuestions.length > 0) &&
                        <div className='w-full'>
                            <div className='w-full pt-2 text-left pl-20'>
                                <p className='inline-flex text-zinc-800 pt-1 font-bold text-[18px]'>Questions</p>
                            </div>
                            <div className='w-10/12 pt-2 justify-center text-left pl-20'>
                                <table className='w-full'>
                                    {interviewQuestions.map((achieve, index) => (
                                        <tr key={index}>
                                            <td className='text-gray-600 text-[16px] font-semibold '>{achieve.question}</td>
                                            <td className='text-gray-600 text-[16px] font-semibold '>{achieve.questionTime}</td>
                                            <td>
                                                {/* <button className='' type='button' onClick={() => handleOpen()}><img className=" w-5 h-5" src="../assets/icons/edit.png" alt='' /></button> */}

                                                <Avatar sx={{ bgcolor: '#f44336', width: 20, height: 20 }}>
                                                    <RemoveCircle sx={{ bgcolor: '#f44336', width: 20, height: 20 }} onClick={() => handleRemoveAchievement(index)} />
                                                </Avatar>
                                                {/* <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                                                    onClick={handleRemoveAchievement(index)}>
                                                    - Remove Achievement
                                                </button> */}
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                    }
                    <div className='flex flex-row mt-10 justify-end'>
                        <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleSubmit(onSubmit)}>Save</button>
                    </div>

                </Box>

            </Modal>
        </>
    )
}
