import React, { useState, useMemo, useEffect } from 'react';
import Swal from 'sweetalert2';
import { startGoogleSignIn, startLoginWithEmailPassword, startLogout } from '../../store/auth/thunks';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { useCheckAuth } from '../../hooks/useCheckAuth';
import { setActiveProfile } from '../../store/slices/profile/profileSlice';

export const LoginPage = () => {
    const authResult = useCheckAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { status, errorMessage } = useSelector(state => state.auth);
    const [lookingJob, setLookingJob] = useState(false);
    const [userType, setUserType] = useState('');
    const isAuthenticating = useMemo(() => status === 'checking', [status]);

    const handleGoogleSignIn = () => {
        if (lookingJob) {
            sessionStorage.setItem('userType', 'Empleado');
        } else {
            sessionStorage.setItem('userType', 'Empleador');
        }
        dispatch(startGoogleSignIn());
        navigate("/");
    }

    useEffect(() => {
        dispatch(setActiveProfile());
    }, [dispatch])

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                submit();
            }
        }
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    })

    const submit = (event) => {
        if (email.length > 0) {
            dispatch(startLoginWithEmailPassword({ email, password }));
            navigate("/jobs");
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error...',
                text: 'Por favor revisa los datos ingresados'
            })
        }
    }

    const handleToggle = () => {
        setLookingJob(!lookingJob);
    }

    return (
        <div className='flex flex-col items-center justify-center w-full min-h-screen bg-white lg:flex-row'>
            <div className='flex flex-1 w-full lg:flex-row lg:items-center lg:justify-center mb-6 ml-6 mt-6'>
                <div className='w-5/12 hidden lg:block'>
                    {lookingJob &&
                        <img className="Steptodown3 w-[669px] h-[932px] rounded-3xl" src="../assets/images/employee.png" />
                    }
                    {!lookingJob &&
                        <img className="Steptodown3 w-[669px] h-[932px] rounded-3xl" src="../assets/images/steptodown.png" />
                    }
                </div>
                <div className='w-full lg:w-7/12 px-6 lg:px-20'>
                    <div className='flex flex-wrap w-full'>
                        <div className='w-full sm:w-1/2 flex justify-center sm:justify-start'>
                            <img src="https://file.rendit.io/n/azQfRkGArF4IpCUMMQE4.png" className="relative min-w-0 mb-px mt-14" alt='' />
                        </div>
                        <div className='w-full sm:w-1/2 flex justify-center sm:justify-end'>
                            <button className="text-center font-['Poppins'] font-medium bg-[#FF7F11] text-white shadow-md rounded-[32px] w-60 h-14 mt-4 sm:mt-16" style={{ padding: '16px 37px' }} onClick={handleToggle}>
                                {lookingJob ? t('login.lookinghire') : t('login.lookingjob')} 
                            </button>
                        </div>
                    </div>
                    <div className='flex flex-col mt-12 lg:mt-36 justify-center items-center text-center'>
                        <div className='w-full px-8 lg:px-32'>
                            <div className="flex flex-row text-center justify-center w-full font-['Poppins'] text-gray-900 text-2xl font-bold">{t('login.login')}</div>
                            <div className='flex flex-col w-full mt-12 justify-center items-center'>
                                <input className='w-full rounded-lg indent-3 h-14' type='text' value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('login.email')} style={{ border: '1px solid #D7DEE1' }} />
                            </div>
                            <div className='flex flex-col w-full mt-8 justify-center items-center'>
                                <input className='w-full rounded-lg indent-3 h-14' type='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder={t('login.password')} style={{ border: '1px solid #D7DEE1' }} />
                            </div>
                            <div className='flex mt-4 w-full text-left'>
                                <div className="text-sky-900 text-[12px] font-medium">{t('login.forgotpassword')}</div>
                            </div>
                            <div className='w-full mt-12'>
                                <button className='w-full h-14 bg-[#0D497D] rounded-full text-white' type='button' onClick={() => submit()} disabled={isAuthenticating}>{t('login.getStarted')}</button>
                            </div>
                            {errorMessage && (
                                <div className='pt-4'>
                                    <Alert severity="error">{t('login.errorMessage')}</Alert>
                                </div>
                            )}
                            <div className='flex mt-4 w-full text-center'>
                                <div className='w-full flex justify-center'>
                                    <p>{t('login.register')}</p>
                                    <Link to='/register'>
                                        <div className="text-sky-900 text-[16px] font-medium pl-2">{t('login.signup')}</div>
                                    </Link>
                                </div>
                            </div>
                            <div className={`font-['Poppins'] text-lg font-bold text-gray-400 my-4 ${lookingJob ? 'invisible' : ''}`}>- {t('login.or')} -</div>
                            <div className={`flex my-4 justify-between items-center h-14 ${lookingJob ? 'invisible' : ''}`}>
                                <button className='w-full h-14 text-gray-900 border rounded-lg flex justify-center items-center' onClick={handleGoogleSignIn}>
                                    <img className='w-8 mr-2' src="../assets/images/google_logo.png" alt='' />
                                    <p className='text-[13px]'>{t('login.google')}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
