import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateBadgeEmployer } from './CreateBadgeEmployer';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Card, CardContent, CardHeader, CardMedia, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { setActiveBadgesCatalog } from '../../../store/slices/badgesCatalog/badgesCatalogSlice';
import { BadgePreviewEmployer } from './BadgePreviewEmployer';
import { startLoadingBadgeCategories } from '../../../store/slices/badgesCategories/thunks';
import { startDeleteBadge, startLoadingBadgesCatalog } from '../../../store/slices/badgesCatalog/thunks';
import { startLoadingCompanyProfile } from '../../../store/slices/companyProfile/thunks';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const { evaluations } = useSelector(state => state.evaluation);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const BadgesEmployer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [newBadge, setNewBadge] = useState(false);
    const { badgesCategories } = useSelector(state => state.badgesCategorie);
    const { badgesCatalogs } = useSelector(state => state.badgesCatalog);
    const { profile } = useSelector(state => state.profile);
    const { companyProfile } = useSelector(state => state.companyProfile);
    const [basicFitBadges, setBasicFitBadges] = useState([]);
    const [compatibilityBadges, setCompatibilityBadges] = useState([]);
    const [skillBadges, setSkillBadges] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [value, setValue] = React.useState(0);
    const [edit, setEdit] = useState(false);

    const onCreateBadge = () => {
        setNewBadge(true);
        setEdit(false);
    }

    useEffect(() => {
        if (profile.length > 0) {
            dispatch(startLoadingCompanyProfile(profile[0].idCompany));
        }
    }, [])

    useEffect(() => {
        dispatch(startLoadingBadgeCategories());
    }, [])

    useEffect(() => {
        dispatch(startLoadingBadgesCatalog());
    }, [])

    useEffect(() => {
        dispatch(startLoadingBadgesCatalog());
    }, [newBadge])

    useEffect(() => {
        let tmpBasicFit = [];
        let tmpSkillAssesment = [];
        let tmpCompatility = [];
        let idCompany = '';
        if (companyProfile.length > 0) {
            idCompany = companyProfile[0].idCompany;
        }
        if (badgesCatalogs && badgesCategories) {
            let tmpBasicFitCategorie = badgesCategories.filter(x => x.name === 'Basic Fit');
            let tmpSkillAssesmentCategorie = badgesCategories.filter(x => x.name === 'Skill-Assessment');
            let tmpCompatibilityCategorie = badgesCategories.filter(x => x.name === 'Compatibility');
            if (tmpBasicFitCategorie.length > 0) {
                tmpBasicFit = badgesCatalogs.filter(x => x.categorie === tmpBasicFitCategorie[0].id && x.companyId === idCompany);
            }
            if (tmpSkillAssesmentCategorie.length > 0) {
                tmpSkillAssesment = badgesCatalogs.filter(x => x.categorie === tmpSkillAssesmentCategorie[0].id && x.companyId === idCompany);
            }
            if (tmpCompatibilityCategorie.length > 0) {
                tmpCompatility = badgesCatalogs.filter(x => x.categorie === tmpCompatibilityCategorie[0].id && x.companyId === idCompany);
            }
            setBasicFitBadges(tmpBasicFit);
            setSkillBadges(tmpSkillAssesment);
            setCompatibilityBadges(tmpCompatility);
        }
    }, [badgesCatalogs])

    const changeUpdateBadge = () => {
        setNewBadge(false);
    }

    const changePreviewBadge = () => {
        setShowPreview(false);
        setNewBadge(false);
    }

    const showUpdateBadge = () => {
        setEdit(true);
        setNewBadge(true);
        setShowPreview(false);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getBadgeDetail = (item) => {
        const {
            id,
            categorie,
            imageUrl,
            name,
            description,
            creationDate
        } = item;

        dispatch(setActiveBadgesCatalog({ id, name, description, imageUrl, categorie, creationDate }));
        setNewBadge(false);
        setShowPreview(true);
    }



    return (
        <>
            {showPreview &&
                <BadgePreviewEmployer onClick={changePreviewBadge} onClickUpdate={showUpdateBadge} />
            }
            {newBadge &&
                <CreateBadgeEmployer onClick={changeUpdateBadge} dataCategories={badgesCategories} edit={edit} />
            }
            {!newBadge && !showPreview &&
                <div className='flex flex-col bg-white w-full place-items-center min-h-[780px]'>
                    <div className="inline-flex items-start justify-start pl-20 pr-24 pt-14 pb-4 bg-white rounded-xl" >
                        <div className="inline-flex flex-col space-y-6 place-content-center items-center px-20 pt-8 pb-5 bg-gray-100 rounded-xl">
                            <p className="text-2xl font-bold text-center text-gray-800">{t('badges.title')}</p>
                            <button className='inline-flex  px-14 py-4 bg-blue-900 rounded-full items-center justify-center w-58 text-white text-base font-medium ' onClick={() => onCreateBadge()}>{t('badges.create')}</button>
                        </div>
                    </div>

                    <Box sx={{ width: '80%', marginLeft: '60px' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Basic Fit" {...a11yProps(0)} />
                                <Tab label="Compatibility" {...a11yProps(1)} />
                                <Tab label="Skills" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <div className='w-full flex flex-wrap pt-10'>
                                {basicFitBadges.map((item, index) => (
                                    <div key={index} className='w-1/3 h-[390px] pl-4'>
                                        <Button onClick={() => getBadgeDetail(item)}>
                                            <Card className='h-[330px] hover:transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...'>
                                                <CardHeader
                                                    title={item.name}
                                                />
                                                <div className='w-full flex justify-center place-content-center items-center text-center'>
                                                    <CardMedia
                                                        component="img"
                                                        // height="154"
                                                        sx={{ height: 140, width: 140, justifyContent: 'center', textAlign: 'center', alignContent: 'center', placeContent: 'center' }}
                                                        image={item.imageUrl}
                                                    />
                                                </div>
                                                <CardContent>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </CardContent>
                                            </Card></Button>
                                             
                                    </div>
                                ))

                                }
                            </div>

                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className='w-full flex flex-wrap pt-10'>
                                {compatibilityBadges.map((item, index) => (
                                    <div key={index} className='w-1/3 h-[390px] pl-4'>
                                        <Button onClick={() => getBadgeDetail(item)}>
                                            <Card className='h-[330px] hover:transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...'>
                                                <CardHeader
                                                    title={item.name}
                                                />
                                                <div className='w-full flex justify-center place-content-center items-center text-center'>
                                                    <CardMedia
                                                        component="img"
                                                        // height="154"
                                                        sx={{ height: 140, width: 140, justifyContent: 'center', textAlign: 'center', alignContent: 'center', placeContent: 'center' }}
                                                        image={item.imageUrl}
                                                    />
                                                </div>
                                                <CardContent>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Button>
                                        
                                    </div>
                                ))

                                }
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className='w-full flex flex-wrap pt-10'>
                                {skillBadges.map((item, index) => (
                                    <div key={index} className='w-1/3 h-[390px] pl-4'>
                                        <Button onClick={() => getBadgeDetail(item)}>
                                            <Card className='h-[330px] hover:transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...'>
                                                <CardHeader
                                                    title={item.name}
                                                />
                                                <div className='w-full flex justify-center place-content-center items-center text-center'>
                                                    <CardMedia
                                                        component="img"
                                                        // height="154"
                                                        sx={{ height: 140, width: 140, justifyContent: 'center', textAlign: 'center', alignContent: 'center', placeContent: 'center' }}
                                                        image={item.imageUrl}
                                                    />
                                                </div>
                                                <CardContent>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Button>
                                         
                                    </div>
                                ))

                                }
                            </div>
                        </TabPanel>
                    </Box>

                    {/* <div className='w-full pl-20 pr-20 pb-40'>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                            >
                                <Typography>Basic Fit</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <div className='w-full flex flex-wrap'>
                                    {basicFitBadges.map((item, index) => (
                                        <div key={index} className='w-1/4'>
                                            <Card>
                                                <CardHeader
                                                    title={item.name}
                                                />
                                                <CardMedia
                                                    component="img"
                                                    height="134"
                                                    image={item.imageUrl}
                                                />
                                                <CardContent>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    ))

                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                            >
                                <Typography>Compatibility Check</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                            >
                                <Typography>Skill Assesments</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='w-full flex flex-wrap'>
                                    {skillBadges.map((item, index) => (
                                        <div key={index} className='w-1/4'>
                                            <Card>
                                                <CardHeader
                                                    title={item.name}
                                                />
                                                <CardMedia
                                                    component="img"
                                                    height="134"
                                                    image={item.imageUrl}
                                                />
                                                <CardContent>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    ))

                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div> */}


                </div>
            }
        </>
    )
}
