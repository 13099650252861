import { query, collection, getDocs, where } from "firebase/firestore/lite";
import { FirebaseDB } from "../firebase/config";

export const loadBadgesCatalog = async () => {

    const q = query(
        collection(FirebaseDB, 'badges'),
    );

    const docs = await getDocs(q);

    const badge = [];
    docs.forEach(doc => {
        badge.push({ id: doc.id, ...doc.data() });
    })

    return badge;
}