import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: false,
    isSaving: false,
    comments: [],
    active: null
}

export const commentsSlice = createSlice({
    name: "comments",
    initialState,
    reducers: {
        savingNewComment: (state) => {
            state.isSaving = true
        },
        addNewComment: (state, action) =>{
            const newComment = action.payload;
            state.comments.push(newComment);
            state.isSaving = false;
        },
        setActiveComment : (state, action) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setComments: (state,action) =>{
            state.comments = action.payload
        },
        setSaving: (state) => {
            state.isSaving = true;
            state.messageSaved = '';
        }
    }
})

export const {
    savingNewComment,
    addNewComment,
    setActiveComment,
    setComments,
    setSaving
} = commentsSlice.actions