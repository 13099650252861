import React, { useEffect, useState } from 'react'
import { TakeEvaluation } from './TakeEvaluation';
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingEvaluations } from '../../store/slices/evaluation/thunks';
import { setActiveEvaluation } from '../../store/slices/evaluation/evaluationSlice';
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import { Quiz } from './Quiz';


export const EvaluationsEmployee = ({ onClick, onClickEvaluation, onClickCloseEvaluation, showBegin}) => {
    const dispatch = useDispatch();
    const { active } = useSelector(state => state.badgesCatalog);
    const { evaluations } = useSelector(state => state.evaluation);
    const [takeEvaluation, setTakeEvaluation] = useState(false);
    const [badgeEvaluations, setBadgeEvaluations] = useState([]);
    const allowEvaluation = showBegin;

    useEffect(() => {
        dispatch(startLoadingEvaluations());
    }, [])

    useEffect(() => {
        let tmpBadge = [];
        let id = active.id;
        if (evaluations.length > 0) {
            evaluations.map((item) => {
                let tmpBadge2 = item.badge.filter(x => x === id);
                if (tmpBadge2.length > 0) {
                    tmpBadge.push({ id: item.id, badges: item.badge });
                }
            })
        }
        let tmpData = evaluations.filter(x => tmpBadge.some(y => y.id === x.id));
        setBadgeEvaluations(tmpData);
    }, [evaluations])

    const takeEvaluations = (item) => {
        dispatch(setActiveEvaluation(item))
        setTakeEvaluation(true);
        onClickEvaluation();
    }

    const changeEvaluationState = () => {
        setTakeEvaluation(false);
        onClickCloseEvaluation();
    }

    const closeEvaluation = () => {
        onClick(true);
    }

    return (
        <>
            {takeEvaluation &&
                // <TakeEvaluation onClick={changeEvaluationState} />
                <Quiz onClick={changeEvaluationState} />
            }
            {!takeEvaluation &&
                <>
                    <div className='w-full flex   space-x-4 pt-10'>
                        <div className='w-full flex flex-row pr-20 pl-20'>
                            {badgeEvaluations.map((item, index) => (

                                <div key={index} className='w-1/3 flex flex-wrap'>
                                    <Card sx={{ minWidth: 280 }}>
                                        <CardMedia
                                            component="img"
                                            alt=""
                                            height="140"
                                            image="/static/images/cards/contemplative-reptile.jpg"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant='h5' component='div'>
                                                {item.evaluationName}
                                            </Typography>


                                            <Typography variant="body2" color="text.secondary">
                                                {item.description}
                                            </Typography>
                                        </CardContent>
                                        {allowEvaluation &&
                                        <CardActions sx={{ alignContent: 'end', justifyContent: 'end' }}>
                                            <Button onClick={() => takeEvaluations(item)} size="small">Iniciar</Button>
                                        </CardActions>
                                        }
                                    </Card>
                                </div>

                            ))}
                        </div>
                    </div>
                    {/* <div className='flex flex-col bg-white w-full place-items-center h-[780px] pl-20 pr-20'>
                        {badgeEvaluations.map((item, index) => (
                            <div key={index} className='w-10/12 flex flex-row pt-10' onClick={() => takeEvaluations(item)}>
                                <div className='w-full flex flex-row justify-center pt-6 h-20'>
                                    <div className='w-full flex flex-row bg-white rounded-3xl border opacity-90 border-[#818181]' >
                                        <div className='w-4/12 flex'>
                                            <div className='w-2/3 pt-4'>{item.evaluationName}</div>
                                        </div>
                                        <div className='w-3/12 flex'>
                                            <div className='w-full flex flex-row pt-2'>
                                                <img className="w-9 h-9" src="../assets/icons/clear-badge.png" />
                                                <img className="w-9 h-9 pl-1" src="../assets/icons/clear-badge.png" />
                                                <img className="w-9 h-9 pl-1" src="../assets/icons/clear-badge.png" />
                                                <img className="w-9 h-9 pl-1" src="../assets/icons/clear-badge.png" />
                                                <img className="w-9 h-9 pl-1" src="../assets/icons/clear-badge.png" />
                                            </div>
                                        </div>

                                        <div className='w-3/12 pl-6 pr-6'>
                                            <div className='flex flex-row w-full'>
                                                <div className='w-1/2'>
                                                    <div className='flex flex-row w-full'>
                                                        <p className="text-[#2B2C2B] font-['Poppins'] text-lg">Periodo</p>
                                                    </div>
                                                    <div className='flex flex-row w-full'>
                                                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-bold">{item.evaluationPeriod}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-3/12 pl-6 pr-6'>
                                            <div className='flex flex-row w-full'>
                                                <div className='w-1/2'>
                                                    <div className='flex flex-row w-full'>
                                                        <p className="text-[#2B2C2B] font-['Poppins'] text-lg">Validez</p>
                                                    </div>
                                                    <div className='flex flex-row w-full'>
                                                        <p className="text-[#0D497D] text-xs font-['Poppins'] font-bold">{item.evaluationAttempts[0]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </>
            }
        </>
    )
}
