import React, { useEffect, useState } from 'react';
import { Fab, FormControl, InputLabel, MenuItem, Select, TextField, Modal, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useForm, Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

const educationLevelData = [
    'Ingeniería',
    'Licenciatura',
    'Doctorado',
    'Técnico',
    'Maestría'
];

const universityData = [
    'Universidad San Carlos',
    'Universidad Francisco Marroquín',
    'Universidad Rafael Landivar',
    'Universidad Galileo',
    'Universidad Mariano Galvez'
];

const countrys = [
    { name: '🇬🇹 Guatemala', value: 'Guatemala' },
    { name: '🇺🇸 Estados Unidos', value: 'Estados Unidos' },
    { name: '🇲🇽 Mexico', value: 'Mexico' },
    { name: '🇨🇦 Canadá', value: 'Canadá' },
    { name: '🇭🇳 Honduras', value: 'Honduras' },
    { name: '🇸🇻 El Salvador', value: 'El Salvador' },
    { name: '🇳🇮 Nicaragua', value: 'Nicaragua' },
    { name: '🇨🇷 Costa Rica', value: 'Costa Rica' },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export const EducationEmployee = ({ getEducation, data }) => {
    const [education, setEducation] = useState([]);
    const [educationTmp, setEducationTmp] = useState(null);
    const [educationLevel, setEducationLevel] = useState([]);
    const [university, setUniversity] = useState('');
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const { t } = useTranslation();

    const { control, register, handleSubmit, setValue, formState: { errors } } = useForm({
        defaultValues: {
            title: "",
            location: "",
        }
    });

    useEffect(() => {
        if (data) {
            setEducation(data);
        }
    }, [data]);

    useEffect(() => {
        if (educationTmp) {
            setIsSaving(true);

            const {
                title,
                location
            } = educationTmp;

            const dataTmp = {
                title,
                location,
                institution: university,
                educationLevel: educationLevel,
                startDate: dayjs(startDate).format("DD/MM/YYYY"),
                endDate: dayjs(endDate).format("DD/MM/YYYY")
            };

            if (isEditing) {
                const updatedEducation = [...education];
                updatedEducation[editIndex] = dataTmp;
                setEducation(updatedEducation);
            } else {
                setEducation([...education, dataTmp]);
            }
        }
    }, [educationTmp]);

    useEffect(() => {
        if (education && isSaving) {
            saveEducation(education);
        }
    }, [education]);

    const saveEducation = (dataEducation) => {
        getEducation(dataEducation);
        limpiar();
        handleClose();
    };

    const handleChangeEducationLevel = (event) => {
        const { target: { value } } = event;
        setEducationLevel(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeUniversity = (event) => {
        const { value } = event.target;
        setUniversity(value);
    };

    const limpiar = () => {
        setValue('title', "");
        setStartDate(null);
        setEndDate(null);
        setUniversity('');
        setEducationLevel([]);
        setIsSaving(false);
        setIsEditing(false);
        setEditIndex(null);
    };

    const handleOpen = (index = null) => {
        if (index !== null) {
            const educationToEdit = education[index];
            setValue('title', educationToEdit.title);
            setUniversity(educationToEdit.institution);
            setEducationLevel(educationToEdit.educationLevel);
            setStartDate(dayjs(educationToEdit.startDate, "DD/MM/YYYY"));
            setEndDate(dayjs(educationToEdit.endDate, "DD/MM/YYYY"));
            setIsEditing(true);
            setEditIndex(index);
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleStartDateChange = (newValue) => {
        if (newValue && dayjs(newValue).isValid()) {
            setStartDate(newValue);
        } else {
            console.error("Formato de objeto newValue no válido:", newValue);
        }
    };

    const handleEndDateChange = (newValue) => {
        if (newValue && dayjs(newValue).isValid()) {
            setEndDate(newValue);
        } else {
            console.error("Formato de objeto newValue no válido:", newValue);
        }
    };

    const onSubmit = (data) => {
        setEducationTmp(data);
    };

    return (
        <>
            <div className='flex flex-row w-full mt-8 pl-6'>
                <div className='w-full text-left'>
                    <p className='inline-flex text-zinc-800 pt-1 font-bold text-[18px]'>{t('profileTabEmployee.education')}</p>
                </div>
                <div className='w-1/2 justify-end items-end place-content-end'>
                    <button type='button' className='w-44 h-8 bg-white border rounded-[32px] border-slate-800 text-slate-800 text-[16px] font-medium'
                        onClick={() => handleOpen()}>
                        + {t('profileTabEmployee.addEducation')}
                    </button>
                </div>
            </div>
            {(education.length > 0) &&
                <div className='w-full pt-8 justify-start items-start text-left pl-6'>
                    <table className='w-full'>
                        {education.map((education, index) => (
                            <tr key={index}>
                                <td className='text-gray-600 text-[16px] font-semibold '>{education.title}</td>
                                <td className='pl-4'>{education.institution}</td>
                                <td className='pl-4'>{education.educationLevel}</td>
                                <td className='pl-4'>{education.startDate && <Moment format='YYYY'>{dayjs(education.startDate, 'DD/MM/YYYY')}</Moment>}</td>
                                <td className='pl-4'>{education.endDate && <Moment format='YYYY'>{dayjs(education.endDate, 'DD/MM/YYYY')}</Moment>}</td>
                                <td><button className='' type='button' onClick={() => handleOpen(index)}><img className=" w-5 h-5" src="../assets/icons/edit.png" alt='' /></button></td>
                            </tr>
                        ))}
                    </table>
                </div>
            }
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full mt-2 pl-6 text-left'>
                        <div className='w-1/2 mt-4'>
                            <p className=' text-zinc-800 font-bold text-[18px]'>{t('profileTabEmployee.education')}</p>
                        </div>
                    </div>
                    <div className='flex flex-row mt-6 pl-4 pr-4'>
                        <TextField
                            id='Title'
                            className='w-full'
                            placeholder={t('educationEmployee.title')}
                            {...register("title")}
                        />
                    </div>
                    <div className='flex flex-row mt-6 pl-4 justify-between'>
                        <div className='w-4/12'>
                            <FormControl className='w-11/12'>
                                <TextField
                                    id="institution"
                                    label={t('educationEmployee.institution')}
                                    value={university}
                                    onChange={handleChangeUniversity}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                />
                            </FormControl>
                        </div>
                        <div className='w-4/12'>
                            <FormControl className='w-11/12'>
                                <InputLabel id="level-label">{t('educationEmployee.country')}</InputLabel>
                                <Select
                                    labelId="job-location-label"
                                    id="job-location"
                                    label={t('educationEmployee.country')}
                                    placeholder={t('educationEmployee.country')}
                                    defaultValue=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    {...register("location")}
                                >
                                    {countrys.map((name) => (
                                        <MenuItem key={name.value} value={name.value}>
                                            {name.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='w-4/12'>
                            <FormControl className='w-11/12'>
                                <InputLabel id="level-label">{t('educationEmployee.level')}</InputLabel>
                                <Select
                                    labelId="level-label"
                                    id="level"
                                    label={t('educationEmployee.level')}
                                    placeholder={t('educationEmployee.level')}
                                    defaultValue=""
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleChangeEducationLevel}
                                    value={educationLevel}
                                >
                                    {educationLevelData.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='flex flex-row mt-6  pl-6'>
                        <div className='w-4/12'>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Controller
                                    control={control}
                                    name="startDate"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            value={startDate}
                                            onChange={(date) => handleStartDateChange(date)}
                                            className='w-11/12'
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='w-4/12'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    control={control}
                                    name="endDate"
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            value={endDate}
                                            onChange={(date) => handleEndDateChange(date)}
                                            className='w-11/12'
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className='flex flex-row mt-20 justify-end'>
                        <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleSubmit(onSubmit)}>{t('pagesEmployee.save')}</button>
                    </div>
                </Box>
            </Modal>
        </>
    );
};
