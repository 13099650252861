import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { formUpdateProfile } from '../store/slices/profile/thunks';

export const PersonalInformation = ({ onClick }) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [rol, setRol] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const dispatch = useDispatch()
    const {profile} = useSelector(state => state.profile)
    const id = profile[0].id

    const submit = async () => {
        if (name.length > 0) {
            onClick('3');
            const formdata = {
                name,
                rol,
                phone
            }
            await dispatch(formUpdateProfile({ id: id, formdata: formdata }));
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error...',
                text: 'Por favor revisa los datos ingresados'
            })
        }
    }

    return (
        <>
            <div className='flex flex-col bg-white w-full place-items-center h-[780px]'>
                <div className='flex flex-col w-4/12 my-14'>
                    <div className="flex flex-row w-full justify-between mb-5 gap-20">
                        <div className="w-9/12 font-['Poppins'] text-gray-900  whitespace-nowrap text-2xl font-bold">Personal Information</div>
                        <div className="w-3/12 font-['Poppins'] text-2xl font-bold text-gray-400 relative">2/3</div>
                    </div>
                    <div className='flex flex-col w-full my-4'>
                        <input className='w-full rounded-lg indent-3' type='text' value={name} onChange={(e) => setName(e.target.value)} placeholder='Name' style={{ border: '1px solid #D7DEE1', height: '56px' }} />
                    </div>
                    <div className='flex flex-col w-full my-2'>
                        <select className='w-full rounded-lg indent-3' type='text' defaultValue={'1'} onChange={(e) => setRol(e.target.value)} placeholder='Rol' style={{ border: '1px solid #D7DEE1', height: '56px' }}>
                            <option value="1" disabled>Rol</option>
                            <option value="Reclutador">Reclutador</option>
                            <option value="Recruso-Humanos">Recursos Humanos</option>
                            <option value="SP">Sleccion de Personal</option>
                        </select>
                    </div>
                    <div className='flex flex-col w-full my-4'>
                        <input className='w-full rounded-lg indent-3' type='text' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder={t('tellus.phone')} style={{ border: '1px solid #D7DEE1', height: '56px' }} />
                    </div>
                    <div className='my-2'>
                        <button className=' h-14 bg-[#0D497D] rounded-full w-full text-white' type='submit' onClick={() => submit()}>{t('tellus.continue')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
