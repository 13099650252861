import { Button, Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { EvaluationsEmployee } from './EvaluationsEmployee';
import { BadgesByCategory } from '../../components/Employee/Badges/BadgesByCategory';
import { setActiveBadgesCatalog } from '../../store/slices/badgesCatalog/badgesCatalogSlice';
import { useTranslation } from 'react-i18next'
import { BadgePreview } from '../../components/Employee/Badges/BadgePreview';
import { startLoadingBadgeCategories } from '../../store/slices/badgesCategories/thunks';
import { startLoadingBadgesCatalog } from '../../store/slices/badgesCatalog/thunks';
import { startLoadingEarnBadge } from '../../store/slices/earnBadge/thunks';
import { BadgePreviewByCategory } from '../../components/Employee/Badges/BadgePreviewByCategory';

export const BadgeEmployee = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { badgesCatalogs } = useSelector(state => state.badgesCatalog);
    const { badgesCategories } = useSelector(state => state.badgesCategorie);
    const { earnBadges } = useSelector(state => state.earnBadge)
    const totalEarnBadges = earnBadges.length;
    const [points, setPoints] = useState('0');
    const [evaluation, setEvaluation] = useState(false);
    const { profile } = useSelector(state => state.profile);
    const [page, setPage] = useState({});
    const [basicFitBadges, setBasicFitBadges] = useState([]);
    const [compatibilityBadges, setCompatibilityBadges] = useState([]);
    const [skillBadges, setSkillBadges] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [showPreview2, setShowPreview2] = useState(false);
    const [myEarnBadges, setMyEarnBadges] = useState([]);
    const [enableStart, setEnableStart] = useState(false);

    useEffect(() => {
        dispatch(startLoadingBadgeCategories());
        dispatch(startLoadingBadgesCatalog());
        dispatch(startLoadingEarnBadge());
    }, [])

    useEffect(() => {
        let tmpBadges = [];
        let tmpEarnBadges = [];
        tmpEarnBadges = badgesCatalogs.filter(x => earnBadges.some(y => y.id_badge == x.id));
        setMyEarnBadges(tmpEarnBadges);
        tmpBadges = badgesCatalogs.filter(x => x.id)
    }, [badgesCatalogs])

    useEffect(() => {

    }, [myEarnBadges])

    useEffect(() => {
        let tmpBasicFit = [];
        let tmpSkillAssesment = [];
        let tmpCompatility = [];
        if (badgesCatalogs && badgesCategories) {
            let tmpBasicFitCategorie = badgesCategories.filter(x => x.name === 'Basic Fit');
            let tmpSkillAssesmentCategorie = badgesCategories.filter(x => x.name === 'Skill-Assessment');
            let tmpCompatibilityCategorie = badgesCategories.filter(x => x.name === 'Compatibility');

            if (tmpBasicFitCategorie.length > 0) {
                tmpBasicFit = badgesCatalogs.filter(x => x.categorie === tmpBasicFitCategorie[0].id);
                setBasicFitBadges(tmpBasicFit);
            }
            if (tmpBasicFitCategorie.length > 0) {
                tmpSkillAssesment = badgesCatalogs.filter(x => x.categorie === tmpSkillAssesmentCategorie[0].id);
                setSkillBadges(tmpSkillAssesment);
            }
            if (tmpBasicFitCategorie.length > 0) {
                tmpCompatility = badgesCatalogs.filter(x => x.categorie === tmpCompatibilityCategorie[0].id);
                setCompatibilityBadges(tmpCompatility);
            }
        }
    }, [badgesCatalogs])

    useEffect(() => {
        if (profile) {
            setPage(profile[0].pages);
        }
    }, [profile])

    const goEvaluations = () => {
        setEvaluation(true);
    }

    const changeToBadges = () => {
        setEvaluation(false);
    }

    const getBadgeDetail = (item) => {
        const {
            id,
            categorie,
            imageUrl,
            name,
            description,
            creationDate,
            companyId
        } = item;

        dispatch(setActiveBadgesCatalog({ id, name, description, imageUrl, categorie, creationDate, companyId }));
        setShowPreview(true);
    }

    const changePreviewBadge = () => {
        setShowPreview(false);
        setShowPreview2(false);
    }

    const changePreviewBadge2 = () => {
        setShowPreview2(true);
    }

    return (
        <>
            {/* {evaluation &&
                <EvaluationsEmployee onClick={changeToBadges} />
            }
             */}
            {showPreview &&
                <BadgePreview onClick={changePreviewBadge} enableStart={enableStart}/>
            }
            {showPreview2 &&
                <BadgePreviewByCategory onClick={changePreviewBadge} enableStart={true}/>
            }
            {!showPreview && !showPreview2 &&
                <>
                    <div className='flex flex-col bg-white w-full place-items-center min-h-[720px] pl-4 pt-6'>
                        <div className='flex flex-row w-full mt-12 pl-2'>
                            <div className='flex flex-1 w-full'>
                                <div className='w-3/12'>
                                    <div className='border-r h-2/5'>
                                        <div className='flex flex-row'>
                                            {page.profileImageUrl &&
                                                <img className="w-48 h-48 mx-auto rounded-full object-cover" src={page.profileImageUrl} />
                                            }
                                            {!page.profileImageUrl &&
                                                <img className="w-48" src="../assets/images/profile_default.png" />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='w-9/12 flex flex-wrap pl-6 pr-6 space-x-2'>
                                    <div className='w-1/12'></div>
                                    <div className='w-3/12'>
                                        <Card className='h-[150px] hover:transition ease-in-out delay-150 bg-blue-400 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-400 duration-300 ...'
                                        >
                                            <CardHeader
                                                title={t('badgesEmployee.myBadges')}
                                            />
                                            <CardContent>
                                                <Typography color="green" sx={{ fontSize: 40, paddingTop: 0 }}>
                                                    {totalEarnBadges}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <div className='w-3/12'>
                                        <Card className='h-[150px] hover:transition ease-in-out delay-150 bg-green-400 hover:-translate-y-1 hover:scale-110 hover:bg-green-400 duration-300 ...'
                                        >
                                            <CardHeader
                                                title={t('badgesEmployee.evaluations')}
                                            />
                                            <CardContent>
                                                <Typography color="green" sx={{ fontSize: 40, paddingTop: 0 }}>
                                                    {totalEarnBadges}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <div className='w-3/12'>
                                        <Card className='h-[150px] hover:transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...'
                                        >
                                            <CardHeader
                                                title={t('badgesEmployee.points')}
                                            />
                                            <CardContent>
                                                <Typography color="green" sx={{ fontSize: 40, paddingTop: 0 }}>
                                                    {points}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <div className='w-1/12'></div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col bg-white w-full place-items-center  pt-20'>
                            <div className='w-full flex flex-row'>
                                <p className="absolute text-2xl font-bold text-gray-800 ml-1 mt-[38px]" style={{ left: 86 }}>{t('badgesEmployee.myEarn')}</p>
                            </div>
                            <div className='w-full flex flex-row pt-20 pl-10 pr-10 overflow-scroll'>

                                {myEarnBadges.map((item, index) => (
                                    <div className='w-1/4' key={index}>
                                        <div className='w-full flex flex-wrap'>
                                            <div className='w-full h-[340px] pl-4'>
                                                <Button onClick={() => getBadgeDetail(item)}>
                                                    <Card className=' w-56 h-[230px] hover:transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ...'>
                                                        <CardHeader
                                                            title={item.name}
                                                        />
                                                        <div className='w-full flex justify-center place-content-center items-center text-center'>
                                                            <CardMedia
                                                                component="img"
                                                                // height="154"
                                                                sx={{ height: 140, width: 140, justifyContent: 'center', textAlign: 'center', alignContent: 'center', placeContent: 'center' }}
                                                                image={item.imageUrl}
                                                            />
                                                        </div>
                                                    </Card></Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <div className='flex flex-col bg-white w-full place-items-center'>
                        <div className='w-full flex flex-row'>
                                <p className="absolute text-2xl font-bold text-gray-800 ml-1 mt-[38px]" style={{ left: 86 }}>{t('badgesEmployee.category')}</p>
                            </div>
                            <div className='w-full flex flex-row'>
                                <BadgesByCategory onClick={changePreviewBadge2} onClick2={changePreviewBadge}/>
                            </div>
                        </div>
                    </div>
                </>
            }

        </>
    )
}
