import React, { useState } from 'react'
import Swal from 'sweetalert2';
import { TellUsAboutEmployer } from '../components/TellUsAboutEmployer';
import { CompleteDetailsEmployer } from '../components/CompleteDetailsEmployer';
import { PersonalInformation } from '../components/PersonalInformation';


export const UpdateEmployerProfile = ({ updateState }) => {
    const [step, setStep] = useState('1');

    const changeStep = (value) => {
        setStep(value);
    }

    return (
        <>
            {(step == '1') && <TellUsAboutEmployer onClick={changeStep} />}
            {(step == '2') && <PersonalInformation onClick={changeStep} />}
            {(step == '3') && <CompleteDetailsEmployer onFinish={updateState} />}

        </>
    )
}