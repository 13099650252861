import { FirebaseDB } from "../../../firebase/config";
import { loadMessages } from "../../../helpers/loadMessages";
import { savingNewMessage, addNewEmptyMessage, setActiveMessage, setMessages, updateMessage, setSaving } from "./messagesSlice"
import { collection, doc, setDoc, getDoc } from 'firebase/firestore/lite';

export const startNewMessage = (message) => {
    return async (dispatch, getState) => {
        dispatch(savingNewMessage());

        const { uid } = getState().auth;
        const {
            messages,
            uidSender,
            uidReceived,
            senderProfileImage
        } = message;

        const newMessage = {
            messages,
            date: new Date().getTime(),
            uidSender,
            uidReceived,
            senderProfileImage
            //time
        }

        const newDoc = doc(collection(FirebaseDB, `message`))
        await setDoc(newDoc, newMessage);
        newMessage.id = newDoc.id;
        dispatch(addNewEmptyMessage(newMessage));
        dispatch(setActiveMessage(newMessage))

    }
}

export const startLoadingMessages = () => {
    return async (dispatch, getState) => {
        const { uid } = getState().auth;

        if (!uid) throw new Error("El UID del usuario no existe");
        const messages = await loadMessages(uid)
        dispatch(setMessages(messages))
    }
}


export const startSaveMessage = () => {
    return async (dispatch, getState) => {

        dispatch(setSaving());

        const { uid } = getState().auth;
        const { active: message } = getState().message;

        const profileToFireStore = { ...message };
        delete profileToFireStore.id;

        const docRef = doc(FirebaseDB, `message/${message.id}`)
        await setDoc(docRef, profileToFireStore, { merge: true });
        dispatch(updateMessage(message));
    }
}

