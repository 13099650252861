import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs, Modal, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import { ProfileTabEmployee } from '../../components/Employee/ProfileTabEmployee';
import { PreviewProfile } from '../../components/Employee/PreviewProfile';
import { BillingEmployee } from '../../components/Employee/BillingEmployee';
import { AccountSettings } from './AccountSettings';
import { useForm } from 'react-hook-form';
import { setActiveJob } from '../../store/slices/job/jobSlice';
import { useSelector, useDispatch } from 'react-redux';
import { startLoadingProfile } from '../../store/slices/profile/thunks';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 0,
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export const ProfileEmployee = () => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);
    const [previewProfile, setPreviewProfile] = useState(false);
    const { profile } = useSelector(state => state.profile);
    const { jobs } = useSelector(state => state.job);
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const [jobDetail, setJobDetail] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {

    }, [previewProfile])

    const { register, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            name: "",
            joblocation: "",
            startDate: "",
            endDate: ""
        }
    });

    const onSubmit = () => {

    }

    const jobTitleResult = (type) => {
        let typeTmp = '';
        if (type === 'FS') {
            typeTmp = 'Full Stack Developer'
        }
        if (type === 'PM') {
            typeTmp = 'Project Manager'
        }
        if (type === 'FD') {
            typeTmp = 'Front End Developer'
        }
        if (type === 'BD') {
            typeTmp = 'Back End Developer'
        }
        if (typeTmp === '') {
            typeTmp = type;
        }
        return (
            <>
                <p className="absolute text-base font-medium text-gray-800" style={{ left: 86, top: 33, }}>{typeTmp}</p>
            </>
        )
    }

    const handleOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    const changetoUpdateProfile = () => {
        setPreviewProfile(false);
    }

    const changetoPreviewProfile = () => {
        setPreviewProfile(true);
    }

    useEffect(() => {
        dispatch(startLoadingProfile());
    }, [previewProfile])

    const onSelectJob = (id, companyName, maximum, minimum, customJobTitle, jobModel, jobtitle, jobType, description, joblocation, tagsJob, department, urlVideo) => {
        dispatch(setActiveJob({ id, companyName, maximum, minimum, customJobTitle, jobModel, jobtitle, jobType, description, joblocation, tagsJob, department, urlVideo }));
        setJobDetail(true);
    }

    return (
        <>
            <div className='flex flex-col bg-white w-full place-items-center min-h-[720px] pl-4 pt-6'>
                <Box sx={{ width: '100%' }}>
                    {/* <div className="text-zinc-800 text-[18px] font-bold">Profile</div> */}
                    <Box sx={{ fontFamily: 'Poppins', fontSize: 18 }}>
                        <Tabs value={value} onChange={handleChange}
                            // aria-label="basic tabs example"
                            TabIndicatorProps={{ sx: { backgroundColor: '#FF7F11', height: 2 } }}
                            sx={{
                                "& button": { color: '#77A7C1', fontFamily: 'Poppins', textTransform: 'none', fontWeight: '600', fontSize: 18, wordWrap: 'break-word' },
                                "& button:hover": { color: '#2B2C2B', fontFamily: 'Poppins', textTransform: 'none', fontWeight: '600', fontSize: 18, wordWrap: 'break-word' },
                                "& button:focus": { color: '#2B2C2B', fontFamily: 'Poppins', textTransform: 'none', fontWeight: '600', fontSize: 18, wordWrap: 'break-word' }
                            }}>
                            <Tab label="Profile" {...a11yProps(0)} />
                            {/* <Tab label="References" {...a11yProps(1)} /> */}
                            {/* <Tab label="Job Alerts" {...a11yProps(2)} /> */}
                            {/*<Tab label="Billing" {...a11yProps(1)} />*/}
                            {/* <Tab label="Account Settings" {...a11yProps(4)} /> */}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {!previewProfile &&
                            <PreviewProfile onClick={changetoPreviewProfile} data={profile} />
                        }
                        {previewProfile &&
                            <ProfileTabEmployee onClick={changetoUpdateProfile} data={profile} />
                        }
                    </TabPanel>
                    {/* <TabPanel value={value} index={1}>
                        <div className='w-full flex flex-row items-start min-h-[600px]'>
                            <div className="w-52 h-64 px-14 py-20 rounded--lg border border-zinc-400 flex-col justify-center  gap-0.5 inline-flex">
                                <div className="flex  flex-row text-center justify-center text-sky-900 text-lg font-medium">Add<br />Reference</div>
                                <div className='flex flex-row text-center justify-center items-center w-full'>
                                    <button type='button' onClick={handleOpen}><img className="w-6 h-6 relative flex" src='../assets/icons/plus-circle.png' /></button>
                                </div>
                            </div>
                            <div className='pl-8'>
                                <div className="w-52 h-64 relative rounded--lg border border-zinc-400">
                                    <img className="w-20 h-20 left-[61px] top-[24px] absolute rounded--full" src="../assets/icons/Howard.png" />
                                    <div className="w-40 left-[25px] top-[147px] absolute text-center text-gray-600 text-xs font-normal">Company<br />Job Title<br />Work Relationship<br />Phone number<br />E-mail</div>
                                    <div className="left-[27px] top-[116px] absolute text-zinc-800 text-base font-medium">Andres Thompson</div>
                                    <div className="w-6 h-6 left-[13px] top-[10px] absolute" />
                                    <div className="w-6 h-6 left-[163px] top-[10px] absolute">
                                        <div className="w-6 h-6 left-0 top-0 absolute bg-lime-400 rounded-full border-2 border-lime-400" />
                                        <div className="w-3.5 h-3.5 left-[5px] top-[5px] absolute" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel> */}
                    {/* <TabPanel value={value} index={2}>
                        {jobs.map((item, index) =>
                            <div className='flex flex-row w-full justify-center pt-4' key={index} onClick={() => onSelectJob(item.id, item.companyName, item.maximum, item.minimum, item.customJobTitle, item.jobModel, item.jobtitle, item.jobType, item.description, item.joblocation, item.tagsJob, item.department, item.urlVideo)}>
                                <div className="w-11/12 h-20 opacity-90 bg-white rounded-full border border-[#818181]">
                                    <div className='w-1/12 relative'>
                                        <div className="absolute" style={{ width: 56, height: 56, left: 10, top: 10, }}>
                                            <div className="w-14 h-14 bg-gray-100 rounded-full" />
                                            <img className="w-11 h-4 absolute m-auto inset-0" src="../assets/icons/company.png" />
                                        </div>
                                    </div>
                                    <div className='relative w-3/12'>
                                        <p className="absolute text-xs text-gray-600" style={{ left: 86, top: 20, }}>{item.companyName}</p>
                                        {jobTitleResult(item.jobtitle)}
                                        <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 absolute" style={{ left: 286, top: 14, }}></div>
                                    </div>

                                    <div className='w-3/12 relative'>
                                        <p className="absolute w-full text-xs font-medium text-zinc-800" style={{ left: 286, top: 20, }}>60% Advanced</p>
                                        <p className="text-gray-600 w-full text-xs font-normal absolute pt-2" style={{ left: 286, top: 30, }}>2 badges missed</p>
                                    </div>
                                    <div className='w-3/12 relative'>
                                        <div className="inline-flex space-x-2.5 items-center justify-end w-48 h-8 absolute" style={{ left: 532, top: 24, }}>
                                            <img className="w-1/5 h-full" src="../assets/icons/clear-badge.png" />
                                            <img className="w-7 h-full" src="../assets/icons/clear-badge.png" />
                                            <img className="w-8 h-full" src="../assets/icons/clear-badge.png" />
                                            <img className="w-7 h-full" src="../assets/icons/clear-badge.png" />
                                            <img className="w-8 h-full" src="../assets/icons/clear-badge.png" />
                                        </div>
                                        <div className="w-12 h-px origin-top-left rotate-90 border border-neutral-300 absolute" style={{ left: 786, top: 14, }}></div>
                                    </div>
                                    <div className='w-2/12 relative'>
                                        <div className='inline-flex space-x-2.5 items-center absolute' style={{ left: 882, top: 24 }}>
                                            <div className='border rounded-full w-7 h-7'><img className='w-6 h-6' src='../assets/icons/star.png' alt='' /></div>
                                            <button className="text-slate-800 w-28 h-8 text-sm font-normal rounded-3xl border border-slate-800">Job Details</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )}

                    </TabPanel> */}
                   {/*} <TabPanel value={value} index={1}>
                        <BillingEmployee />
                    </TabPanel>*/}
                    {/* <TabPanel value={value} index={4}>
                        <AccountSettings />
                    </TabPanel> */}
                </Box>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='flex flex-row w-full text-left'>
                        {/* <div className='w-full mt-4'> */}
                        <div className="w-full h-16 bg-zinc-100 rounded-tl-lg pl-6 pt-4 rounded-tr-lg">
                            <p className=' text-zinc-800 font-bold text-[18px]'>Request Work Reference</p>
                        </div>
                    </div>
                    <div className='flex flex-row mt-4 pl-6'>
                        <div className='w-full'>
                            <TextField
                                id='Company'
                                className='w-11/12'
                                placeholder='Email(s)'
                            />
                        </div>
                    </div>
                    <div className='flex flex-row pl-6 pt-4'>
                        <div className='w-full'>
                            <TextField
                                id='Job Title'
                                multiline
                                rows={4}
                                className='w-11/12'
                                placeholder='Comments'
                            />
                        </div>
                    </div>

                    <div className='flex flex-row mt-10 justify-center'>
                        {/* <button type="submit" className='w-3/12 h-10 bg-sky-900 text-white rounded-full font-medium' onClick={handleSubmit(onSubmit)}>Save</button> */}
                        <div className="w-68 h-12 pl-8 pr-7 py-1 bg-orange-500 rounded-3xl justify-center items-center gap-2.5 inline-flex">
                            <button type='button' className="text-white text-base font-medium">Request Reference</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
