import React, { useEffect } from 'react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { startLoadingMessages, startNewMessage, startSaveMessage } from '../store/slices/messages/thunks';
import { setActiveMessage } from '../store/slices/messages/messagesSlice';

export const SendMessage = ({ sendUpdate, data }) => {
    const { uid, displayName, photoURL } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const { messages } = useSelector(state => state.message);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm({
        defaultValues: {
            summary: ""
        }
    });

    //Agregar validacion y que separe la imagen de quien envia y quien recibe (si es mi perfil, que agrege mi foto, sino que agregue la del employee).
    //Replicar funcionalidad de sendMessage en el cuadro para enviar un mensaje para que funcione de la misma forma.

    useEffect(() => {
        dispatch(startLoadingMessages())
    }, [])
  
    console.log("data on submit", data);
    const onSubmit = (tmpData) => {
        //console.log("variable desde firebase", messages)
        //console.log("data dentro del on submit", tmpData);
        let tmpConversation = messages.filter(x => x.uidSender === uid || x.uidReceived === uid); 
        let tmpMessages = tmpConversation.filter(x => x.uidSender === data.id || x.uidReceived === data.id); 
        //console.log("Print tmpMessages", tmpMessages);

        if(tmpMessages.length < 1){
            const dataMessage = {
                uidSender: uid,
                uidReceived: data.id,
                messages: [{message: tmpData.summary, date: new Date().getTime(), uidSender: uid, uidReceived: data.id, senderName: displayName, senderProfileImage: data.image}],
                senderName: displayName,
                senderProfileImage: data.image
              }
            //console.log("data Message dentro", dataMessage)
            dispatch(startNewMessage(dataMessage))
            sendUpdate();
        } else {
            let extractMessages = [];
            //extractMessages = tmpMessages[0].messages;
            tmpMessages[0].messages.map((item) => {
                extractMessages.push(item)
            })
            extractMessages.push({message: tmpData.summary, date: new Date().getTime(), uidSender: uid, uidReceived: data.id, senderName: displayName, senderProfileImage: data.image});
            //console.log("nueva variable en el else", extractMessages);
            const newData = {
                id: tmpMessages[0].id,
                messages: extractMessages
            }
            dispatch(setActiveMessage(newData));
            dispatch(startSaveMessage());
            sendUpdate();
        }
    }

    const onReject = (data) => {
        console.log('data', data);
    }

    useEffect(() => {
        
    }, [data])

    return (
        <>
            <div className="w-full h-24 bg-zinc-100 flex">
                <div className='p-4'>
                    <img className="w-14 h-14 rounded-full" src={data?.image} />
                </div>
                <div className='pt-6'>
                    <div className="text-slate-800 text-lg font-bold font-['Poppins']">Enviar Mensaje</div>
                    <div className="text-gray-600 text-sm font-medium font-['Poppins']">{data?.name}</div>
                </div>
            </div>
            <div className='w-full'>
                <div>
                    <div className='w-full pt-4 pl-10 pr-4'>
                        <p className="text-gray-600 text-sm font-medium font-['Poppins']">Envía un mensaje al candidato, he inicia una conversacion con él. </p>
                    </div>
                    <div className='flex flex-row mt-4 pl-6'>
                        <div className='w-full pl-6 pr-6'>
                            <TextField
                                multiline
                                rows={4}
                                placeholder='Agregar Descripcion de Mensaje'
                                className='w-full'
                                {...register("summary")}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='w-full justify-center items-center text-center pt-8'>
                            <button className="w-72 h-14 px-8 py-1 bg-sky-900 rounded-3xl justify-center items-center gap-2.5 inline-flex text-white text-base font-medium font-['Poppins']" type="onSubmit" onClick={handleSubmit(onSubmit)}>
                                Send Message
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
