import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    page: 0,
    isLoading: false,
    messageSaved: '',
    isSaving: false,
    activejobs: [],
    active: null
}

export const activeJobsSlice = createSlice({
    name: 'activeJob',
    initialState,
    reducers: {
        updateActiveJob: (state, action) => {

        },
        setActiveJobs: (state, action) => {
            state.activejobs = action.payload;
        }
    }
})

export const {
    updateActiveJob,
    setActiveJobs
} = activeJobsSlice.actions